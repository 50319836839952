const template = {
  specificationHeader: 'Product Features',
  headerLink: id => `/product/${id}`,
  headerText: 'Read more',
  freeDelivery: 'Free delivery available',
  eligibleDelivery: 'Free delivery available',
  collectInStore: 'Eligible for store collection',
  installAndRecycle: 'Installation & recycling available',
  yesLabel: 'Yes',
  pageTypeBrowse: 'browse',
  pageTypeSearch: 'search',
  browseAddToTrolleyOrigin: 'PLP:productCard',
  searchAddToTrolleyOrigin: 'SLP:productCard',
  clothingLayout: 'clothing',
  fashionTemplate: 'fashion',
  whiteGoodTemplate: 'white goods',
  defaultTemplate: 'default',
  searchRelevancyHeader: 'See more related results for',
  hasFulfillmentOption: (attributes, fulfillmentOption) => {
    const { variants, definingAttributes } = attributes

    return (
      (variants &&
        variants[fulfillmentOption] &&
        variants[fulfillmentOption].find(att => att.variantLabel === template.yesLabel)) ||
      (definingAttributes &&
        definingAttributes[fulfillmentOption] &&
        definingAttributes[fulfillmentOption].find(att => att.label === template.yesLabel))
    )
  },
  secondaryDataArray: attributes => {
    const secondaryData = []

    const freeDelivery = attributes.freeDelivery && attributes.deliverable
    const eligibleDelivery = !attributes.freeDelivery && attributes.deliverable
    const collectionInStore = attributes.reservable && template.hasFulfillmentOption(attributes, 'serviceStorePickup')
    const installAndRecycle = template.hasFulfillmentOption(attributes, 'serviceInstallation')

    if (freeDelivery) {
      secondaryData.push(template.freeDelivery)
    }
    if (!freeDelivery && eligibleDelivery) {
      secondaryData.push(template.eligibleDelivery)
    }
    if (collectionInStore) {
      secondaryData.push(template.collectInStore)
    }
    if (installAndRecycle) {
      secondaryData.push(template.installAndRecycle)
    }
    return secondaryData
  }
}

export default template
