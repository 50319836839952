export default {
  searchTermHeaderStart: `Sorry, we can't find any results for "`,
  searchTermHeaderEnd: `"`,
  searchTermSubheader: 'Please search again, or browse our product categories:',
  simpleHeader: `Sorry, we can't find the page you were looking for`,
  simpleSubheader: `But while you're here, why not take a look around?`,
  exploreHeader: 'Explore',
  bestSellers: `Best sellers right now`,
  featuredProducts: `We think you'll like`
}
