import { getBrowsePath } from '@sainsburys-tech/boltui-utils'
import { CategoryHelper } from '../helpers/CategoryHelper'
import Logger from '../utils/Logger'
import TotalRequestHelper from '../metrics/TotalRequestHelper'
import { ServiceHelper } from '../helpers/ServiceHelper'
import { BrowseHelper } from '../helpers/BrowseHelper'
import paramsParser from '../helpers/ParamsParser'
import { ProductRequestHelper } from '../helpers/ProductRequestHelper'
import { ProductResponseHelper } from '../helpers/ProductResponseHelper'
import config from '../config'
import { citrusCategoryProductHandler } from '../helpers/CitrusHelper'
import { getContent } from '../redux/cms/getContent'
import {
  loadingStart,
  loadingEnd,
  updatePageType,
  contentFailure,
  resetAvailabilityError,
  setCollectionStoresError
} from '../redux/application/application'
import { isCitrusEnabled } from '../utils/isCitrusEnabled'
import { PAGE_TYPES } from '../utils/pageTypes'
import { failedBrowse } from '../redux/category/category'

const logger = new Logger()
const totalRequest = TotalRequestHelper.getInstance().getTotalRequest()
const statusCodes = config.httpStatusCodes

export default function(context, payload, cb, ...args) {
  let data
  let queryParams = payload.query
  const categoryId = payload.params.category ? payload.params.category.replace('c:', '') : ''

  const pathParams = payload.params.path || ''
  const { page, sort, refinements } = paramsParser.getParams(pathParams)
  const urlRefinements = paramsParser.getParam(pathParams, 'refinements')
  // Invalid refinement values that don't have values in the URL, e.g. abcdef in /browse/technology/c:29949/abcdef/
  const hasInvalidRefinements = refinements?.length && refinements.some(refinement => !refinement.value)

  const { isClearance, isSaleRoute } = payload
  const pageType = PAGE_TYPES.BROWSE

  const done = () => {
    context.reduxStore.dispatch(loadingEnd())
    context.reduxStore.dispatch(updatePageType({ pageType, isClearance, isSaleRoute, urlRefinements }))
    cb()
  }

  logger.debug('functionLogger', { args }, 'executeBrowse')
  totalRequest.incrementHttpBrowseRequest(1)

  context.reduxStore.dispatch(resetAvailabilityError())
  context.reduxStore.dispatch(setCollectionStoresError(false))
  context.reduxStore.dispatch(loadingStart())

  if (hasInvalidRefinements) {
    context.reduxStore.dispatch(failedBrowse({ status: 404 }))
    done()
  }

  if (BrowseHelper.didRosettaModifyRequest(queryParams)) {
    const redirectTaxonomyRequest = ServiceHelper.buildRedirectTaxonomyServiceRequest({ categoryId })
    context.service.read(redirectTaxonomyRequest.service, redirectTaxonomyRequest.params, {}, function(
      err,
      redirectTaxonomyResponse,
      meta
    ) {
      if (!redirectTaxonomyResponse || !redirectTaxonomyResponse.response) {
        return BrowseHelper.handleBadBrowseResponse(redirectTaxonomyResponse, meta, context, done, err)
      }
      delete queryParams.rosetta
      const queryString = BrowseHelper.buildQueryString(queryParams)
      const response = redirectTaxonomyResponse.response.data
      const browseResponse = BrowseHelper.getBrowseParamsFromBreadcrumbResponse(response)
      const browseParams = {
        currentCatName: browseResponse.currentCatName,
        ancestors: browseResponse.ancestors,
        currentCatId: browseResponse.currentCatId,
        isClearance,
        isSale: isSaleRoute
      }
      const validCategories = BrowseHelper.buildCorrectUrlBrowseParams(browseParams)

      const redirectData = BrowseHelper.constructRedirectData(
        getBrowsePath(validCategories),
        pathParams,
        queryString,
        false
      )
      const statusCode = BrowseHelper.determineRedirectStatusCode(browseResponse.isCategoryLive)

      BrowseHelper.redirectTo(redirectData, context, statusCode)
      return done()
    })
  } else {
    const payloadPath = payload.path
    const categoryRequest = ServiceHelper.buildCategoryServiceRequest({ categoryId, payloadPath }, context)
    const promises = []
    if (!isClearance) {
      const contentServiceRequest = ServiceHelper.buildContentServiceRequest(
        { categoryId, payloadPath },
        context,
        urlRefinements
      )
      promises.push(
        new Promise(resolve => {
          context.service.read(contentServiceRequest.service, contentServiceRequest.params, {}, function(
            err,
            contentServiceResponse
          ) {
            let action = {}
            if (!contentServiceResponse || !contentServiceResponse.response || err) {
              action = {
                payload: {
                  status: statusCodes.serviceUnavailable
                },
                reduxAction: payload =>
                  Promise.resolve()
                    .then(() =>
                      context.reduxStore.dispatch(
                        getContent({ error: 'Failed Content Service', status: statusCodes.serviceUnavailable })
                      )
                    )
                    .then(() => context.reduxStore.dispatch(contentFailure(payload)))
              }
              return resolve(action)
            }
            action = {
              payload: contentServiceResponse,
              reduxAction: contentServiceRequest.reduxAction
            }
            context.reduxStore.dispatch(getContent({ ...contentServiceResponse, urlRefinements }))
            resolve(action)
          })
        })
      )
    }
    promises.push(
      new Promise((resolve, reject) => {
        context.service.read(categoryRequest.service, categoryRequest.params, {}, function(
          err,
          categoryResponse,
          meta
        ) {
          if (err) return resolve(ServiceHelper.handleBrowseRequest(err, categoryResponse, context))
          const queryString = BrowseHelper.buildQueryString(queryParams)
          const currentBrowseUrl = !!payload && !!payload.path && BrowseHelper.stripUneededRefinements(payload.path)
          const correctBrowseUrl =
            !!categoryResponse &&
            !!categoryResponse.response &&
            BrowseHelper.getCorrectBrowseUrl(categoryResponse.response, isClearance, isSaleRoute)
          if (!correctBrowseUrl || !BrowseHelper.hasProducts(categoryResponse.response)) {
            const handleReject = () =>
              BrowseHelper.handleBadBrowseResponse(categoryResponse, meta, context, () => {}, false)
            return reject(handleReject)
          }
          if (BrowseHelper.shouldRedirect(currentBrowseUrl, correctBrowseUrl, pathParams)) {
            const redirectData = BrowseHelper.constructRedirectData(correctBrowseUrl, pathParams, queryString, false)
            const { response } = categoryResponse
            let status = statusCodes.permanentRedirect
            if (!!response && !!response.data) {
              const isCategoryLive = !response.data[0].attributes.isWCS
              status = BrowseHelper.determineRedirectStatusCode(isCategoryLive)
            }

            BrowseHelper.redirectTo(redirectData, context, status)
            return resolve()
          }
          let action = ServiceHelper.handleBrowseRequest(err, categoryResponse, context)
          if (action.reduxAction) return resolve(action)

          data = categoryResponse.response.data[0] // eslint-disable-line

          // If Browse PLP
          if (CategoryHelper.isLeafNode(data) || CategoryHelper.isPlpPlus(data) || isClearance) {
            const templateType = CategoryHelper.getTemplateType(data)

            queryParams = ProductRequestHelper.buildQueryParams(
              data.id,
              page,
              sort,
              refinements,
              queryParams,
              data,
              templateType,
              isClearance,
              true
            )

            const { isNewIn: isNewInCategory } = data?.attributes || {}

            const payloadPath = payload.path

            const params = { queryParams, isBrowse: true, isNewInCategory, categoryId: data.id, payloadPath }
            const productRequest = ServiceHelper.buildProductServiceRequest(params, context)

            context.service.read(productRequest.service, productRequest.params, {}, function(err, productResponse) {
              if (ProductResponseHelper.executeNoResultsModal(context, productResponse)) return resolve()
              action = ServiceHelper.handlePLPRequest(err, categoryResponse, productResponse, context)
              if (isCitrusEnabled({ pageType })) {
                citrusCategoryProductHandler(pageType, context, categoryResponse, productResponse)
              }
              resolve(action)
            })
          } else {
            // If Browse CLP
            action.payload = categoryResponse
            action.reduxAction = categoryRequest.reduxAction
            resolve(action)
          }
        })
      })
    )

    Promise.all(promises)
      .then(values => {
        values.forEach(value => {
          if (value) {
            ServiceHelper.handleActions(context, value)
          }
        })
        return done()
      })
      .catch(handleReject => {
        logger.error('handleReject', handleReject)
        if (typeof handleReject === 'function') handleReject()
        return done()
      })
  }
}
