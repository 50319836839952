import { get, isObjectEmpty } from '../utils/PolyfillLodash'
import getCitrusProductAdData from './GetCitrusProductAdData'

const mergePdpDataToCitrus = params => {
  const hlResBody = get(params, 'response.body')
  const hlResAds = get(params, 'response.body.ads')
  const searchProductAd = []
  const prodAttrBySKU = {}
  let hlData = {}
  let partNumber

  if (hlResBody && hlResAds && Array.isArray(hlResAds) && hlResAds.length > 0) {
    const partNumbers = []
    get(params, 'pdpResponse.data', []).forEach(v => {
      partNumber = v.attributes?.partNumber
      partNumbers.push(partNumber)
      prodAttrBySKU[partNumber] = {
        ProductName: v.attributes.name,
        ProductSKU: partNumber,
        shipping: v.attributes.deliveryPrice,
        deliverable: v.attributes.deliverable,
        brand: v.attributes.brand,
        detailAttributes: []
      }
    })
    if (params.pdpResponse.included !== undefined) {
      get(params, 'pdpResponse.included', []).forEach(v => {
        if (prodAttrBySKU[v.attributes?.sku] !== undefined) {
          if (v.type === 'badges') {
            prodAttrBySKU[v.attributes.sku] = {
              ...prodAttrBySKU[v.attributes.sku],
              badge: {
                BADGE_VALUE: [v.attributes.badgeReference]
              }
            }
          }
        }
        if (prodAttrBySKU[v.id] !== undefined) {
          if (v.type === 'prices') {
            prodAttrBySKU[v.id].Price = v.attributes.now
            prodAttrBySKU[v.id].PromoText = v.attributes.flashText
            prodAttrBySKU[v.id].ComparePrice = v.attributes.was
          }
          if (v.type === 'reviewstatistics') {
            prodAttrBySKU[v.id].avgRating = v.attributes.avgRating
            prodAttrBySKU[v.id].reviewsCount = v.attributes.reviewCount
          }
          if (
            v.type === 'energy' &&
            !isObjectEmpty(v.attributes) &&
            typeof v.attributes.energyEfficiencyClass !== 'undefined'
          ) {
            prodAttrBySKU[v.id].detailAttributes.push({
              name: v.attributes.energyEfficiencyClass.title,
              value: v.attributes.energyEfficiencyClass.value
            })
          }
          if (v.type === 'dimensions' && !isObjectEmpty(v.attributes) && typeof v.attributes.size !== 'undefined') {
            prodAttrBySKU[v.id].detailAttributes.push({
              name: 'Size (cm)',
              value: v.attributes.size
            })
          }
        }
      })
    }

    get(params, 'response.body.ads', []).forEach(v => {
      if (prodAttrBySKU && typeof prodAttrBySKU[v.gtin] === 'undefined') {
        return false
      }
      const hlVal = getCitrusProductAdData(v)
      const merged = { ...hlVal, ...prodAttrBySKU[v.gtin] }
      searchProductAd.push(merged)
    })
    hlData = { ...hlResBody }
    hlData.ads = searchProductAd
  }
  return hlData
}

export default mergePdpDataToCitrus
