import styled from 'styled-components'
import { Slider } from '@sainsburys-tech/bolt'

export const Container = styled.div`
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
`

export const Heading = styled.h1`
  text-align: center;
  margin: 10px auto 30px;
`

export const Subheading = styled.h2`
  text-align: center;
  padding-bottom: 20px;
`

export const Header = styled.h2`
  width: 100%;
  margin: 40px 0 20px;
  padding-bottom: 15px;
`

export const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const SliderComponent = styled(Slider)`
  .no-results-suggestions__slider-slide {
    flex-direction: column;
    @media (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
      flex-direction: row;
    }
  }
`
export const ProductCardWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
    margin-top: 0;
    margin-bottom: 0;
  }
`
