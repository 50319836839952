import React, { useState, useEffect } from 'react'
import { ErrorBoundary } from '@sainsburys-tech/bolt'

import PropTypes from 'prop-types'

import * as Styled from './styles'
import Template from './Feedback.template'
import { pushEventThenTrack } from '../../helpers/TaggingSerializer'

const Feedback = ({ searchTerm }) => {
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    setClicked(false)
  }, [searchTerm])

  function doClick(yesorno) {
    setClicked(true)
    pushEventThenTrack({
      eventInfo: {
        eventName: 'searchResults_searchQualityFeedback',
        eventAction: 'Search Results Search Quality Feedback'
      },
      attributes: {
        searchResultsHelpful: yesorno
      }
    })
  }

  return (
    <ErrorBoundary>
      <Styled.FeedbackBanner data-el='search-feedback'>
        <div>
          <strong>{Template.feedbackStart}</strong>
          {Template.feedbackMessage}
        </div>
        {clicked ? (
          <Styled.SuccessAlert data-el='feedback-banner-success' kind='success' isCloseable={false}>
            {Template.successMessage}
          </Styled.SuccessAlert>
        ) : (
          <Styled.Buttons data-el='feedback-banner__buttons'>
            <Styled.FeedbackButton data-el='search-feedback-button-yes' kind='secondary' onClick={() => doClick('yes')}>
              Yes
            </Styled.FeedbackButton>
            <Styled.FeedbackButton data-el='search-feedback-button-no' kind='secondary' onClick={() => doClick('no')}>
              No
            </Styled.FeedbackButton>
          </Styled.Buttons>
        )}
      </Styled.FeedbackBanner>
    </ErrorBoundary>
  )
}

Feedback.propTypes = {
  searchTerm: PropTypes.string
}
Feedback.defaultProps = {
  searchTerm: ''
}

export default Feedback
