import styled from 'styled-components'
import { Alert, Button } from '@sainsburys-tech/bolt'

const borderRadius = '2px'
const basePadding = '15px'

export const FeedbackBanner = styled.div(
  props => `
	display: block;
	border: 1px solid ${props.theme.color.monochrome.light};
	border-radius: ${borderRadius};
	padding: ${basePadding} ${basePadding} 0;
	margin: ${basePadding} ${basePadding} 40px ${basePadding};

	@media (min-width: ${props.theme.size.breakpoint['min-width'].lg}) {
		margin: 0 0 40px;
	}
`
)

export const Buttons = styled.div`
  padding: ${basePadding} 0;
  margin: 10px 0;
`
export const SuccessAlert = styled(Alert)`
  width: 100%;
  border-radius: ${borderRadius};
  padding-right: ${basePadding};
`

export const FeedbackButton = styled(Button)`
  width: 65px;
  border-radius: ${borderRadius};

  :first-child {
    margin-right: 10px;
  }
`
