import { connect } from 'react-redux'

import Header from '../../components/Header/Header'

const getWishlistCount = state => {
  const getNonDeletedItems = list => {
    return list.filter(item => !item.deleted)
  }
  const list =
    state.userDetails && state.userDetails.data && state.userDetails.data.userState === 'LOGGEDIN'
      ? state.globalList
      : state.localList

  return list.data && list.data.wishlists && list.data.wishlists.length
    ? getNonDeletedItems(list.data.wishlists[0].items).length
    : 0
}

export const mapStateToProps = state => {
  return {
    categoryStore: state.category,
    applicationStore: state.application,
    productStore: state.product,
    spellcheckedSearch: state.product.spellcheckedSearch || false,
    inputSearchTerm: state.product.inputSearchTerm || null,
    brandSuggestions: state.product.brandSuggestions,
    trolleyCount: state.trolleyItems.total,
    wishlistCount: getWishlistCount(state),
    pageTitle: state.application.pageTitle,
    searchTerm: state.application.searchTerm,
    showLoader: state.application?.loader?.show,
    description: state.application.description
  }
}

export default connect(mapStateToProps)(Header)
