import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useCookies } from 'react-cookie'

import {
  Input,
  DrawerHeader as ModalHeader,
  DrawerContent as ModalContent,
  RectangleSkeleton,
  Alert,
  Button,
  Select
} from '@sainsburys-tech/bolt'
import { isUkPostcode } from '@sainsburys-tech/bolt-validators'
import {
  pushEventThenTrack,
  trackAvailabilityModalOpen,
  trackAvailabilityModalSuccess
} from '../../helpers/TaggingSerializer'
import { getAvailabilityRadius, setAvailabilityRadius } from '../../helpers/AvailabilityHelper'
import formatPostcode from '../../utils/formatPostcode'
import { getValue } from '../../utils/ConfigProvider'

import Template from './AvailabilityModal.template'
import * as Styled from './styles'

const AvailabilityModal = ({
  showLocalisationModal,
  localisationPostcode,
  partNumber,
  pageType,
  handleLocalisationModal,
  fetchUserLocalisation,
  resetAvailabilityError,
  setCollectionStoresError,
  tooltipCookie,
  availabilityTooltipEnabled,
  availabilityRadiusToggleIsEnabled
}) => {
  const [postcode, setPostcode] = useState(localisationPostcode)
  const [errorMessage, setErrorMessage] = useState('')
  const [alert, setAlert] = useState('')
  const [loading, setLoading] = useState(false)

  // Availability Radius
  const { radiusOptions } = getValue('features.availability.radius')
  const defaultRadius = getAvailabilityRadius() // Get radius from local storage or returns a default radius
  const [radius, setRadius] = useState(defaultRadius)

  // Availability Tooltip Cookies
  const { cookieName, path, expires } = tooltipCookie
  const [getTooltipCookie, setTooltipCookie] = useCookies([cookieName])
  const isTooltipCookieSet = getTooltipCookie

  const {
    localised,
    unlocalised,
    errorMessages: { emptyPostcodeMessage, invalidPostcodeMessage, failedCallMessage }
  } = Template

  useEffect(() => {
    if (showLocalisationModal) {
      setRadius(getAvailabilityRadius()) // Prevent selected radius from being saved without submitting
      setPostcode(formatPostcode(localisationPostcode))

      trackAvailabilityModalOpen(pageType, availabilityTooltipEnabled && !!isTooltipCookieSet)

      // Set cookie to close tooltip
      if (availabilityTooltipEnabled && isTooltipCookieSet) {
        setTooltipCookie(cookieName, 'closed', { path, expires })
      }
    }
  }, [showLocalisationModal])

  // Removing any spacing
  const normalisedPostcode = postcode.trim().replace(/\s/g, '')

  const handleSubmit = e => {
    e.preventDefault()
    clearErrors()

    const hasSamePostcode = normalisedPostcode.toUpperCase().trim() === localisationPostcode.toUpperCase().trim()

    if (!normalisedPostcode) {
      setErrorMessage(emptyPostcodeMessage)
    } else if (!isUkPostcode(normalisedPostcode)) {
      setErrorMessage(invalidPostcodeMessage)
    } else if (hasSamePostcode && defaultRadius === radius) {
      // Prevent making a call if the same postcode or radius is submitted
      handleModalClose()
    } else {
      // Set radius to local storage
      setAvailabilityRadius(radius)
      // Reset radius and error message on submit
      setCollectionStoresError(false)
      resetAvailabilityError()

      // Track success
      trackAvailabilityModalSuccess(
        normalisedPostcode,
        pageType,
        availabilityTooltipEnabled && !!isTooltipCookieSet,
        radius
      )

      // Avoid localising if same postcode is used
      hasSamePostcode ? handleModalClose() : localiseUser()
    }
  }

  const localiseUser = async () => {
    setLoading(true)
    const url = `/stores/api/orchestrator/v0/cis-locator/availability?maxDistance=${radius}&maxResults=1&skuQty=${partNumber}_1&postcode=${postcode.trim()}&channel=web_plp`
    fetch(url)
      .then(async response => {
        if (!response.ok) {
          return Promise.reject(response)
        }
        setLoading(false)
        // Callback to update postcode to state
        fetchUserLocalisation()
        handleModalClose()
      })
      .catch(error => {
        setLoading(false)
        setAlert(failedCallMessage)
        console.error(error)

        // Track failure
        pushEventThenTrack({
          eventInfo: {
            eventName: 'localisation_localisationFailure',
            eventAction: 'Localisation Failure'
          },
          attributes: {
            pageType: ['search', 'canned', 'sd'].includes(pageType) ? 'search' : 'plp',
            error: error.status,
            errorMessage: error.statusText
          }
        })
      })
  }

  const clearErrors = () => {
    errorMessage && setErrorMessage('')
    alert && setAlert('')
  }

  const handleModalClose = () => {
    clearErrors()
    handleLocalisationModal()
  }

  const content = () => {
    const data = localisationPostcode ? localised(localisationPostcode, radius) : unlocalised
    const { headerText, bodyText } = data

    return (
      <>
        <ModalHeader>{headerText}</ModalHeader>
        <ModalContent>
          <p>{bodyText}</p>
          {loading ? (
            <RectangleSkeleton height={20} />
          ) : (
            <>
              {alert && (
                <Alert kind='error' isCloseable={false}>
                  {alert}
                </Alert>
              )}
              <form onSubmit={handleSubmit} data-test='availability-modal-form'>
                <Input
                  label='Postcode'
                  name='postcode'
                  type='text'
                  data-test='postcode-input'
                  placeholder='Enter postcode'
                  aria-label='Enter postcode'
                  value={postcode}
                  onChange={e => setPostcode(e.target.value)}
                  error={errorMessage}
                  autoComplete='off'
                />
                {availabilityRadiusToggleIsEnabled && (
                  <Select
                    options={radiusOptions}
                    value={radius}
                    onChange={e => setRadius(e.target.value)}
                    label='Store distance'
                    data-test='radius-select-options'
                  />
                )}

                <Button
                  type='submit'
                  kind='primary'
                  disabled={loading}
                  full
                  data-test='availability-modal-submit-button'>
                  Submit details
                </Button>
              </form>
            </>
          )}
        </ModalContent>
      </>
    )
  }

  return (
    <Styled.ExtendedModal
      padding
      show={showLocalisationModal}
      height='initial'
      onClose={() => handleModalClose()}
      data-test='availability-modal'>
      {content()}
    </Styled.ExtendedModal>
  )
}

AvailabilityModal.propTypes = {
  localisationPostcode: PropTypes.string,
  showLocalisationModal: PropTypes.bool,
  partNumber: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
  handleLocalisationModal: PropTypes.func.isRequired,
  fetchUserLocalisation: PropTypes.func.isRequired,
  resetAvailabilityError: PropTypes.func.isRequired,
  setCollectionStoresError: PropTypes.func.isRequired,
  availabilityTooltipEnabled: PropTypes.bool,
  tooltipCookie: PropTypes.shape({
    cookieName: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    expires: PropTypes.string.isRequired
  }),
  availabilityRadiusToggleIsEnabled: PropTypes.bool.isRequired
}

AvailabilityModal.defaultProps = {
  localisationPostcode: '',
  showLocalisationModal: false,
  tooltipCookie: {},
  availabilityTooltipEnabled: false
}

export default AvailabilityModal
