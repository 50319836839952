/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { CloseIcon } from '@sainsburys-tech/bolt-icons'
import { DrawerContent, DrawerFooter, ErrorBoundary, RectangleSkeleton } from '@sainsburys-tech/bolt'
import { sortVariants, typeMapper, breakpoints } from '@sainsburys-tech/boltui-utils'

import PlpPlusCategoryFacet from '../PlpPlusCategoryFacet/PlpPlusCategoryFacet'
import AppliedFilters from '../AppliedFilters/AppliedFilters'
import { CATEGORY_FILTER_ID } from '../../helpers/CategoryHelper'
import { BrowseHelper } from '../../helpers/BrowseHelper'
import resetFilters from '../../actions/resetFilters'
import addFilter from '../../actions/addFilter'
import removeFilter from '../../actions/removeFilter'
import replaceRadioFilter from '../../actions/replaceRadioFilter'
import setCategory from '../../actions/setCategory'
import clearFacet from '../../actions/clearFacet'
import Template from './FilterPanel.template'
import Facet from '../Facet/Facet'
import { getValue } from '../../utils/ConfigProvider'

import * as Styled from './styles.js'

const moveArrayIndex = (array, { from, to }) => {
  const reorderedArray = [...array]
  reorderedArray.splice(to, 0, reorderedArray.splice(from, 1)[0])
  return reorderedArray
}

export default class FilterPanel extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    this.getType = this.getType.bind(this)
    this.clearFacet = this.clearFacet.bind(this)
    this.toggleFilter = this.toggleFilter.bind(this)
    this.selectCategory = this.selectCategory.bind(this)
    this.goToClearanceCategory = this.goToClearanceCategory.bind(this)
    this.handleBreakpointChange = this.handleBreakpointChange.bind(this)
    this.hideUsingKey = this.hideUsingKey.bind(this)
    this.hideFilterModal = this.hideFilterModal.bind(this)
    this.resetFilters = () => resetFilters(context, {}, this)
  }

  componentDidMount() {
    const breakpoint = breakpoints.lg

    this.mql = window.matchMedia(breakpoint)
    this.mql.addListener(this.handleBreakpointChange)

    this.handleBreakpointChange()

    window.addEventListener('keydown', this.hideUsingKey)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.hideUsingKey)

    if (this.mql) {
      this.mql.removeListener(this.handleBreakpointChange)
    }
    this.mql = null
  }

  getCategoryFilter(filters) {
    const categoriesToTranspose = getValue('features.filterPanel.transposeCategories')
    // Extract 'Category' filter from the list of filters
    const categoryFilter = filters.find(filter => filter.id === CATEGORY_FILTER_ID)
    const { pageType } = this.props
    const searchPageTypes = ['search', 'canned', 'directory']

    let transposedFilters = []
    let subcategories = []

    // If there are no specified category Ids, behave as before and return full list of category filters
    if (!categoriesToTranspose) return categoryFilter || undefined

    subcategories = categoryFilter?.values || []

    // For a Brand that has specified a category ID, transpose those subcategories up and remove the category
    if (searchPageTypes.includes(pageType) && categoriesToTranspose.length > 0) {
      // First extract the subcategories
      categoriesToTranspose.forEach(categoryId => {
        const category = categoryFilter?.values.find(child => child.id === categoryId)

        transposedFilters = [...transposedFilters, ...(category ? category.children : [])]

        // Then remove the parent as we want to hide it from the list
        subcategories = [...subcategories.filter(child => child.id !== categoryId)]
      })
    }

    return {
      ...categoryFilter,
      values: [...transposedFilters, ...subcategories]
    }
  }

  getTotalProducts(categoryFilter) {
    let totalProducts = 0
    const categories = categoryFilter.values
    categories.forEach(function(category) {
      if (!category.applied) {
        totalProducts += category.value
      }
    })

    return totalProducts
  }

  getShowMoreCategoryLabel(categoryFilter = {}) {
    const catCount = categoryFilter.values.length - 1
    const categoryText = catCount === 1 ? 'category' : 'categories'
    const totalProducts = this.getTotalProducts(categoryFilter)
    return `+ ${totalProducts} product${totalProducts > 1 ? 's' : ''} in ${catCount} ${categoryText}`
  }

  getExpandedFacetScreenSize(i) {
    switch (true) {
      case i < 4:
        return 'xs'
      case i < 6:
        return 'lg'
      default:
        return ''
    }
  }

  getType(id) {
    switch (id) {
      case 'customer rating':
        return 'rating'
      case 'stone colour':
      case 'colour':
      case 'colour family':
      case 'colour group':
        return 'color'
      case 'size':
        return 'size'
      case 'price':
        return 'price'
      default:
        return id.replace(/\s/g, '-') || 'default'
    }
  }

  getFacets() {
    const { filters, appliedFilters, pageType, setClickOrigin } = this.props
    return filters.map((filter, i) => {
      if (
        filter.id !== CATEGORY_FILTER_ID &&
        (filter.values.length > 0 || (this.getType(filter.id) === 'price' && filter.max))
      ) {
        const type = this.getType(filter.id)
        const values = filter.values.length > 0 ? filter.values : [filter.min, filter.max]
        if (type === 'size') {
          filter.values.sort(sortVariants(value => typeMapper(type)(value.id)))
        }

        const defaultRowCount = filter.id === 'customer rating' ? 6 : 5
        const expand = this.getExpandedFacetScreenSize(i)
        return (
          <ErrorBoundary key={`${filter.id}-boundary`}>
            <Facet
              expand={expand}
              key={`${filter.id}`}
              data-facet={filter.id}
              id={filter.id}
              type={type}
              label={filter.label}
              filters={values}
              selectFilter={this.toggleFilter}
              clearFacet={this.clearFacet}
              defaultRowCount={defaultRowCount}
              appliedFilters={appliedFilters}
              pageType={pageType}
              setClickOrigin={setClickOrigin}
            />
          </ErrorBoundary>
        )
      }
      return null
    })
  }

  orderCategoryFilters = values => {
    values.forEach(filter => {
      if (filter.children?.length > 0) {
        filter.children = this.orderCategoryFilters(filter.children)
      }
    })
    const categoryFilterIndex = values.findIndex(cat => cat.applied)
    const categoryFilterIndexWithChild = values.findIndex(cat => cat.isChildApplied)
    if (categoryFilterIndex > -1) return moveArrayIndex(values, { from: categoryFilterIndex, to: 0 })
    if (categoryFilterIndexWithChild > -1) return moveArrayIndex(values, { from: categoryFilterIndexWithChild, to: 0 })
    return values
  }

  categoryUnmatch = () => {
    this.context.navigateTo(`${window.location.pathname}opt/catmatch:off/`)
  }

  goToClearanceCategory({ filterId, filterLabel }) {
    const { isPlp, ancestors, categoryId, categoryName, loadingStart, isSaleRoute } = this.props
    const childBrowseUrl = BrowseHelper.buildUrlForCategory({
      futureCategoryId: filterId,
      futureCategoryName: filterLabel,
      categoryType: 'PLP',
      ancestors,
      currentCategoryId: categoryId,
      currentCategoryName: categoryName,
      isPlp,
      isClearance: true,
      isSaleRoute
    })
    this.hideFilterModal()
    loadingStart()
    window.location.href = childBrowseUrl
    return true
  }

  selectCategory({ filterId }) {
    setCategory(this.context, { filterId, facetId: CATEGORY_FILTER_ID })
    return true
  }

  hideFilterModal(e) {
    e && e.preventDefault()
    if (this.props.filterModalVisible) {
      this.props.hideFilterModal()
    }
  }

  hideUsingKey(e) {
    if (e.keyCode === 27) {
      // ESC key
      e.preventDefault()
      e.stopPropagation()
      this.hideFilterModal(e)
    }
  }

  handleBreakpointChange() {
    if (this.mql && this.mql.matches) {
      this.props.toggleFilterPanelStatic(true)
    } else {
      this.props.toggleFilterPanelStatic(false)
      this.hideFilterModal()
    }
  }

  toggleFilter({ facetId, filterId, applied }) {
    const addOrRemoveFilter = applied ? addFilter : removeFilter
    const actionName = facetId === 'customer rating' ? replaceRadioFilter : addOrRemoveFilter
    actionName(this.context, { filterId, facetId })
    return true
  }

  clearFacet({ facetId }) {
    clearFacet(this.context, { facetId })
    return true
  }

  hasCategoryAndFilterSelected() {
    return (
      this.props.appliedFilters.some(filter => filter.id === CATEGORY_FILTER_ID) && this.props.appliedFilters.length > 1
    )
  }

  isCategoryOnlyFilter() {
    return this.props.appliedFilters.length === 1 && this.props.appliedFilters[0].id === CATEGORY_FILTER_ID
  }

  isCategoryAndClearanceOnlyFilter() {
    return this.props.appliedFilters.length <= 2 && this.props.appliedFilters.some(filter => filter.id === 'clearance')
  }

  displayResetButton() {
    if (!this.props.appliedFilters.length || this.isCategoryOnlyFilter() || this.isCategoryAndClearanceOnlyFilter()) {
      return false
    }
    return true
  }

  render() {
    if (!this.props.numberOfResults) return null
    const {
      filters,
      appliedFilters,
      appliedFiltersOrder,
      categoryName,
      isPlp,
      isPlpPlus,
      isPlpPlusDescendant,
      searchTerm,
      subcategories,
      categoryId,
      numberOfResults,
      ancestors,
      topLevelCategoryId,
      filterModalVisible,
      filterPanelStatic,
      displayErrorModal,
      hideFilterModal,
      loadingStart,
      isClearance,
      isCannedSearch,
      categoryMatch,
      showLHN,
      setClickOrigin
    } = this.props

    const maxCategoryFilters = isCannedSearch ? 100 : 20
    const extendedCategoryFilter = this.getCategoryFilter(filters) || { values: [] }
    const orderedCategoryFilters = this.orderCategoryFilters(extendedCategoryFilter.values)
    const categoryFilter = {
      id: 'category',
      label: 'Category',
      values: orderedCategoryFilters.slice(0, maxCategoryFilters)
    }
    const categoryShowMoreLabel = this.getShowMoreCategoryLabel(categoryFilter)
    const showBrowseCategoryFacet = !isClearance && (isPlpPlus || isPlpPlusDescendant)
    const showCategoryFacet =
      categoryFilter &&
      categoryFilter.values.length > 0 &&
      ((searchTerm && !isPlp && !isPlpPlusDescendant) || isClearance) // Show category filter when categories exist
    const showFacets = isPlpPlus || isPlp || searchTerm || isClearance

    const appliedFiltersNoCategory = appliedFilters.filter(filter => filter.id !== CATEGORY_FILTER_ID)
    const appliedFiltersHasLength = appliedFiltersNoCategory && appliedFiltersNoCategory.length > 0
    const showAppliedFilters = appliedFiltersHasLength && !isPlpPlus && !isPlpPlusDescendant
    const categoryFilters = this.orderCategoryFilters(categoryFilter.values)
    const extendedCategoryFilters = this.orderCategoryFilters(extendedCategoryFilter.values)
    const showCategoryFilter = categoryFilters.length > 0

    if (typeof document !== 'undefined') {
      document.body.style.position = filterModalVisible && !displayErrorModal && !filterPanelStatic ? 'fixed' : ''
    }

    return (
      <ErrorBoundary>
        <Styled.FilterPanel
          className='lg-3--none'
          data-el='filter-panel'
          aria-labelledby='filter_panel_header'
          data-open={(filterModalVisible && !displayErrorModal) || filterPanelStatic}
          heading=''
          height='viewheight'
          position='left'
          size={filterPanelStatic ? 'sm' : 'full'}
          focusLock={false}
          hasBorder={false}
          padding={false}
          isStatic={filterPanelStatic}
          show={(filterModalVisible && !displayErrorModal) || filterPanelStatic}
          onClose={this.hideFilterModal}
          removeScroll={false}>
          <DrawerContent>
            <Styled.FilterPanelInner>
              {showBrowseCategoryFacet && (
                <ErrorBoundary>
                  <PlpPlusCategoryFacet
                    categories={subcategories}
                    categoryName={categoryName}
                    categoryId={categoryId}
                    numberOfResults={numberOfResults}
                    ancestors={ancestors}
                    isPlp={isPlp}
                    topLevelCategoryId={topLevelCategoryId}
                    hideFilterModal={hideFilterModal}
                    loadingStart={loadingStart}
                    isClearance={isClearance}
                    filters={filters}
                  />
                </ErrorBoundary>
              )}

              {!showLHN ? (
                showCategoryFacet && (
                  <Styled.Group>
                    <Styled.FilterPanelHeader>
                      <Styled.FilterPanelTitle tabIndex='0' role='heading' aria-level='1' id='filter_panel_header'>
                        <strong>{Template.showResults}</strong>
                      </Styled.FilterPanelTitle>
                      {!showBrowseCategoryFacet && (
                        <Styled.CloseButton
                          type='button'
                          data-el='filter-panel-close'
                          onClick={this.hideFilterModal}
                          role='button'>
                          <span className='sr-only'>{Template.closeFilter}</span>
                          <CloseIcon width='1.5em' height='1.5em' style={{ fill: 'currentColor' }} />
                        </Styled.CloseButton>
                      )}
                    </Styled.FilterPanelHeader>

                    {showCategoryFilter && (
                      <ErrorBoundary>
                        <Facet
                          key={`cat-${categoryName}-${categoryShowMoreLabel}`}
                          type='category'
                          id='category'
                          data-facet='category'
                          expand='xs'
                          label={categoryFilter.label}
                          filters={this.hasCategoryAndFilterSelected() ? extendedCategoryFilters : categoryFilters}
                          selectFilter={isClearance ? this.goToClearanceCategory : this.selectCategory}
                          showMoreLabel={categoryName ? categoryShowMoreLabel : undefined}
                          defaultRowCount={5}
                          categoryMatch={categoryMatch}
                          categoryUnmatch={this.categoryUnmatch}
                          clearFacet={this.clearFacet}
                          showBreadcrumb={!isClearance}
                          setClickOrigin={setClickOrigin}
                        />
                      </ErrorBoundary>
                    )}
                  </Styled.Group>
                )
              ) : (
                <Styled.Group data-el='filter-panel-skeleton'>
                  <Styled.FilterPanelHeader>
                    <Styled.FilterPanelTitle tabIndex='0' role='heading'>
                      <strong>{Template.showResults}</strong>
                    </Styled.FilterPanelTitle>
                  </Styled.FilterPanelHeader>
                  <RectangleSkeleton height={200} />
                </Styled.Group>
              )}

              {!showLHN ? (
                <Styled.Group>
                  <Styled.FilterPanelHeader>
                    <Styled.FilterPanelTitle
                      tabIndex='0'
                      role='heading'
                      aria-level='1'
                      id={showCategoryFacet ? 'filter_by_header' : 'filter_panel_header'}>
                      <strong>{Template.filterBy}</strong>
                    </Styled.FilterPanelTitle>
                    {this.displayResetButton() && (
                      <Styled.ClearButton type='button' data-el='clear-filters' onClick={this.resetFilters}>
                        {Template.clearAll}
                      </Styled.ClearButton>
                    )}
                    {!showBrowseCategoryFacet && !categoryFilters.length > 0 && (
                      <Styled.CloseButton
                        type='button'
                        data-el='filter-panel-close'
                        onClick={this.hideFilterModal}
                        role='button'>
                        <span className='sr-only'>{Template.closeFilter}</span>
                        <CloseIcon width='1.5em' height='1.5em' style={{ fill: 'currentColor' }} />
                      </Styled.CloseButton>
                    )}
                  </Styled.FilterPanelHeader>
                  {showAppliedFilters && <AppliedFilters filters={filters} order={appliedFiltersOrder} />}
                  {showFacets && this.getFacets()}
                </Styled.Group>
              ) : (
                <Styled.Group data-el='filter-panel-skeleton'>
                  <Styled.FilterPanelHeader>
                    <Styled.FilterPanelTitle tabIndex='0' role='heading'>
                      <strong>{Template.filterBy}</strong>
                    </Styled.FilterPanelTitle>
                  </Styled.FilterPanelHeader>
                  <RectangleSkeleton height={400} />
                </Styled.Group>
              )}
            </Styled.FilterPanelInner>
          </DrawerContent>
          {!filterPanelStatic && (
            <DrawerFooter>
              <Styled.ApplyButton data-el='filter-panel__apply' onClick={this.hideFilterModal} full>
                {Template.showBtn(numberOfResults)}
              </Styled.ApplyButton>
            </DrawerFooter>
          )}
        </Styled.FilterPanel>
      </ErrorBoundary>
    )
  }
}

FilterPanel.propTypes = {
  filters: PropTypes.array.isRequired,
  appliedFilters: PropTypes.array.isRequired,
  appliedFiltersOrder: PropTypes.array,
  filterModalVisible: PropTypes.bool.isRequired,
  filterPanelStatic: PropTypes.bool.isRequired,
  toggleFilterPanelStatic: PropTypes.func,
  searchTerm: PropTypes.string,
  categoryName: PropTypes.string,
  numberOfResults: PropTypes.number,
  categoryMatch: PropTypes.bool,
  isPlp: PropTypes.bool,
  isPlpPlus: PropTypes.bool,
  isPlpPlusDescendant: PropTypes.bool,
  isClearance: PropTypes.bool,
  isSaleRoute: PropTypes.bool,
  isCannedSearch: PropTypes.bool,
  ancestors: PropTypes.array,
  subcategories: PropTypes.array,
  categoryId: PropTypes.number,
  hideFilterModal: PropTypes.func,
  loadingStart: PropTypes.func,
  showLHN: PropTypes.bool,
  displayErrorModal: PropTypes.bool,
  topLevelCategoryId: PropTypes.bool
}

FilterPanel.contextTypes = {
  reduxStore: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  navigateTo: PropTypes.func
}

FilterPanel.defaultProps = {
  toggleFilterPanelStatic: () => {},
  searchTerm: '',
  categoryName: '',
  numberOfResults: null,
  categoryMatch: false,
  isPlp: false,
  isPlpPlus: false,
  isPlpPlusDescendant: false,
  isClearance: false,
  isSaleRoute: false,
  isCannedSearch: false,
  ancestors: [],
  subcategories: [],
  categoryId: null,
  hideFilterModal: () => {},
  loadingStart: () => {},
  displayErrorModal: false,
  topLevelCategoryId: false,
  appliedFiltersOrder: [],
  showLHN: false
}
