/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'

import * as Styled from './RangeSlider.styles'

const RangeSlider = ({ min = 0, max = 500, selectFilter, appliedPrices }) => {
  let lowerApplied
  let upperApplied
  let sliderTimeout

  const [lowerState, setLowerState] = useState()
  const [upperState, setUpperState] = useState()
  const [lastTimeout, setLastTimeout] = useState(undefined)
  const [lowerInputError, setLowerInputError] = useState(undefined)
  const [upperInputError, setUpperInputError] = useState(undefined)

  if (appliedPrices) {
    const label = appliedPrices.values[0]
    const minValue = parseInt(label.split(' - ')[0].replace('£', ''), 10)
    const maxValue = parseInt(label.split(' - ')[1].replace('£', ''), 10)
    lowerApplied = minValue < min ? min : minValue
    upperApplied = maxValue > max ? max : maxValue
  } else {
    lowerApplied = min
    upperApplied = max
  }

  const lowerValue = parseInt(lowerState?.toString() || lowerApplied?.toString() || min, 10)
  const upperValue = upperState || upperApplied || max
  const step = (max - min) / 50

  useEffect(() => {
    setLowerState(lowerValue)
    setUpperState(upperValue)
  }, [min, max])
  useEffect(() => {
    setLowerState(lowerApplied)
    setUpperState(upperApplied)
  }, [lowerApplied, upperApplied])

  const handleLowerOnChange = e => {
    const roundedValue = Math.floor(e.target.value)
    const isHigherThanUpperValue = parseInt(roundedValue, 10) > parseInt(upperValue, 10) - parseInt(step, 10)

    if (!isHigherThanUpperValue) {
      setLowerState(parseInt(roundedValue, 10))
    }
  }

  const handleUpperOnChange = e => {
    const roundedValue = Math.ceil(e.target.value)
    const isLowerThanLowerValue = parseInt(roundedValue, 10) < parseInt(lowerValue, 10) + parseInt(step, 10)

    if (!isLowerThanLowerValue) {
      setUpperState(parseInt(roundedValue, 10))
    }
  }

  const handleTextInputChange = (e, setValueFunction) => {
    const newValue = e.target.value ? parseInt(e.target.value, 10) : '0'
    setValueFunction(newValue)
  }

  const onChangeSliderHandler = () => {
    const isSameValue = lowerValue === lowerApplied && upperValue === upperApplied
    if (!isSameValue) {
      sliderTimeout = setTimeout(() => {
        setLowerInputError('')
        selectFilter({ facetId: 'price-range', filterId: `${lowerValue},${upperValue}`, applied: true })
      }, 500)
      setLastTimeout(sliderTimeout)
    }
  }

  const onChangeLowerTextHandler = () => {
    const displayError = lowerValue < min || lowerValue >= upperValue
    const skipRequest = lowerValue === upperApplied || displayError

    setLowerState(lowerValue)

    if (displayError) {
      setLowerInputError('Invalid value')
    } else {
      setLowerInputError('')
    }

    if (!skipRequest) {
      selectFilter({ facetId: 'price-range', filterId: `${lowerValue},${upperValue}`, applied: true })
    }
  }

  const onChangeUpperTextHandler = () => {
    const displayError = upperValue > max || upperValue <= lowerValue
    const skipRequest = upperValue === upperApplied || displayError

    setUpperState(upperValue)

    if (displayError) {
      setUpperInputError('Invalid value')
    } else {
      setUpperInputError('')
    }

    if (!skipRequest) {
      selectFilter({ facetId: 'price-range', filterId: `${lowerValue},${upperValue}`, applied: true })
    }
  }

  return (
    <Styled.Container>
      <Styled.InputSectionContainer>
        <Styled.Label htmlFor='lowerValue'>min</Styled.Label>
        <Styled.Label htmlFor='upperValue'>max</Styled.Label>
        <Styled.TextInput
          type='number'
          id='lowerValue'
          value={lowerValue.toString()}
          onChange={e => handleTextInputChange(e, setLowerState)}
          onBlur={() => onChangeLowerTextHandler(false)}
          onKeyUp={e => {
            if (e.key === 'Enter') onChangeLowerTextHandler()
          }}
          error={lowerInputError}
        />
        <Styled.TextInput
          type='number'
          id='upperValue'
          value={upperValue.toString()}
          onChange={e => handleTextInputChange(e, setUpperState)}
          onBlur={() => onChangeUpperTextHandler(true)}
          onKeyUp={e => {
            if (e.key === 'Enter') onChangeUpperTextHandler()
          }}
          error={upperInputError}
        />
      </Styled.InputSectionContainer>
      <Styled.Wrapper>
        <Styled.Range
          type='range'
          min={min}
          max={max}
          step={step}
          value={Math.floor(lowerValue)}
          upperPercentage={(upperValue / max) * 100}
          lowerPercentage={(lowerValue / max) * 100}
          onChange={e => handleLowerOnChange(e)}
          onMouseUp={onChangeSliderHandler}
          onTouchEnd={onChangeSliderHandler}
          onMouseDown={() => clearTimeout(lastTimeout)}
          onTouchStart={() => clearTimeout(lastTimeout)}
        />
        <Styled.Range
          type='range'
          min={min}
          max={max}
          step={step}
          value={Math.ceil(upperValue)}
          upperPercentage={(upperValue / max) * 100}
          lowerPercentage={(lowerValue / max) * 100}
          onChange={e => handleUpperOnChange(e)}
          onMouseUp={onChangeSliderHandler}
          onTouchEnd={onChangeSliderHandler}
          onMouseDown={() => clearTimeout(lastTimeout)}
          onTouchStart={() => clearTimeout(lastTimeout)}
        />
      </Styled.Wrapper>
    </Styled.Container>
  )
}

export default RangeSlider
