import { connect } from 'react-redux'

import Modals from '../../components/Modals/Modals'

const mapStateToProps = state => ({
  displayErrorModal: state.application.displayErrorModal,
  errorModalReason: state.application.errorModalReason,
  noResultsModal: state.application.noResultsModalVisible
})

export default connect(mapStateToProps)(Modals)
