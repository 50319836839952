import styled from 'styled-components'
import { Button } from '@sainsburys-tech/bolt'

export const Title = styled.h2`
  margin: 15px 15px;
`

export const ErrorButton = styled(Button)`
  margin: 15px;
`
