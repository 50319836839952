import { useState, useEffect } from 'react'
import superagent from 'superagent'

import { getUserIdFromCookieInfo } from '../helpers/CitrusHelper'
import Logger from '../utils/Logger'

const logger = new Logger()

const useCitrusBanners = (searchTerm, categoryName, placement, isSponsoredPage) => {
  const cookies = typeof window !== 'undefined' && window.document ? document.cookie : null
  const citrusCatalogId = typeof window !== 'undefined' && window.citrusCatalogId ? window.citrusCatalogId : ''
  const citrusContentId = typeof window !== 'undefined' && window.citrusContentId ? window.citrusContentId : ''
  const [banners, setBanners] = useState([])
  const userId = cookies ? getUserIdFromCookieInfo(cookies) : ''
  let slotId

  switch (placement) {
    case 'search-below-grid-argos':
      slotId = 'SLP_search_below_grid'
      break
    case 'category-below-grid-argos':
      slotId = 'PLP_category_below_grid'
      break
    case 'category-landing-argos':
      slotId = 'CLP_category_landing'
      break
    default:
      break
  }

  const prepareCategoryFilter = categoryData => {
    if (categoryData.constructor === Array) return categoryData.map(category => [`taxonomy:${category}`])
    return [[`taxonomy:${categoryData}`]]
  }

  const getCitrusBannersX = () => {
    const requestBody = {
      sessionId: `${userId}`,
      placement: `${placement}`,
      searchTerm: searchTerm || '',
      catalogId: `${citrusCatalogId}`,
      productFilters: searchTerm
        ? null
        : prepareCategoryFilter(isSponsoredPage ? categoryName.split(',') : categoryName),
      options: {
        filterMode: `${placement === 'category-below-grid-argos' ? 'OrAnd' : 'AndOr'}`
      },
      contentStandardId: `${citrusContentId}`,
      bannerSlotIds: [
        {
          slotId: `${slotId}`,
          maxNumberOfAds: 2
        }
      ]
    }
    const getHeaders = () => {
      return {
        'cache-control': 'no-cache'
      }
    }
    const parseResponseText = text => {
      try {
        return JSON.parse(text)
      } catch (error) {
        return {}
      }
    }

    return new Promise(resolve => {
      superagent
        .post('/finder-api/sbanners/', requestBody)
        .withCredentials()
        .set(getHeaders())
        .timeout({
          response: 10000,
          deadline: 20000
        })
        .end((err, response) => {
          if (err) {
            resolve({ timeout: true })
          }
          if (response && response.text) {
            resolve(parseResponseText(response.text))
          }
        })
    })
  }

  useEffect(() => {
    if (banners.length === 0) {
      getCitrusBannersX()
        .then(response => {
          const { ads } = response
          if (ads.length > 0) {
            const adsBanners = ads[0]?.banners
            setBanners(adsBanners)
          }
        })
        .catch(err => {
          logger.error('apiLogger', err, 'Unable to fetch Citrus Banners.')
        })
    }
  }, [searchTerm, categoryName])

  return banners
}

useCitrusBanners.defaultProps = {
  isSponsoredPage: false
}

export default useCitrusBanners
