import { canUseDom } from '@sainsburys-tech/boltui-utils'
import deepcopy from 'lodash.clonedeep'
import jsesc from 'jsesc'
import Logger from '../utils/Logger'
import { CATEGORY_FILTER_ID, CategoryHelper } from './CategoryHelper'
import { categoryTypes, searchTypes } from '../config'
import { availabilityIsEnabled } from '../utils/availabilityIsEnabled'
import { isTu, showTuLabels } from './Availability/AvailabilityTuHelper'
import { hasAppliedFilter } from './GetAppliedFilter'

const logger = new Logger()

function sanitizeString(string, ...args) {
  if (!string) return ''
  logger.debug('functionLogger', { args }, 'TaggingSerializer sanitizeString')
  return string
    .toLowerCase()
    .replace('&', 'and')
    .replace(/\W/g, '')
}

class TaggingSerializer {
  constructor(productStoreState, categoryStoreState, applicationStoreState, taggingOptions = {}, userInfo = {}) {
    const defaultTaggingOptions = { sprintReference: '1.0', channel: '', globalView: false }

    this.productStoreState = productStoreState
    this.categoryStoreState = categoryStoreState
    this.applicationStoreState = applicationStoreState
    this.taggingOptions = Object.assign(defaultTaggingOptions, taggingOptions)
    this.userInfo = userInfo
    this.platform = 'react_search_and_browse'
    this.currentPage = this.isListerPage() ? this.productStoreState.currentPage : ''
    this.pageSize = this.isListerPage() ? this.productStoreState.pageSize : ''
  }

  hasBrandSuggestions() {
    return this.productStoreState.brandSuggestions && this.productStoreState.brandSuggestions.length > 0
  }

  getDataObject() {
    const pageObject = this.getPageObject()

    if (this.productStoreState.isCannedSearch) {
      pageObject.category = { ...pageObject.category, pageType: 'slp_canned' }
    }
    if (this.hasBrandSuggestions()) {
      pageObject.category = { ...pageObject.category, pageType: 'slp_notincatalogue' }
    }
    if (this.isOnListEndpoint() && this.isValidCannedSearch() && this.isNoResults()) {
      pageObject.category = { ...pageObject.category, pageType: 'error' }
    }
    if (this.isOnListEndpoint() && !this.isValidCannedSearch()) {
      pageObject.category = { ...pageObject.category, pageType: 'error' }
    }

    return {
      page: pageObject,
      user: this.getUserObject(),
      // Uncomment ine below for the next AB test
      experiments: this.setAbTestProperties()
      // experiments: []
    }
  }

  getSearchCategoryId() {
    const ps = this.productStoreState
    const categoryArr = []

    if (ps.appliedFilters && ps.appliedFilters.length > 0 && ps.appliedFilters[0].id === 'category') {
      ps.appliedFilters.forEach(appliedFilter => {
        if (appliedFilter.id === 'category') {
          categoryArr.push(appliedFilter.pimId)
        }
      })
    } else if (
      ps.meta &&
      ps.meta.aggregations &&
      ps.meta.aggregations.length > 0 &&
      ps.meta.aggregations[0].id === 'category'
    ) {
      ps.meta.aggregations[0].values.forEach(element => {
        categoryArr.push(element.id)
      })
    }

    return categoryArr
  }

  getPageObject() {
    const siteSection = this.getSiteSection()
    const pageName = this.getPageName()

    const testIsWhiteGoods = canUseDom() && typeof window.getCookie === 'function'
    const checkIfMobile =
      canUseDom() && typeof window.getCookie === 'function' && window.getCookie('analytics_channel') === 'mcomm'
    const isWhiteGoods = this.categoryStoreState.templateType === 'white goods' && testIsWhiteGoods
    const oneItemRow = isWhiteGoods || checkIfMobile
    const badgesPerPage = this.productStoreState.products
      ? this.productStoreState.products.filter(product => Object.keys(product.attributes.badge).length !== 0).length
      : 0
    const badgeTypes = {}
    this.productStoreState.products &&
      this.productStoreState.products.forEach(product => {
        Object.keys(product.attributes.badge).forEach(badgeValue => {
          badgeTypes[badgeValue] = (badgeTypes[badgeValue] || 0) + 1
        })
      })
    const numberOfResultsPerPage = this.productStoreState.products ? this.productStoreState.products.length : 0
    const hasAvailabilityLabels = (products = [], appliedFilters = []) => {
      if (isTu && products?.length) {
        return showTuLabels(products, appliedFilters) ? 'availabilityMessagesDisplayed' : ''
      }
      return '' // Empty string for Argos & Habitat
    }

    return {
      pageInfo: {
        siteSection: `${siteSection}:`,
        pageName: pageName ? `${siteSection}:${pageName}:` : `${siteSection}:`,
        onsiteSearchTerms: this.getSearchTermProperty(),
        attributes: {
          templateTitle: this.getTemplateTitle(),
          sprintReference: this.taggingOptions.sprintReference
        },
        searchCategoryID: this.getSearchCategoryId()
      },
      attributes: {
        sortBy: this.getSortBy(),
        refinement: this.getRefinement(),
        refinementValue: this.getRefinementValues(),
        platform: this.platform,
        channel: this.taggingOptions.channel,
        globalView: this.taggingOptions.globalView,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        rowSize: oneItemRow ? 1 : 3,
        numberOfResults: this.productStoreState.numberOfResults >= 0 ? this.productStoreState.numberOfResults : '',
        error: this.getErrorType(),
        badgesPerPage,
        badgeTypes,
        numberOfResultsPerPage,
        localisationOffered: availabilityIsEnabled({
          pageType: this.getPageType(),
          isClothing: this.isClothing(),
          isWhiteGoods: this.isWhiteGoods(),
          urlPath: this.getLocationPathname()
        }),
        availabilityMessagesDisplayed: hasAvailabilityLabels(
          this.productStoreState.products,
          this.productStoreState.appliedFilters
        ),
        clickOrigin: this.getClickOrigin()
      },
      lightbox: {
        pageName: this.getPageName(),
        siteSection: this.getSiteSection(),
        type: '',
        product: []
      },
      search: this.getSearchProperty(),
      category: this.getCategoryObject()
    }
  }

  getErrorType() {
    if (!this.applicationStoreState) return ''
    if (!this.isErrorModalVisible() && !this.isNoResultsModalVisible()) return ''
    if (this.productStoreState.searchTerm) {
      return this.getSlpError()
    }
    if (this.isPLPorPLPPlus()) {
      return this.getPlpError()
    }
    return ''
  }

  getSlpError() {
    if (this.isErrorModalVisible()) {
      return 'slp_timeoutError'
    }
    if (this.isNoResultsModalVisible()) {
      return 'slp_filteringError'
    }
  }

  getPlpError() {
    if (this.isErrorModalVisible()) {
      return 'plp_timeoutError'
    }
    if (this.isNoResultsModalVisible()) {
      return 'plp_filteringError'
    }
  }

  isNoResultsModalVisible() {
    return this.applicationStoreState.noResultsModalVisible
  }

  isErrorModalVisible() {
    return this.applicationStoreState.displayErrorModal
  }

  getSearchProperty() {
    if (!this.productStoreState.searchTerm) {
      return ''
    }

    const search = {
      term: this.productStoreState.searchTerm,
      suggestion: {}
    }

    const setDidYouMeanProperties = search => {
      const isSpellcheckedSearch = this.productStoreState.spellcheckedSearch
      const hasSearchTermSuggestions =
        this.productStoreState.searchTermSuggestions && this.productStoreState.searchTermSuggestions.length > 0
      const isDidYouMeanSearch = this.productStoreState.searchType === searchTypes.didYouMean
      const isNICSearch = this.productStoreState.searchType === searchTypes.brandsNIC
      const isNRSSearch = this.productStoreState.searchType === searchTypes.NRS
      const isNotInCatalogue = this.hasBrandSuggestions()
      if (isNotInCatalogue) {
        const hasCategorySuggestions = isNotInCatalogue && this.productStoreState.brandSuggestions.length > 1
        search.banner = hasCategorySuggestions ? 'slp_nic_cat_banner' : 'slp_nic_banner'
        search.suggestion.primaryterm = this.productStoreState.brandSuggestions[0].text
        search.suggestion.secondaryterm = this.productStoreState.brandSuggestions
          .map(suggestion => suggestion.text)
          .join('|')
      } else if (isSpellcheckedSearch) {
        search.suggestedfor = this.productStoreState.inputSearchTerm
        search.dymMethod = 'dym_rep'
        search.banner = 'search-replacement-banner'
      } else if (isNICSearch || isNRSSearch) {
        search.suggestion.nic = this.productStoreState.searchTerm
        if (isNRSSearch) {
          search.suggestion.nrs = this.productStoreState.searchTerm
        }
      } else if (isDidYouMeanSearch) {
        search.dymMethod = 'dym_sug'
      } else if (hasSearchTermSuggestions) {
        search.suggestion.primaryterm = this.productStoreState.searchTermSuggestions[0].text
        search.suggestion.secondaryterm = this.productStoreState.searchTermSuggestions
          .map(suggestion => suggestion.text)
          .join('|')
        search.dymMethod = 'dym_display'
        search.banner = 'search-suggestion-banner'
      }
    }

    const setAutoSuggestProperties = search => {
      if (!global.window) return

      const { autoSuggestCookie } = this.taggingOptions

      if (['active_used', 'active_notused', 'active_notseen'].includes(autoSuggestCookie)) {
        search.autosuggest = autoSuggestCookie
      } else {
        search.autosuggest = 'inactive'
      }

      if (autoSuggestCookie === 'active_used') {
        search.inputmethod = 'autosuggest'
      }
    }

    /*
    Create category hierachy string for tracking, e.g. for /search/microwaves - 'Appliances:Small kitchen appliances:Microwaves'
    Searches through filters and finds first category which has an applied value (including children)
    Appends applied category label to result and repeat on children filters
    The result should match the categories the category facet in the UI LHN (excluding All Categories)
    */
    const getCategoryHierarchy = (filters, hierarchy = '') => {
      const appliedCategory = filters.find(filter => {
        return filter.isGrandchildApplied || filter.isChildApplied || filter.applied
      })

      if (!appliedCategory) {
        return hierarchy
      }

      const { applied, label, children = [] } = appliedCategory

      return applied ? hierarchy.concat(label) : getCategoryHierarchy(children, hierarchy.concat(`${label}:`))
    }

    const setCategoryMatchHierarchy = search => {
      const isCatMatchAlgorithm = search?.algorithm?.some(
        algorithm => algorithm.name === 'algoType' && algorithm.value === 'cat_match'
      )

      const hasCategoryIdAlgorithm = search?.algorithm?.some(
        algorithm => algorithm.name === 'categoryId' && algorithm.value !== '0' // check for valid category id, is set to '0' for when no category match
      )

      const { filters = [] } = this.productStoreState

      const categoryFilter = filters.find(filter => filter.id === 'category')?.values

      if (isCatMatchAlgorithm && hasCategoryIdAlgorithm && categoryFilter) {
        const categoryMatchHierarchy = getCategoryHierarchy(categoryFilter)
        search.categoryMatchHierarchy = categoryMatchHierarchy
      }

      return search
    }

    const setTrackingAlgorithmProperties = search => {
      const searchAlgorithmTracking = !!this.productStoreState.meta && this.productStoreState.meta.tracking
      if (searchAlgorithmTracking) {
        search.algorithm = searchAlgorithmTracking
      }
    }

    setDidYouMeanProperties(search)
    setAutoSuggestProperties(search)
    setTrackingAlgorithmProperties(search)
    setCategoryMatchHierarchy(search)

    return search
  }

  setAbTestProperties() {
    if (!canUseDom() || typeof window.getCookie !== 'function') return
    const testCookieValue = window.getCookie('Findability_Test_Group_1')
    return [
      {
        experimentInfo: {
          experimentName: '', // opt no longer needed
          experimentCookie: 'Findability_Test_Group_1',
          experimentVariant: testCookieValue
        }
      }
    ]
  }

  getLocationPathname() {
    if (!canUseDom()) return
    return window.location.pathname
  }

  getSearchTermProperty() {
    let pageType = ''
    if (this.productStoreState.searchTerm) {
      if (this.productStoreState.numberOfResults === 0) {
        pageType = 'noresults'
      } else if (this.hasBrandSuggestions()) {
        pageType = 'notincatalogue'
        return `${pageType}:${this.productStoreState.inputSearchTerm}`
      } else {
        pageType = 'searchlist'
      }
      return `${pageType}:${this.productStoreState.searchTerm}`
    }

    return pageType
  }

  getUserObject() {
    let loginStatus

    if (Object.keys(this.userInfo).length === 0) {
      return {}
    }

    if (this.userInfo.userState === 'GUEST') {
      loginStatus = 'Non-Registered'
    } else if (this.userInfo.userState === 'RECOGNISED') {
      loginStatus = 'Recognised'
    } else {
      loginStatus = 'Registered'
    }

    const localisationPostcode = this.applicationStoreState.availability?.localisationPostcode || ''

    return [
      {
        profile: [
          {
            attributes: {
              loginStatus
            },
            profileInfo: {
              profileID: this.userInfo.userId
            },
            localisation: {
              status: !!localisationPostcode,
              postcode: localisationPostcode.substring(0, 4)
            }
          }
        ]
      }
    ]
  }

  getCategoryDepth() {
    if (this.categoryStoreState.ancestors) {
      return this.categoryStoreState.ancestors.length + 1
    }
    return null
  }

  getCategoryObject() {
    const category = {}
    if (this.isOnListEndpoint() && !this.isValidCannedSearch()) {
      category.pageType = 'error'
    } else if (this.isOnListEndpoint() && this.isValidCannedSearch() && this.isNoResults()) {
      category.pageType = 'error'
    } else if (this.productStoreState.searchTerm) {
      category.pageType = 'SLP'
    } else if (this.isNoResults() && this.isPageTypeBrowse() && !this.isCLP()) {
      category.pageType = '404 Not Found'
    } else {
      category.pageType =
        this.categoryStoreState.categoryType === categoryTypes.TOPLEVELCATEGORY
          ? categoryTypes.CLP
          : this.categoryStoreState.categoryType
    }
    category.currentCategoryId = this.categoryStoreState.categoryId
    category.isPlpPlusDescendant = this.categoryStoreState.isPlpPlusDescendant
    category.plpLayout =
      this.categoryStoreState.templateType === 'white goods' ? 'technology' : this.categoryStoreState.templateType

    Object.assign(category, this.getSubCategories())

    return category
  }

  getSubCategories() {
    const ancestors = this.categoryStoreState.ancestors || []
    const maxCategoryDepth = 4
    const subCategories = {}
    let categoryName

    for (let i = 1; i <= maxCategoryDepth; i++) {
      categoryName = ''

      if (this.isPLPorPLPPlus() || this.isCLP()) {
        if (i === this.getCategoryDepth() - 1) {
          categoryName = this.categoryStoreState.categoryName
        } else if (ancestors[i]) {
          categoryName = ancestors[i].attributes.name
        }
      }

      subCategories[`subCategory${i}`] = categoryName
    }

    return subCategories
  }

  isPLPorPLPPlus() {
    return this.categoryStoreState.isPlp || this.categoryStoreState.isPlpPlus
  }

  isListerPage() {
    return typeof this.productStoreState.numberOfResults === 'number'
  }

  isCLP() {
    return CategoryHelper.convertTopLevelToClp(this.categoryStoreState.categoryType) === categoryTypes.CLP
  }

  isClearance() {
    return this.applicationStoreState.isClearance
  }

  isClothing() {
    return this.categoryStoreState.templateType === 'fashion'
  }

  isWhiteGoods() {
    return this.categoryStoreState.templateType === 'white goods'
  }

  isOnListEndpoint() {
    return this.applicationStoreState.isCannedSearch
  }

  isValidCannedSearch() {
    return this.productStoreState.isCannedSearch
  }

  getClickOrigin() {
    if (!isTu) return ''

    const appliedFilters = this.applicationStoreState?.appliedFilters || []
    const hasFilterSelected = hasAppliedFilter(appliedFilters, 'Size')

    return hasFilterSelected ? this.applicationStoreState.clickOrigin : ''
  }

  getPageType() {
    return this.productStoreState && this.productStoreState.searchTerm ? 'search' : 'browse'
  }

  isPageTypeBrowse() {
    return this.getPageType() === 'browse'
  }

  isNoResults() {
    return this.productStoreState && this.productStoreState.numberOfResults
      ? !this.productStoreState.numberOfResults || this.productStoreState.numberOfResults < 1
      : true
  }

  isRefined() {
    return (
      this.productStoreState.appliedFilters &&
      this.productStoreState.appliedFilters.length > 0 &&
      !this.productStoreState.categoryMatch
    )
  }

  getTemplateTitle() {
    let templateTitle
    let categoryDepth

    if (this.isListerPage()) {
      templateTitle = this.isPLPorPLPPlus() ? 'productlist' : 'searchlist'

      if (this.productStoreState.numberOfResults === 0) {
        templateTitle += '_noresults'
      } else if (this.hasBrandSuggestions()) {
        templateTitle += '_notincatalogue'
      }
    } else {
      categoryDepth = this.getCategoryDepth()
      templateTitle = categoryDepth ? `browse_level${categoryDepth}` : ''
    }

    return templateTitle
  }

  getSiteSection() {
    if (this.isClearance()) {
      return this.isNoResults() ? 'ar:404 Not Found' : 'ar:clearance'
    }
    if (this.isOnListEndpoint()) {
      if (this.isValidCannedSearch() && !this.isNoResults()) {
        return 'ar:search'
      }
      return 'ar:error'
    }
    if (this.isPLPorPLPPlus() || this.isCLP() || this.isPageTypeBrowse()) {
      return !this.isCLP() && this.isNoResults() ? 'ar:404 Not Found' : 'ar:cat'
    }
    return 'ar:search'
  }

  getPageName() {
    if (this.isOnListEndpoint() && !this.isValidCannedSearch()) {
      return 'brokencannedsearch'
    }

    if (this.isOnListEndpoint() && this.isValidCannedSearch() && this.isNoResults()) {
      return 'brokencannedsearch'
    }

    if (this.isPLPorPLPPlus() || this.isCLP() || this.isPageTypeBrowse()) {
      return this.getPageNameForBrowsePages()
    }

    let pageName = ''
    const isRefined = this.isRefined()

    if (this.productStoreState.numberOfResults > 0) {
      if (this.hasBrandSuggestions()) {
        pageName += isRefined ? 'notincatalogue:refined' : 'notincatalogue'
      } else {
        pageName += isRefined ? 'mercadoresultslist:refined' : 'mercadoresultslist'
      }
    } else {
      pageName = 'noresults'
    }

    return pageName
  }

  getPageNameForBrowsePages() {
    if (this.productStoreState.numberOfResults < 1 && !this.isCLP()) return null
    const breadcrumb = []
    let appliedFilters = this.productStoreState.appliedFilters || []
    appliedFilters = appliedFilters.filter(appliedFilter => appliedFilter.id !== CATEGORY_FILTER_ID)

    this.categoryStoreState.ancestors.forEach((category, index) => {
      if (index !== 0 && category.attributes.name !== null) {
        breadcrumb.push(sanitizeString(category.attributes.name))
      }
    })

    if (this.categoryStoreState.ancestors.length) {
      breadcrumb.push(sanitizeString(this.categoryStoreState.categoryName))
    }

    if (appliedFilters.length > 0) {
      breadcrumb.push('refined')
    }

    return breadcrumb.join(':')
  }

  getSortBy() {
    if (this.productStoreState.numberOfResults === 0) {
      // no results page
      return ''
    }

    if (this.productStoreState.appliedSortBy && Object.keys(this.productStoreState.appliedSortBy).length !== 0) {
      const key = Object.keys(this.productStoreState.appliedSortBy)[0]
      return `${sanitizeString(key)}:${sanitizeString(this.productStoreState.appliedSortBy[key])}`
    }

    return ''
  }

  getRefinement() {
    const refinement = []

    if (!this.isListerPage() || this.productStoreState.numberOfResults === 0) {
      return ''
    }
    if (
      !this.isPLPorPLPPlus() &&
      !this.isClearance() &&
      this.categoryStoreState.categoryName &&
      !this.productStoreState.categoryMatch
    ) {
      refinement.push('category')
    }

    const appliedFilters = this.productStoreState.appliedFilters
      .filter(appliedFilter => appliedFilter.id !== CATEGORY_FILTER_ID)
      .map(appliedFilter => sanitizeString(appliedFilter.name))

    refinement.push(...appliedFilters)

    if (refinement.length > 0) {
      refinement.unshift(this.isPLPorPLPPlus() || this.isClearance() ? 'browse' : 'search')
      return refinement.join(':')
    }

    return ''
  }

  getRefinementValues() {
    if (!this.isListerPage() || this.productStoreState.numberOfResults === 0) {
      return ''
    }
    let ungroupedRefinements = ''
    const pageType = this.productStoreState.searchTerm ? 'search' : 'browse'
    const appliedFiltersCopy = deepcopy(this.productStoreState.appliedFilters)
    appliedFiltersCopy.forEach(appliedFilter => {
      if (appliedFilter.id !== CATEGORY_FILTER_ID || !this.productStoreState.categoryMatch) {
        ungroupedRefinements += ungroupedRefinements.includes(pageType) ? '' : pageType
        const allFilters = appliedFilter.labels
        allFilters.unshift('')
        ungroupedRefinements += allFilters.join(`|${sanitizeString(appliedFilter.name)}:`)
      }
    })

    return ungroupedRefinements
  }

  getSatelliteTrackingEvent() {
    const eventName = null
    if (this.isCLP()) {
      return 'CLPResults'
    }

    if (this.isPLPorPLPPlus() || this.isPageTypeBrowse()) {
      if (this.isNoResults()) {
        return '404Page'
      }
      return 'PLPResults'
    }

    if (this.isListerPage()) {
      const expiredCannedSearch = this.isOnListEndpoint() && !this.isValidCannedSearch()
      const noResultsCannedSearch = this.isOnListEndpoint() && this.isValidCannedSearch() && this.isNoResults()

      if (expiredCannedSearch || noResultsCannedSearch) {
        return null
      }
      return 'searchResults'
    }

    return eventName
  }

  serialize() {
    return jsesc(this.getDataObject(), {
      json: true
    })
  }
}

const pushEventAndTrack = eventInfo => {
  if (typeof window.digitalData !== 'undefined' && !!window.digitalData.track) {
    window.digitalData.event = window.digitalData.event || []
    const events = window.digitalData.event
    events.push({ eventInfo })
    window.digitalData.track(eventInfo.eventName)
  }
}

const pushEventThenTrack = eventData => {
  if (typeof window.digitalData !== 'undefined' && !!window.digitalData.track) {
    window.digitalData.event = window.digitalData.event || []
    const events = window.digitalData.event
    events.push(eventData)
    window.digitalData.track(eventData.eventInfo.eventName)
  }
}

const trackWishlist = (isChecked, partNumber, userState) => {
  if (typeof window.digitalData !== 'undefined' && !!window.digitalData.track) {
    window.digitalData.event = window.digitalData.event || []
    const events = window.digitalData.event
    const isLoggedIn = userState === 'LOGGEDIN'
    const eventData = {
      eventInfo: {
        eventName: 'wishlist_interaction',
        eventAction: 'Wishlist Interaction - Heart Button'
      },
      attributes: {
        clickOrigin: 'heartButton',
        productID: partNumber,
        isAddedOrRemoved: isChecked ? 'added' : 'removed',
        customSiteSection: 'findability',
        wishlistType: isLoggedIn ? 'service' : 'local'
      }
    }
    events.push(eventData)
    window.digitalData.track(eventData.eventInfo.eventName)
  }
}

const trackLhnCategorySelect = (categoryName, categoryID) => {
  categoryName = categoryName.replace(/[\W_]/g, '').toLowerCase()
  const browseLevel = global.window.digitalData.page.pageInfo.attributes.templateTitle.replace(/[\W_]/g, '')
  pushEventThenTrack({
    eventInfo: {
      eventName: 'CLPResults_lhsNavClick',
      eventAction: 'CLP Left Hand Side Nav Click'
    },
    attributes: {
      browseLevel,
      categoryName,
      categoryID
    }
  })
}

const trackAvailabilityModalOpen = (pageType, tooltipSeen) => {
  if (typeof window.digitalData !== 'undefined' && !!window.digitalData.track) {
    const prefix = ['search', 'canned', 'sd'].includes(pageType) ? 'search' : 'cat'

    window.digitalData.page.lightbox.pageName = `ar:${prefix}:localisation`
    window.digitalData.page.lightbox.type = 'localisation'
    window.digitalData.page.attributes.localisationPrompt = tooltipSeen
    window.digitalData.track(`${prefix}_localisationLbPage`)
  }
}

const trackAvailabilityModalSuccess = (localisationPostcode, pageType, tooltipSeen, radius) => {
  window.digitalData.page.attributes.globalView = false
  window.digitalData.user[0].profile[0].localisation = {
    status: !!localisationPostcode,
    postcode: localisationPostcode.substring(0, 4),
    distanceFromStore: parseInt(radius)
  }

  pushEventThenTrack({
    eventInfo: {
      eventName: 'localisation_localisationSuccess',
      eventAction: 'Localisation Success'
    },
    attributes: {
      pageType: ['search', 'canned', 'sd'].includes(pageType) ? 'search' : 'plp',
      localisationPrompt: tooltipSeen
    }
  })
}

const trackAvailabilityCollectionStoreError = (pageType = '', errorMessage = '') => {
  pushEventThenTrack({
    eventInfo: {
      eventName: 'localisation_localisationNoStoresError',
      eventAction: 'Localisation No Stores Error'
    },
    attributes: {
      pageType: ['search', 'canned', 'sd'].includes(pageType) ? 'search' : 'plp',
      errorMessage
    }
  })
}

export default TaggingSerializer

export {
  pushEventAndTrack,
  pushEventThenTrack,
  sanitizeString,
  trackWishlist,
  trackLhnCategorySelect,
  trackAvailabilityModalOpen,
  trackAvailabilityModalSuccess,
  trackAvailabilityCollectionStoreError
}
