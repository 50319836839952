// -------------------------------------------------
// INITIAL STATE
// -------------------------------------------------
export const initialState = {
  data: {
    seoArea: []
  },
  error: null
}

// -------------------------------------------------
// HELPERS
// -------------------------------------------------
