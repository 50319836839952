/**
 * Each category id contains a list of exact refinements with their corresponding heading titles
 * e.g. for { '29330': { 'type:wedding': 'Mens wedding rings' } }
 * if the lister category is '29330' and the refinements are exactly 'type:wedding', the heading title will be 'Mens wedding rings'
 *  */
const refinedHeadingOverrides = {
  29235: {
    'type:diffuser-hairdryers': 'Diffuser hair dryers'
  },
  29307: {
    'time-display:digital': "Men's Digital Watches"
  },
  29311: {
    'type:nose-studs': 'Nose studs'
  },
  29312: {
    'type:charm-bracelets': "Women's charm bracelets"
  },
  29315: {
    'type:hoop-earrings': "Women's hoop earrings",
    'type:stud-earrings': "Women's stud earrings"
  },
  29316: {
    'type:lockets': "Women's locket necklaces"
  },
  29317: {
    'type:engagement': "Women's engagement rings",
    'type:wedding': "Women's wedding rings"
  },
  29330: {
    'type:wedding': 'Mens wedding rings'
  },
  29382: {
    'fuel-type:gas': 'Gas barbecues'
  },
  29391: {
    'type:bistro-sets': 'Bistro sets'
  },
  29400: {
    'type:hot-tubs': 'Hot tubs'
  },
  29432: {
    'type:travel-irons': 'Travel irons'
  },
  29439: {
    'type:tower-fans': 'Tower fans',
    'type:desk-fan': 'Desk fans',
    'type:pedestal-fans': 'Pedestal fans'
  },
  29440: {
    'type:air-coolers': 'Air coolers'
  },
  29444: {
    'type:oil-filled-radiators': 'Oil filled radiators',
    'type:halogen-heaters': 'Halogen heaters',
    'type:fan-heaters': 'Fan heaters',
    'type:ceramic-heaters': 'Ceramic heaters'
  },
  29454: {
    'type:electric-stove-fires': 'Electric stove fires'
  },
  29471: {
    'type:letterboxes-and-plaques': 'Letterboxes'
  },
  29472: {
    'type:heated-throws': 'Heated throws'
  },
  29476: {
    'type:fitted-sheets': 'Fitted sheets',
    'type:flat-sheets': 'Flat Bed sheets'
  },
  29517: {
    'power-type:electric': 'Electric lawnmowers',
    'power-type:petrol': 'Petrol Lawnmowers'
  },
  29525: {
    'type:alarm-clocks': 'Alarm clocks',
    'type:wall-clocks': 'Wall clocks'
  },
  29526: {
    'type:weighted-blankets': 'Weighted blankets'
  },
  29529: {
    'rug-size:large-%28201%252D240cm%29': 'Large rugs'
  },
  29540: {
    'feature:blackout': 'Blackout blinds',
    'type:roller-blinds': 'Roller blinds',
    'type:venetian-blinds': 'Venetian blinds',
    'type:vertical-blinds': 'Vertical blinds'
  },
  29541: {
    'lining:blackout': 'Blackout curtains',
    'type:pencil-pleat': 'Pencil pleat curtains',
    'type:eyelet': 'Eyelet curtains'
  },
  29542: {
    'type:curtain-rings': 'Curtain rings'
  },
  29543: {
    'type:curtain-tracks': 'Curtain tracks'
  },
  29550: {
    'type:soap-dispensers-and-holders': 'Soap dispensers'
  },
  29559: {
    'type:combination-microwave': 'Combination microwaves'
  },
  29560: {
    'type:travel-kettles': 'Travel kettles',
    'type:glass-kettles': 'Glass kettles'
  },
  29562: {
    'type:bean-to-cup-coffee-machines': 'Bean to cup coffee machines',
    'type:filter-coffee-machines': 'Filter coffee machines',
    'type:pod-coffee-machines': 'Pod coffee machines',
    'type:espresso-coffee-machines': 'Espresso coffee machines'
  },
  29597: {
    'type:vacuum-storage-bags': 'Vacuum storage bags'
  },
  29601: {
    'type:sliding': 'Sliding wardrobes',
    'type:3-door-wardrobes': '3 Door Wardrobes',
    'type:4-door-wardrobes': '4 Door Wardrobes',
    'type:2-door-wardrobes': '2 Door Wardrobes'
  },
  29614: {
    'type:condenser-tumble-dryers': 'Condenser tumble dryers',
    'type:heat-pump-tumble-dryers': 'Heat pump tumble dryers',
    'type:vented-tumble-dryers': 'Vented tumble dryers'
  },
  29616: {
    'type:under-counter-fridges': 'Under counter fridges',
    'type:tall-upright-fridges': 'Tall fridges',
    'type:table-top-fridges': 'Table top fridges'
  },
  29617: {
    'type:american-fridge-freezers': 'American Fridge Freezers'
  },
  29618: {
    'type:chest-freezers': 'Chest Freezers',
    'type:under-counter-freezers': 'Under counter freezers',
    'type:tall-freezers': 'Tall freezers',
    'type:tabletop-freezers': 'Table top freezers'
  },
  29623: {
    'type:electric-cookers': 'Freestanding Electric Cookers'
  },
  29625: {
    'type:induction': 'Induction hobs',
    'type:gas': 'Gas hobs'
  },
  29641: {
    'type:cuddle-chairs': 'Cuddle chairs',
    'type:rise-and-recline': 'Rise and recline Armchairs',
    'type:high-back-chairs': 'High back Chairs'
  },
  29643: {
    'type:2-seater': '2 seater sofas',
    'type:3-seater': '3 seater sofas',
    'type:corner': 'Corner sofas',
    'type:recliner': 'Recliner sofas'
  },
  29644: {
    'type:futon': 'Futons',
    'type:chair-beds': 'Chair beds'
  },
  29648: {
    'shape:round': 'Round dining tables',
    'type:extendable': 'Extendable dining tables'
  },
  29653: {
    'type:stepladder': 'Stepladders'
  },
  29668: {
    'type:circular-saws': 'Circular saws'
  },
  29693: {
    'type:robot': 'Robot Vacuums'
  },
  29712: {
    'type:recycling-bins': 'Recycling bins',
    'lid-mechanism:pedal': 'Pedal bins'
  },
  29728: {
    'type:baking-trays-and-sheets': 'Baking trays'
  },
  29741: {
    'type:wine-glasses': 'Wine glasses'
  },
  29802: {
    'type:spotlights': 'Ceiling spotlights'
  },
  29817: {
    'tree-shape:slim': 'Slim Christmas trees',
    'lights:fibre-optic': 'Fibre optic Christmas trees'
  },
  29859: {
    'type:tall-boys': 'Tall bathroom cabinets'
  },
  29863: {
    'type:corner-desks': 'Corner desks'
  },
  29867: {
    'frame-finish:wooden': 'Wooden bed frames'
  },
  29870: {
    'type:memory-foam': 'Memory foam Mattresses',
    'type:orthopaedic': 'Orthopaedic Mattresses'
  },
  29873: {
    'type:bunk-beds': 'Bunk beds',
    'type:cabin-beds': 'Cabin beds',
    'type:toddler-beds': 'Toddler beds'
  },
  29967: {
    'type:cordless-telephones': 'Cordless phones'
  },
  30054: {
    'type:ethernet': 'Ethernet cables'
  },
  30073: {
    'storage-capacity-(gb):2tb': '2TB External hard drives'
  },
  30088: {
    'type:laser-printers': 'Laser printers',
    'type:inkjet-printers': 'Inkjet printers'
  },
  30095: {
    'type:label-makers': 'Label makers'
  },
  30106: {
    'screen-type:oled': 'OLED TVs',
    'screen-type:qled': 'QLED TVs',
    'screen-type:led': 'LED TVs'
  },
  30113: {
    'aerial-type:indoor-aerials': 'Indoor TV aerials'
  },
  30128: {
    'type:wireless-earbuds': 'Wireless earbuds',
    'type:over%252Dear-headphones': 'Over-ear headphones',
    'type:in%252Dear-headphones': 'In-ear Headphones'
  },
  30134: {
    'type:dab-radios': 'DAB Radios'
  },
  30145: {
    'sim-card-plan:pay-as-you-go': 'Pay as you go SIM cards'
  },
  30147: {
    'operating-system:android': 'Android phones'
  },
  30151: {
    'compatibility:samsung': 'Samsung phone cases'
  },
  30158: {
    'ipad-version:air': 'iPad Air',
    'ipad-version:ipad-pro': 'iPad Pro'
  },
  30159: {
    'operating-system:android': 'Android tablets'
  },
  30218: {
    'power-type:rechargeable': 'Rechargeable batteries',
    'type:aa': 'AA Batteries'
  },
  30275: {
    'model:macbook-air': 'Macbook Air',
    'model:macbook-pro': 'Macbook Pro'
  },
  30379: {
    'character:lego-technic': 'LEGO Technic',
    'character:lego-friends': 'LEGO Friends',
    'character:lego-ninjago': 'LEGO NINJAGO',
    'interest:minifigures': 'LEGO Minifigures',
    'character:lego-speed-champions': 'LEGO Speed Champions',
    'character:lego-city': 'LEGO City',
    'character:lego-minecraft': 'LEGO Minecraft',
    'character:lego-marvel': 'LEGO Marvel',
    'character:lego-jurassic-world': 'LEGO Jurassic World',
    'character:lego-creator': 'LEGO Creator',
    'character:lego-dots': 'LEGO DOTS',
    'character:lego-disney': 'LEGO Disney',
    'character:lego-classic': 'LEGO Classic'
  },
  30381: {
    'type:baby-dolls': 'Baby dolls'
  },
  30424: {
    'type:3d-puzzles': '3D Puzzles'
  },
  30482: {
    'type:electric-bikes': 'Electric bikes',
    'type:road-bikes': 'Road bikes',
    'type:hybrid-bikes': 'Hybrid bikes',
    'type:folding-bikes': 'Folding bikes',
    'type:mountain-bikes': 'Mountain bikes'
  },
  30485: {
    'type:balance-bikes': 'Balance bikes'
  },
  30505: {
    'person:4': '4 Person Tents'
  },
  30511: {
    'type:cool-bags': 'Cool bags'
  },
  30512: {
    'type:food-flasks': 'Food flasks'
  },
  30626: {
    'type:push-up-and-pull-up-bars': 'Push up and pull up bars'
  },
  30630: {
    'type:weight-sets': 'Weight sets'
  },
  30675: {
    'type:notebooks': 'Notebooks'
  },
  789422: {
    'type:heated': 'Heated clothes airers'
  }
}

export default refinedHeadingOverrides
