import { connect } from 'react-redux'

import { canUseDom } from '@sainsburys-tech/boltui-utils'
import { NoResultsModal } from '../../components/NoResultsModal/NoResultsModal'

const mapStateToProps = state => ({
  showModal: state.product.showModal,
  isBrowser: canUseDom()
})

export default connect(mapStateToProps)(NoResultsModal)
