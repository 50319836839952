import styled from 'styled-components'
import { Input } from '@sainsburys-tech/bolt'

const Container = styled.div`
  position: relative;
`

const TextInput = styled(Input)`
  display: inline-block;
  width: 100px;
`

const Wrapper = styled.div`
  height: 20px;
  margin: 10px 2px;
  position: relative;
`

const InputSectionContainer = styled.form`
  display: flex;
  margin-left: -1px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
`

const Label = styled.label`
  color: ${props => props.theme.color.monochrome.base};
  margin-bottom: 5px;
  width: 50%;
  text-align: right;

  &:first-child {
    text-align: left;
  }
`

const Range = styled.input`
  display: inline-block;
  position: absolute;
  overflow: visible;
  left: 0;
  top: 0;
  margin-bottom: 50px;
  width: 100%;
  outline: none;
  height: 4px;
  touch-action: none;
  pointer-events: none;

  &::-webkit-slider-thumb {
    pointer-events: all;
    position: relative;
    z-index: 1;
    outline: 0;
    appearance: none;
    width: 1.5em;
    height: 1.5em;
    border: none;
    border-radius: 50%;
    margin-top: -8px;
    background: ${props => props.theme.color.palette['brand-1']};

    &:hover {
      background: ${props => props.theme.color.monochrome.base};
    }
  }

  &::-moz-range-thumb {
    pointer-events: all;
    position: relative;
    z-index: 1;
    outline: 0;
    appearance: none;
    width: 1.5em;
    height: 1.5em;
    border: none;
    border-radius: 50%;
    margin-top: -8px;
    background: ${props => props.theme.color.palette['brand-1']};

    &:hover {
      background: ${props => props.theme.color.monochrome.base};
    }
  }

  &::-ms-thumb {
    pointer-events: all;
    position: relative;
    z-index: 10;
    -ms-appearance: none;
    width: $input-height;
    height: $input-height;
    border: 0;
  }

  &::-webkit-slider-runnable-track {
    cursor: default;
    outline: 0;
    -webkit-appearance: none;
    border: 0;
    height: 4px;
    background: ${props =>
      `linear-gradient(to right, ${props.theme.color.monochrome.base} 0%, ${props.theme.color.monochrome.base} ${props.lowerPercentage}%, ${props.theme.color.palette['brand-1']} ${props.lowerPercentage}%, ${props.theme.color.palette['brand-1']} ${props.upperPercentage}%, ${props.theme.color.monochrome.base} ${props.upperPercentage}%, ${props.theme.color.monochrome.base} 100%)`};
  }

  &::-moz-range-track {
    cursor: default;
    outline: 0;
    -moz-appearance: none;
    border: 0;
    height: 4px;
    background: ${props =>
      `linear-gradient(to right, ${props.theme.color.monochrome.base} 0%, ${props.theme.color.monochrome.base} ${props.lowerPercentage}%, ${props.theme.color.palette['brand-1']} ${props.lowerPercentage}%, ${props.theme.color.palette['brand-1']} ${props.upperPercentage}%, ${props.theme.color.monochrome.base} ${props.upperPercentage}%, ${props.theme.color.monochrome.base} 100%)`};
  }

  &::-moz-focus-outer {
    border: 0;
  }
`

export { Container, Range, InputSectionContainer, TextInput, Label, Wrapper }
