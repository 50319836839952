import deepcopy from 'lodash.clonedeep'
import ContentHelper from '../../helpers/ContentHelper'
import { initialState } from '.'

// -------------------------------------------------
// CONSTANTS
// -------------------------------------------------
export const GET_BROWSE_SEO_SUCCESS = 'GET_BROWSE_SEO_SUCCESS'
export const GET_BROWSE_SEO_FAILURE = 'GET_BROWSE_SEO_FAILURE'

// -------------------------------------------------
// ACTIONS
// -------------------------------------------------
export const getBrowseSeoSuccess = payload => ({ type: GET_BROWSE_SEO_SUCCESS, payload })
export const getBrowseSeoFailure = payload => ({ type: GET_BROWSE_SEO_FAILURE, payload })

// -------------------------------------------------
// REDUCER
// -------------------------------------------------
export default (state = initialState, action) => {
  const newState = deepcopy(state)

  switch (action.type) {
    case GET_BROWSE_SEO_SUCCESS: {
      const { urlRefinements } = action.payload
      const browseSeo = ContentHelper.getSeoArea(action.payload.response.data)
      newState.data.seoArea = ContentHelper.getSeoFooterArea(browseSeo, urlRefinements)
      return newState
    }
    case GET_BROWSE_SEO_FAILURE:
      newState.data.seoArea = []
      return newState
    default:
      return state
  }
}
