import { getSearchPath, getBrowsePath } from '@sainsburys-tech/boltui-utils'
import Logger from '../utils/Logger'
import { get } from '../utils/PolyfillLodash'
import { categoryTypes, seoMetaPlaceholders } from '../config'
import { BrowseHelper, MASTER_CATEGORY_ID } from './BrowseHelper'

const logger = new Logger()

export const CLOTHING_CATEGORY_ID = 29131
export const LIMITED_STOCK_CLEARANCE_ID = 33372952
export const CATEGORY_FILTER_KEY = 'filter[category]'
export const CATEGORY_FILTER_ID = 'category'
export const TOP_LEVEL_TITLES = new Map([
  ['Gifts', 'Buy Experience Days, Wedding & Christening Gifts & Gadgets at Argos'],
  ['Technology', 'Buy Televisions, Tablets, iPods & Cameras at Argos'],
  ['Home and garden', 'Home & Garden, Furniture, Electrical Appliances at Argos'],
  ['Baby and nursery', 'Buy Pushchairs, Cots, Car Seats & Top Nursery brands at Argos'],
  ['Toys', 'Buy Lego, Barbie & Chad Valley Toys at Argos, the No.1 UK Toy Retailer'],
  ['Sports and leisure', 'Buy Bikes, Camping, Home Gym Equipment & Luggage at Argos'],
  ['Health and beauty', 'Buy Hair Dryers, Straighteners, Shavers & Toothbrushes at Argos'],
  ['Clothing', "Buy Women's, Men's, Baby & Kids' Clothing at Argos"],
  ['Jewellery and watches', 'Buy Jewellery, Watches, Necklaces, Earrings & Rings at Argos']
])

export class CategoryHelper {
  static isLeafNode(data, ...args) {
    logger.debug('functionLogger', { args }, 'CategoryHelper isLeafNode')
    if (data && data.attributes) {
      const sanitisedCategoryType = this.getCategoryType(data)
      return sanitisedCategoryType === categoryTypes.PLP
    }
    return false
  }

  static isPlpPlus(data, ...args) {
    logger.debug('functionLogger', { args }, 'CategoryHelper isPlpPlus')
    if (data && data.attributes) {
      const sanitisedCategoryType = this.getCategoryType(data)
      return sanitisedCategoryType === categoryTypes.PLPPLUS
    }
    return false
  }

  static getTemplateType(data) {
    if (data && data.attributes) {
      return data.attributes.templateType
    }

    return null
  }

  static addPathToChildrenCategories(children, ancestors, currentCatName, ...args) {
    logger.debug('functionLogger', { args }, 'CategoryHelper buildUrlForCategories')
    const modifiedChildren = children.map(child => {
      if (this.getCategoryType(child) === categoryTypes.SUBHEADING) {
        this.addPathToChildrenCategories(child.attributes.children, ancestors, currentCatName)
      } else {
        const browseParams = {
          currentCatName,
          ancestors,
          currentCatId: child.id,
          futureCatName: child.attributes.name
        }
        const params = BrowseHelper.buildCorrectUrlBrowseParams(browseParams)
        child.attributes.path = getBrowsePath(params)
      }
      return child
    })
    return modifiedChildren
  }

  static isPlpPlusDescendant(ancestors) {
    return !!ancestors && CategoryHelper.isPlpPlus(ancestors[ancestors.length - 1])
  }

  static getCategoryType(data, ...args) {
    logger.debug('functionLogger', { args }, 'CategoryHelper getCategoryType')
    return data && data.type
      ? data.type
          .replace(/\s|-/g, '')
          .trim()
          .toUpperCase()
      : ''
  }

  static getCategoryLevel(response, ...args) {
    logger.debug('functionLogger', { args }, 'CategoryHelper getCategoryLevel')

    if (!!response && !!response.data) {
      const data = response.data[0]
      if (data && data.attributes) {
        return data.attributes.level
      }
    }

    return null
  }

  static convertTopLevelToClp(type) {
    return type === categoryTypes.TOPLEVELCATEGORY ? categoryTypes.CLP : type
  }

  static getCategoryAncestors(response) {
    if (!!response && !!response.data) {
      let categoryAncestors = response.data
      // removes master category
      categoryAncestors = categoryAncestors.filter(ancestor => ancestor.id !== MASTER_CATEGORY_ID)
      const ancestorCount = categoryAncestors.length
      // removes current category
      categoryAncestors = categoryAncestors.slice(0, ancestorCount - 1)
      return categoryAncestors
    }

    return []
  }

  static getBreadcrumbAncestors(
    categoryAncestors,
    searchTerm,
    searchType,
    isClearance = false,
    isSaleRoute,
    isCannedSearch = false,
    categoryName = ''
  ) {
    if (categoryName && searchTerm) {
      return [
        {
          name: `Back to search results for "${searchTerm}"`,
          path: getSearchPath(searchTerm, { searchType, isCannedSearch })
        }
      ]
    }
    if (categoryAncestors.length > 0) {
      return BrowseHelper.getAncestorsForBrowseBreadcrumb(categoryAncestors, isClearance, isSaleRoute)
    }
  }

  static getCurrentCategoryId(categories) {
    if (!categories || categories.length < 1) return null

    const currentCategory = categories[categories.length - 1]
    return currentCategory ? currentCategory.id : null
  }

  static getTopLevelCategory(ancestors) {
    if (ancestors && ancestors.length) {
      return ancestors.find(ancestor => {
        return (
          ancestor.type
            .replace(/\s|-/g, '')
            .trim()
            .toUpperCase() === categoryTypes.TOPLEVELCATEGORY
        )
      })
    }
  }

  static getCategoryName(response) {
    if (!!response && !!response.data) {
      if (response.data[0].attributes) {
        return response.data[0].attributes.name
      }
      const categories = response.data
      const currentCategory = categories[categories.length - 1]
      return currentCategory ? currentCategory.name : null
    }

    return null
  }

  static getCategoryFilterId(response) {
    const appliedFilters =
      (response && response.response && response.response.meta && response.response.meta.appliedFilters) || []
    let categoryFilterId = null
    appliedFilters.forEach(appliedFilter => {
      if (appliedFilter.id === CATEGORY_FILTER_ID) {
        categoryFilterId = appliedFilter.pimId
      }
    })
    return categoryFilterId
  }

  static getCategoryIdFromResponse(response) {
    return response.data[0].id
  }

  static flattenAncestors(response, ...args) {
    logger.debug('functionLogger', { args }, 'CategoryHelper flattenAncestors')

    const data = response.data[0]
    let ancestorsRelationships
    let ancestorsCategoryIds = []
    let ancestorCategories = []

    if (data && data.relationships) {
      const filteredAncestors = data.relationships.ancestors.data.filter(ancestor => {
        return this.getCategoryType(ancestor) !== categoryTypes.SUBHEADING
      })
      ancestorsRelationships = filteredAncestors
      ancestorsCategoryIds = ancestorsRelationships.map(item => item.id)
    }

    ancestorCategories = get(response, 'data[0].relationships.ancestors.data', []).filter(category =>
      ancestorsCategoryIds.includes(category.id)
    )

    return ancestorCategories.sort((categoryA, categoryB) => categoryA.attributes.level > categoryB.attributes.level)
  }

  static flattenChildren(response, ...args) {
    logger.debug('functionLogger', { args }, 'CategoryHelper flattenChildren')

    const data = response.data[0]
    let childRelationships
    let childCategoryIds = []
    let childCategories = []

    if (data && data.relationships) {
      childRelationships = data.relationships.children.data
      childCategoryIds = childRelationships
        .map(item => item.id)
        .filter(id => id !== LIMITED_STOCK_CLEARANCE_ID.toString())
    }

    childCategories = get(response, 'data[0].relationships.children.data', []).filter(category =>
      childCategoryIds.includes(category.id)
    )

    return childCategories
  }

  static flattenSiblings(response, ...args) {
    logger.debug('functionLogger', { args }, 'CategoryHelper flattenSiblings')

    const data = response.data[0]
    let siblingRelationships
    let siblingCategoryIds = []
    let siblingCategories = []

    if (data && data.relationships && data.relationships.siblings.data) {
      siblingRelationships = data.relationships.siblings.data
      siblingCategoryIds = siblingRelationships
        .map(item => item.id)
        .filter(id => id !== LIMITED_STOCK_CLEARANCE_ID.toString())
      siblingCategories = get(response, 'data[0].relationships.siblings.data', []).filter(category =>
        siblingCategoryIds.includes(category.id)
      )
    }

    return siblingCategories
  }

  static formatBrandName = brand => (brand ? brand.charAt(0).toUpperCase() + brand.slice(1) : '')

  static getChildCount(response, ...args) {
    logger.debug('functionLogger', { args }, 'CategoryHelper getChildCount')

    const data = response.data[0]
    let childCount = null
    if (data && data.relationships && data.relationships.children) {
      childCount = data.relationships.children.data ? data.relationships.children.data.length : null
    }
    return childCount
  }

  static getBrowseTitle(
    seoMeta,
    currentCategory,
    filterString,
    numberOfResults,
    pageNumber,
    isClearance = false,
    isSaleRoute = false,
    brand = 'argos',
    ...args
  ) {
    logger.debug('functionLogger', { args }, 'CategoryHelper getBrowseTitle')

    if (numberOfResults === 0) {
      const readableBrand = this.formatBrandName(brand)
      return `No results found | ${readableBrand}`
    }

    let tempTitle = ''

    if (filterString) {
      tempTitle = get(seoMeta, 'refinedTitle', seoMetaPlaceholders.categoryRefinedFallback)
      if (tempTitle === '') tempTitle = seoMetaPlaceholders.categoryRefinedFallback
    } else {
      tempTitle = get(seoMeta, 'unrefinedTitle', seoMetaPlaceholders.categoryFallback)
      if (tempTitle === '') tempTitle = seoMetaPlaceholders.categoryFallback
    }

    return `${this.formatBrowseSeoMeta(
      tempTitle,
      currentCategory,
      filterString,
      isClearance,
      isSaleRoute,
      brand
    )}${this.getPageNumberForTitle(pageNumber)}`
  }

  static formatBrowseSeoMeta(
    meta,
    currentCategory,
    filterString,
    isClearance = false,
    isSaleRoute = false,
    brand = 'argos'
  ) {
    const isSalePath = isClearance && isSaleRoute
    const isClearancePath = isClearance && !isSaleRoute

    const clearanceTitle = isSalePath ? ' Sale' : isClearancePath ? ' Clearance' : ''
    const readableBrand = this.formatBrandName(brand)
    return meta
      .replace(new RegExp(seoMetaPlaceholders.category, 'g'), `${currentCategory}${clearanceTitle}`)
      .replace(new RegExp(seoMetaPlaceholders.refinement, 'g'), filterString)
      .replace(new RegExp(seoMetaPlaceholders.brand, 'g'), readableBrand)
      .replace()
  }

  static getPageNumberForTitle(pageNumber) {
    return pageNumber > 1 ? ` - page ${pageNumber}` : ''
  }

  static getBrowseDescription(
    seoMeta,
    currentCategory,
    filterString,
    isClearance = false,
    isSaleRoute = false,
    brand,
    ...args
  ) {
    logger.debug('functionLogger', { args }, 'CategoryHelper getBrowseDescription')

    let tempDescription = ''
    const isClearancePath = isClearance && !isSaleRoute
    const readableBrand = this.formatBrandName(brand)
    const clearanceDescriptions = [
      isClearance && isClearancePath ? 'clearance ' : '',
      isClearance ? ` on sale at ${readableBrand}` : ''
    ]

    if (filterString) {
      tempDescription = get(
        seoMeta,
        'refinedDescription',
        `Browse our range of ${clearanceDescriptions[0]}${seoMetaPlaceholders.refinement} ${seoMetaPlaceholders.category}${clearanceDescriptions[1]}. Same Day delivery 7 days a week, or fast store collection.`
      )
      if (tempDescription === '')
        tempDescription = `Browse our range of ${clearanceDescriptions[0]}${seoMetaPlaceholders.refinement} ${seoMetaPlaceholders.category}${clearanceDescriptions[1]}. Same Day delivery 7 days a week, or fast store collection.`
    } else {
      tempDescription = get(
        seoMeta,
        'unrefinedDescription',
        `Browse our range of ${clearanceDescriptions[0]}${seoMetaPlaceholders.category}${clearanceDescriptions[1]}. Same Day delivery 7 days a week, or fast store collection.`
      )
      if (tempDescription === '')
        tempDescription = `Browse our range of ${clearanceDescriptions[0]}${seoMetaPlaceholders.category}${clearanceDescriptions[1]}. Same Day delivery 7 days a week, or fast store collection.`
    }

    return this.formatBrowseSeoMeta(tempDescription, currentCategory, filterString)
  }

  static getFastTrackLabel(allFilters) {
    const fastTrackFilter = allFilters.find(filter => filter.id === 'fasttrack')
    return fastTrackFilter && fastTrackFilter.values[0] ? fastTrackFilter.values[0].label : ''
  }

  static getFilterString(appliedFilters, allFilters, ...args) {
    if (!appliedFilters || !allFilters) {
      return null
    }

    const getItem = (items, value, fn) => items.find(item => fn(item, value))
    logger.debug('functionLogger', { args }, 'CategoryHelper getFilterString')

    const filters = []

    appliedFilters.forEach(filter => {
      const key = Object.keys(filter)[0]
      if (key.toLowerCase() === 'fasttrack') {
        filters.push(this.getFastTrackLabel(allFilters))
      } else {
        const item = getItem(allFilters, key, (item, value) => item.label.trim() === value.trim())
        const selectedFilter =
          item &&
          getItem(item.values, filter[key], (item, value) => {
            return item.id.trim() === value[0].trim()
          })

        if (selectedFilter) {
          filters.push(selectedFilter.label)
        }
      }
    })

    filters.sort()
    const data = filters.length ? filters.join(' ') : null

    return data ? data.trim() : null
  }

  static getFBCTitle(ancestors, thisCategory, searchTerm, filterString, ...args) {
    logger.debug('functionLogger', { args }, 'CategoryHelper getFBCTitle')
    const crumb = ancestors.map(category => category.name)
    let browseTitle
    if (thisCategory) {
      if (filterString) {
        browseTitle = `Results for ${filterString} ${searchTerm} in ${crumb.join(', ')}, ${thisCategory}`
      } else {
        browseTitle = `Results for ${searchTerm} in ${crumb.join(', ')}, ${thisCategory}`
      }
    } else {
      browseTitle = `Results for ${filterString} ${searchTerm}`
    }
    return browseTitle
  }

  static getFBCDescription(ancestors, thisCategory, searchTerm, filterString, brand = 'argos', ...args) {
    logger.debug('functionLogger', { args }, 'CategoryHelper getFBCDescription')

    const brandTagline = brand === 'argos' ? `Get set for ` : ``
    const readableBrand = this.formatBrandName(brand)

    const crumb = ancestors.map(category => category.name)
    let browseDescription
    if (thisCategory) {
      if (filterString) {
        browseDescription = `${brandTagline}${filterString} ${searchTerm} in ${crumb.join(
          ', '
        )}, ${thisCategory} at ${readableBrand}. Same Day delivery 7 days a week, or fast store collection.`
      } else {
        browseDescription = `${brandTagline}${searchTerm} in ${crumb.join(
          ', '
        )}, ${thisCategory} at ${readableBrand}. Same Day delivery 7 days a week, or fast store collection.`
      }
    } else {
      browseDescription = `${brandTagline}${filterString} ${searchTerm} at ${readableBrand}. Same Day delivery 7 days a week, or fast store collection.`
    }
    if (brand === 'tu') {
      browseDescription = `Shop ${CategoryHelper.formatBrandName(
        brand
      )} online. Free in-store Click & Collect or 3-5 day/next day Home Delivery on all orders.`
    }
    return browseDescription
  }
}
