import styled, { css, keyframes } from 'styled-components'
import { FilterIcon, ChevronIcon, LocationIcon } from '@sainsburys-tech/bolt-icons'
import { Alert } from '@sainsburys-tech/bolt'

export const ListerTools = styled.div`
  display: flex;
  flex-wrap: wrap;
  transition: opacity 0.1s ease-out;
  position: sticky;
  top: ${({ offset }) => (offset ? `${offset}px` : 0)};
  left: 0;
  z-index: 4;
  opacity: 1;
  border: 1px solid ${props => props.theme.color.monochrome.light};
  border-width: 1px 0;
  align-items: center;
  justify-content: space-between;
  visibility: visible;
  background-color: ${props => props.theme.color.monochrome.white};

  ${props =>
    props.hidden &&
    css`
      opacity: 0;
    `}

  @media (max-width: ${props => props.theme.size.breakpoint['max-width'].sm}) {
    box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.3);
  }

  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
    position: inherit;
    opacity: 1;
  }
  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].md}) {
    border-width: 1px 0 0;
  }

`

export const FilterButton = styled.button`
  width: 100%;
  border-right: 1px solid ${props => props.theme.color.monochrome.light};
  border-width: 0 1px 0 0;
  height: 46px;
  line-height: 20px;
  text-align: left;
  padding: 10px 15px;
  text-transform: none;
  border-radius: 0;
  position: relative;
  background-color: ${props => props.theme.color.monochrome.white};

  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].md}) {
    height: 42px;
    padding: 11px 10px;
    line-height: 16px;
    border-bottom: ${props => (props.availabilityEnabled ? `1px solid ${props.theme.color.monochrome.light}` : null)};
  }

  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    border: none;
  }
`

export const FilterButtonLabel = styled.span`
  color: ${props => props.theme.color.palette['brand-1']};
  margin-right: 10px;
  font-size: ${props => props.theme.font.size.root};
  font-family: ${props => props.theme.font.family.base};
  float: left;

  /* Really obsure edge-case for item counts with 4 chars */
  @media screen and (max-width: 340px) {
    margin-right: 5px;
  }
`

export const FilterButtonCount = styled.i`
  font-size: 14px;
  color: ${props => props.theme.color.monochrome.dark};
  float: left;
`

export const FilterButtonIcon = styled(FilterIcon)`
  color: ${props => props.theme.color.alias.link.default.color};
  float: right;
  height: 19px;
  font-size: 18px;
`

export const SortPanel = styled.label`
  display: flex;
  width: 100%;
  position: relative;

  ${props =>
    props.availabilityEnabled &&
    css`
      @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].md}) {
        border-bottom: 1px solid ${props => props.theme.color.monochrome.light};
      }

      @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
        border: none;
      }
    `}
`

export const SortSelectWrapper = styled.div`
  width: 100%;
  margin-left: 15px;
`

export const SortBy = styled.span`
  display: none;

  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    line-height: 42px;
    display: inline-block;
    height: 42px;
    color: ${props => props.theme.color.monochrome.dark};
    text-align: right;
    white-space: nowrap;
    padding: 0;
    &::after {
      content: '';
      border-right: 1px solid ${props => props.theme.color.monochrome.light};
      bottom: 10px;
      padding-left: 10px;
      height: 24px;
    }
  }
`

export const SortIcon = styled(ChevronIcon)`
  position: absolute;
  top: 13px;
  right: 15px;
  color: ${props => props.theme.color.alias.link.default.color};
  font-size: ${props => props.theme.font.size.root};
  line-height: 16px;
  pointer-events: none;
  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].md}) {
    top: 10px;
    right: 10px;
  }
`

export const SortSelect = styled.select`
  color: ${props => props.theme.color.palette['brand-1']};
  border: none;
  height: 42px;
  margin: 0;
  text-align: left;
  width: calc(100% - 10px);
  appearance: none;
  border-radius: 0;
  background-color: ${props => props.theme.color.monochrome.white};
  font-size: ${props => props.theme.font.size.root};
  font-family: ${props => props.theme.font.family.base};
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }

  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].md}) {
    height: 41px;
    line-height: 23px;
  }
  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    font-weight: 600;
    padding-left: 5px;
    width: 100%;
  }
`

const flickerAnimation = keyframes`
  40% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`

export const ListerMeta = styled.div`
  color: ${props => props.theme.color.palette.green};
  text-align: center;
  padding: 10px 0;
  opacity: 1;
  animation: ${flickerAnimation} 5s infinite;
`

export const ResultsCount = styled.span`
  transition: opacity 0.25s ease-out;
  text-align: left;
  padding: 10px;
  color: ${props => props.theme.color.monochrome.dark};
  width: 100%;
  left: 0;
  visibility: visible;
  opacity: 1;
  line-height: 1.5rem;
  background-color: ${props => props.theme.color.monochrome.white};
  height: 46px;
  order: 2;

  ${props =>
    props.availabilityEnabled &&
    css`
      text-align: center;
      border-top: 1px solid ${props.theme.color.monochrome.light};

      @media only screen and (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        border: none;
        text-align: right;
      }

      @media only screen and (min-width: ${props.theme.size.breakpoint['min-width'].lg}) {
        border-top: 1px solid ${props.theme.color.monochrome.light};
        text-align: center;
      }
    `}

  ${props =>
    !props.availabilityEnabled &&
    css`
      /* desktop */
      @media only screen and (min-width: ${props.theme.size.breakpoint['min-width'].lg}) {
        order: unset;
      }

      /* tablet and mobile */
      @media only screen and (max-width: ${props.theme.size.breakpoint['max-width'].md}) {
        text-align: center;
        border-top: 1px solid ${props.theme.color.monochrome.light};
      }
    `}
`

export const Localisation = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  line-height: 1.5rem;
  text-align: left;
  padding: 10px;
  order: 1;
  border-top: 1px solid ${props => props.theme.color.monochrome.light};

  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].md}) {
    border: none;
    justify-content: left;
  }

  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    order: unset;
    padding: 8px 0;
  }
`

export const LoadingLocalisation = styled(Localisation)`
  /* Rectangle skeleton wrapper */
  & > div {
    padding: 0;
  }

  & svg {
    vertical-align: middle;
    width: 100%;
    @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
      width: 350px;
    }
  }
`

export const LocalisationContainer = styled.div`
  display: inline-block;
`

export const LocalisationHeaderWrapper = styled.div`
  display: inline-flex;
  background-color: ${props => props.theme.color.palette['brand-1-light']};
  border-radius: 2px;
  padding: 8px;

  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
    display: inline-flex;
  }
`

export const LocalisationIcon = styled(LocationIcon)`
  margin-right: 5px;
  vertical-align: middle;
  height: 1.5em;
  width: 1.5em;
  fill: ${props => props.theme.color.palette['brand-1']};
`

export const LocalisationText = styled.span`
  color: ${props => props.theme.color.palette['brand-1']};
`

export const AvailabilityErrorAlertContainer = styled.div`
  padding: 0 15px;

  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    padding: 0;
  }
`

export const AvailabilityErrorAlert = styled(Alert)`
  padding: 10px 15px;
  border-radius: 2px;
`
