import React from 'react'
import { ErrorBoundary } from '@sainsburys-tech/bolt'
import PropTypes from 'prop-types'

import useCitrusBanners from '../../hooks/useCitrusBanners'
import InViewBanner from './InViewBanner'

const CitrusBanners = ({ searchTerm, categoryName, placement, clpPosition }) => {
  const banners = useCitrusBanners(searchTerm, categoryName, placement)

  return (
    <ErrorBoundary>
      <div data-test='citrus-bannersx'>
        {banners?.length > 0 && placement !== 'category-landing-argos'
          ? banners.map(banner => (
              <InViewBanner
                key={banner.position}
                searchTerm={searchTerm}
                categoryName={categoryName}
                placement={placement}
                banner={banner}
              />
            ))
          : null}
        {banners?.length > 0 && placement === 'category-landing-argos' && clpPosition === 'top' ? (
          <InViewBanner searchTerm={searchTerm} categoryName={categoryName} placement={placement} banner={banners[0]} />
        ) : null}
        {banners?.length > 1 && placement === 'category-landing-argos' && clpPosition === 'bottom' ? (
          <InViewBanner searchTerm={searchTerm} categoryName={categoryName} placement={placement} banner={banners[1]} />
        ) : null}
      </div>
    </ErrorBoundary>
  )
}

CitrusBanners.propTypes = {
  searchTerm: PropTypes.string,
  categoryName: PropTypes.string,
  placement: PropTypes.string,
  clpPosition: PropTypes.string
}

CitrusBanners.defaultProps = {
  searchTerm: null,
  categoryName: '',
  placement: '',
  clpPosition: 'top'
}

export default CitrusBanners
