import { getValue } from './ConfigProvider'
import { availabilityExclusions } from '../config'

const toBoolean = value => value === true || value === 'true'

export const availabilityIsEnabled = ({ pageType = 'search', isClothing = false, urlPath = '' } = {}) => {
  const isBrandEnabled = getValue('features.availability.enabled') || false
  const isDirectory = pageType === 'directory'
  const isPageTypeIncluded = !isDirectory && !isClothing

  const isEnvironmentEnabled =
    typeof window !== 'undefined' ? toBoolean(window.availabilityEnabled) : toBoolean(process.env.AVAILABILITY_ENABLED)

  const isPageExcluded = availabilityExclusions.some(url => urlPath.toLowerCase().includes(url.toLowerCase()))

  return isBrandEnabled && isEnvironmentEnabled && isPageTypeIncluded && !isPageExcluded
}

export const availabilityRadiusToggleIsEnabled =
  typeof window !== 'undefined'
    ? toBoolean(window.availabilityRadiusToggleEnabled)
    : toBoolean(process.env.AVAILABILITY_RADIUS_TOGGLE_ENABLED)
