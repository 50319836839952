import { CATEGORY_FILTER_ID } from './CategoryHelper'

export class FilterUriFragmentsHelper {
  static facetHasNoSelectedFilter(appliedFilterId, appliedFilterUriFragments) {
    return (
      typeof appliedFilterUriFragments[appliedFilterId] === 'undefined' && this.isFilterNotCategory(appliedFilterId)
    )
  }

  static splitFilterValue(appliedFilter) {
    return appliedFilter.includes(':') ? appliedFilter.split(`:`)[1] : ''
  }

  static isFilterNotCategory(appliedFilterId) {
    return appliedFilterId !== CATEGORY_FILTER_ID
  }

  static isFilterCategory(appliedFilterId) {
    return appliedFilterId === CATEGORY_FILTER_ID
  }
}
