const themeContract = {
  argos: {
    filterLabel: {
      backgroundColor: 'brand-1',
      color: 'white'
    }
  },
  habitat: {
    filterLabel: {
      backgroundColor: 'brand-1',
      color: 'white'
    }
  },
  tu: {
    filterLabel: {
      backgroundColor: 'brand-1',
      color: 'white'
    }
  }
}

export { themeContract }
