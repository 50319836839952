import React from 'react'
import formatPostcode from '../../utils/formatPostcode'
import { formatMilesText } from '../../helpers/AvailabilityHelper'

export default {
  localised: (localisationPostcode, radius) => ({
    headerText: `Collection and delivery for ${formatPostcode(localisationPostcode)}`,
    bodyText: (
      <>
        We’ll show you all collection locations within <strong>{radius}</strong> {formatMilesText(radius)}
      </>
    )
  }),
  unlocalised: {
    headerText: 'Enter your postcode',
    bodyText: 'With your postcode added we can show you what’s available for delivery and collection near you'
  },
  errorMessages: {
    emptyPostcodeMessage: 'Please enter your postcode',
    invalidPostcodeMessage: 'Is this definitely the right postcode?',
    failedCallMessage: `Sorry, we’re experiencing some difficulties with our service right now. We’re trying to fix the problem as soon as possible`
  }
}
