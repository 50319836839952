import { createSelector, createStructuredSelector } from 'reselect'
import { contentAreaSelector } from './content'
import { pageTypeSelector, isSearchVariantSelector } from './application'
import { mergeProducts } from '../helpers/CitrusHelper'
import { splitByRelevancy, mergeContentInList } from '../helpers/ProductHelper'
import { createDeepEqualSelector } from './selectors'
import { contentAreaIds } from '../config'

const showCitrusSelector = state => state.citrus.show
const currentPageSelector = state => state.product.currentPage
const isCannedSelector = state => state.product.isCannedSearch
export const totalDataSelector = state => state.product.numberOfResults
const categoryMatchSelector = state => state.product.meta?.categoryMatch
const pageSizeSelector = state => state.product.meta?.pageSize
const totalPagesSelector = state => state.product.meta?.totalPages

export const appliedFiltersOrdersSelectors = createDeepEqualSelector(
  state => state.product.appliedFiltersOrder,
  v => v
)

export const appliedFiltersSelector = createDeepEqualSelector(
  state => state.product.appliedFilters,
  v => v
)

const citrusProductsSelector = createDeepEqualSelector(
  state => state.citrus.products,
  v => v
)

const productsSelector = createDeepEqualSelector(
  state => state.product.products,
  v => v
)

export const appliedFilterUriFragmentsSelector = createDeepEqualSelector(
  state => state.product.appliedFilterUriFragments,
  v => v
)

export const filtersSelector = createDeepEqualSelector(
  state => state.product.filters,
  v => v
)

export const appliedSortBySelector = createDeepEqualSelector(
  state => state.product.appliedSortBy,
  v => v
)

export const searchTermSuggestionsSelector = createDeepEqualSelector(
  state => state.product.searchTermSuggestions,
  v => v
)

// Whether to display content in lists
const showInspirationSelector = createSelector(
  pageTypeSelector,
  isCannedSelector,
  isSearchVariantSelector,
  (pageType, isCanned, isSearchVariant) => {
    return pageType === 'browse' || isCanned || (pageType === 'search' && isSearchVariant)
  }
)

export const categoryNameFromFilterSelector = createSelector(appliedFiltersSelector, filters => {
  const categoryFilter = filters.find(e => e.id === 'category')
  if (categoryFilter && categoryFilter.labels) return categoryFilter.labels[0]
})

export const hasProductsSelector = createSelector(productsSelector, products => products && products.length >= 0)

export const allProductsSelector = createSelector(
  productsSelector,
  showCitrusSelector,
  citrusProductsSelector,
  contentAreaSelector,
  currentPageSelector,
  showInspirationSelector,
  (products, showCitrus, citrusProducts, contentAreas, currentPage, showInspiration) => {
    const contentInList = contentAreas?.find(area => area?.id === contentAreaIds.ContentInListArea)
    const inspirationCards = contentInList?.attributes?.length && contentInList.attributes
    const productsWithInspiration =
      showInspiration && currentPage === 1 && inspirationCards
        ? mergeContentInList(products, inspirationCards)
        : products
    return showCitrus
      ? splitByRelevancy(mergeProducts(productsWithInspiration, citrusProducts))
      : splitByRelevancy(productsWithInspiration)
  }
)

export const metaSelector = createStructuredSelector({
  totalData: totalDataSelector,
  categoryMatch: categoryMatchSelector,
  pageSize: pageSizeSelector,
  totalPages: totalPagesSelector,
  currentPage: currentPageSelector
})
