import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from '@sainsburys-tech/bolt'
import Template from './ListerTitle.template'
import { searchTypes } from '../../config'
import { PAGE_TYPES } from '../../utils/pageTypes'
import { getValue } from '../../utils/ConfigProvider'
import { refinedBrowsePageTitle, refinedBrowsePageTitleForSEO } from '../../helpers/RefinementHelper'
import * as Styled from './styles'

const ListerTitle = props => {
  const {
    inputSearchTerm,
    searchTerm,
    categoryName,
    isCannedSearch,
    hasBrandSuggestions,
    brandSuggestions,
    spellcheckedSearch,
    searchType,
    fallBackCategoryName,
    categoryUnmatchCallback,
    pageType,
    isClearance,
    isSaleRoute,
    enableCommunicatingRelevancy,
    categoryAncestors,
    subHeadingAncestorId,
    filters,
    appliedFilters,
    customListerTitle
  } = props
  const displayCatMatchExperience = false
  const displayTermInCategory =
    searchType === null &&
    categoryName &&
    inputSearchTerm &&
    inputSearchTerm.toLowerCase() !== categoryName.toLowerCase()

  const excludedParentCategoryIds = getValue('features.refinedTitlePage.excludedParentCategoryIds') || []

  const isExcludedRefinedTitleCategory = excludedParentCategoryIds?.includes(subHeadingAncestorId)

  switch (true) {
    case pageType === 'directory':
      return (
        <ErrorBoundary>
          <Styled.SearchTitle data-el='search-title' tabIndex='0' role='heading' aria-level='1'>
            <Styled.SearchTerm>{searchTerm}</Styled.SearchTerm>
          </Styled.SearchTitle>
        </ErrorBoundary>
      )

    case displayCatMatchExperience:
      return (
        <ErrorBoundary>
          <Styled.SearchTitle data-el='search-title' tabIndex='0' role='heading' aria-level='1'>
            {!isCannedSearch && (
              <h4 data-el='search-no-cat-match-label'>
                {Template.categ_match_top}
                <br />
                {Template.categ_match_bottom.start}
                {inputSearchTerm}
                {Template.categ_match_bottom.end}{' '}
                <a href='' onClick={categoryUnmatchCallback}>
                  here
                </a>
                .
              </h4>
            )}
            <Styled.SearchTerm>{categoryName || searchTerm}</Styled.SearchTerm>
          </Styled.SearchTitle>
        </ErrorBoundary>
      )

    case hasBrandSuggestions:
      let type = 'default'
      if (!!brandSuggestions[0].type && brandSuggestions[0].type === 'category_no_brand') {
        if (searchType === searchTypes.NRS) {
          type = 'category_no_results'
        } else {
          type = 'category_no_brand'
        }
      }
      return (
        <ErrorBoundary>
          <Styled.SearchTitle data-el='search-title' tabIndex='0' role='heading' aria-level='1'>
            {!isCannedSearch && (
              <h4 data-el='search-nic-label'>
                {Template[type].start}
                <strong>{inputSearchTerm}</strong>
                {Template[type].end}
              </h4>
            )}
            <Styled.SearchTerm>{categoryName || searchTerm}</Styled.SearchTerm>
          </Styled.SearchTitle>
        </ErrorBoundary>
      )

    case displayTermInCategory:
      return (
        <ErrorBoundary>
          <Styled.SearchTitle data-el='search-title' tabIndex='0' role='heading' aria-level='1'>
            <h4 data-el='search-title__label'>
              {Template.filter_by_category.start}
              {inputSearchTerm}
              {Template.filter_by_category.end}
            </h4>
            <h1>{categoryName}</h1>
          </Styled.SearchTitle>
        </ErrorBoundary>
      )

    case spellcheckedSearch && !!inputSearchTerm:
      return (
        <ErrorBoundary>
          <Styled.SearchTitle data-el='search-title' tabIndex='0' role='heading' aria-level='1'>
            <h4 data-el='search-replacement__label'>
              {Template.spellcheckTitleStart}
              <strong>{inputSearchTerm}</strong>
              {Template.spellcheckTitleEnd}
            </h4>
            <Styled.SearchTerm>{searchTerm}</Styled.SearchTerm>
          </Styled.SearchTitle>
        </ErrorBoundary>
      )

    case !!categoryName:
      let pageTitle = categoryName
      let pageTitleForScreenReaders = categoryName

      // Use custom lister title if category ID and refinements match a custom heading in the config
      if (customListerTitle) {
        pageTitle = customListerTitle
        pageTitleForScreenReaders = customListerTitle
      }
      // Only build refinement titles for Browse pages
      else if (
        pageType === PAGE_TYPES.BROWSE &&
        getValue('features.refinedTitlePage.enabled') &&
        !isExcludedRefinedTitleCategory
      ) {
        pageTitle = refinedBrowsePageTitle({
          categoryName,
          categoryAncestors,
          appliedFilters,
          filters
        })
        if (getValue('features.refinedTitlePage.useRefinementTypeConfig')) {
          pageTitleForScreenReaders = pageTitle
        } else {
          pageTitleForScreenReaders = refinedBrowsePageTitleForSEO({
            categoryName,
            categoryAncestors,
            appliedFilters,
            filters
          })
        }
      }
      if (isSaleRoute) {
        pageTitle = `${pageTitle} Sale`
      } else if (isClearance) {
        pageTitle = `${pageTitle} Clearance`
      }
      return (
        <ErrorBoundary>
          <Styled.SearchTitle data-el='search-title' tabIndex='0' role='heading' aria-level='1' aria-live='polite'>
            <Styled.SearchTerm aria-hidden='true' dangerouslySetInnerHTML={{ __html: pageTitle }} />
            <span className='sr-only'>{pageTitleForScreenReaders}</span>
          </Styled.SearchTitle>
        </ErrorBoundary>
      )

    case !!searchTerm:
      return (
        <ErrorBoundary>
          <Styled.SearchTitle data-el='search-title' tabIndex='0' role='heading' aria-level='1'>
            {!isCannedSearch && (
              <h4 data-el='search-title__label'>
                {enableCommunicatingRelevancy ? Template.title_relevancy_1 : Template.title}
              </h4>
            )}
            <Styled.SearchTerm>{inputSearchTerm}</Styled.SearchTerm>
          </Styled.SearchTitle>
        </ErrorBoundary>
      )

    default:
      return (
        <ErrorBoundary>
          <Styled.SearchTitle data-el='search-title' tabIndex='0' role='heading' aria-level='1'>
            <Styled.SearchTerm aria-hidden='true' dangerouslySetInnerHTML={{ __html: fallBackCategoryName }} />
            <span className='sr-only'>{fallBackCategoryName}</span>
          </Styled.SearchTitle>
        </ErrorBoundary>
      )
  }
}

ListerTitle.propTypes = {
  searchTerm: PropTypes.string,
  categoryName: PropTypes.string,
  isCannedSearch: PropTypes.bool,
  spellcheckedSearch: PropTypes.bool,
  inputSearchTerm: PropTypes.string,
  hasBrandSuggestions: PropTypes.bool,
  brandSuggestions: PropTypes.array,
  searchType: PropTypes.string,
  pageType: PropTypes.string,
  isClearance: PropTypes.bool,
  isSaleRoute: PropTypes.bool,
  enableCommunicatingRelevancy: PropTypes.bool,
  fallBackCategoryName: PropTypes.string,
  categoryUnmatchCallback: PropTypes.func,
  categoryAncestors: PropTypes.array,
  subHeadingAncestorId: PropTypes.string,
  appliedFilters: PropTypes.array,
  filters: PropTypes.array,
  customListerTitle: PropTypes.string
}

ListerTitle.defaultProps = {
  searchTerm: '',
  categoryName: '',
  isCannedSearch: false,
  spellcheckedSearch: false,
  inputSearchTerm: '',
  hasBrandSuggestions: false,
  brandSuggestions: [],
  searchType: null,
  pageType: null,
  isClearance: false,
  isSaleRoute: false,
  enableCommunicatingRelevancy: false,
  fallBackCategoryName: '',
  categoryUnmatchCallback: () => {},
  categoryAncestors: [],
  subHeadingAncestorId: '',
  appliedFilters: [],
  filters: [],
  customListerTitle: ''
}

export default React.memo(ListerTitle)
