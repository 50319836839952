import deepcopy from 'lodash.clonedeep'
import { initialState, getProducts } from '.'
import Actions from '../../constants/citrus'

// -------------------------------------------------
// ACTIONS
// -------------------------------------------------
export const handleCitrus = products => ({ type: Actions.RECEIVED_CITRUS_PRODUCTS, products })
export const showCitrusProducts = () => ({ type: Actions.SHOW_CITRUS_PRODUCTS })
export const hideCitrusProducts = () => ({ type: Actions.HIDE_CITRUS_PRODUCTS })
export const onCitrusBeaconSent = payload => ({ type: Actions.BEACON_SENT, productSku: payload.productSku })
export const clearCitrusProducts = () => ({ type: Actions.CLEAR_CITRUS_PRODUCTS })

// -------------------------------------------------
// REDUCER
// -------------------------------------------------
export default (state = initialState, action) => {
  const newState = deepcopy(state)

  switch (action.type) {
    case Actions.RECEIVED_CITRUS_PRODUCTS:
      newState.products = getProducts(action.products)
      return newState
    case Actions.SHOW_CITRUS_PRODUCTS:
      newState.show = true
      return newState
    case Actions.HIDE_CITRUS_PRODUCTS:
      newState.show = false
      return newState
    case Actions.BEACON_SENT:
      newState.products = newState.products.map(product => {
        if (product.id === action.productSku) {
          // eslint-disable-line
          product.attributes = { ...product.attributes, beaconSent: true }
        }
        return product
      })
      return newState
    case Actions.CLEAR_CITRUS_PRODUCTS:
      if (state.products.length > 0) {
        newState.products = []
        return newState
      }
      return state
    default:
      return state
  }
}
