import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const SuggestionBanner = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 15px;
  }

  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    margin: 0;
  }
`

export const BannerLinks = styled.div`
  width: 100%;
  border-radius: 3px;
  padding: 0;
  margin: 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
    padding: 0;
    margin: 0 0 0 3px;
    width: auto;
  }
`
export const SuggestionLink = styled(Link)`
  font-size: 15px;
  color: ${props => props.theme.color.alias.link.default.color};
  text-decoration: underline;
`
