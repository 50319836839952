import { getSearchPath, getBrowsePath } from '@sainsburys-tech/boltui-utils'
import { loadingStart } from '../redux/application/application'
import { BrowseHelper } from '../helpers/BrowseHelper'
import { getUriFragmentForFilterId } from '../redux/product'
import { deselectFilter } from '../redux/product/product'
import { getSearchTermForSearchType, getSearchType } from '../helpers/ProductHelper'

export default function(context, payload) {
  const productStore = context.reduxStore.getState().product
  const categoryStore = context.reduxStore.getState().category
  const applicationStore = context.reduxStore.getState().application
  const { appliedSortBy, appliedFilterUriFragments, filters, isCannedSearch } = productStore
  const filtersToRemove = getUriFragmentForFilterId(
    payload.filterId.toLowerCase(),
    payload.facetId.toLowerCase(),
    filters
  )
  const searchTerm = getSearchTermForSearchType(productStore)
  const filterUriFragments = appliedFilterUriFragments.allFilters

  filtersToRemove.forEach(filterToRemove => {
    filterToRemove = filterToRemove.split(':')[1] //eslint-disable-line
    Object.keys(filterUriFragments).forEach(fragmentKey => {
      filterUriFragments[fragmentKey] = filterUriFragments[fragmentKey].filter(
        uriFragment => uriFragment !== filterToRemove
      )
    })
  })

  context.reduxStore.dispatch(loadingStart())

  // Remove filter from the applied order
  context.reduxStore.dispatch(deselectFilter(payload))

  if (searchTerm) {
    const { categoryFilterId: categoryId, categoryMatchOverride } = productStore
    const url = getSearchPath(searchTerm, {
      categoryId,
      filterUriFragments,
      sortBy: appliedSortBy,
      searchType: getSearchType(productStore),
      isCannedSearch,
      categoryMatch: categoryMatchOverride
    })

    context.history.push(url)

    return
  }

  const { ancestors, categoryName, categoryId } = categoryStore
  const { isClearance, isSaleRoute } = applicationStore
  const params = {
    currentCatName: categoryName,
    ancestors,
    currentCatId: categoryId,
    filterUriFragments,
    sortBy: appliedSortBy,
    isClearance,
    isSale: isSaleRoute
  }

  const url = getBrowsePath(BrowseHelper.buildCorrectUrlBrowseParams(params))
  context.history.push(url)
}
