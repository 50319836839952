import { PAGE_TYPES } from "../utils/pageTypes";
import { getValue, getBrand } from '../utils/ConfigProvider'
import getDefaultSort from "../utils/defaultSort";

const brand = getBrand()
const enableSortbyNew = getValue('features.productList.enableSortbyNew')

const config = {
  basePath: '',
  home: 'https://www.argos.co.uk',
  domain: 'www.argos.co.uk',
  xhrPath: '/finder-api', // Path for XHR to be served from
  xhrTimeout: 5000,
  httpStatusCodes: {
    success: 200,
    serverError: 500,
    serviceUnavailable: 503,
    notFound: 404,
    permanentRedirect: 301,
    temporaryRedirect: 302
  },
  getProductPath: (partNumber) => {
    return `product/${partNumber}`
  },
  globalFilterIds: ['fasttrack', 'price', 'customer rating'],
  dfp: {
    channelId: 42685526,
    enabled: true
  }
}

const getSortByNewValues = (isBrowse = false, categoryId = undefined, isClearance = false) => ({
  'key': 'newness_desc',
  'value': {
    'type': 'newness',
    'order': 'desc'
  },
  'label': (brand === 'tu') ? 'New Arrivals' : 'New in',
  'path': getDefaultSort(!isBrowse, isBrowse, categoryId, isClearance) === 'newness' ? '' : 'sort:new-arrivals'
})

const sortByValues = [{
  'key': 'price_asc',
  'value': {
    'type': 'price',
    'order': 'asc'
  },
  'label': 'Price: Low - High',
  'path': 'sort:price'
}, {
  'key': 'price_desc',
  'value': {
    'type': 'price',
    'order': 'desc'
  },
  'label': 'Price: High - Low',
  'path': 'sort:price:desc'
}, {
  'key': 'customer rating_desc',
  'value': {
    'type': 'customer rating',
    'order': 'desc'
  },
  'label': 'Customer Rating',
  'path': 'sort:customer-rating'
}]

config.searchSortByValues = [
  {
    'key': 'relevance_desc',
    'value': {
      'type': 'relevance',
      'order': 'desc'
    },
    'label': 'Relevance',
    'path': getDefaultSort(true, false) === 'newness' ? 'sort:relevance:desc' : ''
  },
  ...sortByValues,
  ...(enableSortbyNew ? [getSortByNewValues()] : [])
]

config.browseSortByValues = (categoryId, isClearance = false) => {
  const browseSortByValues = [
    {
      'key': 'relevance_desc',
      'value': {
        'type': 'relevance',
        'order': 'desc'
      },
      'label': 'Relevance',
      'path': getDefaultSort(false, true, categoryId, isClearance) === 'newness' ? 'sort:relevance:desc' : ''
    },
    ...sortByValues,
  ]

  if (enableSortbyNew) {
    // Add newness filter to top of sort options if it is the default
    if ((brand === 'tu' || isClearance) && getDefaultSort(false, true, categoryId, isClearance) === 'newness') {
      browseSortByValues.unshift(getSortByNewValues(true, categoryId, isClearance))
    } else {
      // Add newness filter to bottom of sort options if not the default
      browseSortByValues.push(getSortByNewValues(true, categoryId, isClearance))
    }
  }

  return browseSortByValues
}

const categoryTypes = {
  PLP: 'PLP',
  CLP: 'CLP',
  PLPPLUS: 'PLPPLUS',
  SUBHEADING: 'SUBHEADING',
  TOPLEVELCATEGORY: 'TOPLEVELCATEGORY',
  MASTERCATEGORY: 'MASTERCATEGORY'
}

const contentAreaTypes = {
  GridLayoutThree: 'GL003',
  NavigationComponent: 'M012',
  ContentInList: 'M053'
}

const contentAreaIds = {
  AreaA: 'A',
  SeoArea: 'SEO',
  Dfp: {
    top: 'DFPTOP',
    bottom: 'DFPBOTTOM'
  },
  ContentInListArea: 'G'
}

const seoMetaPlaceholders = {
  category: '{{cat}}',
  refinement: '{{ref}}',
  brand: '{{brand}}',
  categoryFallback: '{{cat}} | {{brand}}',
  categoryRefinedFallback: '{{ref}} {{cat}} | {{brand}}'
}

const searchTypes = {
  brandsNIC: 'nic',
  didYouMean: 'dym',
  spellChecked: 'sc',
  NRS: 'nrs'
}

const availabilityExclusions = [
  // Rings
  '/browse/jewellery-and-watches/womens-jewellery/womens-rings/c:29317',
  '/browse/jewellery-and-watches/mens-jewellery/mens-rings/c:29330',
  '/list/shop-for-engagement-rings',
  '/list/shop-for-engagement-and-bridal',
  '/list/shop-all-rings-at-argos',
  '/list/shop-all-jewellery-at-argos',
  '/list/shop-for-mens-wedding-rings',
  '/search/rings',
  '/search/engagement-ring/',
  '/search/ring',
  '/search/wedding-ring',
  '/search/mens-rings'
]

const citrusRestrictedPages = [
  PAGE_TYPES.CANNED
]

export {
  categoryTypes,
  contentAreaTypes,
  contentAreaIds,
  seoMetaPlaceholders,
  searchTypes,
  availabilityExclusions,
  citrusRestrictedPages
}
export default config
