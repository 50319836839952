import React, { useState, useEffect } from 'react'
import { Button } from '@sainsburys-tech/bolt'
import { Container, Form, Label, Input } from './styles'

const DatePicker = () => {
  const [date, setDate] = useState('')

  let dateParam

  if (typeof window !== 'undefined') {
    dateParam = new URLSearchParams(window.location.search).get('forDate')
  }

  const onDateSubmit = event => {
    event.preventDefault()
    if (!date) {
      return
    }
    const { origin, pathname } = window.location

    window.location.href = `${origin}${pathname}?forDate=${date}`
  }

  useEffect(() => {
    if (dateParam) setDate(dateParam)
  }, [dateParam])

  return (
    <Container>
      <Form data-test='date-form' onSubmit={onDateSubmit}>
        <Label htmlFor='preview'>
          Preview date:
          <Input
            type='datetime-local'
            id='preview'
            data-test='date-input'
            name='preview'
            required
            pattern='[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}'
            value={date}
            onChange={event => setDate(event.target.value)}
          />
          <Button type='submit'>Preview</Button>
        </Label>
      </Form>
    </Container>
  )
}

export default DatePicker
