import { getBrowsePath } from '@sainsburys-tech/boltui-utils'
import { MASTER_CATEGORY_ID, BrowseHelper } from '../../helpers/BrowseHelper'
import Logger from '../../utils/Logger'
import { categoryTypes } from '../../config'
import { get } from '../../utils/PolyfillLodash'

const logger = new Logger()
export const LIMITED_STOCK_CLEARANCE_ID = 33372952
// -------------------------------------------------
// INITIAL STATE
// -------------------------------------------------
export const initialState = {
  children: [],
  ancestors: [],
  subHeadingAncestorId: '',
  siblings: [],
  categoryId: null,
  categoryName: null,
  productsInCategory: null,
  productsInCategoryTree: null,
  cache: {},
  isPopulated: false,
  status: null,
  failedService: null,
  included: null,
  isPlp: false,
  isPlpPlus: false,
  isPlpPlusDescendant: false,
  categoryType: null,
  isCategoryLive: null,
  categoryUrl: null,
  categoryCanonicalTree: [],
  topLevelCategoryId: '',
  categoryLevel: null,
  subcategories: null,
  templateType: null
}

// -------------------------------------------------
// HELPERS
// -------------------------------------------------
export function getCurrentCategoryId(categories) {
  if (!categories || categories.length < 1) return null

  const currentCategory = categories[categories.length - 1]
  return currentCategory ? currentCategory.id : null
}

export function getCategoryAncestors(response) {
  if (!!response && !!response.data) {
    let categoryAncestors = response.data
    // removes master category
    categoryAncestors = categoryAncestors.filter(ancestor => ancestor.id !== MASTER_CATEGORY_ID)
    const ancestorCount = categoryAncestors.length
    // removes current category
    categoryAncestors = categoryAncestors.slice(0, ancestorCount - 1)
    return categoryAncestors
  }

  return []
}

export function getCategoryName(response) {
  if (!!response && !!response.data) {
    if (response.data[0] && response.data[0].attributes) {
      return response.data[0].attributes.name
    }
    const categories = response.data
    const currentCategory = categories[categories.length - 1]
    return currentCategory ? currentCategory.name : null
  }

  return null
}

export function getCategoryLevel(response, ...args) {
  logger.debug('functionLogger', { args }, 'CategoryHelper getCategoryLevel')

  if (!!response && !!response.data) {
    const data = response.data[0]
    if (data && data.attributes && data.attributes.level) {
      return data.attributes.level
    }
  }

  return null
}

export function isPlpPlus(data, ...args) {
  logger.debug('functionLogger', { args }, 'CategoryHelper isPlpPlus')
  if (data && data.attributes) {
    const sanitisedCategoryType = getCategoryType(data)
    return sanitisedCategoryType === categoryTypes.PLPPLUS
  }
  return false
}

export function isLeafNode(data, ...args) {
  logger.debug('functionLogger', { args }, 'CategoryHelper isLeafNode')
  if (data && data.attributes) {
    const sanitisedCategoryType = getCategoryType(data)
    return sanitisedCategoryType === categoryTypes.PLP
  }
  return false
}

export function getCategoryType(data, ...args) {
  logger.debug('functionLogger', { args }, 'CategoryHelper getCategoryType')
  return data && data.type
    ? data.type
        .replace(/\s|-/g, '')
        .trim()
        .toUpperCase()
    : ''
}

export function getSubHeadingAncestor(response) {
  const data = response?.data?.[0]
  const ancestors = data?.relationships?.ancestors?.data || []
  // Reverse ancestors array to find last, immediate subheading ancestor
  const reverseAncestors = ancestors.slice().reverse()
  const subHeadingAncestor = reverseAncestors?.find(
    ancestor => this.getCategoryType(ancestor) === categoryTypes.SUBHEADING
  )
  return subHeadingAncestor?.id || ''
}

export function flattenAncestors(response, ...args) {
  logger.debug('functionLogger', { args }, 'CategoryHelper flattenAncestors')
  if (!response || !response.data[0]) return null

  const data = response.data[0]
  let ancestorsRelationships
  let ancestorsCategoryIds = []

  if (data && data.relationships) {
    ancestorsRelationships = data.relationships.ancestors.data.filter(ancestor => {
      return getCategoryType(ancestor) !== categoryTypes.SUBHEADING
    })
    ancestorsCategoryIds = ancestorsRelationships.map(item => item.id)
  }

  const ancestorCategories = get(response, 'data[0].relationships.ancestors.data', []).filter(category =>
    ancestorsCategoryIds.includes(category.id)
  )
  return ancestorCategories.sort((categoryA, categoryB) => categoryA.attributes.level > categoryB.attributes.level)
}

export function flattenChildren(response, ...args) {
  logger.debug('functionLogger', { args }, 'CategoryHelper flattenChildren')
  if (!response || !response.data[0]) return null

  const data = response.data[0]
  let childRelationships
  let childCategoryIds = []

  if (data && data.relationships && data.relationships.children && data.relationships.children.data) {
    childRelationships = data.relationships.children.data
    childCategoryIds = childRelationships
      .map(item => item.id)
      .filter(id => id !== LIMITED_STOCK_CLEARANCE_ID.toString())
  }

  return get(response, 'data[0].relationships.children.data', []).filter(category =>
    childCategoryIds.includes(category.id)
  )
}

export function flattenSiblings(response, ...args) {
  logger.debug('functionLogger', { args }, 'CategoryHelper flattenSiblings')
  if (!response || !response.data[0]) return null

  const data = response.data[0]
  let siblingRelationships
  let siblingCategoryIds = []
  let siblingCategories = []

  if (data && data.relationships && data.relationships.siblings && data.relationships.siblings.data) {
    siblingRelationships = data.relationships.siblings.data
    siblingCategoryIds = siblingRelationships
      .map(item => item.id)
      .filter(id => id !== LIMITED_STOCK_CLEARANCE_ID.toString())
    siblingCategories = get(response, 'data[0].relationships.siblings.data', []).filter(category =>
      siblingCategoryIds.includes(category.id)
    )
  }

  return siblingCategories
}

export function getPlpPlusCategories(state) {
  if (!state) return null

  const categories = []

  if (state.isPlpPlus) {
    categories.push({
      id: state.categoryId,
      type: state.categoryType,
      attributes: {
        name: state.categoryName,
        level: state.categoryLevel,
        productsInCategory: state.productsInCategory
      }
    })
    categories.push(...state.children)
  } else if (state.isPlpPlusDescendant) {
    categories.push(...state.siblings)
    state.ancestors[state.ancestors.length - 1].attributes.productsInCategory = state.siblings.reduce(
      (acc, sibling) => acc + sibling.attributes.productsInCategory,
      0
    )
    categories.push(state.ancestors[state.ancestors.length - 1])
  } else if (state.isClearance) {
    categories.push(...state.children)
  }

  return categories.sort((catA, catB) => catA.attributes.level - catB.attributes.level)
}

export function getTopLevelCategory(ancestors) {
  if (ancestors && ancestors.length) {
    return ancestors.find(ancestor => {
      return (
        ancestor.type
          .replace(/\s|-/g, '')
          .trim()
          .toUpperCase() === categoryTypes.TOPLEVELCATEGORY
      )
    })
  }

  return null
}

export function addPathToChildrenCategories(children, ancestors, currentCatName, ...args) {
  logger.debug('functionLogger', { args }, 'CategoryHelper buildUrlForCategories')
  if (!children) return null
  return children.map(child => {
    if (getCategoryType(child) === categoryTypes.SUBHEADING) {
      addPathToChildrenCategories(child.attributes.children, ancestors, currentCatName)
    } else {
      const browseParams = {
        currentCatName,
        ancestors,
        currentCatId: child.id,
        futureCatName: child.attributes.name
      }
      const params = BrowseHelper.buildCorrectUrlBrowseParams(browseParams)
      child.attributes.path = getBrowsePath(params)
    }
    return child
  })
}

export function isPlpPlusDescendant(ancestors) {
  return !!ancestors && isPlpPlus(ancestors[ancestors.length - 1])
}

export default {
  getCurrentCategoryId,
  getCategoryAncestors,
  getCategoryName,
  getPlpPlusCategories,
  getCategoryLevel,
  getTopLevelCategory,
  isPlpPlus,
  isLeafNode,
  isPlpPlusDescendant,
  getCategoryType,
  getSubHeadingAncestor,
  flattenAncestors,
  flattenChildren,
  flattenSiblings,
  addPathToChildrenCategories
}
