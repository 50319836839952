const ProcessTime = function ProcessTime() {
  this.amount = 0
  this.type = 'meter'
}

ProcessTime.prototype.update = function(n) {
  this.amount = n || 1
}

ProcessTime.prototype.printObj = function() {
  return { type: 'meter', amount: this.amount, unit: 'seconds' }
}

export default ProcessTime
