import { useCallback, useReducer } from 'react'

function useAsync() {
  const initialState = {
    status: 'idle',
    complete: false,
    error: undefined,
    data: undefined
  }

  const asyncReducer = (state, action) => {
    switch (action.type) {
      case 'reset':
        return { ...initialState }
      case 'pending':
        return { ...initialState, status: 'pending' }
      case 'resolved':
        return { ...initialState, status: 'resolved', complete: true, data: action.payload }
      case 'rejected':
        return { ...initialState, status: 'rejected', complete: true, error: action.payload }

      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(asyncReducer, initialState)

  const run = useCallback(
    promise => {
      dispatch({ type: 'pending' })

      promise.then(
        data => {
          dispatch({ type: 'resolved', payload: data })
        },
        error => {
          dispatch({ type: 'rejected', payload: error.message })
        }
      )
    },
    [dispatch]
  )

  const resetHookState = () => dispatch({ type: 'reset' })

  return { ...state, run, resetHookState }
}

export default useAsync
