import React from 'react'
import PropTypes from 'prop-types'
import ListerPage from '../containers/ListerPage/ListerPage'
import executeBrowse from '../actions/executeBrowse'
import formatActionPayload from '../helpers/RoutingHelper'

class Browse extends React.Component {
  static fetchData(routeMatch, url, store, fetchr) {
    const context = {
      reduxStore: store,
      service: fetchr
    }

    const payload = formatActionPayload(routeMatch, url)

    return new Promise(resolve => {
      executeBrowse(context, payload, resolve)
    })
  }

  componentDidMount() {
    const { location } = this.props
    const isHabitatBrowsePath = path => (path && path.startsWith('/browse') && process.env.BRAND === 'habitat') || false
    if (isHabitatBrowsePath(location.pathname) && document.getElementById('hulla') !== null) {
      const script = document.createElement('script')
      script.src = 'https://live.hullabalook.com/static/js/hulla_embed.js'
      script.async = true

      document.body.appendChild(script)
      script.onload = () => window?.Hulla?.init({ embed_url: 'https://live.hullabalook.com', target_div: 'hulla' })
    }
  }

  render() {
    return <ListerPage />
  }
}

Browse.contextTypes = {
  fetchr: PropTypes.object
}

Browse.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
}

export default Browse
