import React from 'react'
import PropTypes from 'prop-types'
import { getValue } from '../../utils/ConfigProvider'
import FilterPillsContainer from '../FilterPillsContainer/FilterPillsContainer'

const getNumOfFilterValuesForFilterType = (pillFilterType = '', filters = []) => {
  if (!pillFilterType || filters.length === 0) return 0

  const filterType = filters.find(filter => filter.id === pillFilterType)
  const filterTypeValues = filterType?.values

  if (!filterTypeValues) return 0

  return filterTypeValues.length
}

const getFilterTypeToDisplay = (filterTypeList = [], filters = [], numOfFilterTypeValues = 0) => {
  if (!filterTypeList || !filters) return ''

  return filterTypeList.find(filterType => {
    const numOfFilterValues = getNumOfFilterValuesForFilterType(filterType, filters)

    if (numOfFilterValues < numOfFilterTypeValues) return false

    return numOfFilterValues
  })
}

const FilterPillsConfigWrapper = ({ pageType, filters, onActivate, onDeactivate, setClickOrigin }) => {
  const minNumOfFilterPillValuesToDisplay = 2

  const filterPillFiltersConfig = getValue('features.filterPills.filters')
  if (!filterPillFiltersConfig) return null

  // Getting the filter pills configuration per page type (browse, search, or default)
  const filterPillsTypes = pageType ? filterPillFiltersConfig[pageType] : filterPillFiltersConfig?.default
  if (!filterPillsTypes || filterPillsTypes.length === 0) {
    return null
  }

  // Selecting the filter type to display for the filter pills
  const pillFilterType = getFilterTypeToDisplay(filterPillsTypes, filters, minNumOfFilterPillValuesToDisplay)

  return (
    <FilterPillsContainer
      pageType={pageType}
      pillFilterType={pillFilterType}
      filters={filters}
      onActivate={pill => {
        onActivate(pill, pillFilterType)
      }}
      onDeactivate={pill => {
        onDeactivate(pill, pillFilterType)
      }}
      data-test='filter-pills'
      setClickOrigin={setClickOrigin}
    />
  )
}

FilterPillsConfigWrapper.propTypes = {
  pageType: PropTypes.string,
  filters: PropTypes.array,
  onActivate: PropTypes.func,
  onDeactivate: PropTypes.func,
  setClickOrigin: PropTypes.func
}

FilterPillsConfigWrapper.defaultProps = {
  pageType: '',
  filters: [],
  onActivate: () => {},
  onDeactivate: () => {},
  setClickOrigin: () => {}
}

export default FilterPillsConfigWrapper
