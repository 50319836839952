import styled from 'styled-components'
import { Tooltip } from '@sainsburys-tech/bolt'

const AvailabilityTooltip = styled(Tooltip)`
  /* Bubble */
  div[direction='downleft'] {
    background-color: ${props => props.theme.color.palette['brand-1']};
    box-shadow: none;
    border: none;
  }

  /* Close button */
  button > svg {
    fill: ${props => props.theme.color.monochrome.white};
  }

  /* Arrow */
  div[direction='down'] {
    &:after {
      border-bottom: 9px solid ${props => props.theme.color.palette['brand-1']};
    }

    &:before {
      border-bottom: 9px solid ${props => props.theme.color.palette['brand-1']};
    }
  }
`

const Text = styled.strong`
  color: ${props => props.theme.color.monochrome.white};
  text-align: left;
  padding-right: 5px;
  line-height: 24px;
`
export { AvailabilityTooltip, Text }
