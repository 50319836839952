import React from 'react'
import PropTypes from 'prop-types'
import { Pill } from '@sainsburys-tech/bolt'
import { PillsWrapper } from './styles'

const SimilarAiPills = ({ similarAiLinksData }) => {
  return (
    <div data-test='similar-ai-links' className='xs-block'>
      <h2>Related categories</h2>
      <PillsWrapper>
        {similarAiLinksData.map((link, index) => (
          <Pill key={index} label={link.name} href={link.url} />
        ))}
      </PillsWrapper>
    </div>
  )
}

SimilarAiPills.propTypes = {
  similarAiLinksData: PropTypes.array.isRequired
}

export default SimilarAiPills
