import { getSearchPath, getBrowsePath } from '@sainsburys-tech/boltui-utils'
import { loadingStart } from '../redux/application/application'
import Logger from '../utils/Logger'
import { BrowseHelper } from '../helpers/BrowseHelper'
import { getUriFragmentForFilterId } from '../redux/product'
import { getSearchTermForSearchType, getSearchType } from '../helpers/ProductHelper'

const logger = new Logger()

export default function(context, payload, ...args) {
  logger.debug('functionLogger', { args }, 'setCategory')

  const productStore = context.reduxStore.getState().product
  const categoryStore = context.reduxStore.getState().category
  const applicationStore = context.reduxStore.getState().application
  const { appliedSortBy, appliedFilterUriFragments, filters, isCannedSearch } = productStore
  const filtersToAdd = getUriFragmentForFilterId(payload.filterId, payload.facetId, filters)
  const searchTerm = getSearchTermForSearchType(productStore)
  let filterId = ''
  const filterUriFragments = appliedFilterUriFragments.allFilters

  if (filtersToAdd && filtersToAdd.length > 0) {
    if (filtersToAdd[0].includes('filter')) {
      ;[, filterId] = filtersToAdd[0].split(`filter=${payload.facetId}:`)
    }
  }

  if (filtersToAdd !== 'attributeFacets=true') {
    filterUriFragments[payload.facetId] = [filterId]
  }

  context.reduxStore.dispatch(loadingStart())

  if (searchTerm) {
    const url = getSearchPath(searchTerm, {
      categoryId: filterId,
      filterUriFragments,
      sortBy: appliedSortBy,
      searchType: getSearchType(productStore),
      isCannedSearch
    })
    context.history.push(url)

    return
  }

  const { ancestors, categoryName } = categoryStore
  const { isClearance, isSaleRoute } = applicationStore
  const params = {
    currentCatName: categoryName,
    ancestors,
    currentCatId: filterId,
    filterUriFragments,
    sortBy: appliedSortBy,
    isClearance,
    isSale: isSaleRoute
  }

  const url = getBrowsePath(BrowseHelper.buildCorrectUrlBrowseParams(params))
  context.history.push(url)
}
