/* eslint-disable react/no-string-refs */
import React from 'react'
import { ErrorBoundary } from '@sainsburys-tech/bolt'

import * as Styled from './styles'
import Template from './ErrorPage.template'

class ErrorPage extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      backLink: Template.href
    }
  }

  componentDidMount() {
    if (document.referrer.includes('argos.co.uk')) {
      this.setState({
        backLink: document.referrer
      })
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <Styled.ErrorPage
          className='container'
          itemProp='mainEntityOfPage'
          itemScope
          itemType='http://schema.org/Product'
          data-test='error-page'>
          <h2>{Template.header}</h2>
          <p>{Template.subHeader}</p>
          <div>
            <Styled.PageButton data-button-name='take-me-back' href={this.state.backLink} ref='takemeback'>
              {Template.back}
            </Styled.PageButton>
            <Styled.PageButton data-button-name='take-me-back-home' href={Template.href}>
              {Template.backHome}
            </Styled.PageButton>
            <Styled.PageButton data-button-name='contact-us' href={Template.contactHref}>
              {Template.contactUs}
            </Styled.PageButton>
          </div>
        </Styled.ErrorPage>
      </ErrorBoundary>
    )
  }
}

export default ErrorPage
