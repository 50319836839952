import { contentAreaIds } from '../../config'

// -------------------------------------------------
// INITIAL STATE
// -------------------------------------------------
export const initialState = {
  data: {},
  error: null
}

// -------------------------------------------------
// HELPERS
// -------------------------------------------------
export const getContentAreas = response => {
  if (response) {
    return response.filter(area => {
      return area.id !== contentAreaIds.SeoArea
    })
  }
  return []
}
