import React from 'react'
import PropTypes from 'prop-types'
import { getSearchPath, getBrowsePath } from '@sainsburys-tech/boltui-utils'
import { renderRoutes, matchRoutes } from 'react-router-config'
import { Propbar, ErrorBoundary } from '@sainsburys-tech/bolt'
import LazyHydrate from 'react-lazy-hydration'
import { withRouter } from 'react-router-dom'
import loadable from '@loadable/component'
import routes from '../config/routes'
import config from '../config'
import Modals from '../containers/Modals/Modals'
import HeaderWrapper from '../containers/Header/Header'
import DatePicker from './DatePicker/DatePicker'
import { loadingEnd, loadingStart, updateDestinationPageType } from '../redux/application/application'
import * as Styled from './styles'

const Footer = loadable(() => import(/* webpackChunkName: "footer" */ '@sainsburys-tech/bolt-footer'))

const Fetchr = require('fetchr')

class Application extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.loadingStart = this.loadingStart.bind(this)
    this.updateDestinationPageType = this.updateDestinationPageType.bind(this)
    this.loadingEnd = this.loadingEnd.bind(this)
    this.navigateTo = this.navigateTo.bind(this)
    this.reduxStore = context.store
    this.fetchr = new Fetchr({
      xhrPath: config.xhrPath, // Path for XHR to be served from
      xhrTimeout: config.xhrTimeout
    })
  }

  getChildContext() {
    return {
      loadingStart: this.loadingStart,
      updateDestinationPageType: this.updateDestinationPageType,
      loadingEnd: this.loadingEnd,
      navigateTo: this.navigateTo,
      getSearchPath,
      getBrowsePath,
      reduxStore: this.reduxStore,
      history: this.props.history,
      fetchr: this.fetchr
    }
  }

  componentDidMount() {
    this.props.history.listen(location => {
      const branch = matchRoutes(routes, location.pathname)
      branch.map(({ route, match }) => {
        const { fetchData } = route.component
        fetchData instanceof Function
          ? fetchData(match, location.search, this.reduxStore, this.fetchr)
          : Promise.resolve(null)
      })
    })
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.numberOfResults !== this.props.numberOfResults || nextProps.redirectPath !== this.props.redirectPath
    )
  }

  componentDidCatch(error, info) {
    // we need a logging solution for this in production
    // eg. http://docs.trackjs.com/tracker/framework-integrations#reactredux
    console.log(error, info)
  }

  setAppClassName() {
    const className = ['app']
    this.props.numberOfResults && this.props.numberOfResults > 0 && className.push('app--search-lister')
    return className.join(' ')
  }

  loadingStart(payload) {
    loadingStart(this.context, payload)
  }

  updateDestinationPageType(payload) {
    this.reduxStore.dispatch(updateDestinationPageType(payload))
  }

  loadingEnd() {
    loadingEnd(this.context)
  }

  navigateTo(path) {
    window.scrollTo(0, 0)
    this.props.history.push(path)
  }

  render() {
    const { redirectPath, brand } = this.props
    const toBoolean = value => value === true || value === 'true'
    const isPreviewAuthorEnabled =
      typeof window !== 'undefined'
        ? toBoolean(window.previewAuthorEnabled)
        : toBoolean(process.env.ENABLE_AUTHOR_PREVIEW)

    if (this.props && redirectPath !== '') {
      const { location } = window
      location.replace(redirectPath)
    }

    return (
      <>
        <Styled.GlobalStyle />
        <div className={this.setAppClassName()}>
          <HeaderWrapper useArgosHeader={brand === 'argos'} />
          <LazyHydrate ssrOnly>
            <ErrorBoundary>
              <Styled.PropbarContainer>
                <Propbar brand={brand} />
              </Styled.PropbarContainer>
            </ErrorBoundary>
          </LazyHydrate>

          <div id='findability' className='container'>
            {renderRoutes(routes)}
          </div>
          <Modals />
        </div>
        {isPreviewAuthorEnabled && <DatePicker />}
        {brand !== 'tu' && <Footer brand={brand} />}
      </>
    )
  }
}

Application.propTypes = {
  brand: PropTypes.string.isRequired,
  redirectPath: PropTypes.string,
  numberOfResults: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    listen: PropTypes.func.isRequired
  }).isRequired
}
Application.defaultProps = {
  redirectPath: '',
  numberOfResults: null
}

Application.contextTypes = {
  store: PropTypes.object,
  fetchr: PropTypes.object
}

Application.childContextTypes = {
  loadingStart: PropTypes.func,
  updateDestinationPageType: PropTypes.func,
  loadingEnd: PropTypes.func,
  navigateTo: PropTypes.func,
  getSearchPath: PropTypes.func,
  getBrowsePath: PropTypes.func,
  reduxStore: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  fetchr: PropTypes.object
}

export default withRouter(Application)
