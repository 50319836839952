import { getSearchPath, getBrowsePath } from '@sainsburys-tech/boltui-utils'
import { loadingStart } from '../redux/application/application'
import { deselectFacet } from '../redux/product/product'
import { FilterUriFragmentsHelper } from '../helpers/FilterUriFragmentsHelper'
import { getSearchTermForSearchType, getSearchType } from '../helpers/ProductHelper'
import { BrowseHelper } from '../helpers/BrowseHelper'

export default function(context, payload) {
  const productStore = context.reduxStore.getState().product
  const categoryStore = context.reduxStore.getState().category
  const applicationStore = context.reduxStore.getState().application
  const { appliedSortBy, appliedFilters, isCannedSearch } = productStore
  const searchTerm = getSearchTermForSearchType(productStore)
  const maintainedFacets = appliedFilters.filter(appliedFilter => appliedFilter.id !== payload.facetId)

  const filteredFacetFilters = {}
  maintainedFacets.forEach(facet => {
    const appliedFilterId = facet.id
    if (facet.filter) {
      facet.filter.split(`&`).forEach(chosenFilter => {
        const filterValue = FilterUriFragmentsHelper.splitFilterValue(chosenFilter)
        if (FilterUriFragmentsHelper.facetHasNoSelectedFilter(appliedFilterId, filteredFacetFilters)) {
          filteredFacetFilters[appliedFilterId] = [filterValue]
        } else if (FilterUriFragmentsHelper.isFilterNotCategory(appliedFilterId)) {
          filteredFacetFilters[appliedFilterId].push(filterValue)
        }
      })
    }
  })

  context.reduxStore.dispatch(loadingStart())

  // Remove facet filters from the applied order
  context.reduxStore.dispatch(deselectFacet(payload))

  let url
  if (searchTerm) {
    if (payload.facetId === 'category') {
      url = getSearchPath(searchTerm, {
        categoryId: null,
        filterUriFragments: filteredFacetFilters,
        sortBy: appliedSortBy,
        searchType: getSearchType(productStore),
        isCannedSearch,
        categoryMatch: 'no'
      })
    } else {
      const { categoryFilterId: categoryId, categoryMatchOverride } = productStore
      url = getSearchPath(searchTerm, {
        categoryId,
        filterUriFragments: filteredFacetFilters,
        sortBy: appliedSortBy,
        searchType: getSearchType(productStore),
        isCannedSearch,
        categoryMatch: categoryMatchOverride
      })
    }

    context.history.push(url)

    return
  }

  const { ancestors, categoryName, categoryId } = categoryStore
  const { isClearance, isSaleRoute } = applicationStore
  const params = {
    currentCatName: categoryName,
    ancestors,
    currentCatId: categoryId,
    filterUriFragments: filteredFacetFilters,
    sortBy: appliedSortBy,
    isClearance,
    isSale: isSaleRoute
  }

  context.history.push(getBrowsePath(BrowseHelper.buildCorrectUrlBrowseParams(params)))
}
