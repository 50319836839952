const findabilityDecoder = {}

/**
 * @param {string} text Encoded string
 */
findabilityDecoder.encode = function(text) {
  return text.replace(/-/g, '%252D').replace(/%20/g, '-')
}

/**
 * Encode angle brackets (<>) to prevent XSS
 * @param {string} text Decoded string
 */
findabilityDecoder.encodeAngleBrackets = function(text) {
  return text?.replace(/>/g, '%3E')?.replace(/</g, '%3C')
}

/**
 * @param {string} text Encoded string
 */
findabilityDecoder.decode = function(text) {
  return text.replace(/-/g, '%20').replace(/%252D/g, '-')
}

/**
 * @param {string} text Encoded string
 */
findabilityDecoder.getSanitised = function(text) {
  try {
    return decodeURIComponent(findabilityDecoder.decode(text))
  } catch (e) {
    return findabilityDecoder.decode(text)
  }
}

/**
 * Sanitise and decode search term but leave angle brackets encoded to prevent XSS
 * @param {string} text Encoded string
 */
findabilityDecoder.getSanitisedSearchTerm = function(searchTerm) {
  return findabilityDecoder.encodeAngleBrackets(findabilityDecoder.getSanitised(searchTerm))
}

findabilityDecoder.lowerCaseEncodedRefinements = function(refinements) {
  return findabilityDecoder
    .encode(findabilityDecoder.getSanitised(refinements).toLowerCase())
    .replace(/ /g, '-')
    .replace(/%2C/gi, '%252C')
}

findabilityDecoder.stripPunctuation = text => {
  return text.replace(/[^a-zA-Z0-9-]/g, '').replace(/^-*|-*$/g, '')
}

export default findabilityDecoder
