import { canUseDom } from '@sainsburys-tech/boltui-utils'
import { get } from '../utils/PolyfillLodash'

import { showNoResultsModal } from '../actions/noResultsModal'
import { loadingEnd } from '../redux/application/application'

export class ProductResponseHelper {
  static executeNoResultsModal(context, productResponse) {
    if (
      canUseDom() &&
      get(productResponse, 'response.data.length') === 0 &&
      get(productResponse, 'response.meta.appliedFilters.length') > 0
    ) {
      showNoResultsModal(context)
      context.reduxStore.dispatch(loadingEnd())
      return true
    }
    return false
  }
}
