import React from 'react'
import PropTypes from 'prop-types'
import { Accordion } from '@sainsburys-tech/bolt'
import Filter, { CategoryFilter } from './Filter'
import * as Styled from './styles'

export const findAppliedItem = (values, level = 0) => {
  const appliedItemIndex = values.findIndex(filter => filter.applied)
  let appliedItem = { appliedItemId: '', appliedItemLevel: 0 }
  if (appliedItemIndex >= 0) return { appliedItemId: values[appliedItemIndex].id, appliedItemLevel: level }
  values.forEach(filter => {
    if (filter.children?.length > 0) {
      const appliedItemObject = findAppliedItem(filter.children, level + 1)
      if (appliedItemObject.appliedItemId) {
        appliedItem = appliedItemObject
      }
    }
  })
  return appliedItem
}

const CategoryFacet = ({ filters, selectFilter, clearFacet, refCb, from, max, appliedItem, shouldShowBreadcrumb }) => {
  const { appliedItemId } = appliedItem
  const { appliedItemLevel } = appliedItem
  let appliedLevel = null
  const hashSuffix = () =>
    Math.random()
      .toString(36)
      .substring(7)

  const showBreadcrumb = shouldShowBreadcrumb && !!appliedItemId
  const getCategoryFacetContents = (filtersList, maxFilters = 5, from = 0, level = 0) => {
    return (
      <Styled.FiltersList type='category' indented={level > appliedItemLevel} data-test='findability-category-facet'>
        {level === 0 && showBreadcrumb && (
          <li>
            <Styled.ClearCategoryButton onClick={clearFacet} data-test='findability-category-clear-facet'>
              <CategoryFilter label='All Categories' isDescendantApplied isLabel />
            </Styled.ClearCategoryButton>
          </li>
        )}
        {filtersList.slice(from, maxFilters).map((filter, itemIndex) => {
          const applied = filter.id === appliedItemId
          const filterId = filter.id || filter.label
          const hasChildren = filter.children?.length > 0
          const uniqueId = hasChildren ? filterId : filterId?.concat(hashSuffix())
          const selfOrDescendantApplied = filter.isChildApplied || filter.applied || filter.isGrandchildApplied
          const isFocusNode = itemIndex === 4
          const isExpanded = filtersList.length === 1
          const shouldBeOpen = selfOrDescendantApplied || isExpanded
          const RightHandElement = () =>
            filter.count > 0 || filter.value > 0 ? (
              <Styled.FilterCount nonLeaf data-el='facet__filter-non-leaf-count'>
                ({filter.count || filter.value})
              </Styled.FilterCount>
            ) : (
              <Styled.CategoryChevron width='16px' height='16px' dir='right' />
            )
          const Title = (
            <Filter
              key={`filter-${filter.label}-${filter.id}`}
              filterGroup='category'
              type='category'
              selectFilter={selectFilter}
              refCb={node => refCb(node, isFocusNode)}
              filter={filter}
              id={filter.id}
              uniqueId={uniqueId}
              applied={applied}
              isChildApplied={filter.isChildApplied}
              isGrandchildApplied={filter.isGrandchildApplied}
              disabled={filter.disabled}
              label={filter.label}
              count={filter.count}
              level={level}
              value={hasChildren ? 0 : filter.value}
            />
          )
          const modifiedChildren =
            hasChildren &&
            filter.children.filter(filter => {
              if (appliedItemId) {
                if (appliedLevel === level + 1) return false
                const hasLevelParentApplied = filter.isGrandchildApplied || filter.isChildApplied || filter.applied
                appliedLevel =
                  hasLevelParentApplied && (level + 1 >= appliedLevel || !appliedLevel) ? level + 1 : appliedLevel
                return (
                  filter.isGrandchildApplied || filter.isChildApplied || filter.applied || level + 1 > appliedItemLevel
                )
              }
              return true
            })
          const children = hasChildren ? getCategoryFacetContents(modifiedChildren, maxFilters, 0, level + 1) : Title
          const ExpandedContent =
            modifiedChildren && maxFilters < modifiedChildren.length ? (
              <Accordion.Expanded>
                {getCategoryFacetContents(modifiedChildren, modifiedChildren.length, maxFilters, level + 1)}
              </Accordion.Expanded>
            ) : (
              <></>
            )
          return hasChildren ? (
            <Accordion.Item
              data-selected={applied}
              title={
                <CategoryFilter
                  label={filter.label}
                  isDescendantApplied={filter.isChildApplied || filter.isGrandchildApplied}
                  isApplied={filter.applied}
                  isLabel
                  level={level}
                />
              }
              customToggle={!selfOrDescendantApplied ? <RightHandElement /> : <></>}
              wrapperPadding={0}
              titleBodySpacing={0}
              label={filter.label}
              as='div'
              wrapperElement='li'
              index={`filter-${filter.id}`}
              disabled={filter.applied}
              noAnimation
              isOpen={shouldBeOpen}
              key={`filter-${filter.id}`}
              onClick={() =>
                selectFilter({ facetId: 'category', filterId, applied: !applied, filterLabel: filter.label })
              }>
              {children}
              {ExpandedContent}
            </Accordion.Item>
          ) : (
            Title
          )
        })}
      </Styled.FiltersList>
    )
  }
  return getCategoryFacetContents(filters, max, from)
}

CategoryFacet.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      count: PropTypes.number,
      disabled: PropTypes.bool,
      applied: PropTypes.bool,
      children: PropTypes.arrayOf(PropTypes.object)
    })
  ).isRequired,
  className: PropTypes.string,
  selectFilter: PropTypes.func,
  clearFacet: PropTypes.func,
  from: PropTypes.number,
  max: PropTypes.number,
  appliedItem: PropTypes.shape({
    appliedItemId: PropTypes.string,
    appliedItemLevel: PropTypes.string
  }),
  shouldShowBreadcrumb: PropTypes.bool
}

CategoryFacet.defaultProps = {
  from: 0,
  max: 5,
  selectFilter: () => {},
  clearFacet: () => {},
  appliedItem: {
    appliedItemId: '',
    appliedItemLevel: 0
  },
  refCb: () => {},
  shouldShowBreadcrumb: true
}

export default React.memo(CategoryFacet)
