import keyMirror from 'fbjs/lib/keyMirror'

export default keyMirror({
  LOADING_START: null,
  LOADING_END: null,
  CONTENT_FAILURE: null,
  LOST_CONNECTION: null,
  CONNECTION_TIMEOUT: null,
  HIDE_ERROR_MODAL: null,
  REDIRECT: null,
  INITIAL_RENDER: null,
  HIDE_NO_RESULTS_MODAL: null,
  SHOW_NO_RESULTS_MODAL: null,
  CONTENT: null,
  UPDATE_PAGE_TYPE: null,
  UPDATE_DESTINATION_PAGE_TYPE: null,
  TOGGLE_LOCALISATION_MODAL: null,
  UPDATE_LOCALISATION_POSTCODE: null,
  INCREMENT_AVAILABILITY_ERROR_COUNT: null,
  RESET_AVAILABILITY_ERROR: null,
  SET_CLICK_ORIGIN: null,
  SET_COLLECTION_STORES_ERROR: null
})
