import styled from 'styled-components'

export const Container = styled.div`
  margin-left: 15px;

  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    margin-left: 0;
  }
`

export const Heading = styled.div`
  margin-bottom: 10px;
  font-size: ${props => props.theme.font.size['label-1']};
  font-weight: ${props => props.theme.font.weight.bold};
  line-height: ${props => props.theme.font['line-height']['label-1']};

  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    margin-left: 0;
  }
`
