import React from 'react'
import PropTypes from 'prop-types'
import { getSearchPath } from '@sainsburys-tech/boltui-utils'
import { ErrorBoundary } from '@sainsburys-tech/bolt'
import * as Styled from './styles'

import Template from './BrandsNICBanner.template'

const BrandsNICBanner = ({ inputSearchTerm, brandSuggestions, searchType }) => {
  const bannerClassName = 'xs-row xs-auto lg-auto--none'
  const suggestions = brandSuggestions.slice(1, 4)
  return (
    <ErrorBoundary>
      <Styled.BNICBanner data-el='search-nic-banner' className={bannerClassName}>
        <span>
          {Template.suggestStart}
          <strong>{inputSearchTerm}</strong>
          {Template.suggestEnd}
        </span>
        <Styled.Links className='xs-row'>
          {suggestions.map((suggestions, index) => {
            const term = suggestions.text
            const searchSuggestionPath = getSearchPath(term, { searchType })
            return (
              <Styled.BannerLink
                onClick={() => window.scrollTo(0, 0)}
                title={Template.navLinkTitle(term)}
                data-el='search-nic-link'
                to={searchSuggestionPath}
                key={`${term}${index}`}>
                {term}
              </Styled.BannerLink>
            )
          })}
        </Styled.Links>
      </Styled.BNICBanner>
    </ErrorBoundary>
  )
}

BrandsNICBanner.propTypes = {
  inputSearchTerm: PropTypes.string,
  brandSuggestions: PropTypes.array,
  searchType: PropTypes.string
}
BrandsNICBanner.defaultProps = {
  inputSearchTerm: '',
  brandSuggestions: [],
  searchType: ''
}

export default BrandsNICBanner
