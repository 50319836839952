import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import NoResultsSuggestions from '../../components/NoResultsSuggestions/NoResultsSuggestions'

const mapStateToProps = state => {
  return {
    categories: state.noResults.data.categories
  }
}

export default connect(mapStateToProps)(withCookies(NoResultsSuggestions))
