import superagent from 'superagent'

export default function beaconService(beaconUrl) {
  const responseTimeout = window.citrusResponseTimeout !== 'undefined' ? window.citrusResponseTimeout : 1000
  const deadlineTimeout = window.citrusDeadlineTimeout !== 'undefined' ? window.citrusDeadlineTimeout : 2000

  const parseResponseText = text => {
    try {
      return JSON.parse(text)
    } catch (error) {
      return {}
    }
  }
  return new Promise(resolve => {
    superagent
      .post(beaconUrl)
      .timeout({
        response: responseTimeout,
        deadline: deadlineTimeout
      })
      .end((err, response) => {
        if (err) {
          resolve({ timeout: true })
        }
        if (response && response.text) {
          resolve(parseResponseText(response.text))
        }
      })
  })
}
