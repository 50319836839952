import { connect } from 'react-redux'

import FilterPanel from '../../components/FilterPanel/FilterPanel'
import { hideFilterModal, toggleFilterPanelStatic } from '../../redux/product/product'
import { loadingStart, setClickOrigin } from '../../redux/application/application'
import { ancestorsSelector, categoryChildrenSelector, categorySiblingsSelector } from '../../selectors/category'
import { filtersSelector, appliedFiltersSelector, appliedFiltersOrdersSelectors } from '../../selectors/product'

const mapStateToProps = state => ({
  categoryName: state.category.categoryName,
  isPlp: state.category.isPlp,
  isPlpPlus: state.category.isPlpPlus,
  isPlpPlusDescendant: state.category.isPlpPlusDescendant,
  isClearance: state.application.isClearance,
  isSaleRoute: state.application.isSaleRoute,
  isCannedSearch: state.product.isCannedSearch,
  ancestors: ancestorsSelector(state),
  children: categoryChildrenSelector(state),
  siblings: categorySiblingsSelector(state),
  subcategories: state.category.subcategories,
  categoryId: state.category.categoryId,
  topLevelCategoryId: state.category.topLevelCategoryId,
  filters: filtersSelector(state),
  appliedFilters: appliedFiltersSelector(state),
  appliedFiltersOrder: appliedFiltersOrdersSelectors(state),
  filterResetCount: state.product.filterResetCount,
  filterModalVisible: state.product.filterModalVisible,
  filterPanelStatic: state.product.filterPanelStatic,
  searchTerm: state.product.searchTerm,
  ratingFilterApplied: state.product.ratingFilterApplied,
  numberOfResults: state.product.numberOfResults,
  categoryMatch: state.product.categoryMatch,
  brandSuggestions: state.product.brandSuggestions,
  templateType: state.category.templateType,
  displayErrorModal: state.application.displayErrorModal,
  pageType: state.application.pageType,
  showLHN: state.application.loader.showLHN
})

const mapDispatchToProps = dispatch => {
  return {
    hideFilterModal: () => dispatch(hideFilterModal()),
    toggleFilterPanelStatic: payload => dispatch(toggleFilterPanelStatic(payload)),
    loadingStart: () => dispatch(loadingStart()),
    setClickOrigin: payload => dispatch(setClickOrigin(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel)
