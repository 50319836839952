import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'

import ProductList from '../../components/ProductList/ProductList'
import { executeCitrusBeacon } from '../../actions/executeBeacon'
import { onCitrusBeaconSent } from '../../redux/citrus/citrus'
import { userStateSelector } from '../../selectors/application'
import {
  incrementAvailabilityErrorCount,
  resetAvailabilityError,
  setCollectionStoresError
} from '../../redux/application/application'
import { toggleEMWBISModal } from '../../redux/product/product'

const mapStateToProps = state => {
  return {
    templateType: state.category.templateType,
    citrus: state.citrus,
    userState: userStateSelector(state),
    showLoader: state.application.loader.show,
    searchTerm: state.product.searchTerm,
    numberOfResults: state.product.numberOfResults,
    currentPage: state.product.currentPage,
    pageSize: state.product.pageSize,
    localisationPostcode: state.application.availability.localisationPostcode,
    showLocalisationModal: state.application.availability.showLocalisationModal,
    isError: state.application.availability.availabilityError.isError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sendCitrusImpressionBeacon: (attributes, citrus) => {
      if (attributes && attributes.featured && !attributes.beaconSent && !isNaN(attributes.price)) {
        executeCitrusBeacon(
          citrus,
          payload => {
            dispatch(onCitrusBeaconSent(payload))
          },
          { productId: attributes.partNumber },
          'impBeacon'
        )
      }
    },
    sendCitrusClickBeacon: (attributes, citrus, partNumber) => {
      if (attributes && attributes.featured && !isNaN(attributes.price)) {
        setTimeout(() => {
          executeCitrusBeacon(
            citrus,
            payload => {
              dispatch(onCitrusBeaconSent(payload))
            },
            { productId: partNumber },
            'clickBeacon'
          )
        }, 0)
      }
    },

    incrementAvailabilityErrorCount: () => {
      dispatch(incrementAvailabilityErrorCount())
    },
    resetAvailabilityError: () => {
      dispatch(resetAvailabilityError())
    },
    setCollectionStoresError: payload => {
      dispatch(setCollectionStoresError(payload))
    },
    showEMWBISModal: partNumber => {
      dispatch(toggleEMWBISModal({ visible: true, productId: partNumber }))
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withCookies(ProductList)))
