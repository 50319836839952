import { isTu } from './Availability/AvailabilityTuHelper'

const isError = messageKey => messageKey === 'ERROR_ORCHESTRATOR_PDP' || !messageKey

const filterAvailabilityBySku = (sku = '', availabilityResponse = []) => {
  const hasCollectionStores = availabilityResponse.stores !== null && availabilityResponse.stores.length > 0

  // Loop though the 'stores' array in the response (up to 10 stores)
  // Return the 'messages' object from each of the 10 stores for the given SKU (passed in as argument)
  const filterStoresBySKU = sku => (isTu ? [] : availabilityResponse?.stores?.map(store => store?.messages[sku]) || [])
  const filterDeliveryBySku = sku => availabilityResponse?.delivery[0]?.messages[sku]

  // Set priority order of the messageKeys (Fastest lead time as first/top priority)
  const leadTimePriorityOrder = [
    'COLLECTION_NOW',
    'COLLECTION_FROM_TODAY_AT_TIME',
    'COLLECTION_FROM_TOMORROW_AT_TIME',
    'COLLECTION_FROM_DATE',
    'COLLECTION_OUT_OF_STOCK',
    'COLLECTION_NOT_AVAILABLE'
  ]

  const map = new Map()

  // Create map of priorities that we will sort the raw data by
  leadTimePriorityOrder.forEach((x, i) => map.set(x, i))

  const sortedCollectionMessageKeyList = filterStoresBySKU(sku)
  const sortedDeliveryMessageKeyList = filterDeliveryBySku(sku)

  // Sort messageKeys by priority map
  hasCollectionStores && sortedCollectionMessageKeyList.sort((x, y) => map.get(x.messageKey) - map.get(y.messageKey))

  // Check if the collection or delivery message keys display 'ERROR_ORCHESTRATOR_PDP'
  const isCollectionError = isError(sortedCollectionMessageKeyList[0]?.messageKey)
  const isDeliveryError = isError(sortedDeliveryMessageKeyList?.messageKey)

  const availabilityProps = {
    partNumber: sku,
    collection: {
      messageKey: hasCollectionStores && !isCollectionError ? sortedCollectionMessageKeyList[0].messageKey : '',
      text: hasCollectionStores && !isCollectionError ? sortedCollectionMessageKeyList[0].text : '',
      icon: hasCollectionStores && !isCollectionError ? sortedCollectionMessageKeyList[0].icon : ''
    },
    delivery: {
      messageKey: !isDeliveryError ? sortedDeliveryMessageKeyList?.messageKey : '',
      text: !isDeliveryError ? sortedDeliveryMessageKeyList?.text : '',
      icon: !isDeliveryError ? sortedDeliveryMessageKeyList?.icon : ''
    }
  }

  return availabilityProps
}

export default filterAvailabilityBySku
