import { hideFilterModal } from '../redux/product/product'
import {
  hideNoResultsModal as hNoResultsModal,
  showNoResultsModal as sNoResultsModal
} from '../redux/application/application'

export function showNoResultsModal(context) {
  const { filterModalVisible } = context.reduxStore.getState().product
  context.reduxStore.dispatch(hideFilterModal())

  if (filterModalVisible) {
    /**
     * Wait for the filter modal to close. Currently
     * having multiple instances of the modal is
     * problematic by design. Will require a bit of
     * rethinking on our approach.
     */
    setTimeout(() => {
      context.reduxStore.dispatch(sNoResultsModal())
    }, 300)
  } else {
    context.reduxStore.dispatch(sNoResultsModal())
  }
}

export function hideNoResultsModal(context) {
  context.reduxStore.dispatch(hNoResultsModal())
  window.history && window.history.back()
}
