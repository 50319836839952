export default {
  COOKIE_PREFIX: 'ArgosEventTrack_',

  eventToCookieName(eventName) {
    return this.COOKIE_PREFIX + eventName
  },

  getTrackingEvent(eventName) {
    const cookieName = this.eventToCookieName(eventName)
    const cookieValue = this.getCookie(cookieName)
    if (!cookieValue) return

    // the event has been captured so delete it
    this.deleteCookie(cookieName)

    return cookieValue
  },

  /**
   * Retrieve a cookie value
   *
   * @param name
   * @return String|null
   */
  getCookie(name) {
    const nameEQ = `${name}=`
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  },

  /**
   * Remove a cookie
   *
   * @param name
   */
  deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`
  }
}
