import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  DrawerHeader as ModalHeader,
  DrawerContent as ModalContent,
  RectangleSkeleton
} from '@sainsburys-tech/bolt'
import { isEmail } from '@sainsburys-tech/bolt-validators'

import Template from './EmailWhenBackInStockModal.template'

import * as Styled from './styles'

const getErrorMessage = validEmail => {
  if (!validEmail) return 'Please enter a valid email'
  return ''
}

const EmailWhenBackInStockModal = ({ showEmailWhenBackInStockModal, handleModalClose, productId, postcode }) => {
  const [emailAddress, setEmailAddress] = useState('')
  const [loading, setLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [validEmail, setValidEmail] = useState(true)

  const { headerText, bodyText, buttonText, confirmationHeaderText, confirmationBodyText } = Template

  const apiUrl = '/finder-api/back-in-stock'

  const postData = async data => {
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      return response
    } catch (error) {
      return {}
    }
  }

  const postEMWBISRequest = () => {
    setLoading(true)

    const body = {
      emailAddress,
      productId,
      postcode: postcode.replace(/\s/g, ''),
      source: 'web_plp',
      consent: true
    }

    postData(body).then(response => {
      setSubmitSuccess(response.ok)
      setLoading(false)
    })
  }

  const onEmailSubmit = event => {
    event.preventDefault()

    const isValidEmail = isEmail(emailAddress)
    setValidEmail(isValidEmail)

    if (isValidEmail) {
      postEMWBISRequest()
    }
  }

  useEffect(() => {
    if (showEmailWhenBackInStockModal) {
      setEmailAddress('')
      setLoading(false)
      setSubmitSuccess(false)
      setValidEmail(true)
    }
  }, [showEmailWhenBackInStockModal])

  const content = () => {
    return (
      <>
        <ModalHeader>{submitSuccess ? confirmationHeaderText : headerText}</ModalHeader>
        <ModalContent>
          {submitSuccess ? (
            confirmationBodyText
          ) : (
            <>
              {bodyText}
              {loading ? (
                <RectangleSkeleton height={20} />
              ) : (
                <form data-test='emwbis-modal-form' onSubmit={onEmailSubmit}>
                  <Input
                    name='email-address'
                    type='email'
                    data-test='email-address-input'
                    placeholder='Enter email address'
                    aria-label='Email address'
                    value={emailAddress}
                    onChange={event => setEmailAddress(event.target.value)}
                    label='Email address'
                    error={getErrorMessage(validEmail)}
                  />
                  <Styled.Button onClick={onEmailSubmit}>{buttonText}</Styled.Button>
                </form>
              )}
            </>
          )}
        </ModalContent>
      </>
    )
  }

  return (
    <Styled.Modal
      padding
      height='initial'
      data-test='emwbis-modal'
      show={showEmailWhenBackInStockModal}
      onClose={() => handleModalClose()}>
      {content()}
    </Styled.Modal>
  )
}

EmailWhenBackInStockModal.propTypes = {
  showEmailWhenBackInStockModal: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired
}

export default EmailWhenBackInStockModal
