import { get } from '../utils/PolyfillLodash'
import { CategoryHelper } from '../helpers/CategoryHelper'
import { ServiceHelper } from '../helpers/ServiceHelper'
import { ProductRequestHelper } from '../helpers/ProductRequestHelper'
import { ProductResponseHelper } from '../helpers/ProductResponseHelper'
import SearchHelper from '../helpers/SearchHelper'
import { getPageType } from '../utils/pageTypes'
import paramsParser from '../helpers/ParamsParser'
import Logger from '../utils/Logger'
import TotalRequestHelper from '../metrics/TotalRequestHelper'
import findabilityDecoder from '../helpers/FindabilityDecoder'
import { getCitrusProducts } from '../helpers/CitrusHelper'
import { failedSearch, searchStart } from '../redux/product/product'
import { getContent } from '../redux/cms/getContent'
import {
  loadingStart,
  loadingEnd,
  updatePageType,
  contentFailure,
  resetAvailabilityError,
  setCollectionStoresError
} from '../redux/application/application'
import { clearCitrusProducts } from '../redux/citrus/citrus'
import { isCitrusEnabled } from '../utils/isCitrusEnabled'
import config from '../config'

const logger = new Logger()
const totalRequest = TotalRequestHelper.getInstance().getTotalRequest()
const statusCodes = config.httpStatusCodes

export default function(context, payload, cb, ...args) {
  const pageType = payload && payload.path ? getPageType(payload.path) : 'search'

  const req = context?.service?.req
  const searchTestGroupCookie = req?.universalCookies?.cookies?.Search_Test_Group_1
  const searchTestGroupVariantValue = '2'
  const isSearchVariant = searchTestGroupCookie === searchTestGroupVariantValue

  const done = () => {
    context.reduxStore.dispatch(loadingEnd())
    context.reduxStore.dispatch(
      updatePageType({ pageType, isClearance: false, isCannedSearch: false, ...(req && { isSearchVariant }) })
    )
    cb()
  }

  let queryParams = payload.query
  const category = payload.params.category ? payload.params.category.split(':')[1] : undefined
  const searchTerm = payload.params.term
  const { page, sort, refinements, searchType, categoryMatchOverride } = paramsParser.getParams(payload.params.path)

  const hasInvalidRefinements = refinements?.length && refinements.some(refinement => !refinement.value)
  // Invalid refinement values that don't have values in the URL, e.g. abcdef in /search/red/abcdef/
  if (hasInvalidRefinements) {
    context.reduxStore.dispatch(failedSearch({ status: 404 }))
    done()
  }

  logger.debug('functionLogger', { args }, 'executeSearch')
  totalRequest.incrementHttpSearchRequest(1)

  if (!searchTerm) {
    return done()
  }

  if (searchTerm) {
    if (searchTerm === '*') {
      context.reduxStore.dispatch(failedSearch({ status: 404 }))
      done()
    }
  }

  const searchTermIsSku = SearchHelper.searchTermIsSkuId(searchTerm)

  queryParams = ProductRequestHelper.buildQueryParams(category, page, sort, refinements, queryParams)
  const searchParams = {
    searchTerm: searchTermIsSku
      ? decodeURIComponent(searchTerm)
          .split('/')
          .join('')
      : searchTerm,
    searchType: searchType || null,
    queryParams,
    categoryMatchOverride,
    isSearch: true,
    payloadPath: payload.path
  }

  const getTrackingParams = () => {
    const persistQuery = { ...queryParams }
    delete persistQuery.templateType
    delete persistQuery.canned
    return `?${Object.keys(persistQuery)
      .map(key => `${key}=${persistQuery[key]}`)
      .join('&')}`
  }

  // Redirect to Product Page SKU
  if (searchTerm && searchTermIsSku) {
    SearchHelper.redirectToProductPage(searchParams.searchTerm, context, getTrackingParams())
    return done()
  }

  const categoryRequest = ServiceHelper.buildBreadcrumbServiceRequest({ categoryId: null }, context)
  const productRequest = ServiceHelper.buildProductServiceRequest(searchParams, context)
  const contentRequest = ServiceHelper.buildSearchContentServiceRequest({ searchTerm })
  const isNewSearch = searchTerm !== context.reduxStore.getState().product.searchTerm

  context.reduxStore.dispatch(clearCitrusProducts())
  context.reduxStore.dispatch(resetAvailabilityError())
  context.reduxStore.dispatch(setCollectionStoresError(false))
  context.reduxStore.dispatch(searchStart(searchParams))
  // this should change so that sponsored doesn't force skeletons
  context.reduxStore.dispatch(loadingStart({ showLoaderLHN: isNewSearch }))

  const promises = []

  promises.push(
    new Promise(resolve => {
      context.service.read(contentRequest.service, contentRequest.params, {}, (err, contentServiceResponse) => {
        let action = {}
        if (!contentServiceResponse || !contentServiceResponse.response || err) {
          action = {
            payload: {
              status: statusCodes.serviceUnavailable
            },
            reduxAction: payload =>
              Promise.resolve()
                .then(() =>
                  context.reduxStore.dispatch(
                    getContent({ error: 'Failed Content Service', status: statusCodes.serviceUnavailable })
                  )
                )
                .then(() => context.reduxStore.dispatch(contentFailure(payload)))
          }
          return resolve(action)
        }
        action = {
          payload: contentServiceResponse,
          reduxAction: contentRequest.reduxAction
        }
        context.reduxStore.dispatch(getContent(contentServiceResponse))
        resolve(action)
      })
    })
  )

  promises.push(
    new Promise(resolve => {
      context.service.read(productRequest.service, productRequest.params, {}, (err, productResponse) => {
        const trackingParams = getTrackingParams()
        if (get(productResponse, 'redirectData')) {
          const redirectData = {
            path: `${productResponse.redirectData.path}${trackingParams}`,
            isRelative: productResponse.redirectData.isRelative
          }
          SearchHelper.redirectTo(redirectData, context)
          return done()
        }
        const action = ServiceHelper.handleProductRequest(err, productResponse, productRequest, context)
        if (ProductResponseHelper.executeNoResultsModal(context, productResponse)) return
        if (action.loadingEnd) {
          resolve(action)
        }

        const categoryFilterId = CategoryHelper.getCategoryFilterId(productResponse)
        if (categoryFilterId) {
          categoryRequest.params.categoryId = categoryFilterId
          const cachedCategoryInformation = context.reduxStore.getState().category.cache[categoryFilterId] || false
          if (cachedCategoryInformation) {
            const cachedAction = {
              ...action,
              loadingEnd: true,
              dispatch: categoryRequest.successAction,
              payload: { cached: true, ...cachedCategoryInformation },
              reduxAction: payload => categoryRequest.reduxAction(payload)
            }
            resolve([action, cachedAction])
            if (isCitrusEnabled({ pageType })) {
              getCitrusProducts(pageType, context, findabilityDecoder.getSanitised(searchTerm), productResponse, {
                response: {
                  included: cachedCategoryInformation.included
                }
              })
            }
          } else {
            context.service.read(categoryRequest.service, categoryRequest.params, {}, (err, categoryResponse) => {
              const nonCachedAction = ServiceHelper.handleCategoryRequest(err, categoryResponse, categoryRequest)
              nonCachedAction.reduxAction = payload => categoryRequest.reduxAction(payload)
              resolve([action, nonCachedAction])
              if (isCitrusEnabled({ pageType })) {
                getCitrusProducts(
                  pageType,
                  context,
                  findabilityDecoder.getSanitised(searchTerm),
                  productResponse,
                  categoryResponse
                )
              }
              if (nonCachedAction.loadingEnd) {
                return resolve([action, nonCachedAction])
              }
            })
          }
        } else {
          const noIdAction = ServiceHelper.handleCategoryRequest(null, null, categoryRequest)
          noIdAction.reduxAction = payload => categoryRequest.reduxAction(payload)
          resolve([action, noIdAction])
          if (isCitrusEnabled({ pageType })) {
            getCitrusProducts(pageType, context, findabilityDecoder.getSanitised(searchTerm), productResponse)
          }
        }
      })
    })
  )

  Promise.all(promises)
    .then(values => {
      values.forEach(value => {
        if (value) {
          if (Array.isArray(value)) {
            value.forEach(action => {
              ServiceHelper.handleActions(context, action)
            })
          } else {
            ServiceHelper.handleActions(context, value)
          }
        }
      })
      return done()
    })
    .catch(handleReject => {
      logger.error(handleReject)
      if (typeof handleReject === 'function') handleReject()
      return done()
    })
}
