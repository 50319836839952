import styled, { css } from 'styled-components'
import { Drawer, Button } from '@sainsburys-tech/bolt'

const minHeight = '48px'
const filterHeaderHeight = '48px'
export const FilterPanel = styled(Drawer)`
  outline: none;
  margin-left: 0;
  margin-right: 0;

  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].md}) {
    min-width: 280px;
  }

  @media (max-width: ${props => props.theme.size.breakpoint['max-width'].md}) {
    [data-test='component-drawer-overlay'] {
      transition: none;
    }
    ${props =>
      props.isStatic &&
      css`
        display: none;
      `}
  }
`

export const FilterPanelInner = styled.div`
  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    padding-right: 18px;
    margin: 0 18px 0 6px;
    border-right: 1px solid ${props => props.theme.color.monochrome.light};
  }

  @media (max-width: ${props => props.theme.size.breakpoint['max-width'].md}) {
    width: 100%;
    height: 100%;
  }
`

export const FilterPanelHeader = styled.div`
  height: ${filterHeaderHeight};
  line-height: ${minHeight};
  width: 100%;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 15px;

  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    width: auto;
    line-height: auto;
    padding: 30px 0;
    margin: 0;
  }
`

export const FilterPanelTitle = styled.h4`
  margin-top: 0;
  color: ${props => props.theme.color.monochrome.darker};
  float: left;

  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    margin-top: 5px;
  }
`
export const CloseButton = styled.button`
  background: none;
  vertical-align: middle;
  border: none;
  border-left: 1px solid ${props => props.theme.color.monochrome.light};
  position: relative;
  height: 48px;
  flex: 0 0 48px;
  width: 48px;
  font-size: ${props => props.theme.font.size.root};
  padding: 1px 0 0;
  color: ${props => props.theme.color.monochrome.dark};
  border-radius: 0;

  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    display: none;
  }
`

export const Group = styled.div`
  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    margin-bottom: 20px;
  }
`

export const ClearButton = styled.button`
  vertical-align: top;
  font-size: ${props => props.theme.font.size.caption};
  font-family: ${props => props.theme.font.family.base};
  text-decoration: underline;
  color: ${props => props.theme.color.palette['brand-1']};
  background-color: ${props => props.theme.color.monochrome.white};
  border: none;
  padding: 0;
  margin-left: auto;
  margin-right: 15px;
  cursor: pointer;

  :focus {
    outline-offset: 3px;
    outline: ${props => props.theme.color.palette['brand-1']} auto 5px;
  }
  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    padding-right: 0;
    margin-right: 0;
  }
`

export const ApplyButton = styled(Button)`
  position: relative;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 4;

  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    display: none;
  }
`
