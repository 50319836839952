import deepcopy from 'lodash.clonedeep'

import Logger from '../../utils/Logger'
import { CATEGORY_FILTER_ID, CategoryHelper } from '../../helpers/CategoryHelper'
import config from '../../config/index'
import Template from './application.template'

const logger = new Logger()

// -------------------------------------------------
// INITIAL STATE
// -------------------------------------------------
export const initialState = {
  currentPageName: null,
  currentPage: null,
  pageTitle: '404 Not Found',
  pageType: '',
  isSearchVariant: false,
  searchTerm: '',
  loader: {
    show: false,
    returnFocus: false
  },
  ancestors: [],
  categoryName: '',
  searchNoIndex: false,
  numberOfResults: null,
  productStatus: null,
  categoryStatus: null,
  contentStatus: null,
  showErrorPage: null,
  urlRefinements: '',
  appliedFilters: [],
  description: '',
  isPopulated: false,
  failureStatus: null,
  displayErrorModal: false,
  errorModalReason: '',
  redirectPath: '',
  redirectStatusCode: null,
  allFilters: [],
  initialRenderComplete: false,
  noResultsModalVisible: false,
  seoMeta: [],
  seoRobotMeta: [],
  inputSearchTerm: '',
  isClearance: false,
  isSaleRoute: false,
  isCannedSearch: false,
  brand: process.env.BRAND || 'argos',
  availability: {
    showLocalisationModal: false,
    collectionStoresError: false,
    localisationPostcode: '',
    availabilityError: {
      isError: false,
      errorCount: 0
    }
  },
  clickOrigin: ''
}

// -------------------------------------------------
// HELPERS
// -------------------------------------------------

export const getErrorPageTitle = (isPopulated, status, isCannedSearch = false, ...args) => {
  logger.debug('functionLogger', { args }, 'ApplicationStore getErrorPageTitle')
  if (status === config.httpStatusCodes.notFound || !isPopulated || isCannedSearch) {
    return Template.title404
  }
  return Template.title500
}

export const getRouteDescription = (isSearchOrCannedOrSd, filterString, newState, ...args) => {
  logger.debug('functionLogger', { args }, 'ApplicationStore getRouteDescription')

  if (isSearchOrCannedOrSd) {
    if (newState.numberOfResults === 0) {
      return ''
    }
    if ((newState.ancestors && newState.ancestors.length) || filterString) {
      return CategoryHelper.getFBCDescription(
        newState.ancestors,
        newState.categoryName,
        newState.searchTerm,
        filterString,
        newState.brand
      )
    }
    return Template.searchDelivery(newState.searchTerm, newState.brand)
  }
  return CategoryHelper.getBrowseDescription(
    newState.seoMeta,
    newState.categoryName,
    filterString,
    newState.isClearance,
    newState.isSaleRoute,
    newState.brand
  )
}

export const getRoutePageTitle = (isSearchOrCannedOrSd, filterString, newState, ...args) => {
  logger.debug('functionLogger', { args }, 'ApplicationStore getRoutePageTitle')
  if (isSearchOrCannedOrSd) {
    if (newState.numberOfResults === 0) {
      return Template.mobileResultTitle(newState.brand)
    }
    if ((newState.ancestors && newState.ancestors.length) || filterString) {
      return CategoryHelper.getFBCTitle(newState.ancestors, newState.categoryName, newState.searchTerm, filterString)
    }

    return Template.searchResult(newState.searchTerm)
  }
  return CategoryHelper.getBrowseTitle(
    newState.seoMeta,
    newState.categoryName,
    filterString,
    newState.numberOfResults,
    newState.currentPage,
    newState.isClearance,
    newState.isSaleRoute,
    newState.brand
  )
}

export const setFailureState = newState => {
  const updateState = deepcopy(newState)
  updateState.failureStatus = null
  updateState.showErrorPage = false

  const getFailureStatus = status => {
    return status !== config.httpStatusCodes.success ? status : null
  }
  updateState.failureStatus =
    getFailureStatus(updateState.categoryStatus) ||
    getFailureStatus(updateState.productStatus) ||
    getFailureStatus(updateState.contentStatus)
  updateState.showErrorPage = !!(
    getFailureStatus(updateState.categoryStatus) || getFailureStatus(updateState.productStatus)
  )

  return updateState
}

export const updateSearchState = (payload = {}, state, ...args) => {
  logger.debug('functionLogger', { args }, 'Application updateSearchState')
  const newState = deepcopy(state)
  newState.categoryName = null
  newState.currentPage = null
  newState.isClearance = false
  if (!payload) {
    newState.numberOfResults = null
    return newState
  }
  newState.productStatus = payload.status
  if (payload.response) {
    const { aggregations } = payload.response.meta
    const idMap = new Map()
    aggregations.forEach(aggregation => {
      idMap.set(aggregation.id, aggregation.label)
    })
    newState.currentPage = payload.response.meta.currentPage
    if (payload.response.meta.appliedFilters) {
      newState.appliedFilters = payload.response.meta.appliedFilters
        .filter(filter => filter.id !== CATEGORY_FILTER_ID && idMap.get(filter.id))
        .map(filter => ({ [idMap.get(filter.id)]: filter.values }))
    }
    newState.allFilters = payload.response.meta.aggregations
    newState.searchTerm = payload.searchTerm
    newState.inputSearchTerm = payload.inputSearchTerm
    newState.numberOfResults = payload.response.meta.totalData
    newState.searchNoIndex = payload.noIndex
    newState.isProductPopulated = payload.status === 200
    newState.canned = payload.response.meta.canned
  }
  return newState
}

export const updateAncestors = (payload = {}, state, ...args) => {
  logger.debug('functionLogger', { args }, 'ApplicationStore updateAncestors')
  const newState = deepcopy(state)
  if (payload.response) {
    newState.ancestors = CategoryHelper.flattenAncestors(payload.response)
    const data = payload.response.data[0]
    newState.categoryName = data ? data.attributes.name : null
  }
  return newState
}

export const updateBrowseState = (payload = {}, state, ...args) => {
  logger.debug('functionLogger', { args }, 'ApplicationStore updateBrowseState')
  const newState = deepcopy(state)
  newState.isCategoryPopulated = true

  if (!payload) {
    newState.ancestors = []
    newState.categoryStatus = null
    return newState
  }
  newState.categoryStatus = payload.status
  if (payload.response) {
    return updateAncestors(payload, newState)
  }
  return newState
}

export default {
  updateSearchState,
  updateBrowseState,
  getRoutePageTitle,
  getRouteDescription,
  getErrorPageTitle,
  setFailureState
}
