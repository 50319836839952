import React from 'react'
import PropTypes from 'prop-types'

const FilterPillLabel = ({ label, value }) => {
  return (
    <>
      <span className='filter-pill-item-label' data-test='filter-pill-item-label'>
        {label}
      </span>{' '}
      (
      <span className='filter-pill-item-value' data-test='filter-pill-item-value'>
        {value}
      </span>
      )
    </>
  )
}

FilterPillLabel.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any
}

FilterPillLabel.defaultProps = {
  label: '',
  value: ''
}

export default FilterPillLabel
