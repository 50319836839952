import { get } from '../../utils/PolyfillLodash'

// -------------------------------------------------
// INITIAL STATE
// -------------------------------------------------
export const initialState = {
  show: false,
  products: []
}

// -------------------------------------------------
// HELPERS
// -------------------------------------------------
const attributeKeyMaps = new Map([
  ['ProductName', 'name'],
  ['PromoText', 'specialOfferText']
])

export function getFreeDelivery(product) {
  return product.shipping === 0
}

export function normalizeProductAttributes(product) {
  const normalizedProductAttributes = {}

  attributeKeyMaps.forEach((key, value) => {
    normalizedProductAttributes[key] = product[value]
  })

  normalizedProductAttributes.avgRating = parseFloat(product.avgRating)
  normalizedProductAttributes.beaconId = product.id
  normalizedProductAttributes.deliverable = product.deliverable
  normalizedProductAttributes.detailAttributes = product.detailAttributes
  normalizedProductAttributes.featured = true
  normalizedProductAttributes.freeDelivery = getFreeDelivery(product)
  normalizedProductAttributes.price = parseFloat(product.Price)
  normalizedProductAttributes.hasVariations = false
  normalizedProductAttributes.hasMultiplePrices = false
  normalizedProductAttributes.productLink = product.ProductPage
  normalizedProductAttributes.reviewsCount = parseInt(product.reviewsCount)
  normalizedProductAttributes.wasPrice = parseFloat(product.ComparePrice)
  normalizedProductAttributes.badge = { BADGE_VALUE: [product?.badge?.BADGE_VALUE[0] || ''] }

  return normalizedProductAttributes
}

export function getProducts(payload) {
  const products = payload.ads || payload.ProductAd
  if (products) {
    const normalisedProducts = products.map(product => {
      const normalizedProduct = {}
      normalizedProduct.id = product.ProductSKU || product.gtin
      normalizedProduct.attributes = normalizeProductAttributes(product)
      return normalizedProduct
    })
    return normalisedProducts
  }
}

function getProduct(store, productSku) {
  return store.products.find(product => product.id == productSku) // eslint-disable-line
}

export function getCitrusImpressionBeacon(store, productSku) {
  if (!store.products && !productSku) {
    return ''
  }

  const product = getProduct(store, productSku)

  if (get(product, 'attributes.beaconSent')) {
    return
  }

  return get(product, 'attributes.beaconId')
}

export function getCitrusClickBeacon(store, productSku) {
  if (!store.products && !productSku) {
    return ''
  }

  const product = getProduct(store, productSku)

  return get(product, 'attributes.beaconId')
}
