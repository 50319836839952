import keyMirror from 'fbjs/lib/keyMirror'

export default keyMirror({
  RECEIVED_CITRUS_PRODUCTS: null,
  SHOW_CITRUS_PRODUCTS: null,
  HIDE_CITRUS_PRODUCTS: null,
  BEACON_SENT: null,
  CLEAR_CITRUS_PRODUCTS: null,

  RECEIVED_CITRUS_PRODUCTS_SAGA: null,
  SHOW_CITRUS_PRODUCTS_SAGA: null,
  HIDE_CITRUS_PRODUCTS_SAGA: null,
  BEACON_SENT_SAGA: null,
  CLEAR_CITRUS_PRODUCTS_SAGA: null
})
