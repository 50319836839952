import React from 'react'
import PropTypes from 'prop-types'
import { CloseIcon } from '@sainsburys-tech/bolt-icons'
import { Accordion, ErrorBoundary } from '@sainsburys-tech/bolt'
import { BrowseHelper } from '../../helpers/BrowseHelper'
import { trackLhnCategorySelect } from '../../helpers/TaggingSerializer'
import Template from './PlpPlusCategoryFacet.template'

import * as Styled from './styles'

const PlpPlusCategoryFacet = (
  {
    categories,
    categoryName: currentCategoryName,
    categoryId: currentCategoryId,
    topLevelCategoryId,
    ancestors,
    isPlp,
    hideFilterModal,
    loadingStart,
    isClearance
  },
  context
) => {
  const maxItems = 5
  const Header = <Styled.CategoryListHeader>Category</Styled.CategoryListHeader>
  const categoryValues = categories.map(value => parseInt(value.id, 10))
  const isSelectedCategoryInCollapsedArea = categoryValues.indexOf(currentCategoryId) >= maxItems

  const selectCategory = (e, browseUrl) => {
    e.preventDefault()
    hideFilterModal()
    loadingStart()
    context.navigateTo(browseUrl)
    return true
  }

  const getCategories = (startAt = 0, maxSectionItems = maxItems) => {
    return categories
      .slice(startAt, maxSectionItems)
      .filter(value => !!value)
      .map((value, index) => {
        const categoryId = value.id
        const isSubHeading = value.type === 'Sub-Heading'
        const categoryDisplayName = value.attributes.name
        const browseUrl =
          isClearance && isSubHeading
            ? null
            : BrowseHelper.buildUrlForCategory({
                futureCategoryId: value.id,
                futureCategoryName: value.attributes.name,
                categoryType: value.type,
                ancestors,
                currentCategoryId,
                currentCategoryName,
                isPlp,
                isClearance
              })
        const onClick =
          isClearance && isSubHeading
            ? null
            : e => {
                selectCategory(e, browseUrl)
                trackLhnCategorySelect(value.attributes.name, value.id)
              }
        if (parseInt(categoryId, 10) === currentCategoryId) {
          return (
            <Styled.CategoryItemWrapper
              as='div'
              active
              categoryId={topLevelCategoryId}
              key={categoryId + index}
              data-category-match-title
              data-el='category-sidebar__item'>
              <Styled.CategoryItem active>{categoryDisplayName}</Styled.CategoryItem>
            </Styled.CategoryItemWrapper>
          )
        }
        return (
          <ErrorBoundary key={index + categoryId}>
            <Styled.CategoryItemWrapper
              isSubHeading={isSubHeading}
              href={browseUrl}
              key={`${categoryId}-${index}`}
              data-filter-id={categoryId}
              data-category-id={categoryId}
              onClick={onClick}
              data-el='category-sidebar__item'>
              <Styled.CategoryItem>{categoryDisplayName}</Styled.CategoryItem>
            </Styled.CategoryItemWrapper>
          </ErrorBoundary>
        )
      })
  }

  const Content = getCategories()
  const ExpandedContent =
    categories.length > maxItems ? (
      <Accordion.Expanded>{getCategories(maxItems, categories.length)}</Accordion.Expanded>
    ) : (
      <></>
    )

  return (
    <ErrorBoundary>
      <Styled.CategoryPanel data-el='category-sidebar'>
        <Styled.CategoryHeader>
          <Styled.CategoryTitle tabIndex='0' role='heading'>
            {Template.title}
          </Styled.CategoryTitle>
          <Styled.CloseButton
            type='button'
            data-el='filter-panel-close'
            onClick={hideFilterModal}
            tabIndex='0'
            role='button'>
            <CloseIcon width='1.5em' height='1.5em' fill='currentColor' />
          </Styled.CloseButton>
        </Styled.CategoryHeader>
        <Styled.CategoriesContainer>
          <Accordion.Item
            wrapperPadding={0}
            title={Header}
            noAnimation
            isOpen
            isExpandedOpen={isSelectedCategoryInCollapsedArea}>
            {Content}
            {ExpandedContent}
          </Accordion.Item>
        </Styled.CategoriesContainer>
      </Styled.CategoryPanel>
    </ErrorBoundary>
  )
}

PlpPlusCategoryFacet.propTypes = {
  categories: PropTypes.array.isRequired,
  categoryName: PropTypes.string.isRequired,
  categoryId: PropTypes.number.isRequired,
  topLevelCategoryId: PropTypes.string.isRequired,
  ancestors: PropTypes.array.isRequired,
  isPlp: PropTypes.bool.isRequired,
  hideFilterModal: PropTypes.func.isRequired,
  loadingStart: PropTypes.func.isRequired,
  isClearance: PropTypes.bool.isRequired
}

PlpPlusCategoryFacet.contextTypes = {
  navigateTo: PropTypes.func
}

export default PlpPlusCategoryFacet
