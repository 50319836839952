import { connect } from 'react-redux'

import ListerPage from '../../components/ListerPage/ListerPage'
import {
  categoryNameFromFilterSelector,
  hasProductsSelector,
  metaSelector,
  totalDataSelector,
  appliedFiltersSelector,
  appliedFilterUriFragmentsSelector,
  appliedSortBySelector,
  filtersSelector,
  searchTermSuggestionsSelector
} from '../../selectors/product'
import { ancestorsSelector, categoryChildrenSelector } from '../../selectors/category'
import { updateDestinationPageType, setClickOrigin } from '../../redux/application/application'

const mapStateToProps = state => ({
  categoryAncestors: ancestorsSelector(state),
  subHeadingAncestorId: state.category.subHeadingAncestorId,
  categoryChildren: categoryChildrenSelector(state),
  categoryName: state.category.categoryName,
  productsInCategoryTree: state.category.productsInCategoryTree,
  topLevelCategoryId: state.category.topLevelCategoryId,
  isPlp: state.category.isPlp,
  isPlpPlus: state.category.isPlpPlus,
  isPlpPlusDescendant: state.category.isPlpPlusDescendant,
  isClearance: state.application.isClearance,
  isSaleRoute: state.application.isSaleRoute,
  appliedFilters: appliedFiltersSelector(state),
  appliedFilterUriFragments: appliedFilterUriFragmentsSelector(state),
  appliedSortBy: appliedSortBySelector(state),
  failedService: state.product.failedService,
  isCannedSearch: state.product.isCannedSearch,
  filterModalVisible: state.product.filterModalVisible,
  filters: filtersSelector(state),
  meta: metaSelector(state),
  hasProducts: hasProductsSelector(state),
  results: totalDataSelector(state),
  searchTerm: state.product.searchTerm,
  filterResetCount: state.product.filterResetCount,
  ratingFilterApplied: state.product.ratingFilterApplied,
  noResultsModalVisible: !!state.product.noResultsModalVisible,
  spellcheckedSearch: state.product.spellcheckedSearch,
  searchType: state.product.searchType,
  inputSearchTerm: state.product.inputSearchTerm,
  searchTermSuggestions: searchTermSuggestionsSelector(state),
  brandSuggestions: state.product.brandSuggestions,
  categoryId: state.product.searchTerm ? state.product.categoryFilterId : state.category.categoryId,
  isPopulated: state.application.isPopulated || state.category.isPopulated,
  showErrorPage: state.application.showErrorPage,
  pageType: state.application.pageType,
  failureStatus: state.application.failureStatus,
  errorModalIsVisible: state.application.errorModalReason !== '',
  categoryNameFromFilter: categoryNameFromFilterSelector(state)
})

const mapDispatchToProps = dispatch => {
  return {
    updateDestinationPageType: payload => dispatch(updateDestinationPageType(payload)),
    setClickOrigin: payload => dispatch(setClickOrigin(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListerPage)
