import Logger from '../utils/Logger'
import TotalRequestHelper from '../metrics/TotalRequestHelper'
import { ServiceHelper } from '../helpers/ServiceHelper'
import { getNoResultsContent } from '../redux/no-results/getNoResultsContent'
import config from '../config'

const logger = new Logger()
const totalRequest = TotalRequestHelper.getInstance().getTotalRequest()
const statusCodes = config.httpStatusCodes

const done = status => {
  logger.debug('executeNoResults: ', status)
}

export default function(context, ...args) {
  logger.debug('functionLogger', { args }, 'executeNoResults')
  totalRequest.incrementHttpBrowseRequest(1)

  const categoryRequest = ServiceHelper.buildNoResultsCategoryServiceRequest({})
  const promises = []

  promises.push(
    new Promise(resolve => {
      context.service.read(categoryRequest.service, categoryRequest.params, {}, (err, categoryResponse) => {
        let categoryAction = {}
        if (!categoryResponse || !categoryResponse.response || err) {
          categoryAction = {
            reduxAction: () =>
              context.reduxStore.dispatch(
                getNoResultsContent({ error: 'Failed No Results Service', status: statusCodes.serviceUnavailable })
              )
          }
          resolve(categoryAction)
        } else {
          categoryAction = {
            payload: categoryResponse.response.data,
            reduxAction: () => context.reduxStore.dispatch(getNoResultsContent(categoryResponse))
          }
          resolve(categoryAction)
        }
      })
    })
  )

  Promise.all(promises)
    .then(values => {
      values.forEach(value => {
        if (value) {
          if (Array.isArray(value)) {
            value.forEach(action => {
              ServiceHelper.handleActions(context, action)
            })
          } else {
            ServiceHelper.handleActions(context, value)
          }
        }
      })
      return done('success!')
    })
    .catch(handleReject => {
      if (typeof handleReject === 'function') handleReject()
      return done('error!')
    })
}
