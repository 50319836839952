import styled from 'styled-components'

export const SearchTitle = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].md}) {
    margin-bottom: 5px;
  }

  @media (max-width: ${props => props.theme.size.breakpoint['max-width'].md}) {
    padding: 20px 5px 10px 20px;
    text-align: left;
  }
`

export const SearchTerm = styled.h1`
  margin: 0 0 20px;
`
