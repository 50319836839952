import styled, { css } from 'styled-components'
import { CrossIcon } from '@sainsburys-tech/bolt-icons'

const buttonPadding = '10px'

const ListHeader = styled.strong`
  color: ${props => props.theme.color.palette['brand-1']};
`
const FilterList = styled.ul`
  border-width: 0 1px;
  width: 100%;

  ${props =>
    props.expanded &&
    css`
      ${ListItem}:first-child::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-top: ${props => `1px dashed ${props.theme.color.monochrome.base}`};
      }
    `}
`

const ListItem = styled.li`
  display: flex;
  align-items: center;
  position: relative;

  :not(:first-child)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top: ${props => `1px dashed ${props.theme.color.monochrome.base}`};
  }
`

const ListButton = styled.button`
  appearance: none;
  background: transparent;
  border: 0;
  padding: 0;

  :focus {
    outline-offset: -5px;
  }
`

const ListIcon = styled(CrossIcon)`
  color: ${props => props.theme.color.palette['brand-1']};
  transition: color 0.2s ease;
  margin: 12px 1px 8px;
  ${/* sc-selector */ ListButton}:hover & {
    color: ${props => props.theme.color.palette['brand-1-dark']};
  }
`

const FilterName = styled.span`
  color: ${props => props.theme.color.monochrome.dark};
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: ${buttonPadding};
  padding-left: 10px;
`
export { FilterList, ListButton, ListItem, ListIcon, FilterName, ListHeader }
