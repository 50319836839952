import Metrics from '../utils/Metrics'
import TotalRequest from './TotalRequest'

const metricsServer = new Metrics()

const TotalRequestHelper = (function() {
  let instance

  function init() {
    return {
      totalRequest: new TotalRequest(),
      getTotalRequest() {
        return this.totalRequest
      }
    }
  }

  return {
    getInstance() {
      if (!instance) {
        instance = init()
        metricsServer.addMetric('co.uk.argos.ui.requests', instance.totalRequest)
      }
      return instance
    }
  }
})()

export default TotalRequestHelper
