import styled from 'styled-components'

const Container = styled.div`
  background: ${props => props.theme.color.monochrome.lighter};
  bottom: 0;
  display: flex;
  left: 0;
  padding: 1rem;
  position: fixed;
  width: 100%;
  z-index: 3;
`

const Form = styled.form`
  width: 100%;
`

const Label = styled.label(
  props => `
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: ${props.theme.font.size['display-1']};
    flex-direction: column;
    @media (min-width: ${props.theme.size.breakpoint['min-width'].sm}) {
      flex-direction: row;
    }
  `
)

const Input = styled.input(
  props => `
    background-color: ${props.theme.color.content.white};
    border: 1px solid ${props.theme.color.content['grey-light']};
    border-radius: ${props.theme.size.border.radius.md};
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
    margin: ${props.theme.size.spacing.lg} 0;
    padding: 12px; // Custom padding size for date input alignment
    @media (min-width: ${props.theme.size.breakpoint['min-width'].sm}) {
      margin: 0 ${props.theme.size.spacing.lg};
    }
    width: 210px;
  `
)

export { Container, Form, Label, Input }
