import React from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import loadable from '@loadable/component'

import { reportCitrusBanner } from '../../actions/executeBeacon'

const CMS = loadable.lib(() => import(/* webpackChunkName: "cms-components" */ '@sainsburys-tech/bolt-cms-components'))

const InViewBanner = ({ banner, searchTerm, categoryName, placement }) => {
  const {
    citrusAdId,
    position,
    bannerText,
    citrusCampaignName,
    heroImage,
    secondaryHeroImage,
    heroImageAltText,
    ctaLink,
    ctaTextAccessibility
  } = banner
  return (
    <InView triggerOnce onChange={view => view && citrusAdId && reportCitrusBanner(citrusAdId, 'impression')}>
      <CMS>
        {({ components }) => {
          const { M020 } = components
          const term = searchTerm || categoryName

          const encodedTerm = Buffer.from(term.toString()).toString('base64')
          const encodedPlacement = Buffer.from(placement).toString('base64')
          const encodedCampaignName = Buffer.from(citrusCampaignName.slice(0, -33)).toString('base64')
          const autoLink = `/sponsored/${encodedTerm}/${encodedPlacement}/${encodedCampaignName}`

          return (
            <M020
              type='M020'
              onLinkClick={() => reportCitrusBanner(citrusAdId, 'click')}
              id={citrusAdId}
              desc={bannerText}
              desktopImage={heroImage}
              tabletImage={heroImage}
              mobileImage={secondaryHeroImage}
              altText={heroImageAltText}
              linkUrl={ctaLink || autoLink}
              linkTarget='_self'
              linkTitle={ctaTextAccessibility}
              data-position={position}
            />
          )
        }}
      </CMS>
    </InView>
  )
}

InViewBanner.propTypes = {
  searchTerm: PropTypes.string,
  categoryName: PropTypes.string,
  placement: PropTypes.string,
  banner: PropTypes.shape({
    citrusAdId: PropTypes.string,
    position: PropTypes.string,
    citrusCampaignName: PropTypes.string,
    bannerText: PropTypes.string,
    heroImage: PropTypes.string,
    secondaryHeroImage: PropTypes.string,
    heroImageAltText: PropTypes.string,
    ctaLink: PropTypes.string,
    ctaTextAccessibility: PropTypes.string
  })
}

InViewBanner.defaultProps = {
  searchTerm: null,
  categoryName: '',
  placement: '',
  banner: {}
}

export default InViewBanner
