import styled, { createGlobalStyle } from 'styled-components'

// Override Tealium tag FORESEE FEEDBACK top, covering filters
// and other global styles
export const GlobalStyle = createGlobalStyle`
	._acs._acsbadge--default._acsmiddleright._acsVertical_right._acsAnimate {
		top: 20% !important;
	}

	input::-webkit-search-decoration,
	input::-webkit-search-cancel-button,
	input::-webkit-search-results-button,
	input::-webkit-search-results-decoration,
	input::-ms-clear,
	input::-ms-reveal  {
		display: none;
	}
`

export const PropbarContainer = styled.div`
  margin-bottom: 16px;
`
