import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from '@sainsburys-tech/bolt'
import { ProductRelevancyHeader, ProductRelevancyHeaderName } from './styles'
import Templates from './ProductList.template'

const ProductListRelevancy = ({ inputSearchTerm, products }) => {
  const hasTwoRelevancyBuckets = products[0].length < 30 && products[1].length && products[0].length !== 0
  return (
    <ErrorBoundary>
      <ProductRelevancyHeader secondHeader={hasTwoRelevancyBuckets} data-el='search-title'>
        <span>{Templates.searchRelevancyHeader}</span>
        <ProductRelevancyHeaderName className='h2'>{inputSearchTerm}</ProductRelevancyHeaderName>
      </ProductRelevancyHeader>
    </ErrorBoundary>
  )
}

ProductListRelevancy.propTypes = {
  inputSearchTerm: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired
}

export default ProductListRelevancy
