/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import Templates from './ListerMeta.template'
import { ListerMeta as StyledListerMeta } from './styles'

class ListerMeta extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      title: null
    }
    this.n = 0
    this.delayId = null
    this.onKeydown = this.onKeydown.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeydown)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { resetDelay } = nextProps
    const resetDelayHasChanged = resetDelay !== this.props.resetDelay
    if (resetDelayHasChanged && resetDelay <= 0) {
      this.delayOff()
    } else if (resetDelayHasChanged) {
      this.delayOff()
      this.delayOn()
    }
    if (nextProps.trackedButtons.join('') !== this.props.trackedButtons.join('')) {
      this.resetN()
      this.delayOff()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeydown)
    this.delayOff()
  }

  onKeydown(e) {
    if (e.keyCode === this.props.trackedButtons[this.n]) {
      this.n++
      this.delayOn()
      if (this.n === this.props.trackedButtons.length) {
        this.setState({ title: Templates.title })
        this.resetN()
        this.delayOff()
        return false
      }
    } else {
      this.resetN(e.keyCode)
      this.delayOff()
    }
  }

  delayOn() {
    if (this.props.resetDelay <= 0) return
    this.delayOff()
    this.delayId = setTimeout(() => this.resetN(), this.props.resetDelay)
  }

  resetN(keyCode) {
    if (!keyCode) {
      this.n = 0
      return
    }

    let count = 1
    while (this.n-- > 0 && this.props.trackedButtons[this.n] === keyCode) {
      count++
    }
    this.n = 0
    while (count-- > 0 && this.props.trackedButtons[this.n] === keyCode) {
      this.n++
    }
  }

  delayOff() {
    if (this.delayId) clearTimeout(this.delayId)
  }

  render() {
    return this.state.title ? <StyledListerMeta data-test='lister-meta'>{this.state.title}</StyledListerMeta> : null
  }
}

ListerMeta.propTypes = {
  resetDelay: PropTypes.number,
  trackedButtons: PropTypes.array
}
ListerMeta.defaultProps = {
  resetDelay: 2000,
  trackedButtons: [38, 38, 40, 40, 37, 39, 37, 39, 66, 65]
}

export default ListerMeta
