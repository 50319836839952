import qs from 'qs'
import { PAGE_TYPES } from '../utils/pageTypes'

const formatActionPayload = (routeMatch, url) => {
  const decode = str => {
    // Decode double encoded commas
    return str ? str.replace(/%252C/g, '%2C') : str
  }

  const decodeParams = obj => {
    const newObj = {}
    Object.keys(obj).forEach(key => {
      newObj[key] = decode(obj[key])
    })

    return newObj
  }

  const getQueryStringParams = url => {
    if (url.split('?').length === 1) {
      return {}
    }

    const queryString = url.split('?')[1]

    return qs.parse(queryString)
  }

  return {
    params: decodeParams(routeMatch.params),
    path: decode(routeMatch.url),
    query: getQueryStringParams(url)
  }
}

export const isSearchOrCannedOrSd = pageType => {
  return pageType === PAGE_TYPES.SEARCH || pageType === PAGE_TYPES.CANNED || pageType === PAGE_TYPES.DIRECTORY
}

export default formatActionPayload
