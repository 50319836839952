import { combineReducers } from 'redux'
import { AddToTrolley, AddToWishlist } from '@sainsburys-tech/bolt'
import CmsReducer from './cms/getContent'
import BrowseSeoReducer from './seo/getBrowseSeo'
import NoResultsReducer from './no-results/getNoResultsContent'
import Citrus from './citrus/citrus'
import Category from './category/category'
import Product from './product/product'
import Application from './application/application'

export default function getRootReducer() {
  return combineReducers({
    ...AddToTrolley.reducers,
    ...AddToWishlist.reducers,
    cms: CmsReducer,
    seo: BrowseSeoReducer,
    citrus: Citrus,
    noResults: NoResultsReducer,
    category: Category,
    product: Product,
    application: Application
  })
}
