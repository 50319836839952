import breakpoints from '../config/breakpoints'

export const PLATFORM_TYPE = {
  DESKTOP: 'web',
  MOBILE: 'mobile'
}

export const getPlatform = () => {
  return window.matchMedia(breakpoints.small).matches ? PLATFORM_TYPE.DESKTOP : PLATFORM_TYPE.MOBILE
}
