import { get } from './PolyfillLodash'
import brands from '../brands'

// Defaults to `argos`
const getBrand = () => process.env.BRAND || window.BRAND || 'argos'

const getBrandConfig = () => brands[getBrand()]

/**
 * Gets the value at `path` of the brand config object.
 * @param {string} path The path of the property to get.
 * @returns {*} Returns the resolved value.
 */
const getValue = path => {
  if (typeof path !== 'string') {
    throw Error('The required "path" property is no a string')
  }

  const config = getBrandConfig()
  if (!config) {
    throw Error(`Config for "${getBrand()}" was not found`)
  }

  return get(config, path)
}

export { getBrand, getValue }
