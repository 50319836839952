import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { canUseDom } from '@sainsburys-tech/boltui-utils'

import getRootReducer from './redux'
import rootSaga from './sagas'

const sagaMiddleware =
  typeof createSagaMiddleware === 'function' ? createSagaMiddleware() : createSagaMiddleware.default()

const getPreloadedState = () => {
  return (canUseDom() && window.App.redux) || {}
}

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
})

export default function getStore() {
  const store = createStore(
    getRootReducer(),
    getPreloadedState(),
    composeEnhancers(applyMiddleware(thunkMiddleware, sagaMiddleware))
  )

  sagaMiddleware.run(rootSaga)

  return store
}
