import superagent from 'superagent'

function getCookieValue(cookieName) {
  const cookies = document.cookie.split('; ')
  return cookies.find(cookie => cookie.indexOf(cookieName) === 0)
}

function getHeaders() {
  const headers = {
    // Uncomment the lines below for Citrus local development
    /* Accept: 'application/json',
    Authorization: 'Basic sk_2vQ6n9OqCjtgX4ilqcpvqZAOgtoyMjMxZWUxOC1jZGEzLTRhYjUtYTRlMi1lNDIxYTQzYTU4MGM=',
    'Content-Type': 'application/json',
    'cache-control': 'no-cache' */
  }
  if (window.stubEnabled === 'true') {
    const dysonCookie = getCookieValue('dyson_findability_session')
    if (dysonCookie) {
      return {
        Cookie: dysonCookie,
        ...headers
      }
    }
  }
  return headers
}

export default function citrusService(citrusUrl, requestBody) {
  const responseTimeout = window.citrusResponseTimeout !== 'undefined' ? window.citrusResponseTimeout : 1000
  const deadlineTimeout = window.citrusDeadlineTimeout !== 'undefined' ? window.citrusDeadlineTimeout : 2000

  const parseResponseText = text => {
    try {
      return JSON.parse(text)
    } catch (error) {
      return {}
    }
  }
  return new Promise(resolve => {
    superagent
      .post(citrusUrl, requestBody)
      .withCredentials()
      .set(getHeaders()) // TODO: Make headers work with the proxy
      .timeout({
        response: responseTimeout,
        deadline: deadlineTimeout
      })
      .end((err, response) => {
        if (err) {
          resolve({ timeout: true })
        }
        if (response && response.text) {
          resolve(parseResponseText(response.text))
        }
      })
  })
}
