const sanitiseOutput = (string = '') => {
  return string.replace(/[<>`]/g, '')
}

// Return the value from an expected values array in local storage
const getLocalStorage = (key = '', expectedValues = [], defaultValue = '') => {
  if (!key) return defaultValue

  if (typeof window !== 'undefined') {
    const item = localStorage.getItem(key)
    // Return default value if no local storage is set
    if (!item) return defaultValue

    // Sanitise local storage value
    const sanitisedItem = sanitiseOutput(item)

    // Return local storage value if it's included in the expected values array
    if (expectedValues.includes(sanitisedItem)) return sanitisedItem
  }

  // Return a default value if it's NOT included in the expected values array
  return defaultValue
}

const setLocalStorage = (name = '', value = '') => {
  if (name && value) localStorage.setItem(name, value)

  return null
}

export { getLocalStorage, setLocalStorage }
