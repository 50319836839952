import { Button as BoltButton, Modal as BoltModal } from '@sainsburys-tech/bolt'
import styled from 'styled-components'

export const Button = styled(BoltButton)`
  width: 100%;
`

export const Modal = styled(BoltModal)`
  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
    height: auto;
  }
`
