import { getSearchPath, getBrowsePath } from '@sainsburys-tech/boltui-utils'
import { loadingStart } from '../redux/application/application'
import { BrowseHelper } from '../helpers/BrowseHelper'
import { getSearchTermForSearchType, getSearchType } from '../helpers/ProductHelper'

export default (sortBy, history) => (dispatch, getState) => {
  const { application: applicationStore, category: categoryStore, product: productStore } = getState()

  const { appliedFilterUriFragments, isCannedSearch, categoryMatchOverride } = productStore
  const filterUriFragments = appliedFilterUriFragments.allFilters
  const searchTerm = getSearchTermForSearchType(productStore)

  dispatch(loadingStart())

  if (searchTerm) {
    const { categoryFilterId: categoryId } = productStore
    const url = getSearchPath(searchTerm, {
      categoryId,
      filterUriFragments,
      sortBy,
      searchType: getSearchType(productStore),
      isCannedSearch,
      categoryMatch: categoryMatchOverride
    })
    history.push(url)

    return
  }

  const { ancestors, categoryName, categoryId } = categoryStore
  const { isClearance, isSaleRoute } = applicationStore
  const params = {
    currentCatName: categoryName,
    ancestors,
    currentCatId: categoryId,
    filterUriFragments,
    sortBy,
    isClearance,
    isSale: isSaleRoute
  }

  const url = getBrowsePath(BrowseHelper.buildCorrectUrlBrowseParams(params))
  history.push(url)
}
