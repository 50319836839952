import React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { AddToTrolley, BackToTop, Breadcrumb, ErrorBoundary } from '@sainsburys-tech/bolt'
import Lister from '../../containers/Lister/Lister'
import { CategoryHelper } from '../../helpers/CategoryHelper'
import { searchTypes } from '../../config'
import findabilityDecoder from '../../helpers/FindabilityDecoder'
import CategoryListSkeleton from '../CategoryList/CategoryListSkeleton'
import { getValue } from '../../utils/ConfigProvider'
import { UniformTracking } from '../UniformTracking/UniformTracking'

const ErrorPage = loadable(() => import(/* webpackChunkName: "error-page" */ '../ErrorPage/ErrorPage'))
const CategoryList = loadable(
  () => import(/* webpackChunkName: "category-list" */ '../../containers/CategoryList/CategoryList'),
  {
    fallback: <CategoryListSkeleton isFullSize />
  }
)
const NoResultsSuggestions = loadable(() =>
  import(/* webpackChunkName: "no-results-suggestions" */ '../../containers/NoResultsSuggestions/NoResultsSuggestions')
)

class ListerPage extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    this.handleBreadcrumbClick = this.handleBreadcrumbClick.bind(this)
  }

  handleBreadcrumbClick(event, attrs = {}) {
    const { updateDestinationPageType } = this.props
    if (!attrs.isHome && attrs.path) {
      event.preventDefault()
      updateDestinationPageType({ type: 'CLP' })
      this.context.navigateTo(attrs.path)
    }
  }

  render() {
    const {
      isPopulated,
      showErrorPage,
      hasProducts,
      meta,
      categoryAncestors,
      searchTerm,
      searchType,
      pageType,
      categoryName,
      categoryId,
      isPlp,
      isPlpPlus,
      isPlpPlusDescendant,
      isClearance,
      isSaleRoute,
      isCannedSearch,
      topLevelCategoryId,
      categoryChildren,
      location
    } = this.props

    if (isCannedSearch === false && pageType === 'canned' && meta.totalData > 0) {
      return <NoResultsSuggestions />
    }

    const invalidListPage = !isPopulated || showErrorPage
    let component
    if (invalidListPage) {
      component = <ErrorPage failureStatus={this.props.failureStatus ? this.props.failureStatus : 404} />
    } else {
      component = hasProducts ? (
        <Lister {...this.props} />
      ) : (
        <CategoryList {...{ topLevelCategoryId, categoryName, categoryChildren, location }} />
      )
    }
    const isValidBreadcrumb =
      (searchType !== searchTypes.brandsNIC &&
        searchType !== searchTypes.NRS &&
        hasProducts &&
        !!meta &&
        !meta.categoryMatch &&
        categoryAncestors.length > 0) ||
      (!hasProducts && categoryAncestors.length > 0)
    const showBreadcrumbHomeLink =
      (!hasProducts && !!categoryAncestors && categoryAncestors.length > 0) ||
      isPlp ||
      isPlpPlus ||
      isPlpPlusDescendant ||
      isClearance
    const sanitisedSearchTerm = searchTerm ? findabilityDecoder.getSanitised(searchTerm) : ''
    const breadcrumbAncestors =
      isValidBreadcrumb &&
      CategoryHelper.getBreadcrumbAncestors(
        categoryAncestors,
        sanitisedSearchTerm,
        searchType,
        isClearance,
        isSaleRoute,
        isCannedSearch,
        categoryName
      )

    const categoryLevel = !breadcrumbAncestors ? 1 : breadcrumbAncestors?.length + 1

    return (
      <ErrorBoundary>
        {getValue('features.addToTrolley.modal.enabled') && (
          <AddToTrolley.Modal
            className='font-standard'
            hideExtras={getValue('features.addToTrolley.modal.hideExtras')}
            hideCategories={getValue('features.addToTrolley.modal.hideCategories')}
          />
        )}
        <BackToTop />
        {isValidBreadcrumb && !invalidListPage && (
          <div className='xs-auto--negative sm-auto--none search-breadcrumb'>
            <ErrorBoundary>
              <Breadcrumb
                metaData={!searchTerm}
                showHomeLink={showBreadcrumbHomeLink}
                currentPageName={!searchTerm ? categoryName : ''}
                ancestors={breadcrumbAncestors}
                onClick={this.handleBreadcrumbClick}
              />
            </ErrorBoundary>
          </div>
        )}
        <ErrorBoundary>
          <UniformTracking
            topLevelCategoryId={topLevelCategoryId}
            categoryId={categoryId}
            categoryLevel={categoryLevel}
          />
        </ErrorBoundary>

        <ErrorBoundary>{component}</ErrorBoundary>
      </ErrorBoundary>
    )
  }
}

ListerPage.defaultProps = {
  categoryAncestors: [],
  filters: [],
  match: {},
  searchType: null,
  categoryId: '',
  categoryName: '',
  filterModalVisible: false,
  meta: {},
  products: [],
  hasProducts: false,
  searchTerm: '',
  failureStatus: null,
  isPopulated: false,
  showErrorPage: false,
  isPlp: false,
  isPlpPlus: false,
  isPlpPlusDescendant: false,
  isClearance: false,
  isSaleRoute: false,
  isCannedSearch: false,
  results: null,
  categoryNameFromFilter: '',
  dfp: {},
  updateDestinationPageType: () => {},
  pageType: '',
  appliedSortBy: {},
  topLevelCategoryId: ''
}

ListerPage.propTypes = {
  match: PropTypes.object,
  searchType: PropTypes.string,
  categoryAncestors: PropTypes.array,
  categoryChildren: PropTypes.array.isRequired,
  categoryId: PropTypes.string,
  categoryName: PropTypes.string,
  filterModalVisible: PropTypes.bool,
  filters: PropTypes.array,
  meta: PropTypes.object,
  products: PropTypes.array,
  searchTerm: PropTypes.string,
  failureStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isPopulated: PropTypes.bool,
  showErrorPage: PropTypes.bool,
  isPlp: PropTypes.bool,
  isPlpPlus: PropTypes.bool,
  isPlpPlusDescendant: PropTypes.bool,
  isClearance: PropTypes.bool,
  isSaleRoute: PropTypes.bool,
  isCannedSearch: PropTypes.bool,
  results: PropTypes.number,
  categoryNameFromFilter: PropTypes.string,
  dfp: PropTypes.object,
  updateDestinationPageType: PropTypes.func,
  pageType: PropTypes.string,
  appliedSortBy: PropTypes.object,
  hasProducts: PropTypes.bool,
  topLevelCategoryId: PropTypes.string,
  location: PropTypes.object.isRequired
}

ListerPage.contextTypes = {
  navigateTo: PropTypes.func
}

export default ListerPage
