import deepcopy from 'lodash.clonedeep'

import ApplicationHelper, { initialState } from './index'
import Actions from '../../constants/application'
import ProductActions from '../../constants/product'
import CategoryActions from '../../constants/category'
import { CategoryHelper } from '../../helpers/CategoryHelper'
import Logger from '../../utils/Logger'
import ContentHelper from '../../helpers/ContentHelper'
import { BrowseHelper } from '../../helpers/BrowseHelper'
import { isSearchOrCannedOrSd } from '../../helpers/RoutingHelper'

const logger = new Logger()

// -------------------------------------------------
// ACTIONS
// -------------------------------------------------
export const loadingStart = payload => ({ type: Actions.LOADING_START, payload })
export const loadingEnd = () => ({ type: Actions.LOADING_END })
export const contentFailure = payload => ({ type: Actions.CONTENT_FAILURE, payload })
export const lostConnection = () => ({ type: Actions.LOST_CONNECTION })
export const connectionTimeout = () => ({ type: Actions.CONNECTION_TIMEOUT })
export const handleRedirect = payload => ({ type: Actions.REDIRECT, payload })
export const handleInitialRender = () => ({ type: Actions.INITIAL_RENDER })
export const hideNoResultsModal = () => ({ type: Actions.HIDE_NO_RESULTS_MODAL })
export const showNoResultsModal = () => ({ type: Actions.SHOW_NO_RESULTS_MODAL })
export const handleContent = payload => ({ type: Actions.CONTENT, payload })
export const updatePageType = payload => ({ type: Actions.UPDATE_PAGE_TYPE, payload })
export const updateDestinationPageType = payload => ({ type: Actions.UPDATE_DESTINATION_PAGE_TYPE, payload })
export const toggleLocalisationModal = payload => ({ type: Actions.TOGGLE_LOCALISATION_MODAL, payload })
export const updateLocalisationPostcode = payload => ({ type: Actions.UPDATE_LOCALISATION_POSTCODE, payload })
export const incrementAvailabilityErrorCount = () => ({ type: Actions.INCREMENT_AVAILABILITY_ERROR_COUNT })
export const resetAvailabilityError = () => ({ type: Actions.RESET_AVAILABILITY_ERROR })
export const setClickOrigin = payload => ({ type: Actions.SET_CLICK_ORIGIN, payload })
export const setCollectionStoresError = payload => ({ type: Actions.SET_COLLECTION_STORES_ERROR, payload })

// -------------------------------------------------
// REDUCER
// -------------------------------------------------

export default (state = initialState, action, ...args) => {
  const newState = deepcopy(state)

  switch (action.type) {
    case ProductActions.SEARCH:
      return ApplicationHelper.updateSearchState(action.productPayload, newState)
    case ProductActions.SEARCH_FAILURE: {
      logger.debug('functionLogger', { args }, 'ApplicationStore searchFailure')
      const { payload } = action
      newState.failureStatus = payload.status
      return ApplicationHelper.updateSearchState(payload, newState)
    }
    case CategoryActions.CATEGORY: {
      logger.debug('functionLogger', { args }, 'ApplicationStore updateCategoryState')
      const { payload } = action
      newState.isCategoryPopulated = true

      if (!payload) {
        newState.ancestors = []
        newState.categoryStatus = null
        return newState
      }
      newState.categoryStatus = payload.status
      if (payload.response) {
        const response = deepcopy(payload.response)
        newState.ancestors = CategoryHelper.getCategoryAncestors(response)
        newState.categoryName = CategoryHelper.getCategoryName(response)
      }
      return newState
    }
    case CategoryActions.BROWSE:
      return ApplicationHelper.updateBrowseState(action.payload, newState)
    case CategoryActions.PLP: {
      const updateState = ApplicationHelper.updateSearchState(action.productPayload, newState)
      return ApplicationHelper.updateBrowseState(action.payload, updateState)
    }
    case CategoryActions.BROWSE_FAILURE: {
      logger.debug('functionLogger', { args }, 'ApplicationStore browseFailure')
      const { payload } = action
      newState.failureStatus = payload.status
      return ApplicationHelper.updateBrowseState(payload, newState)
    }
    case Actions.LOADING_START: {
      const { payload } = action
      if (!newState.loader.show) {
        newState.loader = {
          ...newState.loader,
          show: true,
          showLHN: payload && payload.showLoaderLHN,
          returnFocus: (payload && payload.returnFocus) || false
        }
      }
      return newState
    }
    case Actions.LOADING_END: {
      if (newState.loader.show) {
        newState.loader = { ...newState.loader, show: false, returnFocus: false, showLHN: false }
      }
      newState.destinationPageType = ''
      return newState
    }
    case Actions.CONTENT_FAILURE: {
      logger.debug('functionLogger', { args }, 'ApplicationStore contentFailure')
      newState.seoRobotMeta = ContentHelper.getSeoRobotMeta([])
      newState.contentStatus = action.payload.status
      return newState
    }
    case Actions.LOST_CONNECTION: {
      logger.debug('functionLogger', { args }, 'ApplicationStore lostConnection')
      newState.displayErrorModal = true
      newState.errorModalReason = 'lostConnection'
      newState.isPopulated = true
      return newState
    }
    case Actions.CONNECTION_TIMEOUT: {
      logger.debug('functionLogger', { args }, 'ApplicationStore connectionTimeout')
      newState.displayErrorModal = true
      newState.errorModalReason = 'timeout'
      newState.isPopulated = true
      return newState
    }
    case Actions.REDIRECT: {
      logger.debug('functionLogger', { args }, 'ApplicationStore connectionTimeout')
      if (!action.payload) {
        newState.redirectPath = ''
        newState.redirectStatusCode = null
      } else {
        newState.redirectPath = action.payload.isRelative ? `/${action.payload.path}` : action.payload.path
        newState.redirectStatusCode = action.payload.statusCode
      }
      return newState
    }
    case Actions.INITIAL_RENDER: {
      newState.initialRenderComplete = true
      return newState
    }
    case Actions.HIDE_NO_RESULTS_MODAL: {
      newState.noResultsModalVisible = false
      return newState
    }
    case Actions.SHOW_NO_RESULTS_MODAL: {
      newState.noResultsModalVisible = true
      return newState
    }
    case Actions.CONTENT: {
      const { urlRefinements, response = {} } = action.payload
      const { data } = response

      const seoArea = ContentHelper.getSeoArea(data)
      newState.seoMeta = ContentHelper.getSeoMeta(seoArea, urlRefinements)
      newState.seoRobotMeta = ContentHelper.getSeoRobotMeta(seoArea)

      return newState
    }
    case Actions.UPDATE_PAGE_TYPE: {
      logger.debug('functionLogger', { args }, 'ApplicationStore handlePage')

      if (!newState.isPopulated) {
        if (!newState.searchTerm) {
          if (typeof newState.isProductPopulated === 'undefined') {
            // if it was a plp this would be populated
            newState.isProductPopulated = true
          }
          newState.isPopulated = newState.isCategoryPopulated && newState.isProductPopulated
        } else {
          newState.isPopulated = newState.isProductPopulated
        }
      }
      newState.isClearance = !!action.payload.isClearance
      if (action.payload.isSaleRoute) {
        newState.isSaleRoute = action.payload.isSaleRoute
      }
      newState.isCannedSearch = !!action.payload.isCannedSearch

      const updatedState = ApplicationHelper.setFailureState(newState)
      updatedState.pageType = action.payload.pageType

      if (typeof action.payload.isSearchVariant !== 'undefined') {
        updatedState.isSearchVariant = action.payload.isSearchVariant
      }

      if (updatedState.showErrorPage || !updatedState.isPopulated || newState.isCannedSearch) {
        updatedState.pageTitle = ApplicationHelper.getErrorPageTitle(
          updatedState.isPopulated,
          updatedState.failureStatus,
          newState.isCannedSearch
        )
        updatedState.description = ''
      } else {
        let filterString = ''
        const searchOrCannedOrSd = isSearchOrCannedOrSd(updatedState.pageType)
        if (searchOrCannedOrSd) {
          filterString = CategoryHelper.getFilterString(newState.appliedFilters, newState.allFilters)
        } else {
          filterString = BrowseHelper.getOrderedFilters(newState.appliedFilters, newState.allFilters).join(' ')
        }
        updatedState.isClearance = newState.isClearance
        updatedState.isCannedSearch = newState.isCannedSearch
        updatedState.pageTitle = ApplicationHelper.getRoutePageTitle(searchOrCannedOrSd, filterString, newState)
        updatedState.description = ApplicationHelper.getRouteDescription(searchOrCannedOrSd, filterString, newState)
      }

      updatedState.urlRefinements = action?.payload?.urlRefinements || ''

      return updatedState
    }
    case Actions.UPDATE_DESTINATION_PAGE_TYPE: {
      newState.destinationPageType = action.payload.type
      return newState
    }
    case Actions.TOGGLE_LOCALISATION_MODAL: {
      const { payload } = action

      if (typeof payload !== 'boolean') {
        throw new Error('TOGGLE_LOCALISATION_MODAL action payload must be a boolean')
      }

      newState.availability.showLocalisationModal = payload
      return newState
    }

    case Actions.UPDATE_LOCALISATION_POSTCODE: {
      newState.availability.localisationPostcode = action.payload
      return newState
    }

    case Actions.INCREMENT_AVAILABILITY_ERROR_COUNT: {
      newState.availability.availabilityError.errorCount++

      if (newState.availability.availabilityError.errorCount > 0) {
        newState.availability.availabilityError.isError = true
      }

      return newState
    }

    case Actions.RESET_AVAILABILITY_ERROR: {
      newState.availability.availabilityError.isError = false
      newState.availability.availabilityError.errorCount = 0

      return newState
    }

    case Actions.SET_CLICK_ORIGIN: {
      newState.clickOrigin = action.payload
      return newState
    }

    case Actions.SET_COLLECTION_STORES_ERROR: {
      if (typeof action.payload !== 'boolean') {
        throw new Error('SET_COLLECTION_STORES_ERROR action payload must be a boolean')
      }

      newState.availability.collectionStoresError = action.payload
      return newState
    }

    default:
      return state
  }
}
