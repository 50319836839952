import { isUkPostcode } from '@sainsburys-tech/bolt-validators'

/**
 * Formats a given full UK postcode
 * @param {string} postcode The postcode string. Input: N13LD
 * @returns {string} Returns the postcode formated. Output: N1 3LD
 */

const formatPostcode = (postcode = '') => {
  if (postcode && isUkPostcode(postcode)) {
    // Removes any spacing from the postcode
    const clearPostcodeSpacing = postcode.replace(/\s/g, '')
    return `${clearPostcodeSpacing.slice(0, clearPostcodeSpacing.length - 3)} ${clearPostcodeSpacing.slice(-3)}`
  }

  return ''
}

export default formatPostcode
