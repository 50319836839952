import styled, { css } from 'styled-components'
import LazyHydrate from 'react-lazy-hydration'
import { HabitatTextLogo } from '@sainsburys-tech/bolt-icons'

export const LazyHydrateCard = styled(LazyHydrate)`
  max-width: calc(50% - 22.5px);
  border-top: 1px solid ${props => props.theme.color.monochrome.light};
  padding: 15px 0;

  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
    padding: 15px 0 30px;
  }

  :nth-child(odd) {
    margin-left: 15px;
    margin-right: 7.5px;

    @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
      margin-right: 15px;
    }
  }

  :nth-child(even) {
    margin-left: 7.5px;
    margin-right: 15px;

    @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
      margin-left: 15px;
    }
  }
`

export const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  @media screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    margin: 0 -15px;
  }

  /* Removing border from the first 2 or 3 Product Card in the 1st Product Group Card */
  ${props =>
    props.isFirstGroup &&
    css`
      > div:nth-child(-n + 2) {
        border: 0;
      }

      @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
        > div:nth-child(-n + 3) {
          border: 0;
        }
      }
    `}
`

export const ProductListSkeleton = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const WhiteGoodsWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`

export const ProductRelevancyHeader = styled.h4`
  line-height: 1.5rem;
  padding: 20px;

  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    padding: 5px 0 5px 0;
  }

  ${props =>
    props.secondHeader &&
    css`
      margin-top: 20px;
      border: 1px solid ${props.theme.color.monochrome.light};
      border-width: 1px 0;
      padding: 20px;

      @media (max-width: ${props.theme.size.breakpoint['max-width'].xs}) {
        background-color: ${props.theme.color.monochrome.lighter};
      }

      @media (min-width: ${props.theme.size.breakpoint['min-width'].lg}) {
        padding: 20px 0;
      }
    `}
`
export const ProductRelevancyHeaderName = styled.div`
  margin-bottom: 0;
`
export const SkeletonWrapper = styled.div`
  display: grid;

  @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: flex;
  }

  ${props =>
    props.whiteGoods &&
    css`
      margin: 15px 0;
    `}
`

export const HabitatLogo = styled(HabitatTextLogo)`
  margin-bottom: 5px;
`

export const SkeletonBanner = styled.div`
  padding: 20px 0px 0px;
  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
    padding-top: 22px;
  }
`
