import styled from 'styled-components'
import { Modal } from '@sainsburys-tech/bolt'

const ExtendedModal = styled(Modal)`
  p {
    margin-bottom: 30px;
  }

  form > div {
    margin-bottom: 15px;
  }

  input {
    text-transform: uppercase;

    &::placeholder {
      text-transform: none;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      text-transform: none;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      text-transform: none;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      text-transform: none;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      text-transform: none;
    }
  }

  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
    height: auto;
  }
`

export { ExtendedModal }
