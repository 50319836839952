import './wdyr'
import React from 'react'
import ReactDOM from 'react-dom'
import { CookiesProvider } from 'react-cookie'
import debug from 'debug'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { loadableReady } from '@loadable/component'
import { ThemeProvider } from 'styled-components'
import { VocabProvider } from '@sainsburys-tech/bolt-vocab-provider'
import { AddToWishlist } from '@sainsburys-tech/bolt'
import initClientOnlyRequests from './utils/initClientOnlyRequests'
import Application from './containers/Application'
import config from './config/index'
import getStore from './store'
import reportCustomAttributes from './utils/reportCustomAttributes'

import initCitrus from './utils/initCitrus'
import { getValue } from './utils/ConfigProvider'
import { isCitrusEnabled } from './utils/isCitrusEnabled'
import { getPageType } from './utils/pageTypes'

if (window.assetsPrefixPath) {
  // Set public path for assets loaded on client side routing via loadable
  // https://v4.webpack.js.org/guides/public-path/#on-the-fly
  // eslint-disable-next-line no-undef, camelcase
  __webpack_public_path__ = window.assetsPrefixPath
  delete window.assetsPrefixPath
}

const appDebug = debug('App')

const isRealProduction =
  window.location.hostname.toLowerCase().indexOf('argos.co.uk') !== -1 && process.env.NODE_ENV === 'production'

if (isRealProduction) {
  debug.disable()
} else {
  debug.enable('App')
}

// Add to temporarily remove service workers for Safari 17 and above
const isSafari17andAbove = () => {
  const ua = navigator.userAgent
  const isSafari = ua.indexOf('Safari') !== -1
  const version = ua.substring(ua.indexOf('Version/') + 8)
  const majorVersion = parseInt(version, 10)

  return isSafari && majorVersion >= 17
}

// Register Service Worker
if (getValue('key') === 'argos' && 'serviceWorker' in navigator && !isSafari17andAbove()) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js', {
        scope: '/'
      })
      .then(registration => {
        console.log('SW registered: ', registration)
      })
      .catch(registrationError => {
        console.log('SW registration failed: ', registrationError)
      })
  })
}

const Fetchr = require('fetchr')

const fetchr = new Fetchr({
  xhrPath: config.xhrPath, // Path for XHR to be served from
  xhrTimeout: config.xhrTimeout
})

appDebug('Rehydrating...')

const reduxStore = getStore()

reduxStore.dispatch(AddToWishlist.getUserDetails())

const context = {
  service: fetchr,
  reduxStore
}

if (
  isCitrusEnabled({
    pageType: getPageType(location?.pathname)
  })
) {
  try {
    initCitrus(context)
  } catch (e) {
    throw new Error(e)
  }
}

initClientOnlyRequests(context)

loadableReady(() => {
  ReactDOM.hydrate(
    <Provider store={reduxStore}>
      <BrowserRouter>
        <CookiesProvider>
          <ThemeProvider theme={getValue('theme')}>
            <VocabProvider vocab={getValue('vocab')}>
              <Application brand={getValue('key')} />
            </VocabProvider>
          </ThemeProvider>
        </CookiesProvider>
      </BrowserRouter>
    </Provider>,
    document.getElementById('app'),
    () => {
      initClientOnlyRequests(context)
      appDebug('React Rendered')
      reportCustomAttributes()
    }
  )
})
