import styled, { css } from 'styled-components'
import { Button } from '@sainsburys-tech/bolt'

export const Container = styled.div`
  margin-bottom: 21px;
`

export const OuterContainer = styled.div`
  overflow: hidden;

  ${props =>
    css`
      max-height: ${props.maxHeight};
    `}

  ${props =>
    !props.noAnimation &&
    css`
      will-change: max-height;
      transition: max-height 0.2s ease-out;
    `}
`

export const InnerContainer = styled.div`
  overflow: hidden;
`

export const ToggleButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  color: ${props => props.theme.color.alias.link.default.color};
  font-size: ${props => props.theme.font.size['label-1']};
  font-weight: ${props => props.theme.font.weight.regular};
  line-height: ${props => props.theme.font['line-height']['label-1']};
  border: none;
  background: transparent;

  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.color.alias.link.hover.color};
    background-color: transparent;
  }
`

export const ToggleLabel = styled.span`
  min-width: 84px;
  text-align: left;
`

export const Toggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: ${props => props.theme.color.monochrome.darker};
  pointer-events: none;
  transition: 0.2s ease;

  > svg {
    height: 24px;
    width: 24px;
    margin-right: 0;
    top: inherit;
  }

  ${props =>
    props.isOpen &&
    css`
      transform-origin: center;
      transform: rotate(180deg);
    `}
`
