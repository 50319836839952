import deepcopy from 'lodash.clonedeep'

import Logger from '../../utils/Logger'
import { FilterUriFragmentsHelper } from '../../helpers/FilterUriFragmentsHelper'
import { CATEGORY_FILTER_ID } from '../../helpers/CategoryHelper'

const logger = new Logger()

// -------------------------------------------------
// INITIAL STATE
// -------------------------------------------------
export const initialState = {
  result: null,
  searchTerm: null,
  numberOfResults: null,
  isCannedSearch: null,
  currentPage: null,
  pageSize: null,
  products: undefined,
  filters: [],
  filterModalVisible: true,
  filterPanelStatic: true,
  noResultsModalVisible: null,
  appliedFilters: [],
  appliedFiltersOrder: [],
  appliedSortBy: {},
  brandSuggestions: [],
  productCategorySuggestions: [],
  meta: {
    listerType: '',
    totalData: null,
    pageSize: null,
    currentPage: null,
    totalPages: null,
    canned: false,
    categoryMatch: false,
    appliedSorts: [],
    appliedFilters: [],
    suggestions: [],
    aggregations: [],
    displayableSorts: {},
    tracking: [],
    brandSuggestions: [],
    productCategorySuggestions: []
  },
  similarAiLinks: [],
  success: null,
  appliedFilterUriFragments: null,
  status: null,
  failedService: null,
  isPopulated: false,
  filterResetCount: 0,
  categoryFilterId: null,
  ratingFilterApplied: false,
  categoryMatch: null,
  categoryMatchOverride: null,
  spellcheckedSearch: false,
  searchType: null,
  inputSearchTerm: null,
  searchTermSuggestions: null,
  searchCanonical: '',
  emwbisModalVisible: false,
  emwbisModalData: {}
}

// -------------------------------------------------
// HELPERS
// -------------------------------------------------

export const clearState = newState => {
  const modified = deepcopy(newState)
  modified.isPopulated = true
  modified.numberOfResults = null
  modified.success = null
  modified.products = null
  modified.filters = []
  modified.appliedFilters = []
  modified.appliedFiltersOrder = []
  modified.appliedSortBy = {}
  modified.meta = null
  modified.filterResetCount = 0
  modified.categoryFilterId = null
  modified.ratingFilterApplied = false
  modified.categoryMatch = null
  modified.categoryMatchOverride = null
  modified.spellcheckedSearch = false
  modified.searchType = null
  modified.inputSearchTerm = null
  modified.searchTermSuggestions = null
  modified.filterModalVisible = false
  modified.searchCanonical = ''
  modified.searchTerm = null
  modified.similarAiLinks = []
  return modified
}

export const updateAppliedFilters = newState => {
  const modified = deepcopy(newState)
  modified.appliedFilters =
    modified.meta.appliedFilters.map(filter => {
      filter.id = filter.id.toLowerCase()
      return filter
    }) || []

  modified.appliedFilters.forEach(appliedFilter => {
    const { label, valueLabels } = getFilterLabelFromId(appliedFilter, modified)
    appliedFilter.name = label
    appliedFilter.valueLabels = valueLabels
    if (appliedFilter.name.toLowerCase() === 'customer rating') {
      modified.ratingFilterApplied = true
    }
    if (FilterUriFragmentsHelper.isFilterCategory(appliedFilter.id)) {
      modified.categoryFilterId = parseInt(appliedFilter.pimId, 10)
    }
  })

  return modified
}

const addAppliedValue = (values, appliedFilters) => {
  const valueIsApplied = ({ applicableFilter }) => {
    return appliedFilters.some(
      ({ filter }) => filter.endsWith(applicableFilter) || filter.includes(`${applicableFilter}&`)
    )
  }

  const valuesWithApplied = []
  values.forEach(value => {
    let modifiedValue = deepcopy(value)
    if (value.children?.length > 0) {
      const modifiedChildren = addAppliedValue(value.children, appliedFilters)
      const isChildApplied = modifiedChildren.some(filter => filter.applied)
      const isGrandchildApplied = modifiedChildren.some(filter => filter.isChildApplied || filter.isGrandchildApplied)
      modifiedValue = {
        ...value,
        children: modifiedChildren,
        id: value.id.toLowerCase(),
        isChildApplied,
        isGrandchildApplied
      }
    }
    valuesWithApplied.push({ ...modifiedValue, applied: valueIsApplied(modifiedValue) })
  })
  return valuesWithApplied
}

export const updateFilterState = newState => {
  const modified = deepcopy(newState)
  modified.filters = modified.filters.map(filter => {
    const updatedValues = addAppliedValue(filter.values, modified.appliedFilters)
    return { ...filter, values: updatedValues, id: filter.id.toLowerCase() }
  })
  return modified
}

export const updateFilterUriFragments = newState => {
  const modified = deepcopy(newState)
  const doesFilterExist = (appliedFilterId, filterValue) => {
    return modified.filters.some(filter => {
      if (filter.id === appliedFilterId) {
        return filter.values.some(({ applicableFilter }) => {
          return applicableFilter.endsWith(filterValue) || applicableFilter.includes(`${filterValue}&`)
        })
      }
    })
  }

  modified.appliedFilterUriFragments = {
    allFilters: {},
    matchedFilters: {},
    category: modified.appliedFilters
      .filter(appliedFilter => appliedFilter.id === CATEGORY_FILTER_ID)
      .map(appliedFilter => appliedFilter.filter)
  }

  modified.appliedFilters.forEach(appliedFacet => {
    appliedFacet.filter.split('&').forEach(appliedFilter => {
      const appliedFilterId = appliedFacet.id
      const filterValue = FilterUriFragmentsHelper.splitFilterValue(appliedFilter)

      if (
        FilterUriFragmentsHelper.facetHasNoSelectedFilter(
          appliedFilterId,
          modified.appliedFilterUriFragments.allFilters
        )
      ) {
        modified.appliedFilterUriFragments.allFilters[appliedFilterId] = [filterValue]
      } else if (FilterUriFragmentsHelper.isFilterNotCategory(appliedFilterId)) {
        modified.appliedFilterUriFragments.allFilters[appliedFilterId].push(filterValue)
      }

      if (doesFilterExist(appliedFilterId, filterValue)) {
        if (modified.appliedFilterUriFragments.matchedFilters[appliedFilterId]) {
          modified.appliedFilterUriFragments.matchedFilters[appliedFilterId].push(filterValue)
        } else {
          modified.appliedFilterUriFragments.matchedFilters[appliedFilterId] = [filterValue]
        }
      }
    })
  })

  return modified
}

const findAppliedFilter = (filterId, facetId, aggregation) => {
  let uriFragments = []
  const appliedValue = aggregation.find(value => value.id === filterId)
  if (appliedValue) return appliedValue.applicableFilter.split('&')
  aggregation.forEach(value => {
    if (value.children?.length > 0) {
      const appliedFilter = findAppliedFilter(filterId, facetId, value.children)
      if (appliedFilter.length > 0) {
        uriFragments = appliedFilter
      }
    }
  })
  return uriFragments
}

export const getUriFragmentForFilterId = (filterId, facetId, filters) => {
  let fragments = []
  filters.forEach(aggregation => {
    if (aggregation.id === facetId) {
      fragments = findAppliedFilter(filterId, facetId, aggregation.values)
    }
    if (facetId === 'price-range') {
      fragments = [`filter=price:%C2%A3${filterId.split(',')[0]}+-+%C2%A3${filterId.split(',')[1]}`]
    }
  })
  return fragments
}

const getFilterLabelFromId = ({ id, values }, newState, ...args) => {
  logger.debug('functionLogger', { args }, 'ProductStore getFilterLabelFromId')
  let label = ''
  let valueLabels = []

  // some filter ids are prefixed with "attributes." which means we can't find the label
  id = id.replace('attributes.', '')

  newState.filters.forEach(filter => {
    if (filter.id === id) {
      label = filter.label

      // Get labels for applied filter values
      valueLabels = values
        .map(value => {
          const appliedFilterValue = filter?.values?.find(filterValue => filterValue.id === value)
          return appliedFilterValue?.label
        })
        .filter(appliedFilterLabel => appliedFilterLabel)
    }
  })

  if (id === 'clearance') {
    label = 'Clearance'
  }

  return { label, valueLabels }
}

export default {
  clearState,
  updateAppliedFilters,
  updateFilterState,
  updateFilterUriFragments
}
