import beaconService from '../services/BeaconService'
import { getCitrusImpressionBeacon, getCitrusClickBeacon } from '../redux/citrus'

export function executeCitrusBeacon(citrus, onCitrusBeaconSent, payload, type) {
  const impressionBeacon = getCitrusImpressionBeacon(citrus, payload.productId)
  const clickBeacon = getCitrusClickBeacon(citrus, payload.productId)
  const citrusReportingProxyUrl = '/finder-api/sresource'

  if (type === 'impBeacon' && impressionBeacon) {
    const beaconUrl = `${citrusReportingProxyUrl}/first-i/${impressionBeacon}`
    if (navigator.sendBeacon) {
      navigator.sendBeacon(beaconUrl)
    } else {
      beaconService(beaconUrl)
    }
    onCitrusBeaconSent({
      productSku: payload.productId,
      beaconType: 'impression'
    })
  }

  if (type === 'clickBeacon' && clickBeacon) {
    const beaconUrl = `${citrusReportingProxyUrl}/second-c/${clickBeacon}`
    if (navigator.sendBeacon) {
      navigator.sendBeacon(beaconUrl)
    } else {
      beaconService(beaconUrl)
    }
    onCitrusBeaconSent({
      productSku: payload.productId,
      beaconType: 'click'
    })
  }
}

export const reportCitrusBanner = async (adId, type) => {
  const reportType = type === 'click' ? 'second-c' : 'first-i'
  const beaconUrl = `/finder-api/sresource/${reportType}/${adId}`

  if (navigator.sendBeacon) {
    navigator.sendBeacon(beaconUrl)
  } else {
    beaconService(beaconUrl)
  }
}
