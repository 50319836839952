import { getSearchPath, getBrowsePath } from '@sainsburys-tech/boltui-utils'
import { loadingStart } from '../redux/application/application'
import { BrowseHelper } from '../helpers/BrowseHelper'
import { getUriFragmentForFilterId } from '../redux/product'
import { selectFilter } from '../redux/product/product'
import { getSearchTermForSearchType, getSearchType } from '../helpers/ProductHelper'

export default function(context, payload) {
  const productStore = context.reduxStore.getState().product
  const categoryStore = context.reduxStore.getState().category
  const applicationStore = context.reduxStore.getState().application

  const { appliedSortBy, appliedFilterUriFragments, filters, isCannedSearch } = productStore
  const filterToAdd = getUriFragmentForFilterId(payload.filterId, payload.facetId, filters)
  const searchTerm = getSearchTermForSearchType(productStore)
  const filterUriFragments = appliedFilterUriFragments.allFilters
  payload.facetId = payload.facetId === 'price-range' ? 'price' : payload.facetId

  if (filterToAdd.length > 0) {
    filterUriFragments[payload.facetId] = [filterToAdd[0].split(':')[1]]

    // Track order filters are applied
    context.reduxStore.dispatch(selectFilter(payload))
  }

  context.reduxStore.dispatch(loadingStart())

  if (searchTerm) {
    const { categoryFilterId: categoryId, categoryMatchOverride } = productStore
    const url = getSearchPath(searchTerm, {
      categoryId,
      filterUriFragments,
      sortBy: appliedSortBy,
      searchType: getSearchType(productStore),
      isCannedSearch,
      categoryMatch: categoryMatchOverride
    })
    context.history.push(url)

    return
  }

  const { ancestors, categoryName, categoryId } = categoryStore
  const { isClearance, isSaleRoute } = applicationStore
  const params = {
    currentCatName: categoryName,
    ancestors,
    currentCatId: categoryId,
    filterUriFragments,
    sortBy: appliedSortBy,
    isClearance,
    isSale: isSaleRoute
  }

  const url = getBrowsePath(BrowseHelper.buildCorrectUrlBrowseParams(params))
  context.history.push(url)
}
