import { connect } from 'react-redux'
import Lister from '../../components/Lister/Lister'
import { contentAreaSelector, seoContentAreaSelector, dfpSelector } from '../../selectors/content'
import { allProductsSelector } from '../../selectors/product'

const mapStateToProps = state => ({
  similarAiLinks: state.product.similarAiLinks,
  products: allProductsSelector(state),
  contentAreas: contentAreaSelector(state),
  seoArea: seoContentAreaSelector(state),
  dfp: dfpSelector(state),
  urlRefinements: state.application.urlRefinements
})

export default connect(mapStateToProps)(Lister)
