import { searchTermSelector, pageTypeSelector } from './application'
import { isPlpSelector, categoryNameSelector, ancestorsSelector } from './category'
import { BrowseHelper } from '../helpers/BrowseHelper'
import { createDeepEqualSelector } from './selectors'
import config from '../config'

export const contentAreaSelector = createDeepEqualSelector(
  state => state.cms.data.contentAreas,
  v => v
)

export const seoContentAreaSelector = createDeepEqualSelector(
  state => state.seo.data.seoArea,
  v => v
)

export const dfpSelector = createDeepEqualSelector(
  searchTermSelector,
  isPlpSelector,
  pageTypeSelector,
  categoryNameSelector,
  ancestorsSelector,
  (searchTerm, isPlp, pageType, categoryName, ancestors) => ({
    channelId: config.dfp.channelId,
    taxonomy:
      searchTerm || isPlp
        ? BrowseHelper.getDfpListerQuery(pageType, searchTerm, categoryName)
        : BrowseHelper.getDfpTaxonomyQuery(ancestors, categoryName)
  })
)
