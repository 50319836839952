import { createDeepEqualSelector } from './selectors'

export const isPlpSelector = state => state.category.isPlp
export const categoryNameSelector = state => state.category.categoryName

export const ancestorsSelector = createDeepEqualSelector(
  state => state.category.ancestors,
  v => v
)

export const categoryChildrenSelector = createDeepEqualSelector(
  state => state.category.children,
  v => v
)

export const categorySiblingsSelector = createDeepEqualSelector(
  state => state.category.siblings,
  v => v
)
