/* eslint import/no-duplicates: "off" */
/* eslint no-return-assign: "off" */

import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary, PageNotFound } from '@sainsburys-tech/bolt'
import loadable from '@loadable/component'
import { pushEventAndTrack } from '../../helpers/TaggingSerializer'
import { ServiceHelper } from '../../helpers/ServiceHelper'
import Template from './NoResultsSuggestions.template'
import Logger from '../../utils/Logger'
import * as Styled from './styles'
import { getValue, getBrand } from '../../utils/ConfigProvider'

const ProductCard = loadable(() =>
  import(/* webpackChunkName: "labs-product-card" */ '../ProductList/StyledProductCard.js')
)
const CMS = loadable.lib(() => import(/* webpackChunkName: "cms-components" */ '@sainsburys-tech/bolt-cms-components'))
const logger = new Logger()

export default class NoResultsSuggestions extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      products: []
    }
  }

  componentDidMount() {
    if (typeof document !== 'undefined' && document.getElementsByClassName('app--search-lister')[0]) {
      pushEventAndTrack({
        eventName: 'errorPage',
        eventAction: '404 Canned Search Event'
      })
      document.getElementsByClassName('app--search-lister')[0].classList.add('canned-search')
    }

    if (getValue('features.bestSellers.enabled')) {
      if (typeof window !== 'undefined') {
        window.addEventListener(
          'orientationchange',
          () => {
            this.getBestSellersProducts()
          },
          false
        )
      }

      this.getBestSellersProducts()
    }
  }

  componentWillUnmount() {
    if (
      typeof document !== 'undefined' &&
      document.getElementsByClassName('app--search-lister').length > 0 &&
      document.getElementsByClassName('app--search-lister')[0].classList.contains('canned-search')
    ) {
      document.getElementsByClassName('app--search-lister')[0].classList.remove('canned-search')
    }
  }

  getBestSellersProducts() {
    const self = this
    const contentServiceRequest = ServiceHelper.buildContentServiceRequest({ categoryId: 'all' }, this.context)
    this.context.fetchr.read(contentServiceRequest.service, contentServiceRequest.params, {}, function(
      err,
      contentServiceResponse
    ) {
      if (err) {
        logger.error('componentLogger', err, 'Unable to fetch best selling products.')
      } else {
        const resultsSize = contentServiceResponse.response.data?.length
        const products = []
        let count = 0

        contentServiceResponse.response.data?.forEach(product => {
          self.context.fetchr.read('pdp', { partNumbers: product.id }, {}, (err, pdpResponse) => {
            if (err) {
              logger.debug('componentLogger', err || pdpResponse.errors, 'Unable to fetch pdp service response.')
              count += 1
            } else {
              const productData = pdpResponse.data[0]
              const price = pdpResponse.included.find(included => included.type === 'prices')

              const bestSellersProduct = productData.attributes
              bestSellersProduct.price = price.attributes
              bestSellersProduct.productLink = `/product/${productData.id}`
              bestSellersProduct.id = productData.id

              products.push(bestSellersProduct)
              count += 1

              // only update state once all products have been fetched
              if (count === resultsSize) self.setState({ products })
            }
          })
        })
      }
    })
  }

  render() {
    const { searchTerm, categories } = this.props
    const { products } = this.state
    const headers =
      searchTerm !== ''
        ? {
            mainHeader: `${Template.searchTermHeaderStart}${searchTerm}${Template.searchTermHeaderEnd}`,
            subheader: Template.searchTermSubheader
          }
        : {
            mainHeader: Template.simpleHeader,
            subheader: Template.simpleSubheader
          }

    const itemsPerSlide = {
      itemsPerSlideXs: 2,
      itemsPerSlideSm: 2,
      itemsPerSlideMd: 3,
      itemsPerSlideLg: 4
    }

    return (
      <ErrorBoundary>
        {getBrand() !== 'tu' ? (
          <Styled.Container tabIndex='0' data-el='no-results-suggestions-container'>
            <Styled.Heading className='h2' data-test='no-results-suggestions-heading'>
              {headers.mainHeader}
            </Styled.Heading>
            <Styled.Subheading className='h3' data-test='no-results-suggestions-subheading'>
              {headers.subheader}
            </Styled.Subheading>

            <ErrorBoundary>
              <CMS>
                {({ components }) => {
                  const { C004 } = components

                  return (
                    <Styled.CategoryContainer data-test='no-results-suggestions-category-container'>
                      {categories.map((category, index) => {
                        return (
                          <div
                            className='xs-6 sm-6 md-4 lg-3'
                            data-test='no-results-suggestions-category-tile'
                            key={index}>
                            <C004
                              heading={category.attributes.name}
                              borderColor='black'
                              image={{
                                url: `//media.4rgos.it/i/Argos/${category.id}_IMG`,
                                altText: `${category.attributes.name}`
                              }}
                              link={{
                                url: `${category.attributes.url}`,
                                title: `${category.attributes.url}`
                              }}
                              imageStyle='square'
                            />
                          </div>
                        )
                      })}
                    </Styled.CategoryContainer>
                  )
                }}
              </CMS>
            </ErrorBoundary>

            {getValue('features.bestSellers.enabled') && products.length > 0 && (
              <>
                <Styled.Header data-test='best-sellers-heading'>{Template.bestSellers}</Styled.Header>
                <ErrorBoundary>
                  <Styled.SliderComponent
                    className='no-results-suggestions__slider-product no-results-suggestions__best-sellers-slider'
                    data-test='best-sellers'
                    slideClass='no-results-suggestions__slider-slide'
                    {...itemsPerSlide}
                    items={products.map((product, index) => (
                      <ErrorBoundary key={`${index}-${product.id}-boundary`}>
                        <Styled.ProductCardWrapper className='xs-12 sm-6 md-4 lg-3'>
                          <ProductCard
                            key={`sliderProduct-${index}-${product.id}`}
                            className='no-results-suggestions__slider-product'
                            brand={product.brand}
                            name={product.name}
                            price={product.price.now}
                            specialOfferText={product.price.flashText}
                            productLink={product.productLink}
                            partNumber={product.partNumber}
                            cta={<></>}
                          />
                        </Styled.ProductCardWrapper>
                      </ErrorBoundary>
                    ))}
                  />
                </ErrorBoundary>
              </>
            )}
          </Styled.Container>
        ) : (
          <PageNotFound
            heading={`Sorry, no results for '${searchTerm}'`}
            subHeading={`Don't give up, please try again. You could try a different term or double check the spelling.`}
            links={[
              {
                text: 'Take me home',
                href: '/',
                title: 'Take me home: Tu Homepage'
              },
              {
                text: 'Contact us',
                href: '/help',
                title: 'Contact us: Tu help pages'
              }
            ]}
          />
        )}
      </ErrorBoundary>
    )
  }
}

NoResultsSuggestions.contextTypes = {
  store: PropTypes.object,
  fetchr: PropTypes.object
}

NoResultsSuggestions.propTypes = {
  searchTerm: PropTypes.string,
  categories: PropTypes.array
}

NoResultsSuggestions.defaultProps = {
  searchTerm: '',
  categories: []
}
