import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from '@sainsburys-tech/bolt'
import FilterList from './FilterList'
import removeFilter from '../../actions/removeFilter'
import { pushEventAndTrack } from '../../helpers/TaggingSerializer'

class AppliedFilters extends Component {
  removeAppliedFilter = (filterId, filterName) => {
    removeFilter(this.context, {
      filterId: filterName,
      facetId: filterId
    })

    pushEventAndTrack({
      eventName: 'appliedFilterDeselect',
      eventAction: 'Applied Filter Deselect'
    })
  }

  formatFacetLabel = (type, label) => {
    const facets = ['price', 'brands', 'fasttrack', 'colour', 'colour group']

    if (type.toLowerCase() === 'customer rating') {
      label = this.formatStarRating(label)
    }

    return facets.includes(type.toLowerCase()) ? `${label}` : `${type}: ${label}`
  }

  formatStarRating = rating => {
    return `${rating} Stars`
  }

  render() {
    const { filters, order } = this.props

    const filterGroups = filters
      .filter(filter => filter.id !== 'category')
      .reduce((output, filter) => {
        return [
          ...output,
          ...filter.values
            .filter(value => value.applied)
            .map(value => ({
              id: filter.id,
              name: value.id,
              label: this.formatFacetLabel(filter.label, value.label)
            }))
        ]
      }, [])

    const filterCount = filterGroups.length
    if (!filterCount) return null

    const orderList = order.map(item => item.filterId)
    const orderedFilters = filterGroups.sort((a, b) => {
      return orderList.indexOf(b.name.toLowerCase()) - orderList.indexOf(a.name.toLowerCase())
    })

    const Content = (
      <FilterList filters={orderedFilters} filterCount={filterCount} onRemoveClicked={this.removeAppliedFilter} />
    )

    return <ErrorBoundary>{Content}</ErrorBoundary>
  }
}

AppliedFilters.propTypes = {
  filters: PropTypes.array.isRequired,
  order: PropTypes.array
}
AppliedFilters.defaultProps = {
  order: []
}

AppliedFilters.contextTypes = {
  reduxStore: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default AppliedFilters
