import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { HeaderFetcher } from '@sainsburys-tech/bolt'
import { getSearchPath } from '@sainsburys-tech/boltui-utils'

import { withRouter } from 'react-router-dom'
import TaggingSerializer from '../../helpers/TaggingSerializer'
import CookieTracking from '../../utils/CookieTracking'
import HeadHelper from '../../helpers/HeadHelper'
import CanonicalHelper from '../../helpers/CanonicalHelper'
import { BrowseHelper } from '../../helpers/BrowseHelper'
import { isSearchOrCannedOrSd } from '../../helpers/RoutingHelper'

const searchAbTestPaths = [
  '/search/christmas-tree/',
  '/search/christmas-lights/',
  '/search/christmas-decorations/',
  '/search/baubles/',
  '/search/christmas-baubles/',
  '/search/prelit-christmas-tree/',
  '/search/xmas-tree/',
  '/search/christmas-tree-lights/',
  '/search/christmas-tree-decorations/',
  '/search/christmas-trees/',
  '/search/christmas-garland/',
  '/search/fibre-optic-christmas-tree/',
  '/search/christmas-tree-skirt/',
  '/search/garland/',
  '/search/tree-skirt/',
  '/search/christmas-tree-stand/',
  '/search/christmas-wreath/',
  '/search/wreath/',
  '/search/outdoor-christmas-lights/',
  '/search/half-christmas-tree/'
]
class Header extends React.PureComponent {
  constructor(props, context) {
    super(props, context)

    this.state = {
      userInfo: {
        trolleyCount: 0
      }
    }

    this.onUserInfoLoad = this.onUserInfoLoad.bind(this)
    this.updateDigitalDataObject = this.updateDigitalDataObject.bind(this)
    this.getSearchTerm = this.getSearchTerm.bind(this)
  }

  componentDidMount() {
    // If the Argos header is not used then onUserInfoLoad will never be called,
    // setting up digitalData. As such for brand other than Argos
    // call onUserInfoLoad manually with empty values.
    if (!this.props.useArgosHeader) {
      this.onUserInfoLoad({ loginStatus: '', userId: '' })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showLoader && !this.props.showLoader) {
      this.updateDigitalDataObject()
    }
  }

  onUserInfoLoad(userInfo) {
    this.setState({ userInfo })
    this.updateDigitalDataObject()
    return {}
  }

  getSearchTerm() {
    if (
      (this.props.brandSuggestions && this.props.brandSuggestions.length > 0) ||
      this.props.spellcheckedSearch ||
      this.props.productStore.isCannedSearch ||
      this.props.applicationStore.pageType === 'canned'
    ) {
      return ''
    }
    if (this.props.inputSearchTerm) return this.props.inputSearchTerm
    return this.props.searchTerm || ''
  }

  setRenderNoIndexTag() {
    const appStore = this.props.applicationStore

    // If page is set in Magnolia to have a no index tag (noIndex is a string in the CMS service response...)
    if (appStore?.seoMeta?.noIndex === 'true') {
      return true
    }

    const { categoryStore, productStore } = this.props
    const { isCategoryLive } = categoryStore
    const { pageType } = appStore
    const isCanned = productStore.isCannedSearch
    if (isSearchOrCannedOrSd(pageType)) {
      return isCanned || appStore.searchNoIndex
    }
    if (pageType === 'browse') {
      return BrowseHelper.isNoIndex(appStore.seoRobotMeta, appStore.appliedFilters) && isCategoryLive
    }
    return false
  }

  setMetaTags() {
    const renderNoIndexTag = this.setRenderNoIndexTag()
    const metaTags = [{ name: 'description', content: this.props.description }]
    if (renderNoIndexTag) {
      metaTags.push({ name: 'robots', content: 'NOINDEX' })
    }

    return metaTags
  }

  updateDigitalDataObject() {
    const { productStore, categoryStore, applicationStore } = this.props
    // channel is set using a cookie in <head>
    const { channel } = window.digitalData.page.attributes

    const autoSuggestCookie = CookieTracking.getTrackingEvent('autosuggest')
    // if there is no cookie maintain the previous cookie value
    this.autoSuggestCookie = autoSuggestCookie || this.autoSuggestCookie

    const taggingSerializer = new TaggingSerializer(
      productStore,
      categoryStore,
      applicationStore,
      { channel, autoSuggestCookie: this.autoSuggestCookie },
      this.state.userInfo
    )
    Object.assign(window.digitalData, taggingSerializer.getDataObject())
    const trackingEvent = taggingSerializer.getSatelliteTrackingEvent()
    if (trackingEvent) {
      this.fireSatelliteTrackingEvent(trackingEvent)
    }
  }

  fireSatelliteTrackingEvent(eventName) {
    if (eventName && typeof window.digitalData !== 'undefined' && !!window.digitalData.track) {
      window.digitalData.track(eventName)
    }
  }

  render() {
    const {
      useArgosHeader,
      productStore,
      categoryStore,
      applicationStore,
      pageTitle,
      trolleyCount,
      wishlistCount
    } = this.props
    const { canonical } = new CanonicalHelper(categoryStore, productStore, applicationStore)
    const metaTags = this.setMetaTags()
    const linkTags = HeadHelper.getLinkTags(canonical, productStore, categoryStore, applicationStore)

    const handleSearch = (searchTerm, trackingParams, evt) => {
      if (evt) evt.preventDefault()

      const searchPath = getSearchPath(searchTerm) + trackingParams
      const isSearchABTestPath = searchAbTestPaths.some(abTestPath => searchPath?.startsWith(abTestPath))

      if (isSearchABTestPath) {
        // Force server request so that Akamai A/B test cookie is dropped on these paths
        window.location.href = searchPath
      } else {
        document.activeElement.blur()
        this.context.navigateTo(searchPath)
      }

      return Promise.resolve()
    }

    return (
      <div>
        <Helmet title={pageTitle} link={linkTags} meta={metaTags} />
        {useArgosHeader && (
          <HeaderFetcher.ArgosHeader
            catalogId={10001}
            config='default'
            onSearchSubmit={handleSearch}
            overrideOnSearchSubmit={handleSearch}
            onUserInfoLoad={this.onUserInfoLoad}
            searchTerm={this.getSearchTerm()}
            overrideSearchTerm={this.getSearchTerm()}
            style={{ zIndex: 1, position: 'relative' }}
            overrideTrolleyCount={trolleyCount + this.state.userInfo.trolleyCount}
            overrideWishlistCount={wishlistCount}
            skipToContentTag='#findability'
          />
        )}
      </div>
    )
  }
}

Header.propTypes = {
  searchTerm: PropTypes.string,
  pageTitle: PropTypes.string,
  showLoader: PropTypes.bool,
  categoryStore: PropTypes.object,
  applicationStore: PropTypes.object,
  spellcheckedSearch: PropTypes.bool,
  inputSearchTerm: PropTypes.string,
  productStore: PropTypes.object,
  brandSuggestions: PropTypes.array,
  description: PropTypes.string,
  trolleyCount: PropTypes.number,
  wishlistCount: PropTypes.number,
  useArgosHeader: PropTypes.bool
}

Header.contextTypes = {
  navigateTo: PropTypes.func,
  store: PropTypes.object
}

Header.defaultProps = {
  searchTerm: '',
  pageTitle: '',
  showLoader: false,
  categoryStore: {},
  applicationStore: {},
  spellcheckedSearch: false,
  inputSearchTerm: '',
  productStore: {},
  brandSuggestions: [],
  description: '',
  trolleyCount: null,
  wishlistCount: null,
  useArgosHeader: true
}

export default withRouter(Header)
