import React from 'react'
import PropTypes from 'prop-types'

import { ChevronIcon } from '@sainsburys-tech/bolt-icons'
import { ColorSwatchOption, CheckboxItem } from '@sainsburys-tech/bolt'
import { getBrand } from '../../utils/ConfigProvider'

import * as Styled from './styles'

export const FACET_TYPES = ['default', 'color', 'rating', 'category', 'size']
const CLICK_ORIGIN = 'filter-size-link'

export const CategoryFilter = ({
  label,
  isApplied = false,
  isDescendantApplied = false,
  isLabel = false,
  level = null
}) => (
  <Styled.CategoryFilterText
    data-el='findability-facet__filter-text'
    descendantSelected={isDescendantApplied}
    categorySelected={isApplied}
    isLabel={isLabel}
    data-level={level}>
    {isDescendantApplied && <ChevronIcon dir='left' height='16px' />}
    {label}
  </Styled.CategoryFilterText>
)

const Label = ({ label, type }) => {
  if (type === 'color') return <ColorSwatchOption color={label} swatchAnchorLink />
  if (type === 'category') return <CategoryFilter label={label} />
  return label
}

const RightHandElement = ({ count, value }) =>
  count > 0 || value > 0 ? (
    <Styled.FilterCount data-el='facet__filter-count'>({count || value})</Styled.FilterCount>
  ) : null

const Filter = ({
  filterGroup,
  type,
  id,
  uniqueId,
  label,
  count,
  disabled,
  applied,
  value,
  selectFilter,
  refCb,
  isChildApplied,
  isGrandchildApplied,
  level,
  setClickOrigin
}) => {
  const filterId = id || label
  const filterKey = `filter-${filterGroup}-${filterId}`
  const uniqueFilterId = `filter-${filterGroup}-${uniqueId}-label`
  const categoryIsApplied = type === 'category' && applied
  const filter = { facetId: filterGroup, filterId: id, applied: !applied, filterLabel: label }
  const isTypeSize = type === 'size'
  const isTu = getBrand() === 'tu' && isTypeSize
  const handleOnChange = () => {
    selectFilter(filter)
  }

  if (isTypeSize || type === 'category') {
    return (
      <Styled.Filter data-el='facet__filter' key={filterKey} type={type} applied={applied} data-selected={applied}>
        <Styled.FilterLabel
          as={categoryIsApplied ? 'div' : 'a'}
          type={type}
          applied={applied}
          id={uniqueFilterId}
          ref={refCb}
          data-filter-id={id}
          href='#'
          data-el='facet__filter-label'
          data-test={`${filterKey}-link`}
          onKeyDown={e => {
            if (e.keyCode === 32) {
              e.preventDefault()
              selectFilter(filter)
            }
          }}
          onClick={e => {
            e.preventDefault()
            if (!disabled && !categoryIsApplied) {
              selectFilter(filter)
            }
            isTu && setClickOrigin(CLICK_ORIGIN)
          }}>
          {type === 'category' && (
            <CategoryFilter
              level={level}
              label={label}
              isApplied={applied}
              isDescendantApplied={isChildApplied || isGrandchildApplied}
            />
          )}
          {isTypeSize && label}
          {!isTypeSize && <RightHandElement count={count} value={value} />}
        </Styled.FilterLabel>
      </Styled.Filter>
    )
  }
  return (
    <CheckboxItem
      className='font-standard'
      ariaLabel={value}
      id={filterKey}
      data-filter-id={id}
      name={filterGroup}
      defaultChecked={null}
      checked={applied}
      disabled={categoryIsApplied || disabled}
      value={id || label}
      onChange={() => handleOnChange()}
      hasBackground={false}
      label={<Label type={type} label={label} />}
      padding='8px 5px'
      rightHandElement={<RightHandElement count={count} value={value} />}
      rightPosition={5}
      optionBottomMargin={false}
    />
  )
}

Filter.defaultProps = {
  type: FACET_TYPES[0],
  disabled: false,
  applied: false,
  count: null,
  value: null,
  level: null,
  isChildApplied: false,
  isGrandchildApplied: false,
  setClickOrigin: () => {}
}

Filter.propTypes = {
  selectFilter: PropTypes.func.isRequired,
  refCb: PropTypes.func.isRequired,
  filterGroup: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  uniqueId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(FACET_TYPES),
  count: PropTypes.number,
  disabled: PropTypes.bool,
  applied: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  level: PropTypes.number,
  isChildApplied: PropTypes.bool,
  isGrandchildApplied: PropTypes.bool,
  setClickOrigin: PropTypes.func
}
CategoryFilter.propTypes = {
  label: PropTypes.string.isRequired,
  isApplied: PropTypes.bool,
  isDescendantApplied: PropTypes.bool,
  isLabel: PropTypes.bool,
  level: PropTypes.number
}
CategoryFilter.defaultProps = {
  isApplied: false,
  isDescendantApplied: false,
  isLabel: false,
  level: null
}
Label.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string
}
Label.defaultProps = {
  type: 'default'
}
RightHandElement.propTypes = {
  count: PropTypes.number,
  value: PropTypes.number
}
RightHandElement.defaultProps = {
  count: null,
  value: null
}
export default Filter
