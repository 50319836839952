import React from 'react'
import PropTypes from 'prop-types'
import ReverseAccordion from '../ReverseAccordion/ReverseAccordion'
import FilterPillLabel from '../FilterPills/FilterPillLabel'
import FilterPills from '../FilterPills/FilterPills'

import { Container, Heading } from './styles'

const FilterPillsContainer = ({
  filters,
  onActivate,
  onDeactivate,
  pillFilterType,
  maxRows,
  'data-test': dataTest,
  setClickOrigin
}) => {
  const pillFilter = filters.find(filter => filter.id === pillFilterType)
  if (!pillFilter) return null

  const pills = pillFilter?.values?.map(filter => ({
    label: <FilterPillLabel label={filter.label} value={filter.value} />,
    active: filter.applied,
    disabled: filter?.disabled || false,
    id: filter.id
  }))
  if (!pills || pills.length === 0) return null

  const filterPillsClassName = `filter-pills-${pillFilterType}-container`
  const filterPillsWrapperItemClassName = `filter-pills-${pillFilterType}-wrapper-item`

  return (
    <Container data-test={`${dataTest}-container`}>
      <Heading data-test={`${dataTest}-heading`}>Select your {pillFilterType}</Heading>
      <ReverseAccordion
        className={pillFilterType}
        elementClassName={filterPillsWrapperItemClassName}
        numOfItems={pills.length}
        maxRows={maxRows}
        data-test={dataTest}>
        <FilterPills
          pills={pills}
          onActivate={onActivate}
          onDeactivate={onDeactivate}
          className={filterPillsClassName}
          itemClassName={filterPillsWrapperItemClassName}
          data-test={`filter-pills-${pillFilterType}`}
          setClickOrigin={setClickOrigin}
        />
      </ReverseAccordion>
    </Container>
  )
}

FilterPillsContainer.propTypes = {
  /**
   * Array of available filters grouped by filter types
   */
  filters: PropTypes.array,

  /**
   * Optional prop to provide a callback function which is called when a Pill is activated
   */
  onActivate: PropTypes.func,

  /**
   * Optional prop to provide a callback function which is called when a Pill is deactivated
   */
  onDeactivate: PropTypes.func,

  /**
   * Filter Type for which to display the specific available filters
   */
  pillFilterType: PropTypes.string,

  /**
   * Number of minimum rows of pills to display when there are more than 2 rows of pills
   */
  maxRows: PropTypes.number,

  /**
   * Specify an optional data-test attribute to be added to the FilterPillsContainer
   */
  'data-test': PropTypes.string,

  /**
   * Optional functional prop to set the click origin application state for analytical purpose
   */
  setClickOrigin: PropTypes.func
}

FilterPillsContainer.defaultProps = {
  filters: [],
  onActivate: () => {},
  onDeactivate: () => {},
  pillFilterType: '',
  maxRows: 2,
  'data-test': 'component-filter-pills',
  setClickOrigin: () => {}
}

export default FilterPillsContainer
