import { take, put, fork } from 'redux-saga/effects'
import { getBrowseSeoSuccess, getBrowseSeoFailure } from '../../redux/seo/getBrowseSeo'
import Logger from '../../utils/Logger'
import { GET_CONTENT } from '../../redux/cms/getContent'

const logger = new Logger()

// -------------------------------------------------
// WORKER SAGAS
// -------------------------------------------------
export function* getBrowseSeoContent(payload) {
  try {
    if (payload && !payload.error) {
      yield put(getBrowseSeoSuccess(payload))
    } else {
      yield put(getBrowseSeoFailure(payload))
    }
  } catch (error) {
    logger.error('sagasLogger', error, 'Unable to get SEO content')
  }
}

// -------------------------------------------------
// WATCHER SAGAS
// -------------------------------------------------
export function* watchGetBrowseSeo() {
  while (true) {
    const { payload } = yield take(GET_CONTENT)
    yield fork(getBrowseSeoContent, payload)
  }
}
