// Checks if a specific type of filter exists
const hasAppliedFilter = (appliedFilters = [], filterName = '') => {
  if (!appliedFilters?.length || !filterName) return false

  const hasProperty = appliedFilters.some(filter => filter.hasOwnProperty(filterName))

  return hasProperty
}

export { hasAppliedFilter }
