export default {
  title: `You've searched for`,
  title_relevancy_1: `Best results for`,
  spellcheckTitleStart: `Nothing came up for '`,
  spellcheckTitleEnd: `'. Below are the results for...`,
  filter_by_category: { start: `Results for '`, end: `' in...` },
  category_no_brand: { start: `Bad luck, we don't sell any '`, end: `'items, but you might like...` },
  category_no_results: { start: `We couldn't find anything matching '`, end: `', but you might like...` },
  categ_match_top: `We are showing you the most relevant products.`,
  categ_match_bottom: { start: `See all results for '`, end: `' ` },
  default: { start: `We couldn't find anything matching '`, end: `', but you might like...` }
}
