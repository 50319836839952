import { getValue } from '../utils/ConfigProvider'
import refinedHeadingOverrides from './RefinementTitleOverrides'

import {
  conflictingRefinementTypes,
  refinementTypeConfig,
  orderedRefinementTypes,
  transformCategoryName
} from './RefinementTitleConfig'

const defaultMaxNumOfAppliedFiltersInTitle = 3

const departmentFilterId = 'department'

/**
 * Filters the list of applied filters according to the filterTypes according to the specified in the config
 * and re-formats each filter as a new object with properties `type` and  `filters`
 * @param appliedFilters {[object]}
 * @param filterTypes {[string]}
 * @param max Maximum number of filters
 * @returns {[]}
 */
const formatAppliedFilters = (appliedFilters = [], filterTypes = [], max = defaultMaxNumOfAppliedFiltersInTitle) => {
  const formattedFilters = []

  appliedFilters.forEach(item => {
    const type = Object.keys(item)

    // NOTE: On page load, even if there are applied refinements the applied filter types are not grouped
    if (item.hasOwnProperty('id')) {
      // Ignore refinement types not specified in the brand config
      if (filterTypes.indexOf(item.id) === -1) return null

      formattedFilters.push({
        type: item.id,
        filters: item.values.length > max ? item.values.slice(0, max) : item.values
      })
    } else {
      // Transform keys of filter types to lowercase for consistent comparison (cases for Argos differs from Habitat)
      formattedFilters.push({ type: type[0].toLowerCase(), filters: item[type[0]] })
    }
  })

  return formattedFilters
}

/**
 * Capitalises first letter of the entire list words in a string
 * @param word a word or words to have the first letter of the entire string capitalised
 * @returns {string} word or words with first letter of the entire string capitalised
 */
const capitalisedFirstLetter = (word = '') => {
  if (!word || typeof word !== 'string') return ''

  return word.length > 1 ? word.charAt(0).toUpperCase() + word.slice(1) : word.charAt(0).toUpperCase()
}

/**
 * Capitalises first letter of all words in a string by default
 * @param string word or words
 * @param capitalise {boolean} true for enabling capitalise
 * @returns {string} word or words with first letter of each capitalised
 */
const formatRefinements = (string, capitalise = true) => {
  if (!string || typeof string !== 'string') return ''

  // No capitalising needed for types like price, just return the whole string
  if (!capitalise) return string

  const terms = string.split(' ')
  return terms.map(term => capitalisedFirstLetter(term)).join(' ')
}

/**
 * Capitalises first letter of all words in a string for a list of words
 * @param appliedFilters {[string]} list of words
 * @param capitalise {boolean} true for enabling capitalise
 * @returns {string} List of words capitalised and joined together with an & symbol
 */
const getRefinements = (appliedFilters = [], capitalise = true) => {
  const formattedRefinements = appliedFilters.map(refinement => {
    return formatRefinements(refinement, capitalise)
  })

  return formattedRefinements.join(' & ')
}

/**
 * Returns a pluralised text for the provided department
 * @param {string} departmentName the department to pluralise
 * @returns {string} the pluralised department name if applicable
 */
const pluraliseDepartment = (departmentName = '') => {
  // In Tu sales channel, the following are singular terms that need to be plural
  const tuTermsToPluralise = ['Men', 'Women']

  if (departmentName?.length === 0) return ''

  return tuTermsToPluralise.some(term => {
    const regExp = new RegExp(departmentName, 'mi')
    return term.match(regExp)
  })
    ? `${departmentName}s`
    : departmentName
}

/**
 * Return the 'gendered' department from the category Ancestors used by the breadcrumbs
 * Currently specifically for Tu clothing
 *
 * @param categoryAncestors {[object]} category hierarchy list
 * @param departments {[string]} list of feature departments to find
 * @returns {string} department
 */
const getDepartment = (categoryAncestors = [], departments) => {
  if (!categoryAncestors.length || !departments) return ''

  // Extracting the category ancestors names
  const ancestors = categoryAncestors.reduce((accumulator, category) => {
    const { attributes } = category
    if (!attributes || typeof attributes.name === 'undefined') return accumulator

    accumulator.push(attributes.name)
    return accumulator
  }, [])

  const ancestorName = ancestors.find(name => {
    return departments.indexOf(name) !== -1
  })

  if (!ancestorName) return ''

  return pluraliseDepartment(ancestorName)
}

const wordIncludesSpecificWords = (word = '', specificWords = []) => {
  if (word.length === 0 || specificWords.length === 0) return false

  return specificWords.find(specificWord => {
    return word.includes(specificWord)
  })
}

const getSizes = (sizes = []) => {
  const keyWordsNotToUppercase = [
    'month',
    'months',
    'mth',
    'mnth',
    'mths',
    'year',
    'years',
    'one size',
    'newborn',
    'new born',
    'infant',
    'tiny',
    'kg',
    'lbs'
  ]

  if (sizes.length === 0) return ''

  const sizesToDisplay = sizes.map(size => {
    if (keyWordsNotToUppercase.includes(size) || wordIncludesSpecificWords(size, keyWordsNotToUppercase)) return size

    return size.toUpperCase()
  })

  return `Size ${sizesToDisplay.join(' & ')}`
}

// Fetch formatted string of the preferred refinement types, ignore anything not specified
const processFilters = (appliedFilters = [], type) => {
  if (!appliedFilters.length) return ''

  switch (type.toLowerCase()) {
    case 'size':
      return getSizes(appliedFilters)
    case 'brands':
    case departmentFilterId:
      return getRefinements(appliedFilters)
    case 'style':
    case 'type':
    case 'sleeve length':
    case 'price':
    default:
      return getRefinements(appliedFilters, false)
  }
}

/**
 * Returns a score according to the following:
 * @param typeIndex {number} Index position of the filter group within the featured filter type list
 * @param totalGroups {number} Total number of filter groups
 * @param numberOfFilters {number} Total number of filters per filter group
 * @returns {number} The applied filter group score
 */
const calculateScore = (typeIndex, totalGroups, numberOfFilters) => {
  return (typeIndex + 1 + totalGroups) ** -numberOfFilters
}

/**
 * Adds a score to each applied filters group according to:
 * - total number of filter groups (totalGroups)
 * - position of the filter group within the provided filterTypes (typeIndex)
 * - total number of filters per filter group (totalNumberOfFilters)
 *
 * @param filterGroups {array}
 * @param filterTypes {array} list of filter type to use for generating a score
 * @returns {object}
 */
const applyScore = (filterGroups, filterTypes) => {
  const totalGroups = filterTypes.length

  return filterGroups.map(group => {
    const typeIndex = filterTypes.indexOf(group.type)
    const totalNumberOfFilters = group.filters.length
    return { ...group, ...{ score: calculateScore(typeIndex, totalGroups, totalNumberOfFilters) } }
  })
}

const sortFilters = (filter1, filter2) => {
  return filter1.score - filter2.score
}

/**
 * Returns the total number of applied filters of all the provided filter groups that were selected
 *
 * @param appliedFilters {array} applied filter groups
 * @returns {number} the total number of applied filters from each provided filter group
 */
const getTotalNumOfAppliedFilters = (appliedFilters = []) => {
  if (!appliedFilters || appliedFilters.length === 0) return 0

  return appliedFilters.reduce((accumulator, appliedFilterGroup) => {
    const { id, values } = appliedFilterGroup

    // We ignore the applied category filter group
    if (id === 'category') return accumulator

    // Checking the number of selected filter per filter group
    if (Array.isArray(values)) return accumulator + values.length

    return accumulator
  }, 0)
}

/**
 * Returns the list of applied featured filter groups in relation to the featuredFilterIds or refined list of featured filters
 *
 * @param appliedFilters {array} applied filter groups
 * @param featuredFilterIds {array} featured filter group ids
 * @returns {array} applied featured filter groups
 */
const getAppliedFeaturedFilters = (appliedFilters = [], featuredFilterIds = []) => {
  if (!appliedFilters || appliedFilters.length === 0) return []

  if (!featuredFilterIds || featuredFilterIds.length === 0) return []

  return appliedFilters.filter(
    appliedFilterGroup =>
      typeof appliedFilterGroup.id !== 'undefined' && featuredFilterIds.indexOf(appliedFilterGroup.id) !== -1
  )
}

/**
 * Returns the remaining number of applied filters not being included inside the refined H1 title text
 *
 * @param appliedFilters {array} applied filter groups
 * @param featuredFilterIds {array} featured filter group ids
 * @param max {number} Total of applied filters selected to be refined (not filter groups)
 * @returns {number}
 */
const getRemainingNumOfAppliedFiltersNotInTitle = (
  appliedFilters = [],
  featuredFilterIds = [],
  max = defaultMaxNumOfAppliedFiltersInTitle
) => {
  if (!appliedFilters) return 0

  // Getting the total number of filters applied
  const totalNumOfAppliedFilters = getTotalNumOfAppliedFilters(appliedFilters)

  // No filters been applied
  if (totalNumOfAppliedFilters === 0) return 0

  // No refined filter list
  if (!featuredFilterIds || featuredFilterIds.length === 0) return totalNumOfAppliedFilters

  // Getting the total number of featured filters applied
  const appliedFeaturedFilters = getAppliedFeaturedFilters(appliedFilters, featuredFilterIds)
  const totalNumOfAppliedFeaturedFilters = getTotalNumOfAppliedFilters(appliedFeaturedFilters)

  // No featured filters selected, thus returning total number of non-featured filters
  if (totalNumOfAppliedFeaturedFilters === 0) return totalNumOfAppliedFilters

  // Number of non-featured filters selected
  const totalNumOfNonFeaturedFilters = totalNumOfAppliedFilters - totalNumOfAppliedFeaturedFilters

  // Only featured filters selected - returning the remaining number of applied filters if any
  if (totalNumOfNonFeaturedFilters === 0) {
    // Returning 0 as all featured filters added to the title or
    // the remaining number of applied filters if more than or equal to max
    return totalNumOfAppliedFeaturedFilters < max ? 0 : totalNumOfAppliedFeaturedFilters - max
  }

  // Mix of featured and normal filters selected and more than the maximum of featured filters selected
  if (totalNumOfAppliedFeaturedFilters > max)
    return totalNumOfNonFeaturedFilters + (totalNumOfAppliedFeaturedFilters - max)

  // Mix of featured and normal filters selected and less than the maximum of featured filters selected
  return totalNumOfNonFeaturedFilters
}

/**
 * Returns the refined applied filters text
 *
 * @param appliedFilters {array} applied filter groups
 * @param filterTypes {array} featured filter group ids
 * @param max {number} Total of applied filters selected to be refined (not filter groups)
 * @returns {string}
 */
const buildCustomFilterString = (appliedFilters = [], filterTypes = [], max = defaultMaxNumOfAppliedFiltersInTitle) => {
  if (!appliedFilters.length) return ''

  // Filters the applied filters according to the filterTypes and the number of max filters to include in the title
  // Also formats the applied filters as for example `{ type: 'price', filters: ['£5 - £10', '£10 - £15'] }`
  const formattedFilters = formatAppliedFilters(appliedFilters, filterTypes, max)

  if (!formattedFilters.length) return ''

  const scored = applyScore(formattedFilters, filterTypes)
  const sortedFilters = [...scored]
  sortedFilters.sort(sortFilters)

  // Note: the refinement types are in preferred display order in the array.
  /* eslint-disable prefer-const */
  let customFilterStrings = []
  let counter = 0

  for (let index = 0; index < sortedFilters.length; index += 1) {
    if (counter >= max) break

    let remainder = max - counter - sortedFilters[index].filters.length

    // If the remainder is equal to 0, the set of refinements are already enough
    if (remainder === 0) {
      customFilterStrings.push(processFilters(sortedFilters[index].filters, sortedFilters[index].type))
      counter += sortedFilters[index].filters.length
      break
    }

    // Number of refinements is less than the max, add them all
    if (remainder > 0) {
      customFilterStrings.push(processFilters(sortedFilters[index].filters, sortedFilters[index].type))
      counter += sortedFilters[index].filters.length
    }

    // If taking the next group will go over the max, slice the remainder (there's enough)
    if (remainder < 0) {
      customFilterStrings.push(
        processFilters(sortedFilters[index].filters.slice(0, max - counter), sortedFilters[index].type)
      )
      counter += max - counter
    }
  }

  return customFilterStrings.join(' ')
}

const includeNumRemainingAppliedFiltersInTitle = (
  {
    refinedTitle = '',
    appliedFilters = [],
    featuredFilterIds = [],
    exceptionFilterTypeIds = [],
    max = defaultMaxNumOfAppliedFiltersInTitle
  } = {
    refinedTitle: '',
    appliedFilters: [],
    featuredFilterIds: [],
    exceptionFilterTypeIds: [],
    max: defaultMaxNumOfAppliedFiltersInTitle
  }
) => {
  let filteredAppliedFilters = [...appliedFilters]

  if (appliedFilters?.length > 0 && exceptionFilterTypeIds?.length > 0) {
    // Excluding applied filters whose type is in exceptionFilterTypeIds
    filteredAppliedFilters = appliedFilters.filter(
      appliedFilterToFilter => !exceptionFilterTypeIds.includes(appliedFilterToFilter.id)
    )
  }

  // Checking the remaining number of applied filters not included inside the refined H1 title
  const totalNumOfAppliedFiltersRemaining = getRemainingNumOfAppliedFiltersNotInTitle(
    filteredAppliedFilters,
    featuredFilterIds,
    max
  )

  if (totalNumOfAppliedFiltersRemaining > 0) {
    return `${refinedTitle} (plus ${totalNumOfAppliedFiltersRemaining} more filter${
      totalNumOfAppliedFiltersRemaining === 1 ? '' : 's'
    })`
  }

  return refinedTitle
}

const getValidAppliedFilters = ({ appliedFilters, filters }) => {
  if (!appliedFilters || appliedFilters.length === 0 || !filters || filters.length === 0) return []

  return appliedFilters.reduce((accumulator, appliedFilter) => {
    const filterGroupFound = filters.find(filter => filter.id === appliedFilter?.id)

    // Applied filter type exists within then filter list
    if (filterGroupFound?.values?.length > 0) {
      const validAppliedFilterValues = appliedFilter.values?.filter(value =>
        filterGroupFound.values.find(filterGroupValue => filterGroupValue?.id === value)
      )

      if (validAppliedFilterValues?.length > 0) {
        accumulator.push({
          ...appliedFilter,
          // Getting only the available applied filters existing within the filter type values
          values: validAppliedFilterValues
        })
      }
    }

    return accumulator
  }, [])
}

/**
 * Checks if the selected applied filters include any department filter
 * @param appliedFilters {array} applied filter groups
 * @param specificAppliedFilter {string} the specific applied filter type to check for
 * @returns {boolean} true if any department filter is included
 */
const hasSpecificAppliedFilterType = (appliedFilters = [], specificAppliedFilter = '') => {
  if (appliedFilters?.length === 0 || specificAppliedFilter?.length === 0) return false

  return appliedFilters.some(appliedFilter => {
    return appliedFilter?.id === specificAppliedFilter
  })
}

/**
 * Returns the values for a specific type of filter that have been applied
 * @param appliedFilters {array} applied filter groups
 * @param specificAppliedFilter {string} the specific applied filter type to check for
 * @returns {Array} values for the specific applied filter
 */
const getSpecificAppliedFilterTypeValues = (appliedFilters = [], specificAppliedFilter = '') => {
  if (appliedFilters?.length === 0 || specificAppliedFilter?.length === 0) return []

  return appliedFilters.reduce((accumulator, appliedFilterType) => {
    const { id, values } = appliedFilterType

    if (id && id === specificAppliedFilter && values?.length > 0) {
      // accumulator = accumulator.concat(values)
      accumulator.push(...values)
    }

    return accumulator
  }, [])
}

/**
 * Returns the text for the department information to be added at the beginning of the refined title
 * @param appliedFilters {Array}
 * @param appliedFilterTypeId {string}
 * @returns {string} the department text either the category or the department applied filters
 */
const getAppliedDepartments = (appliedFilters = [], appliedFilterTypeId = '') => {
  // @Note: There is no check on the categoryAncestors for it to include a PLPPLUS page as some pages are not indicating this
  const includesDepartmentAppliedFilters = hasSpecificAppliedFilterType(appliedFilters, appliedFilterTypeId)

  if (includesDepartmentAppliedFilters) {
    // Applied Department filters supersede the department provided within the category ancestors
    let departmentFiltersApplied = getSpecificAppliedFilterTypeValues(appliedFilters, appliedFilterTypeId)

    if (departmentFiltersApplied.length === 0) return ''

    departmentFiltersApplied = departmentFiltersApplied.map(appliedDepartmentFilter =>
      pluraliseDepartment(appliedDepartmentFilter)
    )
    return processFilters(departmentFiltersApplied, appliedFilterTypeId)
  }

  return ''
}

/**
 * Removed the adjacent duplicated word(s) at the end of the refined H1 titles
 *
 * @param refinedAppliedFilters {string} refined text made from the applied featured filters
 * @param categoryName {string} category name
 * @returns {string} the refined H1 title text without adjacent duplicated word(s)
 */
const removeAdjacentDuplicatedWordsBeforeCategory = (refinedAppliedFilters = '', categoryName = '') => {
  if (!refinedAppliedFilters) {
    if (!categoryName) return ''

    return `${categoryName}`
  }

  // Searching for adjacent duplicated words in the refined applied filters
  const categoryWordsRegex = new RegExp(`${categoryName}$`, 'i')
  const hasAdjacentDuplicateWords = categoryWordsRegex.test(refinedAppliedFilters)

  if (hasAdjacentDuplicateWords) return `${refinedAppliedFilters}`

  // Searching for adjacent duplicated word at the end of the refined applied filters
  const categoryFirstWord = categoryName.split(/\s/)[0]
  const categoryFirstWordRegex = new RegExp(`${categoryFirstWord}$`, 'i')
  const hasCategoryFirstWordAtEnd = categoryFirstWordRegex.test(refinedAppliedFilters)

  if (hasCategoryFirstWordAtEnd) {
    const refinedTitle = `${refinedAppliedFilters} ${categoryName}`
    const categoryAdjacentDuplicatedWordRegex = new RegExp(`${categoryFirstWord} ${categoryFirstWord}`, 'i')
    return refinedTitle.replace(categoryAdjacentDuplicatedWordRegex, categoryFirstWord.toLowerCase())
  }

  // No adjacent duplicated words in the refined applied filters
  return `${refinedAppliedFilters} ${categoryName}`
}

const getRefinedCategoryTitle = (transformedCategoryName, titleRefinements) => {
  let prependedRefinements = ''
  let appendedRefinements = ''

  const titleRefinementValues = titleRefinements.map(refinement => refinement.value)

  // Check if title refinements belong to the same refinement type
  const isSameRefinementType =
    titleRefinements.length > 0 && titleRefinements.every(refinement => refinement.id === titleRefinements[0].id)

  if (isSameRefinementType) {
    const refinedFiltersTitle = titleRefinementValues.join(' & ')

    const typeConfig = refinementTypeConfig?.find(type => type.id === titleRefinements[0].id)

    if (typeConfig.isBeforeCategoryTitle) {
      prependedRefinements = refinedFiltersTitle
    } else {
      appendedRefinements = refinedFiltersTitle
    }
  } else {
    // If refinements of different type
    titleRefinements.forEach(refinementValue => {
      const typeConfig = refinementTypeConfig?.find(type => type.id === refinementValue.id)
      if (typeConfig.isBeforeCategoryTitle) {
        prependedRefinements = prependedRefinements.concat(' ', refinementValue.value)
      } else {
        appendedRefinements = appendedRefinements.concat(' ', refinementValue.value)
      }
    })
  }

  // Trim to remove trailing whitespace before and after
  const refinedTitle = `${prependedRefinements} ${transformedCategoryName} ${appendedRefinements}`.trim()
  return refinedTitle
}

export const getRefinedPageTitle = (categoryName, appliedFilters, maxRefinements) => {
  const transformedCategoryName = transformCategoryName(categoryName)

  const hasConflictingTitleRefinements = conflictingRefinementTypes.every(filter =>
    appliedFilters.find(refinedFilter => refinedFilter.id === filter)
  )

  const titleRefinements = appliedFilters
    // Remove filters that aren't in the refinement list
    .filter(filterType => {
      return orderedRefinementTypes.includes(filterType.id)
    })
    // Remove filters that conflict with one another
    .filter(filterType => {
      if (hasConflictingTitleRefinements) {
        return !conflictingRefinementTypes.includes(filterType.id)
      }
      return true
    })
    // Sort refined filters by priority order from refinement config
    .sort((typeA, typeB) => {
      return orderedRefinementTypes.indexOf(typeA.id) - orderedRefinementTypes.indexOf(typeB.id)
    })
    .map(filterType => {
      const { id, valueLabels } = filterType

      const refinementConfig = refinementTypeConfig.find(type => type.id === filterType.id)

      // Modify refinement values before creating the title (e.g. merging price range values together)
      const modifyRefinementValues = refinementConfig?.modifyRefinementValues
      const values = modifyRefinementValues ? modifyRefinementValues(valueLabels) : valueLabels

      const transformRefinementText = refinementConfig?.transformRefinementText

      return values
        .map(value => ({
          id,
          value: transformRefinementText ? transformRefinementText(value, transformedCategoryName) : value // transform refinement text for the title
        }))
        .filter(value => value.value)
    })
    .flat()

  // Get refinements that appear in the title
  const titlePrimaryRefinements = titleRefinements.slice(0, maxRefinements)

  const refinedCategoryTitle = getRefinedCategoryTitle(transformedCategoryName, titlePrimaryRefinements)

  // Get remaining refinements to display as (plus X more filters)
  const titleRemainingRefinements = titleRefinements.slice(maxRefinements)

  const numberOfExtraRefinements = titleRemainingRefinements.length
  const plusMoreFilters = numberOfExtraRefinements
    ? `(plus ${numberOfExtraRefinements} more filter${numberOfExtraRefinements === 1 ? '' : 's'})`
    : ''

  // Trim in case plusMoreFilters is empty
  return `${refinedCategoryTitle} ${plusMoreFilters}`.trim()
}

const refinedBrowsePageTitle = ({ categoryName = '', categoryAncestors = [], appliedFilters = [], filters = [] }) => {
  const refinementTypes = getValue('features.refinedTitlePage.refinementTypes')
  const maxRefinements = getValue('features.refinedTitlePage.maxRefinements')
  const departments = getValue('features.refinedTitlePage.departments')

  let currentDepartment = getDepartment(categoryAncestors, departments)

  // Filtering any applied filter that is no longer included within the provided filters
  const validAppliedFilters = getValidAppliedFilters({ appliedFilters, filters })

  // Use new logic for Argos Refined H1s
  if (getValue('features.refinedTitlePage.useRefinementTypeConfig')) {
    return getRefinedPageTitle(categoryName, validAppliedFilters, maxRefinements)
  }

  // When no valid filters are applied, return the department with category or only the category
  if (validAppliedFilters.length === 0) {
    const refinedTitle = currentDepartment
      ? `${removeAdjacentDuplicatedWordsBeforeCategory(currentDepartment, categoryName)}`
      : categoryName
    return capitalisedFirstLetter(refinedTitle)
  }

  // When any valid filter has been applied
  // Prioritising departments from applied filters over the allowed category department related to the configuration
  const currentAppliedDepartmentText = getAppliedDepartments(validAppliedFilters, departmentFilterId)
  currentDepartment = currentAppliedDepartmentText?.length > 0 ? currentAppliedDepartmentText : currentDepartment

  // If there are filters applied, use logic from NAV-696 and build the string of refinements.
  // Similar to filterString but a bit more sophisticated
  // Adding a `maxRefinements` number of applied filters to the title
  const customFilterString = buildCustomFilterString(validAppliedFilters, refinementTypes, maxRefinements)

  let title
  if (customFilterString) {
    title = removeAdjacentDuplicatedWordsBeforeCategory(customFilterString, categoryName)
  } else {
    title = categoryName
  }
  const refinedTitle = removeAdjacentDuplicatedWordsBeforeCategory(currentDepartment, title)

  // Adding the remaining of all the applied filters as '(plus X more filter(s)) to the refined title'
  const refinedTitleWithAdditionalAppliedFiltersText = includeNumRemainingAppliedFiltersInTitle({
    refinedTitle: refinedTitle.trim(),
    appliedFilters: validAppliedFilters,
    featuredFilterIds: refinementTypes,
    exceptionFilterTypeIds: [departmentFilterId],
    max: maxRefinements
  })

  return capitalisedFirstLetter(refinedTitleWithAdditionalAppliedFiltersText)
}

const refinedBrowsePageTitleForSEO = ({ categoryName = '', categoryAncestors, appliedFilters, filters }) => {
  const refinementTypes = getValue('features.refinedTitlePage.refinementTypes')
  const departments = getValue('features.refinedTitlePage.departments')

  let currentDepartment = getDepartment(categoryAncestors, departments)

  // Filtering any applied filter that is no longer included within the provided filters
  const validAppliedFilters = getValidAppliedFilters({ appliedFilters, filters })

  // When no valid filters are applied, return the department with category or only the category
  if (validAppliedFilters.length === 0) {
    const refinedTitle = currentDepartment
      ? `${removeAdjacentDuplicatedWordsBeforeCategory(currentDepartment, categoryName)}`
      : categoryName
    return capitalisedFirstLetter(refinedTitle)
  }

  // When any valid filter has been applied
  // Prioritising departments from applied filters over the allowed category department related to the configuration
  const currentAppliedDepartmentText = getAppliedDepartments(validAppliedFilters, departmentFilterId)
  currentDepartment = currentAppliedDepartmentText?.length > 0 ? currentAppliedDepartmentText : currentDepartment

  // Getting the total number of featured filters applied
  const appliedFeaturedFilters = getAppliedFeaturedFilters(validAppliedFilters, refinementTypes)
  const totalNumOfAppliedFeaturedFilters = getTotalNumOfAppliedFilters(appliedFeaturedFilters)

  // If there are filters applied, use logic from NAV-696 and build the string of refinements.
  // Similar to filterString but a bit more sophisticated
  // Adding all applied filters related to the refinement types config to the title
  const customFilterString = buildCustomFilterString(
    validAppliedFilters,
    refinementTypes,
    totalNumOfAppliedFeaturedFilters
  )

  let title
  if (customFilterString) {
    title = removeAdjacentDuplicatedWordsBeforeCategory(customFilterString, categoryName)
  } else {
    title = categoryName
  }
  const refinedTitle = removeAdjacentDuplicatedWordsBeforeCategory(currentDepartment, title)

  // Adding the '(plus X more filter(s)) to the refined title' ofor filters not part of the refinement types config
  const refinedTitleWithAdditionalAppliedFiltersText = includeNumRemainingAppliedFiltersInTitle({
    refinedTitle: refinedTitle.trim(),
    appliedFilters: validAppliedFilters,
    featuredFilterIds: refinementTypes,
    exceptionFilterTypeIds: [departmentFilterId],
    max: totalNumOfAppliedFeaturedFilters
  })

  return capitalisedFirstLetter(refinedTitleWithAdditionalAppliedFiltersText)
}

const trimRefinementSlashes = refinement => {
  return refinement
    ?.replace(/^\//, '') // Remove leading slash
    ?.replace(/\/$/, '') // Remove trailing slash
}

// Match specific categories and refinements to custom set titles
export const getCustomRefinedTitle = (urlCategoryId, urlRefinements) => {
  if (!urlCategoryId || !urlRefinements) {
    return null
  }
  const cleanUrlRefinement = trimRefinementSlashes(urlRefinements)

  return refinedHeadingOverrides?.[urlCategoryId]?.[cleanUrlRefinement] || null
}

export {
  refinedBrowsePageTitle,
  refinedBrowsePageTitleForSEO,
  buildCustomFilterString,
  getValidAppliedFilters,
  formatAppliedFilters,
  getAppliedDepartments,
  removeAdjacentDuplicatedWordsBeforeCategory,
  includeNumRemainingAppliedFiltersInTitle,
  getDepartment,
  getRefinements,
  getSizes,
  formatRefinements
}
