const TotalRequest = function TotalRequest() {
  this.apiRequest = 0
  this.productRequest = 0
  this.categoryRequest = 0
  this.contentRequest = 0
  this.redirectTaxonomyRequest = 0
  this.breadcrumbRequest = 0
  this.httpSearchRequest = 0
  this.httpBrowseRequest = 0
  this.productResponseError = 0
  this.categoryResponseError = 0
  this.contentResponseError = 0
  this.redirectTaxonomyResponseError = 0
  this.breadcrumbResponseError = 0
  this.productResponseFatal = 0
  this.categoryResponseFatal = 0
  this.contentResponseFatal = 0
  this.redirectTaxonomyResponseFatal = 0
  this.breadcrumbResponseFatal = 0
  this.totalTimeout = 0
  this.productTimeout = 0
  this.categoryTimeout = 0
  this.contentTimeout = 0
  this.redirectTaxonomyTimeout = 0
  this.breadcrumbTimeout = 0
}

TotalRequest.prototype.incrementProductTimeout = function(n) {
  this.totalTimeout += n || 1
  this.productTimeout += n || 1
}

TotalRequest.prototype.incrementCategoryTimeout = function(n) {
  this.totalTimeout += n || 1
  this.categoryTimeout += n || 1
}

TotalRequest.prototype.incrementContentTimeout = function(n) {
  this.totalTimeout += n || 1
  this.contentTimeout += n || 1
}

TotalRequest.prototype.incrementRedirectTaxonomyTimeout = function(n) {
  this.totalTimeout += n || 1
  this.redirectTaxonomyTimeout += n || 1
}

TotalRequest.prototype.incrementBreadcrumbTimeout = function(n) {
  this.totalTimeout += n || 1
  this.breadcrumbTimeout += n || 1
}

TotalRequest.prototype.incrementApiRequest = function(n) {
  this.apiRequest += n || 1
}

TotalRequest.prototype.incrementProductRequest = function(n) {
  this.productRequest += n || 1
}

TotalRequest.prototype.incrementCategoryRequest = function(n) {
  this.categoryRequest += n || 1
}

TotalRequest.prototype.incrementContentRequest = function(n) {
  this.contentRequest += n || 1
}

TotalRequest.prototype.incrementRedirectTaxonomyRequest = function(n) {
  this.redirectTaxonomyRequest += n || 1
}

TotalRequest.prototype.incrementBreadcrumbRequest = function(n) {
  this.breadcrumbRequest += n || 1
}

TotalRequest.prototype.incrementHttpSearchRequest = function(n) {
  this.httpSearchRequest += n || 1
}

TotalRequest.prototype.incrementHttpBrowseRequest = function(n) {
  this.httpBrowseRequest += n || 1
}

TotalRequest.prototype.incrementProductResponseError = function(n) {
  this.productResponseError += n || 1
}

TotalRequest.prototype.incrementCategoryResponseError = function(n) {
  this.categoryResponseError += n || 1
}

TotalRequest.prototype.incrementContentResponseError = function(n) {
  this.contentResponseError += n || 1
}

TotalRequest.prototype.incrementRedirectTaxonomyResponseError = function(n) {
  this.redirectTaxonomyResponseError += n || 1
}

TotalRequest.prototype.incrementBreadcrumbResponseError = function(n) {
  this.breadcrumbResponseError += n || 1
}

TotalRequest.prototype.incrementProductResponseFatal = function(n) {
  this.productResponseFatal += n || 1
}

TotalRequest.prototype.incrementCategoryResponseFatal = function(n) {
  this.categoryResponseFatal += n || 1
}

TotalRequest.prototype.incrementContentResponseFatal = function(n) {
  this.contentResponseFatal += n || 1
}

TotalRequest.prototype.incrementRedirectTaxonomyResponseFatal = function(n) {
  this.redirectTaxonomyResponseFatal += n || 1
}

TotalRequest.prototype.incrementBreadcrumbResponseFatal = function(n) {
  this.breadcrumbResponseFatal += n || 1
}

TotalRequest.prototype.printObj = function() {
  return {
    type: 'counter',
    apiRequest: this.apiRequest,
    productRequest: this.productRequest,
    categoryRequest: this.categoryRequest,
    contentRequest: this.contentRequest,
    redirectTaxonomyRequest: this.redirectTaxonomyRequest,
    breadcrumbRequest: this.breadcrumbRequest,
    httpSearchRequest: this.httpSearchRequest,
    httpBrowseRequest: this.httpBrowseRequest,
    productResponseError: this.productResponseError,
    categoryResponseError: this.categoryResponseError,
    contentResponseError: this.contentResponseError,
    redirectTaxonomyResponseError: this.redirectTaxonomyResponseError,
    breadcrumbResponseError: this.breadcrumbResponseError,
    productResponseFatal: this.productResponseFatal,
    categoryResponseFatal: this.categoryResponseFatal,
    contentResponseFatal: this.contentResponseFatal,
    redirectTaxonomyResponseFatal: this.redirectTaxonomyResponseFatal,
    breadcrumbResponseFatal: this.breadcrumbResponseFatal,
    totalTimeout: this.totalTimeout,
    productTimeout: this.productTimeout,
    categoryTimeout: this.categoryTimeout,
    contentTimeout: this.contentTimeout,
    redirectTaxonomyTimeout: this.redirectTaxonomyTimeout,
    breadcrumbTimeout: this.breadcrumbTimeout,
    unit: 'requests'
  }
}

export default TotalRequest
