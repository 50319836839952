import metrics from 'metrics'
import ProcessTime from '../metrics/ProcessTime'

const { Report } = metrics
let instance = null

export default class {
  constructor() {
    this.init = this.init.bind(this)
    if (!instance) {
      instance = this.init()
      instance.addMetric('co.uk.argos.ui.uptime', instance.meter)
    }

    // to test whether we have singleton or not
    this.time = new Date()

    return instance
  }

  init() {
    return {
      getReport() {
        this.meter.update(process.uptime())
        return this.report.summary()
      },
      addMetric() {
        this.report.addMetric.apply(this.report, arguments)
      },
      report: new Report(),
      meter: new ProcessTime()
    }
  }
}

// const Metrics = (function () {
//   let instance
//
//   function init () {
//     return {
//       getReport () {
//         this.meter.update(process.uptime())
//         return this.report.summary()
//       },
//       addMetric (local, info) {
//         this.report.addMetric.apply(this.report, arguments)
//       },
//       report: new Report(),
//       meter: new ProcessTime()
//     }
//   }
//
//   return {
//     getInstance: function () {
//       if (!instance) {
//         instance = init()
//         instance.addMetric('co.uk.argos.ui.uptime', instance.meter)
//       }
//       return instance
//     }
//   }
// })()
//
// export default Metrics
