import React from 'react'
import PropTypes from 'prop-types'
import { getSearchPath } from '@sainsburys-tech/boltui-utils'
import { Alert } from '@sainsburys-tech/bolt'
import { searchTypes } from '../../config'
import * as Styled from './styles'

import Template from './SpellcheckBanner.template'

const SpellcheckBanner = ({ didYouMeanSearch, spellcheckedSearch, searchTermSuggestions }) => {
  const showDidYouMeanBanner = !didYouMeanSearch && searchTermSuggestions && searchTermSuggestions.length

  if (showDidYouMeanBanner && !spellcheckedSearch) {
    const { text } = searchTermSuggestions[0]
    const searchSuggestionPath = getSearchPath(text, { searchType: searchTypes.didYouMean })
    return (
      <Alert kind='info' isCloseable={false}>
        <Styled.SuggestionBanner role='alert' data-el='search-suggestion-banner'>
          <span>{Template.dym}</span>
          <Styled.BannerLinks>
            <Styled.SuggestionLink
              onClick={() => window.scrollTo(0, 0)}
              title={Template.navLinkTitle(text)}
              data-el='search-suggestion-link'
              to={searchSuggestionPath}>
              {text}
            </Styled.SuggestionLink>
          </Styled.BannerLinks>
        </Styled.SuggestionBanner>
      </Alert>
    )
  }

  return null
}

SpellcheckBanner.propTypes = {
  didYouMeanSearch: PropTypes.bool,
  spellcheckedSearch: PropTypes.bool,
  searchTermSuggestions: PropTypes.array
}

SpellcheckBanner.defaultProps = {
  didYouMeanSearch: false,
  spellcheckedSearch: false,
  searchTermSuggestions: []
}

export default SpellcheckBanner
