export const PAGE_TYPES = {
  BROWSE: 'browse',
  SEARCH: 'search',
  CANNED: 'canned',
  DIRECTORY: 'directory'
}

export const getPageType = path => {
  switch (true) {
    case path?.startsWith('/browse'):
      return PAGE_TYPES.BROWSE
    case path?.startsWith('/list'):
      return PAGE_TYPES.CANNED
    case path?.startsWith('/sd'):
      return PAGE_TYPES.DIRECTORY
    default:
      return PAGE_TYPES.SEARCH
  }
}
