import styled from 'styled-components'
import { Pagination as PaginationComponent } from '@sainsburys-tech/bolt'

export const FindabilityPagination = styled(PaginationComponent)`
  padding: 15px 0;
  margin: 0 15px;

  @media screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].sm}) {
    padding: 0;
    margin-top: 15px;
    margin-bottom: 30px;
  }

  @media screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    margin: 15px 0 30px;
  }
`
