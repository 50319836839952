import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Accordion } from '@sainsburys-tech/bolt'
import Template from './AppliedFilters.template'
import * as Styled from './styles'
import { Facet } from '../Facet/styles'

const FilterList = ({ onRemoveClicked, filters, filterCount }) => {
  const accordionRef = useRef()
  useEffect(() => {
    if (accordionRef.current) {
      accordionRef.current.updateHeight()
    }
  }, [accordionRef])

  const removeFilter = (e, filterId, filterName) => {
    onRemoveClicked(filterId, filterName)
  }
  const itemsToDisplay = 5
  const filterItems = filters.map((filter, index) => (
    <Styled.ListItem key={index} data-el='filter-list__item'>
      <Styled.ListButton
        data-el='filter-list__button'
        type='button'
        onClick={e => removeFilter(e, filter.id, filter.name)}>
        <Styled.ListIcon filled width='23px' height='23px' fill='currentColor' />
      </Styled.ListButton>
      <Styled.FilterName>{filter.label}</Styled.FilterName>
    </Styled.ListItem>
  ))
  const Header = <Styled.ListHeader>{Template.heading(filterCount)}</Styled.ListHeader>

  const Content = <Styled.FilterList data-el='filter-list'>{filterItems.slice(0, itemsToDisplay)}</Styled.FilterList>
  const ExpandedContent =
    filterItems.length > itemsToDisplay ? (
      <Accordion.Expanded>
        <Styled.FilterList data-el='filter-list' expanded>
          {filterItems.slice(itemsToDisplay)}
        </Styled.FilterList>
      </Accordion.Expanded>
    ) : (
      <></>
    )

  return (
    <Facet data-test='findability-facet'>
      <Accordion.Item ref={accordionRef} wrapperPadding={0} title={Header} isOpen noAnimation>
        {Content}
        {ExpandedContent}
      </Accordion.Item>
    </Facet>
  )
}

export default FilterList

FilterList.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  onRemoveClicked: PropTypes.func.isRequired,
  filterCount: PropTypes.number
}

FilterList.defaultProps = {
  filterCount: null
}
