import { getValue } from '../utils/ConfigProvider'
import { getLocalStorage, setLocalStorage } from '../utils/LocalStorage'
import { availabilityRadiusToggleIsEnabled } from '../utils/availabilityIsEnabled'
import { DEFAULT_POSTCODE, isTu, hasVariants, isTuItem } from './Availability/AvailabilityTuHelper'

const CIS_COOKIE = 'cisId'
const FEATURE_TYPE_KEY = 'FT_'
const CHUNK_SIZE = getValue('features.availability.chunkSize')
const radiusDefaultValue = getValue('features.availability.radius.radiusDefaultValue')
const radiusLocalStorageKey = getValue('features.availability.radius.radiusLocalStorageKey')
const radiusOptions = getValue('features.availability.radius.radiusOptions')
const OUT_OF_STOCK_LABEL = {
  icon: 'out_of_stock',
  message: 'Out of stock'
}
const INFORMATION_LABEL = {
  icon: 'info',
  message: 'Check more options'
}

const excludedEmailBackSkus = ['9441804', '8349000', '1130034', '1130041', '1300479', '8349024', '1259465']

const checkItemAvailability = messageKey => {
  const unavailableKeys = ['OUT_OF_STOCK', 'NOT_AVAILABLE']
  const isUnavailable = unavailableKeys.some(key => messageKey.includes(key) || messageKey === '')

  return isUnavailable
}

// Collection object is empty when there is no collection info for the product
const hasCollectionInformation = availability => {
  if (!availability.collection) return false

  const { messageKey, icon, text } = availability.collection
  if (!messageKey && !icon && !text) return false

  return true
}

const replaceIcon = availability => {
  const { messageKey = '', icon = '' } = availability || {}
  const replaceIconKeys = [
    'COLLECTION_FROM_TODAY_AT_TIME',
    'COLLECTION_FROM_TOMORROW_AT_TIME',
    'COLLECTION_FROM_DATE',
    'FT_30_DELIVERY'
  ]

  const isMatched = replaceIconKeys.some(key => messageKey.includes(key))

  return isMatched ? 'tick' : icon
}

// Split array into size chunks
const chunkArray = (arr, size) =>
  arr.length > size ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)] : [arr]

// Displays the product labels for delivery & collection
const formatAvailabilityMessages = (availability = {}, product = {}) => {
  const { collection = {}, delivery = {} } = availability || {}

  const collectionKey = collection?.messageKey || ''
  const deliveryKey = delivery?.messageKey || ''

  const collectionLabels = collection
    ? {
        icon: replaceIcon(collection || {}),
        message: collection.text || ''
      }
    : {}

  const deliveryLabels = delivery
    ? {
        icon: replaceIcon(delivery || {}),
        message: delivery.text || ''
      }
    : {}

  // Return only delivery messaging for Tu
  if (isTu) {
    return [{ ...deliveryLabels }]
  }

  const isCollectionUnavailable = checkItemAvailability(collectionKey)
  const isDeliveryUnavailable = checkItemAvailability(deliveryKey)

  // If delivery is OOS and there is no collection information
  if (isDeliveryUnavailable && !hasCollectionInformation(availability)) {
    return [deliveryLabels]
  }

  if (isCollectionUnavailable && isDeliveryUnavailable) {
    return hasVariants(product) ? [INFORMATION_LABEL] : [OUT_OF_STOCK_LABEL] // If item is out of stock AND has variants then show information messaging
  }

  const isFeatureType = collectionKey.indexOf(FEATURE_TYPE_KEY) === 0 && deliveryKey.indexOf(FEATURE_TYPE_KEY) === 0

  // Both fufilment methods are available
  if (!isCollectionUnavailable && !isDeliveryUnavailable) {
    if (isFeatureType) {
      // Collection and delivery messages are same for feature type products, so show one
      return [collectionLabels || deliveryLabels]
    }
    return [collectionLabels, deliveryLabels]
  }
  // Then either one or both are unavailable
  return isDeliveryUnavailable ? [collectionLabels] : [deliveryLabels]
}

// Returns the localised postcode or a default postcode for TU
const getLocalisedPostcode = (localisationPostcode = '') => (isTu ? DEFAULT_POSTCODE : localisationPostcode)

// Adding a quantity to a list of products
const appendQuantity = (products = []) => {
  const productsWithQuantity =
    products.length > 0 ? products.filter(product => product?.id).map(product => `${product?.id}_1`) : ''

  return productsWithQuantity.toString()
}

const formatProducts = (products = []) => {
  const filteredProducts = products.filter(product => (isTuItem(product?.id) ? !hasVariants(product) : product?.id))
  const appendedProducts = appendQuantity(filteredProducts)

  return appendedProducts
}

// Formats and returns an array of objects with delivery and collection information
const renderAvailabilityMessages = (product = {}, availabilityLabels = []) => {
  const partNumber = product.id
  const availabilityLabel = availabilityLabels.find(label => label.partNumber === partNumber)
  if (availabilityLabel) {
    return formatAvailabilityMessages(availabilityLabel, product)
  }
  return []
}

// Returns radius value from local storage or a default radius from the config
const getAvailabilityRadius = () => {
  if (!availabilityRadiusToggleIsEnabled) return radiusDefaultValue

  const expectedValues = radiusOptions.map(({ value }) => value.toString())
  const radius = getLocalStorage(radiusLocalStorageKey, expectedValues, radiusDefaultValue)

  return radius
}

// Sets the radius value into local storage
const setAvailabilityRadius = (radiusValue = '') => {
  if (!radiusValue || !availabilityRadiusToggleIsEnabled) return

  setLocalStorage(radiusLocalStorageKey, radiusValue)
}

// Checks if there is any collection stores from the response
const hasCollectionStores = (availabilityResponse = {}) => {
  if (!availabilityResponse || Object.keys(availabilityResponse).length === 0) return false

  const { stores } = availabilityResponse
  return stores?.length > 0
}

const getCollectionStoreErrorMessage = isRadiusEnabled =>
  isRadiusEnabled
    ? `No collection within this range. Expand your store radius to see more collection options`
    : `No collection within this range. See product page for more options`

const formatMilesText = radiusValue => (parseInt(radiusValue) === 1 ? 'mile' : 'miles')

export {
  appendQuantity,
  CIS_COOKIE,
  CHUNK_SIZE,
  chunkArray,
  excludedEmailBackSkus,
  formatAvailabilityMessages,
  formatProducts,
  renderAvailabilityMessages,
  getLocalisedPostcode,
  getAvailabilityRadius,
  setAvailabilityRadius,
  hasCollectionStores,
  formatMilesText,
  getCollectionStoreErrorMessage
}
