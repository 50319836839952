import deepcopy from 'lodash.clonedeep'

import CategoryHelper, { initialState } from '.'
import Actions from '../../constants/category'
import Logger from '../../utils/Logger'
import { get } from '../../utils/PolyfillLodash'

const logger = new Logger()

// -------------------------------------------------
// ACTIONS
// -------------------------------------------------
export const handleCategory = payload => ({ type: Actions.CATEGORY, payload })
export const handleBrowse = payload => ({ type: Actions.BROWSE, payload })
export const failedBrowse = payload => ({ type: Actions.BROWSE_FAILURE, payload })
export const handlePLP = payload => ({
  type: Actions.PLP,
  payload: payload.categoryResponse,
  productPayload: payload.productResponse
})

// -------------------------------------------------
// REDUCER
// -------------------------------------------------
export default (state = initialState, action) => {
  const newState = deepcopy(state)

  switch (action.type) {
    case Actions.CATEGORY: {
      const { payload } = action
      logger.debug('functionLogger', { arguments: payload }, 'CategoryStore handleCategory')
      if (!payload) {
        const initState = deepcopy(initialState)
        initState.isPopulated = true
        return initState
      }
      if (payload.cached) {
        newState.categoryId = payload.categoryId
        newState.ancestors = payload.ancestors
        newState.categoryName = payload.categoryName
        newState.included = payload.included
        newState.productsInCategory = payload.productsInCategory
      }

      const response = deepcopy(payload.response)
      const categoryId = response && response.data ? CategoryHelper.getCurrentCategoryId(response.data) : null

      if (categoryId) {
        newState.isPopulated = true
        newState.categoryId = parseInt(categoryId, 10)
        newState.ancestors = CategoryHelper.getCategoryAncestors(response)
        newState.categoryName = CategoryHelper.getCategoryName(response)
        newState.isPlpPlus = CategoryHelper.isPlpPlus(payload.response.data[0])
        newState.isPlp = CategoryHelper.isLeafNode(payload.response.data[0])
        newState.isPlpPlusDescendant = CategoryHelper.isPlpPlusDescendant(newState.ancestors)
        newState.categoryType = CategoryHelper.getCategoryType(payload.response.data[0])

        newState.cache[payload.response.data[0].id] = {
          categoryId: newState.categoryId,
          ancestors: newState.ancestors,
          categoryName: newState.categoryName
        }
      }
      return newState
    }
    case Actions.PLP:
    case Actions.BROWSE: {
      const resetState = deepcopy(initialState)
      const { payload } = action
      const data = payload.response.data[0]
      logger.debug('functionLogger', { arguments: payload }, 'CategoryStore handleBrowse')

      resetState.isPopulated = true
      resetState.categoryId = parseInt(data.id, 10)
      resetState.categoryName = data ? data.attributes.name : null
      resetState.ancestors = CategoryHelper.flattenAncestors(payload.response)
      resetState.subHeadingAncestorId = CategoryHelper.getSubHeadingAncestor(payload.response)
      resetState.children = CategoryHelper.flattenChildren(payload.response)
      resetState.children = CategoryHelper.addPathToChildrenCategories(
        deepcopy(resetState.children),
        resetState.ancestors,
        resetState.categoryName
      )
      resetState.siblings = CategoryHelper.flattenSiblings(payload.response)
      resetState.productsInCategory = data ? data.attributes.productsInCategory : 0
      resetState.productsInCategoryTree = data ? data.attributes.productsInCategoryTree : 0
      resetState.isPlpPlus = CategoryHelper.isPlpPlus(data)
      resetState.isPlp = CategoryHelper.isLeafNode(data)
      resetState.isPlpPlusDescendant = CategoryHelper.isPlpPlusDescendant(resetState.ancestors)
      resetState.isClearance = !!action.payload.isClearance
      resetState.categoryType = CategoryHelper.getCategoryType(data)
      resetState.categoryLevel = CategoryHelper.getCategoryLevel(payload.response)
      resetState.subcategories =
        resetState.isPlpPlus || resetState.isPlpPlusDescendant || resetState.isClearance
          ? CategoryHelper.getPlpPlusCategories(resetState)
          : []
      resetState.isCategoryLive = data ? !data.attributes.isWCS : false
      resetState.categoryUrl = get(payload, 'response.data[0].attributes.url', null)
      resetState.categoryCanonicalTree = get(payload, 'response.data[0].attributes.canonical', [])
      const topLevelCategory = CategoryHelper.getTopLevelCategory(resetState.ancestors)
      resetState.topLevelCategoryId = topLevelCategory ? topLevelCategory.id : data.id
      resetState.templateType = data?.attributes?.templateType?.toLowerCase() || null
      return resetState
    }
    case Actions.BROWSE_FAILURE: {
      const { payload } = action
      newState.isPopulated = true
      newState.success = false
      newState.status = payload.status
      newState.failedService = payload.failedService
      return newState
    }
    default:
      return state
  }
}
