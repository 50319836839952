import styled from 'styled-components'

export const ErrorPage = styled.main`
  display: flex;
  align-items: center;
  padding: 15px;
  margin-top: 20px;
  min-height: 500px;
  text-align: center;
  flex-direction: column;
  flex-wrap: nowrap;
`
export const PageButton = styled.a(
  props => `
	padding: 12px 20px;
	margin-top: 30px;
	color: ${props.theme.color.alias.link.default.color};
	border: 1px solid ${props.theme.color.monochrome.base};
	background: ${props.theme.color.monochrome.white};
	display: flex;
	justify-content: center;
`
)
