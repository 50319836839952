const mock = () => {}

class Logger {
  constructor () {
    this.trace = mock
    this.debug = mock
    this.info = mock
    this.warn = mock
    this.error = mock
    this.fatal = mock
    this.addStream = mock
    this.addSerializers = mock
    this.child = mock
    this.level = mock
    this.levels = mock
  }
}

const TRACE = 'NO_OP'
const DEBUG = 'NO_OP'
const INFO = 'NO_OP'
const WARN = 'NO_OP'
const ERROR = 'NO_OP'
const FATAL = 'NO_OP'
const resolveLevel = mock
const levelFromName = mock
const nameFromLevel = mock

const VERSION = 'NO_OP'
const LOG_VERSION = 'NO_OP'

const createLogger = (options) => {
  return new Logger(options)
}
const safeCycles = mock
const ConsoleFormattedStream = function () {}
const ConsoleRawStream = function () {}

export default {
  Logger,
  TRACE,
  DEBUG,
  INFO,
  WARN,
  ERROR,
  FATAL,
  resolveLevel,
  levelFromName,
  nameFromLevel,
  VERSION,
  LOG_VERSION,
  createLogger,
  safeCycles,
  ConsoleFormattedStream,
  ConsoleRawStream
}
