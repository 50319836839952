import { PAGE_TYPES } from '../utils/pageTypes'
import { getMaxNumberOfAds, getExtraFilters } from './CitrusHelper'

export const getCitrusUrl = () => {
  const citrusUrl = `${window.citrusEndpoint}`
  return `${citrusUrl}`
}

export const generateCitrusRequestParams = args => {
  if (!args) {
    return null
  }

  const { sessionId, catalogId, appliedFilters, currentCategory, pageType, keyword } = args

  const requestBody = {
    sessionId,
    catalogId,
    maxNumberOfAds: getMaxNumberOfAds(),
    productFilters: getExtraFilters(appliedFilters),
    options: {
      filterMode: 'AndOr'
    }
  }

  if (pageType === PAGE_TYPES.BROWSE) {
    requestBody.placement = 'category-only'
    requestBody.productFilters.push([`taxonomy:${currentCategory?.name?.toLowerCase()}`])
  }

  if (pageType === PAGE_TYPES.SEARCH || pageType === PAGE_TYPES.CANNED || pageType === PAGE_TYPES.DIRECTORY) {
    requestBody.placement = 'search-only'
    requestBody.searchTerm = keyword
  }

  return requestBody
}
