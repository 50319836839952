/* eslint-disable react/require-default-props */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Accordion, RadioList } from '@sainsburys-tech/bolt'
import { debounce, breakpoints } from '@sainsburys-tech/boltui-utils'
import Filter, { FACET_TYPES } from './Filter'
import CategoryFacet, { findAppliedItem } from './CategoryFacet'
import RangeSlider from '../RangeSlider/RangeSlider'

import * as Styled from './styles.js'

const EXPAND_TYPES = ['', 'xs', 'sm', 'md', 'lg']
const SHOW_MORE = 'Show more'
const DEFAULT_ROW_COUNT = 5

const Facet = ({
  expand,
  defaultRowCount,
  className,
  id,
  type,
  label,
  showMoreLabel,
  filters,
  selectFilter,
  clearFacet,
  categoryMatch,
  appliedFilters,
  pageType,
  showBreadcrumb,
  setClickOrigin,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(expand === 'xs')
  let mqlsm
  let mqllg
  let filterNode
  const showTaxonomyExperience = type === 'category'
  const showPriceSlider = false
  // Forcing to false due to issues with the cookie on production.
  // Original code left here to re-enable easily in the future
  // pageType === 'search' && type === 'price' && allCookies && allCookies.Search_Test_Group_1 === VARIANT_PRICE_FACET

  useEffect(() => {
    const breakpointsm = breakpoints.sm
    const breakpointlg = breakpoints.lg

    mqlsm = window.matchMedia(breakpointsm) || {}
    mqllg = window.matchMedia(breakpointlg) || {}

    mqlsm.addListener(handleBreakpointChange)
    mqllg.addListener(handleBreakpointChange)
    handleBreakpointChange()

    return () => {
      if (mqlsm) {
        mqlsm.removeListener(handleBreakpointChange)
      }
      mqlsm = {}
      if (mqllg) {
        mqllg.removeListener(handleBreakpointChange)
      }
      mqllg = {}
      handleBreakpointChange()
    }
  }, [])

  const toggleExpanded = screenSize => {
    const screenSizes = ['xs', 'sm', 'md', 'lg', '']
    if (expand === screenSizes[0]) {
      setIsOpen(true)
    } else if (screenSizes.indexOf(screenSize) >= screenSizes.indexOf(expand)) {
      setIsOpen(true)
    } else if (isOpen) {
      setIsOpen(false)
    }
  }

  const handleBreakpointChange = () => {
    if (!!mqllg && mqllg.matches) {
      toggleExpanded('lg')
    } else if (!!mqlsm && mqlsm.matches) {
      toggleExpanded('sm')
    } else {
      toggleExpanded(false)
    }
  }

  const collapsedRowCount = () => (type === 'size' ? Math.ceil(defaultRowCount * 3) : defaultRowCount)

  const isSingleSelect = () => type === 'rating' || type === 'category'

  const hasAppliedFilter = () => {
    return filters.some(filter => filter.applied)
  }

  const showClearFacetButton = () => {
    return !isSingleSelect() && hasAppliedFilter()
  }

  const showMoreCallBack = ({ show }) => {
    if (filterNode && show) {
      const input = document.getElementById(filterNode.getAttribute('for'))
      input.focus()
    }
  }

  const handleClearFacet = e => {
    e.stopPropagation()
    clearFacet({ facetId: id })
  }

  const selectRating = debounce(filterId => {
    selectFilter({ facetId: 'customer rating', filterId, applied: true, filterLabel: 'customer rating' })
  }, 500)

  const appliedItem = findAppliedItem(filters)
  const { appliedItemId } = appliedItem
  const { appliedItemLevel } = appliedItem
  let appliedLevel = null
  const modifiedFilters = showTaxonomyExperience
    ? filters.filter(filter => {
        const level = 0
        if (appliedItemId) {
          if (appliedLevel === level) return false
          const hasLevelParentApplied = filter.isGrandchildApplied || filter.isChildApplied || filter.applied
          appliedLevel = hasLevelParentApplied && (level >= appliedLevel || !appliedLevel) ? level : appliedLevel
          return filter.isGrandchildApplied || filter.isChildApplied || filter.applied || level > appliedItemLevel
        }
        return true
      })
    : filters

  const getFacetContents = (filtersList, maxFilters, from = 0) => {
    if (type === 'category') {
      return (
        <CategoryFacet
          filters={filtersList}
          selectFilter={selectFilter}
          key={props.key}
          clearFacet={handleClearFacet}
          refCb={(node, isFocusNode) => {
            if (isFocusNode) {
              filterNode = node
            }
          }}
          from={from}
          max={maxFilters}
          appliedItem={appliedItem}
          categoryMatch={categoryMatch}
          categoryUnmatch={props.categoryUnmatch}
          shouldShowBreadcrumb={showBreadcrumb}
        />
      )
    }
    if (showPriceSlider) {
      let minPrice
      let maxPrice

      if (typeof filtersList[0] !== 'number') {
        const lowestPrice = filtersList[0].applicableFilter
        minPrice =
          lowestPrice && (lowestPrice.includes('under') ? '0' : lowestPrice.split('%C2%A3')[1].replace(/[\W_]/g, ''))
        maxPrice = filtersList
          .slice(-1)
          .pop()
          .applicableFilter.split('%A3')
          .pop()
      } else {
        maxPrice = Math.ceil(filtersList[1])
        minPrice = Math.floor(filtersList[0])
      }

      return (
        <RangeSlider
          id='price-range'
          data-facet='price-range'
          selectFilter={selectFilter}
          clearFacet={handleClearFacet}
          min={minPrice}
          max={maxPrice}
          appliedPrices={appliedFilters.find(filter => filter.id === 'price')}
        />
      )
    }

    const listElement = type === 'size' || type === 'category' ? 'ul' : 'div'

    return (
      <Styled.FiltersList as={listElement} type={type}>
        {filtersList.slice(from, maxFilters).map((filter, itemIndex) => {
          const filterId = filter.id || filter.label
          const key = `filter-${id}-${filterId}`
          const isFocusNode = itemIndex === defaultRowCount - 1
          return (
            <Filter
              key={key}
              filterGroup={id}
              type={type}
              selectFilter={selectFilter}
              refCb={node => {
                if (isFocusNode) {
                  filterNode = node
                }
              }}
              filter={filter}
              id={filter.id}
              applied={filter.applied}
              isChildApplied={filter.isChildApplied}
              disabled={filter.disabled}
              label={filter.label}
              count={filter.count}
              value={filter.value}
              setClickOrigin={setClickOrigin}
            />
          )
        })}
      </Styled.FiltersList>
    )
  }
  const getRatingLabels = () => {
    const ratingsArray = []
    filters.map(filter => {
      const rating = parseInt(filter.label, 10)
      const labelFormatted = parseInt(filter.label, 10) || filter.label
      const filterLabel = rating ? (
        <Styled.RatingLabel>
          {labelFormatted}
          <Styled.RatingIcon height='17' width='17' type='full' />
          {rating < 5 ? 'or more' : 'only'}
        </Styled.RatingLabel>
      ) : (
        <Styled.RatingLabel>{labelFormatted}</Styled.RatingLabel>
      )
      const filterID = filter.id
      ratingsArray.push({ label: filterLabel, id: filterID, ariaLabel: filter.label })
    })
    return ratingsArray
  }

  const Content =
    type === 'rating' ? (
      <RadioList
        options={getRatingLabels()}
        hasBackground={false}
        hasBottomMargin={false}
        padding='5px 15px'
        onClick={filterId => selectRating(filterId)}
        defaultOption={appliedItemId || null}
      />
    ) : (
      getFacetContents(modifiedFilters, collapsedRowCount())
    )
  const ExpandedContent =
    !showPriceSlider && collapsedRowCount() < modifiedFilters.length ? (
      <Accordion.Expanded>
        {getFacetContents(modifiedFilters, modifiedFilters.length, collapsedRowCount())}
      </Accordion.Expanded>
    ) : (
      <></>
    )
  const secondaryCTA = showClearFacetButton() ? (
    <Styled.ClearFacetButton
      type='button'
      id={`clear_${id}`}
      data-el='facet__clear'
      onClick={e => {
        handleClearFacet(e)
      }}>
      Clear
    </Styled.ClearFacetButton>
  ) : null
  const Header = <Styled.FacetHeader>{label}</Styled.FacetHeader>

  return (
    <Styled.Facet data-test='findability-facet'>
      <Accordion.Item
        hasBorderTop={false}
        wrapperPadding={0}
        title={Header}
        noAnimation
        secondaryCTA={secondaryCTA}
        showMoreLabel={showMoreLabel}
        isOpen={isOpen}
        showMoreCB={showMoreCallBack}
        data-test={`${type}-facet`}
        {...props}>
        {Content}
        {ExpandedContent}
      </Accordion.Item>
    </Styled.Facet>
  )
}

export default React.memo(Facet)

Facet.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  leaf: PropTypes.bool.isRequired,
  expand: PropTypes.oneOf(EXPAND_TYPES),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      count: PropTypes.number,
      disabled: PropTypes.bool,
      applied: PropTypes.bool
    })
  ).isRequired,
  defaultRowCount: PropTypes.number,
  type: PropTypes.oneOf(FACET_TYPES),
  className: PropTypes.string,
  showMoreLabel: PropTypes.string,
  selectFilter: PropTypes.func,
  clearFacet: PropTypes.func,
  pageType: PropTypes.string,
  showBreadcrumb: PropTypes.bool
}

Facet.defaultProps = {
  className: '',
  type: FACET_TYPES[0],
  defaultRowCount: DEFAULT_ROW_COUNT,
  showMoreLabel: SHOW_MORE,
  selectFilter: () => {},
  clearFacet: () => {},
  expand: '',
  categoryMatch: false,
  showBreadcrumb: true,
  categoryUnmatch: () => {}
}
