import { contentAreaIds, categoryTypes } from '../config'
import { trackLhnCategorySelect } from './TaggingSerializer'
import { getValue } from '../utils/ConfigProvider'

// Format refinement from URL and from Magnolia for easier matching
const formatRefinement = refinement => {
  return refinement
    ?.toLowerCase()
    ?.replace(/^\//, '') // Remove leading slash
    ?.replace(/\/$/, '') // Remove trailing slash
}

class ContentHelper {
  static getSeoArea(response) {
    if (response && response[0]) {
      if (response[0].id === contentAreaIds.SeoArea) return [response[0]]

      const i = response.findIndex(area => area.id === contentAreaIds.SeoArea)
      return [response[i]]
    }
    return []
  }

  static getRefinedSeoCategory(seoRefinedCategories, urlRefinements) {
    if (!urlRefinements) return null

    const formattedRefinement = formatRefinement(urlRefinements)

    const refinedCategory = seoRefinedCategories.find(
      seoCategory => formatRefinement(seoCategory?.refinedCategoryName) === formattedRefinement
    )
    return refinedCategory
  }

  static getSeoFooterArea(seoData = [], urlRefinements) {
    const { type, id, attributes } = seoData[0] || {}
    const seoFooterArea = { type, id, attributes }

    if (urlRefinements) {
      const refinedSeoCategory = ContentHelper.getRefinedSeoCategory(
        ContentHelper.getSeoRefinedCategories(seoData),
        urlRefinements
      )

      const refinedFooterArea = refinedSeoCategory?.attributes
      const hideOnAppliedFilters = getValue('features.seoFooter.hideOnAppliedFilters')
      // Replace default SEO footer component with refined SEO footer (if it exists)
      if (refinedFooterArea) {
        seoFooterArea.attributes = refinedFooterArea
      } else if (hideOnAppliedFilters) {
        // If default SEO footer should be hidden per brand config when refinements exist
        seoFooterArea.attributes = []
      }
    }

    return [seoFooterArea]
  }

  static getSeoMeta(seoData = [], urlRefinements) {
    const seoMeta = seoData?.[0]?.meta || {}

    if (urlRefinements) {
      const refinedSeoCategory = ContentHelper.getRefinedSeoCategory(
        ContentHelper.getSeoRefinedCategories(seoData),
        urlRefinements
      )

      // Replace metadata, no index control and canonicals with refined versions if they exist
      if (refinedSeoCategory) {
        const { meta } = refinedSeoCategory || {}
        const { refinedTitle, refinedDescription, noIndex, canonicalTag } = meta || {}

        const refinedSeoMeta = {
          ...seoMeta,
          ...(refinedTitle && { refinedTitle }),
          ...(refinedDescription && { refinedDescription }),
          ...(noIndex && { noIndex }),
          ...(canonicalTag && { canonicalTag })
        }

        return refinedSeoMeta
      }
    }

    return seoMeta
  }

  static getSeoRobotMeta(seoData = []) {
    return seoData.length && seoData[0] !== undefined && seoData[0].robotMetaData
      ? seoData[0].robotMetaData
      : {
          maxFiltersPerFacet: '#',
          maxFilteredFacets: '#'
        }
  }

  static getSeoRefinedCategories(seoData = []) {
    return seoData.length && seoData[0] !== undefined && seoData[0].refinedCategories
      ? seoData[0].refinedCategories
      : []
  }

  static getContentData(response) {
    return response ? response.filter(area => area.id !== contentAreaIds.SeoArea) : []
  }

  static getBrowseListerType(isPlp, isPlpPlus, isPlpPlusDescendant) {
    if (isPlp) {
      return categoryTypes.PLP
    }
    if (isPlpPlus || isPlpPlusDescendant) {
      return categoryTypes.PLPPLUS
    }
  }

  static onFindabilityClick(e, linkUrl, type = '') {
    if (!linkUrl) return
    const pathArray = linkUrl.split('/')
    const arrayLength = pathArray.length
    const categoryName = pathArray[arrayLength - 3]
    const categoryID = pathArray[arrayLength - 2].split(':')[1]
    const searchAbsoluteRegex = /(?:argos.co.uk|deveng.io)\/search\//
    const searchRelativePath = '/search/'
    if (type) {
      trackLhnCategorySelect(categoryName, categoryID)
    }
    let ajaxLinkUrl
    if (linkUrl.indexOf(searchRelativePath) === 0) {
      ajaxLinkUrl = linkUrl
    } else if (linkUrl.match(searchAbsoluteRegex)) {
      ajaxLinkUrl = searchRelativePath + linkUrl.split(searchRelativePath).pop()
    }

    if (ajaxLinkUrl) {
      e.preventDefault()
      // this.context functions are declared in Application.jsx. If you know how to improve this, be my guest
      if (this.context.updateDestinationPageType) this.context.updateDestinationPageType({ type })
      this.context.loadingStart()
      this.context.navigateTo(ajaxLinkUrl)
    }
  }
}

export default ContentHelper
