import { useState, useEffect } from 'react'
import {
  Context,
  enableDebugConsoleLogDrain,
  enableContextDevTools,
  CookieTransitionDataStore
} from '@uniformdev/context'
import { consentCookieHelper } from '@sainsburys-tech/boltui-utils'
import { getBrand } from '../../utils/ConfigProvider'
import { UpdateEnrichment } from '../../helpers/UpdateEnrichment'

const hasMarketingConsent = () => {
  const { categories, getConsentCookieValue, getCategorySection, hasOptedIn } = consentCookieHelper
  const consentCategory = 'marketing'
  const cookies = typeof window !== 'undefined' && window.document ? document.cookie : null

  if (!cookies) return false

  const consentCookieValue = getConsentCookieValue(cookies)

  if (!consentCookieValue || !consentCookieValue.includes('consent:') || !categories[consentCategory]) {
    return false
  }

  const categorySection = getCategorySection(consentCookieValue, consentCategory)
  if (!categorySection.length && consentCookieValue.includes('consent:true')) {
    return true
  }

  return hasOptedIn(categorySection[0])
}

const fetchUniformManifest = async () => {
  try {
    const url = 'https://cdn.argos.co.uk/assets/personalisation/manifest.json'
    const data = await fetch(url)
    const json = await data.json()
    return json
  } catch (err) {
    console.error('Error fetching manifest', err)
  }
  return null
}

const setUniformContext = async setContext => {
  const plugins = [enableContextDevTools()]

  if (process.env.NODE_ENV !== 'production') {
    plugins.push(enableDebugConsoleLogDrain('debug'))
  }
  const manifest = await fetchUniformManifest()

  const context = new Context({
    defaultConsent: false,
    transitionStore: new CookieTransitionDataStore({}),
    manifest,
    plugins
  })
  setContext(context)
}

export const UniformTracking = ({ topLevelCategoryId, categoryId, categoryLevel }) => {
  const [context, setContext] = useState()

  useEffect(() => {
    if (getBrand() === 'argos') {
      setUniformContext(setContext)
    }
  }, [])

  useEffect(() => {
    const hasConsent = hasMarketingConsent()
    context?.storage.updateData([
      {
        type: 'consent',
        data: hasConsent
      }
    ])
  }, [context])

  useEffect(() => {
    if (topLevelCategoryId && context) {
      UpdateEnrichment(1, topLevelCategoryId, context)
      if (categoryLevel > 1) {
        UpdateEnrichment(categoryLevel, categoryId, context)
      }
    }
  }, [topLevelCategoryId, context])

  return null
}
