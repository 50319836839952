import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary, Modal } from '@sainsburys-tech/bolt'

import * as Styled from '../Modals/styles'
import { hideNoResultsModal } from '../../actions/noResultsModal'
import Template from './NoResultsModal.template'

export class NoResultsModal extends Component {
  constructor(props) {
    super(props)
    this.hideModal = this.hideModal.bind(this)
  }

  hideModal() {
    hideNoResultsModal(this.context)
  }

  render() {
    return (
      <ErrorBoundary>
        <Modal heading={' '} height='auto' onClose={this.hideModal} show>
          <div className='error-modal text-center'>
            <Styled.Title>{Template.title}</Styled.Title>
            <Styled.ErrorButton tabIndex='0' data-el='modal-back' onClick={() => this.hideModal()}>
              {Template.backBtn}
            </Styled.ErrorButton>
          </div>
        </Modal>
      </ErrorBoundary>
    )
  }
}

NoResultsModal.contextTypes = {
  reduxStore: PropTypes.object
}

export default NoResultsModal
