import Logger from '../utils/Logger'

const logger = new Logger()

export const UpdateEnrichment = (catLevel, key, context, enrStr = 10) => {
  const cat = {
    1: 'clp',
    2: 'plp-l2',
    3: 'plp-l3'
  }

  try {
    context.update({
      enrichments: [
        {
          str: enrStr,
          cat: cat[catLevel],
          key
        }
      ]
    })
  } catch (error) {
    logger.error('uniformLogger', error, 'Unable to add enrichment')
  }
}
