import { getExpiryDateByDay } from '../../helpers/CookieHelper'
import { getCollectionStoreErrorMessage } from '../../helpers/AvailabilityHelper'
import { availabilityRadiusToggleIsEnabled } from '../../utils/availabilityIsEnabled'

export default {
  filterBtn: `Filter`,
  editBtn: `Edit Filters`,
  pageCount: totalData => `${totalData} products`,
  sortBy: `Sort by`,
  unlocalisedBtn: `Add postcode`,
  unlocalisedText: ` to see what’s available`,
  localisedText: `All collection and delivery for `,
  availabilityErrorMessage: `Sorry, we’re experiencing some difficulties with our service right now. Please refresh to try again`,
  availabilityNoCollectionStoresMessage: getCollectionStoreErrorMessage(availabilityRadiusToggleIsEnabled),
  availabilityTooltip: {
    cookie: {
      cookieName: 'localisationTooltip',
      value: 'true',
      path: '/',
      expires: getExpiryDateByDay(7)
    }
  }
}
