let allSEO = {}

const SEOHelper = {
  getSEO() {
    return allSEO
  },

  setSEO(seo) {
    allSEO = seo
  },

  getSEOBySearchTerm(searchTerm, seo = allSEO) {
    if (!searchTerm) return null
    searchTerm = searchTerm
      .toLowerCase()
      .trim()
      .replace(/\s{2,}/g, ' ')
    const seoInfo = seo[searchTerm]
    if (seoInfo) return seoInfo
    return null
  },

  seoExistsBySearchTerm(searchTerm, seo = allSEO) {
    if (!searchTerm) return false
    searchTerm = searchTerm
      .toLowerCase()
      .trim()
      .replace(/\s{2,}/g, ' ')
    const seoInfo = seo[searchTerm]
    return !!seoInfo
  }
}

export default SEOHelper
