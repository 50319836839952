const minorWords = [
  'and',
  'as',
  'but',
  'for',
  'if',
  'nor',
  'or',
  'so',
  'yet',
  'a',
  'an',
  'the',
  'as',
  'at',
  'by',
  'for',
  'in',
  'of',
  'off',
  'on',
  'per',
  'to',
  'up',
  'via'
]

// If a word in refinement value matches a word in the category name, remove it from the refinement value
const removeCategoryKeywordsFromRefinement = (refinement, categoryName) => {
  const categoryKeywords = categoryName?.split(' ')?.filter(word => !minorWords.includes(word))
  return categoryKeywords
    .reduce((acc, keyword) => {
      return acc?.replace(new RegExp(`\\b${keyword}\\b`, 'g'), '') // Replace exact words
    }, refinement)
    .trim()
    .replace(/\s\s+/g, ' ') // remove white space around string and in between words
}

// Capitalise all words except for minor words
const capitaliseAllWords = string => {
  const words = string.split(' ')
  const capitalisedWords = words.map(word => {
    if (minorWords.includes(word)) {
      return word
    }
    return word[0].toUpperCase() + word.slice(1)
  })
  return capitalisedWords.join(' ')
}

// Remove specific refinements if they have been applied together
export const conflictingRefinementTypes = ['brands', 'character']

const transformCategoryName = category => {
  return capitaliseAllWords(category)
}
/**
 * List of all refinement types to include in the refined title.
 * Sorted by descending priority (beginning types more likely to appear in the category title when multiple types selected)
 *
 * @const {Array<Object>} refinementTypeConfig
 * @property {string} refinementTypeConfig[].id identifier for the refinement type (from search orchestrator response)
 * @property {function} refinementTypeConfig[].transformRefinementText function to transform refinement value for refined title
 * @property {boolean} refinementTypeConfig[].isBeforeCategoryTitle whether refinement title appears before or after the category name in the refined title
 * @property {function} refinementTypeConfig[].modifyRefinementValues optional function to modify the refinement values before creating the refined title

 *
 */

const refinementTypeConfig = [
  {
    id: 'price',
    modifyRefinementValues: values => {
      // Sort price values correctly
      values.sort((valueA, valueB) => {
        // Sort "Under £X" to the beginning
        const underStringIndex = [valueA, valueB].findIndex(value => value.startsWith('Under'))
        if (underStringIndex !== -1) {
          return underStringIndex - 1
        }
        // Sort numbers correctly (e.g. "£5" before "£10")
        return valueA.localeCompare(valueB, undefined, { numeric: true })
      })

      // Combine consecutive price ranges together (e.g. [Under - £5, £5 - £10, £10 - £15] becomes [Under £15])
      const combinedPriceRanges = values.reduce((combinedPrices, nextValue) => {
        if (!combinedPrices.length) {
          return combinedPrices.concat([nextValue])
        }
        const previousValue = combinedPrices[combinedPrices.length - 1]

        const previousValueWords = previousValue?.split(' ')
        const previousValueMax = previousValueWords?.pop()

        const nextValueWords = nextValue?.split(' ')
        const nextValueMin = nextValueWords?.[0]

        // Compare max value of previous price range with min value of current price range
        if (previousValueMax === nextValueMin) {
          // Join previous price range (excluding last word) with the last word of the next price range
          const combinedValue = previousValueWords?.concat([nextValueWords[nextValueWords.length - 1]]).join(' ')
          combinedPrices[combinedPrices.length - 1] = combinedValue
          return combinedPrices
        }

        // If ranges do not match
        return combinedPrices.concat([nextValue])
      }, [])
      return combinedPriceRanges
    },
    transformRefinementText: text => {
      return capitaliseAllWords(text)
    },
    isBeforeCategoryTitle: true
  },
  {
    id: 'colour',
    transformRefinementText: text => {
      return capitaliseAllWords(text)
    },
    isBeforeCategoryTitle: true
  },
  {
    id: 'colour group',
    transformRefinementText: text => {
      // Check if colour group ends with 's' to determine if plural (but ignore "Glass")
      const isPlural = text.endsWith('s') && !text.endsWith('ss')
      if (isPlural) {
        return capitaliseAllWords(text.slice(0, -1))
      }
      return capitaliseAllWords(text)
    },
    isBeforeCategoryTitle: true
  },
  {
    id: 'brands',
    transformRefinementText: (text, categoryName) => {
      const refinementValue = capitaliseAllWords(text)
      return removeCategoryKeywordsFromRefinement(refinementValue, categoryName)
    },
    isBeforeCategoryTitle: true
  },
  {
    id: 'character',
    transformRefinementText: (text, categoryName) => {
      const refinementValue = capitaliseAllWords(text)
      return removeCategoryKeywordsFromRefinement(refinementValue, categoryName)
    },
    isBeforeCategoryTitle: true
  },
  {
    id: 'screen size range (in)',
    transformRefinementText: text => {
      return capitaliseAllWords(text)
    },
    isBeforeCategoryTitle: true
  },
  {
    id: 'resolution',
    transformRefinementText: text => {
      return capitaliseAllWords(text)
    },
    isBeforeCategoryTitle: true
  },
  {
    id: 'material',
    transformRefinementText: text => {
      return capitaliseAllWords(text)
    },
    isBeforeCategoryTitle: true
  },
  {
    id: 'material type',
    transformRefinementText: text => {
      return capitaliseAllWords(text)
    },
    isBeforeCategoryTitle: true
  },
  {
    id: 'shed material',
    transformRefinementText: text => {
      return capitaliseAllWords(text)
    },
    isBeforeCategoryTitle: true
  },
  {
    id: 'size',
    transformRefinementText: text => {
      return capitaliseAllWords(text)
    },
    isBeforeCategoryTitle: true
  },
  {
    id: 'bed size',
    transformRefinementText: text => {
      return capitaliseAllWords(text)
    },
    isBeforeCategoryTitle: true
  },
  {
    id: 'age range',
    transformRefinementText: text => {
      // Get string in brackets (the age range)
      const ageRange = text.match(/\((.*?)\)/)?.[0]
      if (ageRange) return capitaliseAllWords(ageRange)

      // Add brackets around age range if no brackets found
      return capitaliseAllWords(`(${text})`)
    },
    isBeforeCategoryTitle: false
  }
]

const orderedRefinementTypes = refinementTypeConfig.map(refinementType => refinementType.id)

export { refinementTypeConfig, orderedRefinementTypes, transformCategoryName }
