import deepcopy from 'lodash.clonedeep'

import ProductHelper, { initialState } from './index'
import Actions from '../../constants/product'
import CategoryActions from '../../constants/category'
import findabilityDecoder from '../../helpers/FindabilityDecoder'

// -------------------------------------------------
// ACTIONS
// -------------------------------------------------

export const handleSearch = productPayload => ({ type: Actions.SEARCH, productPayload })
export const searchStart = searchParams => ({ type: Actions.SEARCH_START, searchParams })
export const failedSearch = payload => ({ type: Actions.SEARCH_FAILURE, payload })
export const toggleFilterPanelStatic = payload => ({ type: Actions.FILTER_PANEL_STATIC, payload })
export const showFilterModal = () => ({ type: Actions.SHOW_FILTER_MODAL })
export const hideFilterModal = () => ({ type: Actions.HIDE_FILTER_MODAL })
export const resetAllFilters = () => ({ type: Actions.RESET_ALL_FILTERS })
export const selectFilter = payload => ({ type: Actions.SELECT_FILTER, payload })
export const deselectFilter = payload => ({ type: Actions.DESELECT_FILTER, payload })
export const deselectFacet = payload => ({ type: Actions.DESELECT_FACET, payload })
export const toggleEMWBISModal = payload => ({ type: Actions.TOGGLE_EMWBIS_MODAL, payload })

// -------------------------------------------------
// REDUCER
// -------------------------------------------------
export default (state = initialState, action) => {
  const newState = deepcopy(state)

  switch (action.type) {
    case CategoryActions.BROWSE: {
      return ProductHelper.clearState(newState)
    }
    case CategoryActions.PLP:
    case Actions.SEARCH: {
      const payload = action.productPayload
      newState.searchTerm = payload.searchTerm
      newState.status = payload.status
      if (payload.response) {
        newState.isPopulated = true
        newState.success = true
        newState.meta = {
          ...payload.response.meta,
          appliedFilters: payload?.response?.meta.appliedFilters?.map(appliedFilter => ({
            ...appliedFilter,
            values: appliedFilter?.values?.map(value => findabilityDecoder.encodeAngleBrackets(value)), // encode "<>" characters that are returned by the search orchestrator response to prevent XSS
            labels: appliedFilter?.labels?.map(label => findabilityDecoder.encodeAngleBrackets(label)) // encode "<>" characters that are returned by the search orchestrator response to prevent XSS
          }))
        }
        newState.numberOfResults = newState.meta.totalData
        newState.currentPage = newState.meta.currentPage
        newState.pageSize = newState.meta.pageSize
        newState.isCannedSearch = newState.meta.canned
        newState.products = payload.response.data
        newState.appliedSortBy =
          newState.meta.appliedSorts.length > 1
            ? {
                [newState.meta.appliedSorts[1].name]: newState.meta.appliedSorts[1].order
              }
            : null
        newState.filters = newState.meta.aggregations.map(filter => {
          filter.id = filter.id.toLowerCase()
          return filter
        })
        newState.appliedFilters = []
        newState.brandSuggestions = newState.meta.brandSuggestions
        newState.productCategorySuggestions = newState.meta.productCategorySuggestions
        newState.noResultsModalVisible = false
        newState.categoryFilterId = null
        newState.ratingFilterApplied = false
        newState.categoryMatch = newState.meta.categoryMatch
        newState.categoryMatchOverride = payload.categoryMatchOverride
        newState.spellcheckedSearch = payload.spellcheckedSearch
        newState.searchType = payload.searchType
        if (newState.spellcheckedSearch && !newState.searchType) {
          newState.searchType = 'sc'
        }
        newState.inputSearchTerm = payload.inputSearchTerm
        newState.searchTermSuggestions = newState.meta.suggestions
        newState.searchCanonical = payload.searchTerm === null ? null : payload.searchCanonical
        newState.similarAiLinks = payload.similarAiResponse?.default
      } else {
        ProductHelper.clearState(newState)
      }
      const updatedAppliedFilters = ProductHelper.updateAppliedFilters(newState)
      const updatedFilterState = ProductHelper.updateFilterState(updatedAppliedFilters)
      return ProductHelper.updateFilterUriFragments(updatedFilterState)
    }
    case Actions.SEARCH_START: {
      const { searchParams } = action
      newState.searchTerm = findabilityDecoder.getSanitised(searchParams.searchTerm)
      newState.inputSearchTerm = findabilityDecoder.getSanitised(searchParams.searchTerm)
      return newState
    }
    case Actions.SEARCH_FAILURE: {
      const { payload } = action
      newState.success = false
      newState.status = payload.status
      newState.failedService = payload.failedService
      return newState
    }
    case Actions.SHOW_FILTER_MODAL: {
      newState.filterModalVisible = true
      return newState
    }
    case Actions.HIDE_FILTER_MODAL: {
      newState.filterModalVisible = false
      return newState
    }
    case Actions.FILTER_PANEL_STATIC: {
      newState.filterPanelStatic = action.payload
      return newState
    }
    case Actions.SELECT_FILTER: {
      const { payload } = action
      newState.appliedFiltersOrder = [...state.appliedFiltersOrder, payload]
      return newState
    }
    case Actions.DESELECT_FILTER: {
      const { payload } = action
      newState.appliedFiltersOrder = [
        ...state.appliedFiltersOrder.filter(f => f.filterId !== payload.filterId.toLowerCase())
      ]
      return newState
    }
    case Actions.DESELECT_FACET: {
      const { payload } = action
      newState.appliedFiltersOrder = [
        ...state.appliedFiltersOrder.filter(f => f.facetId !== payload.facetId.toLowerCase())
      ]
      return newState
    }
    case Actions.RESET_ALL_FILTERS: {
      newState.appliedFiltersOrder = []
      newState.filterResetCount += 1
      return newState
    }
    case Actions.TOGGLE_EMWBIS_MODAL: {
      const {
        payload: { visible, ...payload }
      } = action
      newState.emwbisModalVisible = visible
      newState.emwbisModalData = visible ? { ...payload } : {}
      return newState
    }
    default:
      return state
  }
}
