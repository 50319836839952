import { pick } from '../utils/PolyfillLodash'

const getCitrusProductAdData = data => {
  const reqAttr = ['gtin', 'id']
  const myData = pick(data, reqAttr)

  return myData
}

export default getCitrusProductAdData
