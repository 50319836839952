import bunyan from 'bunyan'

const loggerLevel = process.env.BUNYAN_LOG_LEVEL || 'warn'

const loggerNames = [
  'serverLogger',
  'xhrLogger',
  'apiLogger',
  'sagasLogger',
  'functionLogger',
  'redirectLogger',
  'componentLogger',
  'vaultLogger'
]

let instance = null

// Set 'level' in each log to be the string level name instead of integer level (e.g. print "ERROR" instead of 50 as the level for error logs)
// Needed for Elasticsearch indexing in Bosun as other apps are logging 'level' as strings so any logs with 'level' that aren't strings won't appear
const wrappedStdout = () => {
  return {
    write: entry => {
      const logObject = JSON.parse(entry)
      logObject.level = bunyan.nameFromLevel[logObject.level].toUpperCase()
      process.stdout.write(`${JSON.stringify(logObject)}\n`)
    }
  }
}

export default class {
  constructor() {
    this.init = this.init.bind(this)

    if (!instance) {
      instance = this.init()
      instance.setupLoggers(loggerNames)
    }
    return instance
  }

  init() {
    return {
      loggers: {},

      debug(name, responseData, msg) {
        if (!global.window) {
          this.getLoggerInstance(name).debug(responseData, msg)
        }
      },

      info(name, responseData, msg) {
        if (!global.window) {
          this.getLoggerInstance(name).info(responseData, msg)
        }
      },

      warn(name, responseData, msg) {
        if (!global.window) {
          this.getLoggerInstance(name).warn(responseData, msg)
        }
      },

      trace(name, responseData, msg) {
        if (!global.window) {
          this.getLoggerInstance(name).trace(responseData, msg)
        }
      },

      fatal(name, responseData, msg) {
        if (!global.window) {
          this.getLoggerInstance(name).fatal(responseData, msg)
        }
      },

      error(name, responseData, msg) {
        if (!global.window) {
          this.getLoggerInstance(name).error(responseData, msg)
        }
      },

      createLoggerInstance(name) {
        return bunyan.createLogger({
          name,
          stream: wrappedStdout(),
          level: loggerLevel
        })
      },

      getLoggerInstance(name) {
        if (!this.loggers[name]) {
          this.loggers[name] = this.createLoggerInstance(name)
        }

        return this.loggers[name]
      },

      getAllLoggers() {
        return Object.keys(this.loggers).map(key => {
          return this.loggers[key]
        })
      },

      setupLoggers(loggerNames) {
        loggerNames.forEach(name => {
          this.loggers[name] = this.createLoggerInstance(name)
        })
      }
    }
  }
}
