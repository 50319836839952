import { take, put, fork } from 'redux-saga/effects'
import Logger from '../../utils/Logger'
import { GET_CONTENT, getContentSuccess, getContentFailure } from '../../redux/cms/getContent'

const logger = new Logger()

// -------------------------------------------------
// WORKER SAGAS
// -------------------------------------------------
export function* getContent(payload) {
  try {
    if (payload && !payload.error) {
      yield put(getContentSuccess(payload))
    } else {
      yield put(getContentFailure(payload))
    }
  } catch (error) {
    logger.error('sagasLogger', error, 'Unable to get browse content')
  }
}

// -------------------------------------------------
// WATCHER SAGAS
// -------------------------------------------------
export function* watchGetContent() {
  while (true) {
    const { payload } = yield take(GET_CONTENT)
    yield fork(getContent, payload)
  }
}
