import { getValue } from './ConfigProvider'
import { citrusRestrictedPages } from '../config'

const toBoolean = value => value === true || value === 'true'

export const isCitrusEnabled = ({ pageType } = {}) => {
  const isBrandEnabled = getValue('features.sponsoredProducts.enabled') || false
  const isEnvironmentEnabled =
    typeof window !== 'undefined' ? toBoolean(window.citrusEnabled) : toBoolean(process.env.CITRUS_ENABLED)
  const isPageTypeExcluded = citrusRestrictedPages.includes(pageType)
  return isBrandEnabled && isEnvironmentEnabled && !isPageTypeExcluded
}
