import { getValue, getBrand } from './ConfigProvider'

const getDefaultSort = (isSearch, isBrowse, categoryId, isClearance = false) => {
  const sortByRelevanceIds = getValue('features.productList.sortByRelevance')
  const categoryStringId = categoryId?.toString()

  // Use a default sort parameter for SLP but not PLP
  if (isSearch) {
    return 'relevance'
  }
  // Sort category page by relevance if set in the brand config
  if (
    categoryId &&
    sortByRelevanceIds &&
    sortByRelevanceIds.length > 0 &&
    sortByRelevanceIds.find(id => id === categoryStringId)
  ) {
    return 'relevance'
  }
  // Tu Browse pages sort defaults to "New Arrivals" or "New in" on clearance pages
  if ((getBrand() === 'tu' || isClearance) && isBrowse) {
    return 'newness'
  }

  return null
}

export default getDefaultSort
