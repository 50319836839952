import React from 'react'
import PropTypes from 'prop-types'
import ListerPage from '../containers/ListerPage/ListerPage'
import executeSearch from '../actions/executeSearch'
import executeNoResults from '../actions/executeNoResults'
import formatActionPayload from '../helpers/RoutingHelper'

class List extends React.Component {
  static fetchData(routeMatch, url, store, fetchr) {
    const context = {
      reduxStore: store,
      service: fetchr
    }

    const payload = formatActionPayload(routeMatch, url)
    payload.query = {
      ...payload.query,
      canned: true
    }

    return new Promise(resolve => {
      executeSearch(context, payload, resolve)
      executeNoResults(context, payload, resolve) // TODO: see if we can nest this inside executeSearch action? (surround in logic only if numberOfResults === 0)
    })
  }

  render() {
    return <ListerPage />
  }
}

List.contextTypes = {
  fetchr: PropTypes.object,
  store: PropTypes.object
}

export default List
