import React from 'react'
import PropTypes from 'prop-types'
import ListerPage from '../containers/ListerPage/ListerPage'
import executeSearch from '../actions/executeSearch'
import executeNoResults from '../actions/executeNoResults'
import formatActionPayload from '../helpers/RoutingHelper'

class Search extends React.Component {
  static fetchData(routeMatch, url, store, fetchr) {
    const context = {
      reduxStore: store,
      service: fetchr
    }

    const payload = formatActionPayload(routeMatch, url)

    return new Promise(resolve => {
      executeSearch(context, payload, resolve)
      executeNoResults(context, payload, resolve) // TODO: see if we can nest this inside executeSearch action? (surround in logic only if numberOfResults === 0)
    })
  }

  render() {
    return <ListerPage />
  }
}

Search.contextTypes = {
  fetchr: PropTypes.object,
  store: PropTypes.object,
  history: PropTypes.object
}

export default Search
