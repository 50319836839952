const { newrelic } = window

const reportCustomAttributes = () => {
  if (typeof newrelic === 'object') {
    const version = process.env.RELEASE_VERSION || window.RELEASE_VERSION
    newrelic.setCustomAttribute('releaseVersion', version)
  }
}

export default reportCustomAttributes
