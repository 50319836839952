import { getBrand } from '../../utils/ConfigProvider'
import { appendQuantity } from '../AvailabilityHelper'

const isTu = getBrand() === 'tu'

const DEFAULT_POSTCODE = 'EC1N2HT'
const FILTER_NAME = 'size'
const PERCENTAGE_THRESHOLD = 40
const INFORMATION_LABEL = {
  text: 'Check more options',
  icon: 'info'
}

const isTuItem = (partNumber = '') => {
  return partNumber
    .toString()
    .toLowerCase()
    .startsWith('tuc')
}

const isBrowseClothing = (pageType = '', templateType = '') => pageType === 'browse' && templateType === 'fashion'
const getTuProducts = (products = []) => products.filter(product => isTuItem(product?.id))

// Check if a specific product has variants
const hasVariants = (product = {}) => {
  const { hasVariations, variants } = product.attributes || {}
  if (!hasVariations || !variants) return false

  return hasVariations && Object.keys(variants).length > 0
}

// Checking is only one size filter is applied
const isFilterApplied = (filters = []) => {
  if (filters.length === 0) return false

  const sizeFilter = filters.find(filter => filter.name.toString().toLowerCase() === FILTER_NAME) || false
  const hasMultipleSizeApplied = sizeFilter && sizeFilter?.values?.length > 1

  return sizeFilter && !hasMultipleSizeApplied
}

// Returns a string of products without any variants. Used for Tu & Argos clothing browse page
const formatTuProducts = (products = []) => {
  if (products.length === 0) return ''

  const productsWithoutVariants = products.filter(product => isTuItem(product?.id) && !hasVariants(product))
  return appendQuantity(productsWithoutVariants)
}

const showTuLabels = (products = [], appliedFilters = []) => {
  const tuProducts = getTuProducts(products)
  const sizeFilterApplied = isFilterApplied(appliedFilters)

  const productsWithoutVariants = tuProducts.filter(product => !hasVariants(product))
  const percentage = Math.round((productsWithoutVariants.length / tuProducts.length) * 100)

  // Show availability labels if 'PERCENTAGE_THRESHOLD' or more of the products do not have variants OR when the size filter is applied
  if (percentage >= PERCENTAGE_THRESHOLD || sizeFilterApplied) return true

  return false
}

const setInformationMessaging = (product = {}) => {
  if (!product.id) return {}
  return {
    partNumber: product.id,
    delivery: {
      ...INFORMATION_LABEL
    }
  }
}

// Returns an array of availability labels of products with variants
const getInformationLabels = (products = []) => {
  const productsWithVariants = products.filter(product => isTuItem(product?.id) && hasVariants(product))

  if (productsWithVariants.length > 0) {
    return productsWithVariants.map(product => setInformationMessaging(product))
  }

  return []
}

// Check if all products has variants
const hasEqualVariants = (products = []) => {
  const tuProducts = getTuProducts(products)
  const hasNoVariants = tuProducts.some(product => !hasVariants(product))

  if (hasNoVariants) return false

  return true
}

export {
  DEFAULT_POSTCODE,
  FILTER_NAME,
  formatTuProducts,
  getInformationLabels,
  hasVariants,
  INFORMATION_LABEL,
  isFilterApplied,
  isBrowseClothing,
  isTu,
  isTuItem,
  setInformationMessaging,
  showTuLabels,
  PERCENTAGE_THRESHOLD,
  hasEqualVariants,
  getTuProducts
}
