import React from 'react'
import loadable from '@loadable/component'
import {
  AddToTrolley,
  AddToWishlist,
  Image,
  SpecCardSkeleton,
  ProductCardSkeleton,
  ErrorBoundary
} from '@sainsburys-tech/bolt'
import { canUseDom } from '@sainsburys-tech/boltui-utils'
import { TuLogo } from '@sainsburys-tech/bolt-icons'
import config from '../../config'
import { isCookieSet } from '../../helpers/CookieHelper'

import Templates from './ProductList.template'
import { getValue } from '../../utils/ConfigProvider'
import { trackWishlist } from '../../helpers/TaggingSerializer'
import {
  getProductPagePath,
  findAttsByName,
  findAndMoveFirst,
  getColorVariants,
  getFabricSwatchVariants
} from '../../helpers/ProductHelper'
import { availabilityIsEnabled } from '../../utils/availabilityIsEnabled'
import { getLocalisedPostcode, excludedEmailBackSkus } from '../../helpers/AvailabilityHelper'
import { isTu } from '../../helpers/Availability/AvailabilityTuHelper'
import { isCitrusEnabled } from '../../utils/isCitrusEnabled'
import { PAGE_TYPES } from '../../utils/pageTypes'

import * as Styled from './styles.js'

const CLEARANCE_BADGE_VALUE = 'CLEARANCE_BADGE'
const MTO_MAX_VARIATIONS = 5

const ProductListCard = ({
  attributes,
  citrus,
  className,
  currentPage,
  index,
  isCannedSearch,
  isClearance,
  layout,
  localisationPostcode,
  numberOfResults,
  pageSize,
  pageType,
  partNumber,
  productCardOnClick,
  relavancyRecallOffset,
  searchTerm,
  sendCitrusClickBeacon,
  sendCitrusImpressionBeacon,
  showLoader,
  templateType,
  userState,
  whiteGoodCategory,
  location,
  availabilityLabel,
  availabilityStatus,
  showEMWBISModal
}) => {
  const Skeleton = () => (
    <Styled.SkeletonWrapper className={className}>
      <ProductCardSkeleton isPortrait={layout === Templates.clothingLayout} mobileLayout='vertical' />
    </Styled.SkeletonWrapper>
  )
  const SpecSkeleton = () => (
    <Styled.SkeletonWrapper whiteGoods className={className}>
      <SpecCardSkeleton />
    </Styled.SkeletonWrapper>
  )

  const ProductCard = loadable(() => import(/* webpackChunkName: "labs-product-card" */ './StyledProductCard.js'), {
    fallback: <Skeleton />
  })

  const SpecCard = loadable(() => import(/* webpackChunkName: "spec-card" */ './SpecCard.js'), {
    fallback: <SpecSkeleton />
  })

  const isNotABot = () => {
    return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator?.userAgent) === false
  }

  const buildQueryParamTracking = relevancyRank => {
    const position = pageSize * (currentPage - 1) + (index + 1)
    const isSearchDirectoryPage = pageType === PAGE_TYPES.DIRECTORY

    let params = ''

    // Only add click tracking params on the client and if the user is not a bot/crawler
    if (canUseDom() && isNotABot()) {
      // Remove clickSR on /sd pages
      if (searchTerm && !isSearchDirectoryPage) {
        params = `clickSR=slp:term:${searchTerm}:${position}:${numberOfResults}:${relevancyRank}`
      } else if (!searchTerm) {
        params = `clickPR=${!isClearance ? 'plp' : 'clearance'}:${position}:${numberOfResults}`
      }
    }
    // Remove click tracking params from browse listers if configured
    const queryParam = (getValue('features.productCard.disableClickPR') && !searchTerm) || !params ? '' : `?${params}`
    return `/${getProductPagePath(partNumber)}${queryParam}`
  }

  const getBrandLogo = brand => {
    // Get the opt-in feature status
    const additionalBrandLogos = getValue('features.productList.additionalBrandLogos')

    // If the feature value is an array, find a matching logo for the brand.
    if (Array.isArray(additionalBrandLogos)) {
      // The brand name may have different casing to our data, so normalise while searching.
      const brandLogo = additionalBrandLogos.find(
        ({ name }) => String(brand).toLowerCase() === String(name).toLowerCase()
      )

      // If there's a brand in the additionalBrandLogos, and it's got a logo URL, return a BrandLogo
      if (brandLogo && brandLogo.logoUrl) {
        return <Image alt={brand} src={encodeURI(brandLogo.logoUrl)} height={20} />
      }
    }

    // For clothing layouts, apply custom brand logo logic
    if (layout === Templates.clothingLayout) {
      // Display brands that aren't Tu as their brand name
      if (brand !== 'Tu') {
        return brand
      }

      // Don't show the Tu logo for Tu brand products, if it's disabled in features
      if (!getValue('features.productList.disableTuLogo')) {
        return <TuLogo height={24} width={24} />
      }
    }

    // If this is a Habitat browse page, give every product the Habitat logo
    if (pageType === 'browse' && brand === 'Habitat') {
      return <Styled.HabitatLogo height={26} width={60} />
    }

    // Return no brand logo for all other scenarios
    return null
  }

  const populateSpecificationData = () => {
    const specificationDetails = findAttsByName(
      attributes?.detailAttributes,
      getValue('features.detailAttributesWhiteGoods')
    )
    const sortSizeFirst = findAndMoveFirst(specificationDetails, 'Size (cm)')

    return {
      heading: Templates.specificationHeader,
      secondaryData: Templates.secondaryDataArray(attributes),
      headerLink: (
        <>
          <a href={`/${config.getProductPath(attributes.partNumber)}`} onClick={productCardOnClick}>
            {Templates.headerText}
          </a>
        </>
      ),
      overviewData: { content: sortSizeFirst },
      smallTitle: true
    }
  }

  const isExcluded = (isDeliverable, isReservable, hasVariations) => {
    return !isDeliverable && !isReservable && !hasVariations
  }

  const showSkeleton = () => {
    return showLoader || typeof attributes.price !== 'number'
  }

  const isLazyLoadImage = position => {
    return position > 6
  }

  const alternativeImagesConditionChecker = () => {
    if (getValue('features.productList.forceEnableAltImageHover')) {
      return true
    }

    return (
      templateType && templateType.toLowerCase() !== Templates.defaultTemplate && pageType === Templates.pageTypeBrowse
    )
  }

  const handleAddToTrolleyClick = (event, productInfo, positionInList, clickOrigin) =>
    AddToTrolley.onAddToTrolleyModalOpen(productInfo)(clickOrigin, positionInList)()

  const trolleyOnClick = event => {
    const productInfo = [{ id: partNumber, price: attributes.price?.toString(), quantity: 1 }]
    const clickOrigin =
      pageType === Templates.pageTypeBrowse ? Templates.browseAddToTrolleyOrigin : Templates.searchAddToTrolleyOrigin
    handleAddToTrolleyClick(event, productInfo, index + 1, clickOrigin)
    if (isCitrusEnabled({ pageType })) {
      sendCitrusClickBeacon(attributes, citrus, partNumber)
    }
  }

  const handleBackInStockCTAClick = event => {
    event.preventDefault()
    showEMWBISModal(partNumber)
  }

  const productInfoOnly = isExcluded(attributes.deliverable, attributes.reservable, attributes.hasVariations)

  const { fulfilmentAttributes = [] } = attributes

  const isMadeToOrder = fulfilmentAttributes?.some(
    attribute => attribute.name === 'madeToOrder' && attribute.value === 'Yes'
  )

  const colorDefiningAttributes = attributes?.definingAttributes?.Colour || []
  const fabricDefiningAttributes = attributes?.definingAttributes?.['Fabric type'] || []

  const colorVariants = isMadeToOrder
    ? getFabricSwatchVariants(colorDefiningAttributes, fabricDefiningAttributes)
    : getColorVariants(colorDefiningAttributes)

  const mtoProps = isMadeToOrder
    ? {
        mto: true,
        colorSwatchFabricTiles: true
      }
    : {}

  const colorSwatchInline = !isMadeToOrder && getValue('features.productCard.colorSwatchInline')
  const colorSwatchMaxVariations = isMadeToOrder
    ? MTO_MAX_VARIATIONS
    : getValue('features.productCard.colorSwatchMaxVariations')

  let recallPosition = relavancyRecallOffset + index
  recallPosition = !attributes.featured ? (recallPosition += 1) : recallPosition
  const isLazy = isLazyLoadImage(recallPosition)
  const imageVariant = alternativeImagesConditionChecker(templateType, pageType)

  if (attributes.featured && isCitrusEnabled({ pageType })) {
    sendCitrusImpressionBeacon(attributes, citrus)
  }

  const isValueMessaging = getValue('features.productCard.enableValueMessaging')

  const availabilityEnabled = availabilityIsEnabled({
    pageType,
    urlPath: location?.pathname
  })

  const itemNotAvailable = availabilityLabel.some(item => item.icon === 'out_of_stock')
  const itemNotExcluded = !excludedEmailBackSkus.includes(partNumber)
  const itemNotClearance = !(
    Object.keys(attributes?.badge?.BADGE_VALUE || {})?.length > 0 &&
    attributes?.badge?.BADGE_VALUE?.includes(CLEARANCE_BADGE_VALUE)
  )

  const showClearanceBadgeForBrand = getValue('features.productCard.showClearanceBadge')
  const badges = showClearanceBadgeForBrand
    ? attributes?.badge
    : {
        ...attributes?.badge,
        ...(attributes?.badge?.BADGE_VALUE && {
          BADGE_VALUE: attributes?.badge?.BADGE_VALUE?.filter(value => value !== CLEARANCE_BADGE_VALUE)
        })
      }
  const availabilityLabels =
    availabilityEnabled && getLocalisedPostcode(localisationPostcode)
      ? {
          loading: availabilityStatus === 'pending',
          availabilityLabels: availabilityLabel,
          hasBackgroundColor: isTu,
          backInStockCTA: {
            show: getValue('features.backInStock.enabled') && itemNotAvailable && itemNotClearance && itemNotExcluded,
            onClick: event => handleBackInStockCTAClick(event)
          }
        }
      : null

  const energyRating = findAttsByName(attributes?.detailAttributes, ['Energy efficiency class'])

  return !whiteGoodCategory ? (
    !showSkeleton(showLoader, attributes) ? (
      <Styled.LazyHydrateCard
        key={`${partNumber}${index}`}
        whenVisible
        style={{ display: 'flex' }}
        className={className}>
        <ProductCard
          isAvifImageFmt={isCookieSet('Findability_Test_Group_1', '2')}
          onClick={productCardOnClick}
          lazyImageLoad={isLazy}
          isPortrait={layout === Templates.clothingLayout}
          mobileLayout='vertical'
          partNumber={partNumber}
          parentPartNumber={attributes.productId}
          imageHoverActive={imageVariant}
          colorVariants={!!colorVariants && colorVariants.length > 1 && colorVariants}
          brand={getBrandLogo(attributes.brand, layout, pageType)}
          featured={attributes.featured} // TODO: FEATURED
          name={attributes.name}
          isValueMessaging={isValueMessaging}
          price={attributes.price}
          wasPrice={!!attributes.wasPrice && attributes.wasPrice > 0 && attributes.wasPrice}
          specialOfferText={attributes.specialOfferText}
          rating={!!attributes.avgRating && attributes.avgRating > 0 && attributes.avgRating}
          reviewsCount={attributes.reviewsCount}
          productLinkOverride={
            !attributes.productLink && isCannedSearch
              ? `/${getProductPagePath(partNumber)}?clickCSR=slp:cannedSearch`
              : !attributes.productLink && !isCannedSearch
              ? buildQueryParamTracking(attributes.relevancyRank)
              : attributes.productLink
          }
          badge={badges}
          freeDelivery={attributes.freeDelivery}
          hasVariations={attributes.hasVariations}
          hasMultiplePrices={attributes.hasMultiplePrices}
          productInfoOnly={productInfoOnly}
          buttonLabel={attributes.hasVariations && (productInfoOnly ? 'More Details' : 'Choose Options')}
          turnOffCta={getValue('features.productCard.turnOffCta')}
          cta={
            <ErrorBoundary>
              <AddToTrolley.Button
                className='btn-cta'
                partNumber={partNumber}
                openView
                showIcon
                onClick={trolleyOnClick}
              />
            </ErrorBoundary>
          }
          wishlistCta={
            getValue('features.wishlist.enabled') && (
              <AddToWishlist.Button
                item={{
                  partNumber,
                  hasVariant: attributes.hasVariations
                }}
                tooltipDurationToShow={10000}
                tooltipWidth={260}
                onUpdate={isChecked => trackWishlist(isChecked, partNumber, userState)}
                tooltipPosition='right'
              />
            )
          }
          colorSwatchInline={colorSwatchInline}
          colorSwatchAlwaysVisible={getValue('features.productCard.colorSwatchAlwaysVisible')}
          colorSwatchMaxVariations={colorSwatchMaxVariations}
          colorSwatchSize={getValue('features.productCard.colorSwatchSize')}
          availability={availabilityLabels}
          energyRating={{
            value: energyRating.length > 0 ? energyRating[0].value : null,
            pdf: `https://documents.4rgos.it/v1/static/${partNumber}_R_D010`,
            energyLabelhandler: () => window.open(`/product/${partNumber}#energy-rating`, '_self')
          }}
          {...mtoProps}
        />
      </Styled.LazyHydrateCard>
    ) : (
      <Skeleton />
    )
  ) : !showSkeleton(showLoader, attributes) ? (
    <Styled.WhiteGoodsWrapper className={className}>
      <Styled.LazyHydrateCard whenVisible>
        <SpecCard
          key={`${partNumber}${index}`}
          isPortrait={layout === Templates.clothingLayout}
          partNumber={partNumber}
          imageHoverActive={imageVariant}
          colorVariants={!!colorVariants && colorVariants.length > 1 && colorVariants}
          brand={getBrandLogo(attributes.brand, layout, pageType)}
          featured={attributes.featured} // TODO: FEATURED
          name={attributes.name}
          price={attributes.price}
          wasPrice={!!attributes.wasPrice && attributes.wasPrice > 0 && attributes.wasPrice}
          specialOfferText={attributes.specialOfferText}
          rating={!!attributes.avgRating && attributes.avgRating > 0 && attributes.avgRating}
          reviewsCount={attributes.reviewsCount}
          productLinkOverride={
            !attributes.productLink && !isCannedSearch
              ? buildQueryParamTracking(attributes.relevancyRank)
              : attributes.productLink
          }
          badge={badges}
          freeDelivery={attributes.freeDelivery}
          hasVariations={attributes.hasVariations}
          hasMultiplePrices={attributes.hasMultiplePrices}
          productInfoOnly={productInfoOnly}
          buttonLabel={productInfoOnly ? 'More Details' : 'Choose Options'}
          onClick={productCardOnClick}
          cta={
            !getValue('features.productCard.turnOffCta') && (
              <ErrorBoundary>
                <AddToTrolley.Button
                  className='btn-cta'
                  partNumber={partNumber}
                  openView
                  showIcon
                  onClick={trolleyOnClick}
                />
              </ErrorBoundary>
            )
          }
          wishlistCta={
            <AddToWishlist.Button
              item={{
                partNumber,
                hasVariant: attributes.hasVariations
              }}
              tooltipDurationToShow={10000}
              tooltipWidth={260}
              onUpdate={isChecked => trackWishlist(isChecked, partNumber, userState)}
              tooltipPosition='left'
            />
          }
          specificationData={populateSpecificationData()}
          availability={availabilityLabels}
          energyRating={{
            value: energyRating.length > 0 ? energyRating[0].value : null,
            pdf: `https://documents.4rgos.it/v1/static/${partNumber}_R_D010`,
            onEnergyLabelClick: () => window.open(`/product/${partNumber}#energy-rating`, '_self')
          }}
        />
      </Styled.LazyHydrateCard>
    </Styled.WhiteGoodsWrapper>
  ) : (
    <SpecSkeleton />
  )
}

export default ProductListCard
