export const generateM053LinksContentFromProduct = cmsM053ContentData => {
  if (!cmsM053ContentData || !cmsM053ContentData?.link || Object.keys(cmsM053ContentData.link).length === 0) {
    return []
  }

  const link = {
    linkUrl: cmsM053ContentData.link?.url,
    linkTitle: cmsM053ContentData.link?.title,
    linkTarget: cmsM053ContentData.link?.target || '_blank',
    ctaCopy: cmsM053ContentData.ctaText || '',
    ctaStyle: cmsM053ContentData.ctaStyle || ''
  }
  return [link]
}
