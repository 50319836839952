import React, { Children, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ChevronIcon } from '@sainsburys-tech/bolt-icons'
import { useResizeEvent } from '@sainsburys-tech/boltui-hooks'
import { Container, InnerContainer, OuterContainer, ToggleButton, ToggleLabel, Toggle } from './styles'

const getFirstElementInList = (innerContainer, elementClassName) => {
  if (!innerContainer || !elementClassName) return null
  return innerContainer.querySelectorAll(`.${elementClassName}`)?.item(0)
}

const getHeightForNumOfRowsToDisplay = (individualElement, numOfRows = 1) => {
  if (!individualElement) return 'initial'
  const individualElementHeight = individualElement.offsetHeight
  return typeof individualElementHeight === 'number'
    ? `${numOfRows * individualElementHeight}px`
    : individualElementHeight
}

const isToggleBtnToBeDisplayed = (innerContainer, individualElement, numOfRows = 1) => {
  if (!innerContainer || !individualElement) return false
  const innerContainerHeight = innerContainer.offsetHeight
  const individualElementHeight = individualElement.offsetHeight
  return innerContainerHeight > numOfRows * individualElementHeight
}

const setMaxNumOfRowsToDisplayFor = (
  { innerContainer, elementClassName, numOfRows, toggleFn, setHeightFn } = {
    innerContainer: null,
    elementClassName: '',
    numOfRows: 1,
    toggleFn: () => {},
    setHeightFn: () => {}
  }
) => {
  const firstRowElement = getFirstElementInList(innerContainer, elementClassName)
  if (firstRowElement) {
    const maxHeightToDisplay = getHeightForNumOfRowsToDisplay(firstRowElement, numOfRows)
    const isToggleBtnToBeShown = isToggleBtnToBeDisplayed(innerContainer, firstRowElement, numOfRows)
    toggleFn(isToggleBtnToBeShown)
    setHeightFn(maxHeightToDisplay)
  }
}

const ReverseAccordion = ({ maxRows, numOfItems, className, elementClassName, 'data-test': dataTest, children }) => {
  const innerContainerRef = useRef(null)
  const [_containerHeight, setContainerHeight] = useState('0px')
  const [_isOpen, toggleOpen] = useState(false)
  const [_isToggleBtnToShow, setToggleBtnToShow] = useState(false)

  useEffect(() => {
    const innerContainer = innerContainerRef?.current
    setTimeout(() => {
      setMaxNumOfRowsToDisplayFor({
        innerContainer,
        elementClassName,
        numOfRows: maxRows,
        toggleFn: setToggleBtnToShow,
        setHeightFn: setContainerHeight
      })
    }, 0)
  }, [elementClassName, maxRows, numOfItems])

  useResizeEvent({
    resizeHandler: () => {
      setTimeout(() => {
        const innerContainer = innerContainerRef?.current
        setMaxNumOfRowsToDisplayFor({
          innerContainer,
          elementClassName,
          numOfRows: maxRows,
          toggleFn: setToggleBtnToShow,
          setHeightFn: setContainerHeight
        })
      }, 0)
    }
  })

  // The entire content gets initially displayed and then the
  // container max-height gets reduced only to show the minimum rows.
  const outerContainerMaxHeight = _isToggleBtnToShow
    ? _isOpen
      ? 'initial'
      : typeof _containerHeight === 'number'
      ? `${_containerHeight}px`
      : _containerHeight
    : _containerHeight

  const toggleButtonLabel = _isOpen ? 'Show Less' : 'Show More'

  const handleToggleShowMore = () => {
    toggleOpen(!_isOpen)
  }

  return (
    <>
      {Children.count(children) > 0 ? (
        <Container
          className={`reverse-accordion-container ${className}`}
          data-test={`${dataTest}-reverse-accordion-container`}>
          <OuterContainer
            className={`reverse-accordion-outer-container ${className}`}
            maxHeight={outerContainerMaxHeight}
            data-test={`${dataTest}-reverse-accordion-outer-container`}>
            <InnerContainer
              ref={innerContainerRef}
              className={`reverse-accordion-inner-container ${className}`}
              data-test={`${dataTest}-reverse-accordion-inner-container`}>
              {children}
            </InnerContainer>
          </OuterContainer>
          {_isToggleBtnToShow ? (
            <ToggleButton
              kind='link'
              onClick={handleToggleShowMore}
              data-test={`${dataTest}-reverse-accordion-button-show-more`}>
              <ToggleLabel>{toggleButtonLabel}</ToggleLabel>
              <Toggle isOpen={_isOpen} aria-hidden='true'>
                <ChevronIcon dir='down' fill='#404040' width='24px' height='24px' />
              </Toggle>
            </ToggleButton>
          ) : null}
        </Container>
      ) : null}
    </>
  )
}

ReverseAccordion.propTypes = {
  /**
   * Specify the content of your Accordion
   */
  children: PropTypes.node,

  /**
   * Maximum number of rows to display
   */
  maxRows: PropTypes.number,

  /**
   * Number of items to display
   */
  numOfItems: PropTypes.number,

  /**
   * Specify an optional className to be added to the FilterPills container
   */
  className: PropTypes.string,

  /**
   * Class name of the single element that we want to initially show
   */
  elementClassName: PropTypes.string,

  /**
   * Specify an optional data-test attribute to be added to your Accordion for e2e testing
   */
  'data-test': PropTypes.string
}

ReverseAccordion.defaultProps = {
  maxRows: 2,
  numOfItems: 0,
  className: '',
  elementClassName: '',
  children: null,
  'data-test': 'component'
}

export default ReverseAccordion
