import styled, { css } from 'styled-components'
import { StarIcon, ChevronIcon } from '@sainsburys-tech/bolt-icons'
import { themeContract } from './styles.theme.contract'

export const Facet = styled.div(
  props => `
	border-top: 1px solid ${props.theme.color.monochrome.light};
	margin: 0;
	padding: 12px 15px;
	font-size: ${props.theme.font.size.root};
	
	@media (min-width: ${props.theme.size.breakpoint['min-width'].lg}) {
    padding: 15px 0;
  }
`
)
export const FacetHeader = styled.span`
  color: ${props => props.theme.color.palette['brand-1']};
`

export const ClearFacetButton = styled.button`
  position: relative;
  border: none;
  font-size: ${props => props.theme.font.size.caption};
  font-family: ${props => props.theme.font.family.base};
  text-align: left;
  background-color: transparent;
  text-decoration: underline;
  color: ${props => props.theme.color.palette['brand-1']};
  cursor: pointer;
  margin-top: 6px;
  padding: 6px 6px 6px 3px;
`

export const RatingLabel = styled.span`
  font-weight: ${props => props.theme.font.weight.regular};
`

export const RatingIcon = styled(StarIcon)`
  display: inline-block;
  height: 20px;
  position: relative;
  top: 2px;
  margin: 0 3px;
`

export const FiltersList = styled.ul`
  ::after {
    content: '';
    display: table;
    clear: both;
  }
  ${props =>
    props.type === 'size' &&
    css`
      display: flex;
      flex-wrap: wrap;
    `}
  ${props =>
    props.indented &&
    css`
      padding-left: 10px;
    `}
`

export const CategoryFilterText = styled.span`
	font-size: ${props => props.theme.font.stack['body-1']['font-size']};
  font-family: ${props => props.theme.font.family.base};
	display: flex;
	align-items: center;

	${props =>
    props.categorySelected &&
    css`
      font-weight: bold;
    `}

	${props =>
    props.descendantSelected &&
    css`
      font-weight: bold;
      margin-left: -5px;
    `}
	${props =>
    props.isLabel &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: auto;
      width: 100%;
      padding: 7px 25px 7px 0;
      cursor: pointer;
      margin-left: 3px;
    `}
`

export const FilterCount = styled.span`
  letter-spacing: 0.5px;
  flex-basis: auto;
  font-family: ${props => props.theme.font.family.base};

  ${props =>
    props.nonLeaf &&
    css`
      position: absolute;
      right: 0;
      top: 4px;
      font-size: ${props => props.theme.font.size.root};
    `}
`

export const Filter = styled.li`
	display: flex;
	align-items: center;

	${props =>
    props.type === 'size' &&
    css`
      display: inline-block;
      min-width: calc(33% - 10px);
      text-align: center;
      margin-right: 10px;
      border-radius: 3px;
      border: 1px solid ${props.theme.color.monochrome.dark};
      margin-bottom: 12px;
    `}

	${props =>
    props.type === 'category' &&
    css`
      :last-child {
        padding-bottom: 3px;
      }
    `}

	${props =>
    props.disabled &&
    css`
      background-color: $color-grey-light;
      color: $color-grey-mid;
      border-color: $color-grey-light-mid;
    `}
`

export const FilterLabel = styled.a`
	margin-right: 4px;
	flex: 1 1 0;
	line-height: 1.2em;

	${props =>
    props.type === 'size' &&
    css`
      display: flex;
      justify-content: center;
      padding: 0.5em;
      line-height: 1em;
      margin-right: 0;
      height: 100%;
      cursor: pointer;
    `}
	${props =>
    props.type === 'size' &&
    props.applied &&
    css`
      background-color: ${props.theme.color.palette[themeContract[props.theme.channel].filterLabel.backgroundColor]};
      color: ${props.theme.color.palette[themeContract[props.theme.channel].filterLabel.color]};
    `}

		${props =>
      props.type === 'category' &&
      css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: auto;
        width: 100%;
        padding: 7px 0;
        cursor: pointer;
        margin-left: 3px;
      `}

		${props =>
      props.type === 'category' &&
      props.applied &&
      css`
        cursor: default;
        font-weight: bold;
      `}
`
export const CategoryChevron = styled(ChevronIcon)`
  position: absolute;
  right: 0;
  top: 6px;
`

export const ClearCategoryButton = styled.button`
  font: inherit;
  border: inherit;
  appearance: none;
  background: transparent;
  padding: inherit;
`
