import { createSelector } from 'reselect'

export const searchTermSelector = state => state.application.searchTerm
export const pageTypeSelector = state => state.application.pageType
export const isSearchVariantSelector = state => state.application.isSearchVariant

const userDetailsSelector = state => state.userDetails
export const userStateSelector = createSelector(userDetailsSelector, userDetails => {
  return userDetails && userDetails.data ? userDetails.data.userState : ''
})
