import { connect } from 'react-redux'

import AvailabilityModal from '../../components/AvailabilityModal/AvailabilityModal'
import { resetAvailabilityError, setCollectionStoresError } from '../../redux/application/application'

const mapStateToProps = state => ({
  showLocalisationModal: state.application.availability.showLocalisationModal,
  localisationPostcode: state.application.availability.localisationPostcode,
  partNumber: state.product.products[0].id,
  pageType: state.application.pageType
})

const mapDispatchToProps = dispatch => ({
  resetAvailabilityError: () => {
    dispatch(resetAvailabilityError())
  },
  setCollectionStoresError: payload => {
    dispatch(setCollectionStoresError(payload))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityModal)
