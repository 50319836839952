import deepcopy from 'lodash.clonedeep'

// -------------------------------------------------
// CONSTANTS
// -------------------------------------------------
export const GET_NO_RESULTS_CONTENT = 'GET_NO_RESULTS_CONTENT'
export const GET_NO_RESULTS_CONTENT_SUCCESS = 'GET_NO_RESULTS_CONTENT_SUCCESS'
export const GET_NO_RESULTS_CONTENT_FAILURE = 'GET_NO_RESULTS_CONTENT_FAILURE'

// -------------------------------------------------
// ACTIONS
// -------------------------------------------------
export const getNoResultsContent = payload => ({ type: GET_NO_RESULTS_CONTENT, payload })
export const getNoResultsContentSuccess = payload => ({ type: GET_NO_RESULTS_CONTENT_SUCCESS, payload })
export const getNoResultsContentFailure = payload => ({ type: GET_NO_RESULTS_CONTENT_FAILURE, payload })

// -------------------------------------------------
// REDUCER
// -------------------------------------------------
const initialState = {
  data: {},
  error: null
}

export default (state = initialState, action) => {
  const newState = deepcopy(state)
  switch (action.type) {
    case GET_NO_RESULTS_CONTENT_SUCCESS:
      newState.error = null
      newState.data.categories = action.payload.response.data
        ? action.payload.response.data[0].relationships.children.data
        : []
      return newState
    case GET_NO_RESULTS_CONTENT_FAILURE:
      newState.data.categories = {}
      newState.error = true
      return newState
    default:
      return state
  }
}
