const optMatcher = new RegExp(/^(?:(?!^\/?opt\/)(.+?(?=\/opt\/|$)))?(?:\/opt\/)?(.*)$/i)
const PAGE_PARAM = 'page:'
const SORT_PARAM = 'sort:'
const SEARCH_TYPE_PARAM = 'type:'
const CATEGORY_MATCH = 'catmatch:'
const REFINEMENTS = 'refinements'

export default {
  getValue(match) {
    const matchValue = match.split(':')

    if (matchValue.length) {
      return matchValue.slice(1).join(':')
    }
  },

  getMatch(matches, param) {
    return matches.find(match => match && match.includes(param))
  },

  getOptParams(matches) {
    const matchesCopy = [...matches]

    // third capture group contains opt params
    const optParamsIndex = 2
    const paramString = matchesCopy[optParamsIndex]

    return paramString ? paramString.split('/') : []
  },

  normalizePath(path) {
    return path[0] === '/' ? path : `/${path}`
  },

  getParam(path, param) {
    if (!path) {
      return
    }

    const normalizedPath = this.normalizePath(path)
    const matches = optMatcher.exec(normalizedPath)

    if (!matches) {
      return
    }

    if (param === REFINEMENTS) {
      const matchesCopy = [...matches]
      return matchesCopy.splice(1, 1)[0]
    }

    const optParams = this.getOptParams(matches)

    const match = this.getMatch(optParams, param)
    return match ? this.getValue(match) : undefined
  },

  getPage(path) {
    return this.getParam(path, PAGE_PARAM)
  },

  getSort(path) {
    return this.getParam(path, SORT_PARAM)
  },

  getSearchType(path) {
    return this.getParam(path, SEARCH_TYPE_PARAM)
  },

  getCatMatch(path) {
    return this.getParam(path, CATEGORY_MATCH)
  },

  getRefinements(path) {
    const refinementParams = this.getParam(path, REFINEMENTS)

    if (!refinementParams) {
      return
    }

    const refinements = refinementParams
      .split('/')
      .filter(refinement => {
        return refinement
      })
      .reduce((previousValue, currentValue) => {
        const params = currentValue.split(':')

        previousValue.push({
          type: params[0],
          value: params[1]
        })

        return previousValue
      }, [])
    return refinements
  },

  getParams(path) {
    return {
      page: this.getPage(path),
      sort: this.getSort(path),
      refinements: this.getRefinements(path),
      searchType: this.getSearchType(path),
      categoryMatchOverride: this.getCatMatch(path)
    }
  }
}
