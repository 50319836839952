export default {
  header: `Oops, that didn't go to plan`,
  subHeader: `We can't seem to find that page right now...`,
  back: `Take me back`,
  backHome: `Take me back to the homepage`,
  contactUs: `Contact Us`,

  href: `/`,
  contactHref: `/help`
}
