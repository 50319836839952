import styled, { css } from 'styled-components'

export const Container = styled.div`
  @media only screen and (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    padding-left: 18px;
    padding-right: 6px;
    flex: 1;
    min-width: 0;
  }
`

export const PlpSeoText = styled.div`
  margin-bottom: 25px;
`

export const SearchContent = styled.div`
  ${props =>
    props.withBreadcrumb &&
    css`
      @media only screen and (min-width: ${props.theme.size.breakpoint['min-width'].md}) {
        padding-top: 10px;
      }
    `}
`
