import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { ErrorBoundary } from '@sainsburys-tech/bolt'
import { BrowseHelper } from '../../helpers/BrowseHelper'
import { FindabilityPagination } from './styles'

class Pagination extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    this.getPageUrl = this.getPageUrl.bind(this)
  }

  getPageUrl(pageNumber) {
    const {
      searchTerm,
      categoryId,
      appliedFilterUriFragments,
      appliedSortBy,
      searchType,
      categoryName,
      categoryAncestors,
      categoryIsClearance,
      isSaleRoute
    } = this.props
    const { isCannedSearch, categoryMatchOverride } = this.context.store.getState().product
    if (searchTerm) {
      return this.context.getSearchPath(searchTerm, {
        categoryId,
        filterUriFragments: appliedFilterUriFragments,
        sortBy: appliedSortBy,
        searchType,
        pageNumber,
        isCannedSearch,
        categoryMatch: categoryMatchOverride
      })
    }
    const params = {
      currentCatName: categoryName,
      ancestors: categoryAncestors,
      currentCatId: categoryId,
      filterUriFragments: appliedFilterUriFragments,
      sortBy: appliedSortBy,
      pageNumber,
      isClearance: categoryIsClearance,
      isSale: isSaleRoute
    }
    return this.context.getBrowsePath(BrowseHelper.buildCorrectUrlBrowseParams(params))
  }

  render() {
    const { currentPage, totalPages } = this.props
    const onPageClick = (event, pageNumber) => {
      event.preventDefault()
      window.scrollTo(0, 0)
      this.props.history.push(this.getPageUrl(pageNumber))
    }
    return (
      <ErrorBoundary>
        <FindabilityPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onClickFunction={onPageClick}
          getPageUrl={this.getPageUrl}
        />
      </ErrorBoundary>
    )
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
  categoryId: PropTypes.number,
  appliedFilterUriFragments: PropTypes.object,
  appliedSortBy: PropTypes.object,
  searchType: PropTypes.string,
  categoryAncestors: PropTypes.array,
  categoryName: PropTypes.string,
  categoryIsClearance: PropTypes.bool,
  isSaleRoute: PropTypes.bool,
  history: PropTypes.array.isRequired
}

Pagination.contextTypes = {
  getSearchPath: PropTypes.func,
  getStore: PropTypes.func,
  getBrowsePath: PropTypes.func,
  store: PropTypes.object
}

Pagination.defaultProps = {
  searchTerm: '',
  categoryId: null,
  appliedSortBy: {},
  appliedFilterUriFragments: {},
  searchType: '',
  categoryAncestors: [],
  categoryName: '',
  categoryIsClearance: false,
  isSaleRoute: false
}

export default withRouter(Pagination)
