import config, { searchTypes } from '../config'
import { handleRedirect } from '../redux/application/application'
import SEOHelper from './SEOHelper'
import { isSkuId, isStrictTucPrefixedTuSkuId, getProductPagePath } from './ProductHelper'

export default {
  searchTermIsSkuId(searchTerm) {
    const term = decodeURIComponent(searchTerm)
    return isSkuId(term) || isStrictTucPrefixedTuSkuId(term) // Argos accepting TU SKU with tuc and Habitat
  },

  shouldRedirect(productResponse) {
    let products

    if (productResponse) {
      products = productResponse.response && productResponse.response.data
    }

    return !!(products && products.length === 1)
  },

  redirectToProductPage(productId, context, trackingParams, statusCode = config.httpStatusCodes.permanentRedirect) {
    trackingParams = trackingParams || ''
    context.reduxStore.dispatch(
      handleRedirect({
        statusCode,
        isRelative: true,
        path: `${getProductPagePath(productId)}${trackingParams}`
      })
    )
  },

  redirectTo(redirectData, context, statusCode = config.httpStatusCodes.permanentRedirect) {
    redirectData.statusCode = statusCode
    context.reduxStore.dispatch(handleRedirect(redirectData))
  },

  // does term exist in a list of terms separated by line breaks
  isSearchTermInList(searchTerm, listOfTerms) {
    if (!searchTerm || !listOfTerms) {
      return false
    }
    return listOfTerms
      .toLowerCase()
      .trim()
      .split('\n')
      .map(term => term.trim())
      .includes(
        searchTerm
          .toLowerCase()
          .trim()
          .replace(/\s{2,}/g, ' ')
      )
  },

  noIndexSearch({
    searchTerm,
    searchType,
    pageNumber,
    filterArray,
    sortArray,
    canned,
    spellcheckedSearch,
    categoryMatchOverride,
    isSD
  }) {
    const sort = sortArray.length ? sortArray[0] : []
    let isNoIndex = false

    switch (true) {
      case !isSD:
      case canned || spellcheckedSearch:
      case pageNumber > 1:
      case searchType === searchTypes.didYouMean:
      case searchType === searchTypes.brandsNIC:
      case searchType === searchTypes.NRS:
      case searchType === searchTypes.spellChecked:
      case categoryMatchOverride === 'off':
      case Object.keys(sort)[0] !== 'relevance':
      case Object.keys(filterArray).length > 0:
      case isSD && !SEOHelper.seoExistsBySearchTerm(searchTerm):
        isNoIndex = true
        break
      default:
        break
    }
    return isNoIndex
  },

  getSuggestedTerm(productResponse) {
    if (!productResponse.response) return null

    const { meta } = productResponse.response

    if (meta.totalData === 0 && meta.suggestions && meta.suggestions.length > 0) {
      return meta.suggestions[0].text
    }

    return null
  },

  getBrandSuggestions(productResponse) {
    if (!productResponse.response) return null

    const { meta } = productResponse.response

    if (meta.totalData === 0 && meta.brandSuggestions && meta.brandSuggestions.length > 0) {
      return meta.brandSuggestions
    }

    return null
  },

  getCategorySuggestions(productResponse) {
    if (!productResponse.response) return null

    const { meta } = productResponse.response

    if (meta.totalData === 0 && meta.productCategorySuggestions && meta.productCategorySuggestions.length > 0) {
      return meta.productCategorySuggestions
    }

    return null
  }
}
