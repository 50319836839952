import styled, { css } from 'styled-components'
import { FilterPanelHeader, FilterPanelTitle, CloseButton } from '../FilterPanel/styles'

const CategoryPanel = styled.div`
  margin-bottom: 20px;
`
const CategoryHeader = styled(FilterPanelHeader)`
  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    display: none;
  }
`
const CategoriesContainer = styled.ul`
  border-top: 1px solid ${props => props.theme.color.monochrome.light};
  margin: 0;
  padding: 12px 15px;
  width: 100%;

  @media (min-width: ${props => props.theme.size.breakpoint['min-width'].lg}) {
    padding: 15px 0;
    overflow: hidden;
  }
`

const CategoryItemWrapper = styled.a`
  flex: 0 0 auto;
  position: relative;
  display: flex;
  align-items: left;
  cursor: pointer;
  font-size: ${props => props.theme.font.size.root};
  line-height: ${props => props.theme.font.stack['body-1']['line-height']};
  margin-left: 3px;
  padding: 7px 0;
  text-decoration: none;
  width: 100%;

  ${props =>
    props.active &&
    css`
      font-weight: bold;
    `}
  ${props =>
    props.isSubHeading &&
    css`
      flex: 0 0 auto;
      margin: 25px 0 0;
      padding: 0 20px;
      width: 100%;
      color: ${props.theme.color.monochrome.darker};
    `}
`

const CategoryItem = styled.span`
  color: ${props => props.theme.color.monochrome.darker};
  width: 100%;
`

const CategoryListHeader = styled.span`
  color: ${props => props.theme.color.palette['brand-1']};
`

export {
  CategoryPanel,
  CategoryHeader,
  FilterPanelTitle as CategoryTitle,
  CloseButton,
  CategoriesContainer,
  CategoryItemWrapper,
  CategoryItem,
  CategoryListHeader
}
