import styled from 'styled-components'
import { Link } from 'react-router-dom'

const BNICBanner = styled.div`
  display: block;
  color: ${props => props.theme.color.monochrome.dark};
`
const Links = styled.div`
  width: 100%;
  display: flex;
  color: ${props => props.theme.color.palette['brand-1']};
  text-decoration: underline;
  margin: 5px 0 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
`

const BannerLink = styled(Link)`
  padding: 5px 15px 5px 0;
  :last-child {
    padding-right: 0;
  }
`

export { BNICBanner, Links, BannerLink }
