/*
  Replaces _.get()
*/
export const get = (obj, path, def) => {
  if (obj === undefined) return undefined
  const newPath = typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.')
  return newPath
    .split('.')
    .filter(Boolean)
    .every(step => (obj = obj[step]) !== undefined) // eslint-disable-line
    ? obj
    : def
}

/*
  Replaces _.omit()
*/
export const omit = (data, keys) => {
  return Object.keys(data)
    .filter(key => [...keys].indexOf(key) < 0)
    .reduce((newObj, key) => Object.assign(newObj, { [key]: data[key] }), {})
}

/*
  Replaces _.pick()
*/
export const pick = (data, keys) => {
  return Object.keys(data)
    .filter(key => [...keys].indexOf(key) >= 0)
    .reduce((newObj, key) => Object.assign(newObj, { [key]: data[key] }), {})
}

export const isObjectEmpty = objectData => {
  if (!objectData) return true

  return objectData && Object.keys(objectData).length === 0 && objectData.constructor === Object
}
