import keyMirror from 'fbjs/lib/keyMirror'

export default keyMirror({
  SEARCH: null,
  SEARCH_START: null,
  SEARCH_FAILURE: null,
  SHOW_FILTER_MODAL: null,
  HIDE_FILTER_MODAL: null,
  FILTER_PANEL_STATIC: null,
  RESET_ALL_FILTERS: null,
  SELECT_FILTER: null,
  DESELECT_FILTER: null,
  DESELECT_FACET: null,
  TOGGLE_EMWBIS_MODAL: null
})
