import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useCookies } from 'react-cookie'

import * as Styled from './styles'
import { pushEventThenTrack } from '../../helpers/TaggingSerializer'
import { isCookieSet } from '../../helpers/CookieHelper'

const AvailabilityTooltip = ({ cookie, pageType }) => {
  const { cookieName, path, expires } = cookie

  const [isTooltipShown, setTooltip] = useState(true)

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies([cookieName])
  const isTooltipCookieSet = isCookieSet(cookieName, 'closed')

  const prefix = ['search', 'canned', 'sd'].includes(pageType) ? 'slp' : 'plp'

  useEffect(() => {
    if (isTooltipCookieSet) {
      setTooltip(false)
    } else {
      setCookie(cookieName, isTooltipShown ? 'open' : 'closed', { path, expires })

      // Track when tooltip shown
      pushEventThenTrack({
        eventInfo: {
          eventName: `${prefix}_displayLocalisationPrompt`,
          eventAction: `${prefix.toUpperCase()} Display Localisation Prompt`
        }
      })
    }
  }, [isTooltipCookieSet])

  const onCloseTooltip = () => {
    if (Object.keys(cookie).length) {
      setCookie(cookieName, 'closed', { path, expires })
    }

    // Track when tooltip is closed
    pushEventThenTrack({
      eventInfo: {
        eventName: `${prefix}_closeLocalisationPrompt`,
        eventAction: `${prefix.toUpperCase()} Close Localisation Prompt`
      }
    })
  }

  const tooltipProps = {
    width: 275,
    position: 'left',
    direction: 'down',
    content: (
      <Styled.Text>With your postcode added we can show you what is available for collection or delivery.</Styled.Text>
    ),
    durationToShow: 0,
    displayAfter: 2000,
    defaultDisplay: true,
    disableOutsideTouchHandler: true,
    disableScrollIntoViewport: true,
    onClose: onCloseTooltip,
    'data-test': 'availability-tooltip'
  }

  if (isTooltipShown) {
    return <Styled.AvailabilityTooltip {...tooltipProps} />
  }

  return null
}

AvailabilityTooltip.propTypes = {
  cookie: PropTypes.shape({
    cookieName: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    expires: PropTypes.string.isRequired
  }),
  pageType: PropTypes.string.isRequired
}

AvailabilityTooltip.defaultProps = {
  cookie: {}
}

export default AvailabilityTooltip
