import { useEffect, useState } from 'react'

/**
 * Creates a useScrollEffects hook
 * @param {number} safeZone - The vertical position beyond which you need to scroll before scroll effects will happen.
 * @param {boolean} reverse - If the behaviour should be reversed, i.e. it hides when scrolling down, not up.
 */
const useScrollEffects = (safeZone = 375, reverse = false) => {
  const [isHidden, setIsHidden] = useState(false)

  // useEffect here to prevent this running server side, and also to allow us to cleanup.
  useEffect(() => {
    // Close over a value for the previous scroll position. Putting this in React with useState would cause a re-render.
    let previousScrollPosition = 0
    // Close over a value for the current requestAnimationFrame ID.
    let currentRequestId = null

    // Define the event listener within the closure, to ensure it's referentially equal for the removal.
    const onScroll = () => {
      // If we're already awaiting a requestAnimationFrame, cancel that request
      if (currentRequestId) {
        window.cancelAnimationFrame(currentRequestId)
      }

      // Create a new requestAnimationFrame, and store it's request ID
      currentRequestId = window.requestAnimationFrame(() => {
        // Get the current scroll Y value from the window.
        const { scrollY } = window

        // Is the window scrolled beyond the safe zone?
        const isPastSafeZone = scrollY > safeZone
        // Is the new scroll value less than our previous one (we've scrolled up)
        const isScrollingUp = scrollY < previousScrollPosition
        // Is the new scroll value greater than our previous one (we've scrolled down)
        const isScrollingDown = scrollY > previousScrollPosition

        let shouldHide = false

        // If we're beyond the safe zone and we're scrolling up (or down, if reversed), we should hide the ref
        if (isPastSafeZone) {
          shouldHide = reverse ? isScrollingDown : isScrollingUp
        }

        // Update the state for if the element should be hidden
        setIsHidden(shouldHide)

        // Update the previous scroll position with the current one
        previousScrollPosition = scrollY
        // Clear the request ID, as we're finished
        currentRequestId = null
      })
    }

    // Add scroll event listener to window
    window.addEventListener('scroll', onScroll)
    // Return a function to cleanup the listener when the useEffect retriggers
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  // Return the isHidden boolean to be used by consumers
  return [isHidden]
}

export default useScrollEffects
