import { getBrowsePath } from '@sainsburys-tech/boltui-utils'
import { BrowseHelper } from './BrowseHelper'
import { getValue } from '../utils/ConfigProvider'

const canonicalBaseDomain = () => getValue('canonicalBase')

export default class HeadHelper {
  static getLinkTags(canonicalUrl, productStoreState, categoryStoreState, applicationStore) {
    const linkTags = [
      { rel: 'canonical', href: canonicalUrl },
      { rel: 'icon shortcut', href: '/favicon.ico' }
    ]

    const paginationLinkTags = this.getPaginationLinkTagsForPLP(productStoreState, categoryStoreState, applicationStore)

    if (paginationLinkTags.length) {
      linkTags.push.apply(linkTags, paginationLinkTags)
    }

    return linkTags
  }

  static getPaginationLinkTagsForPLP(productStoreState, categoryStoreState, applicationStore) {
    const linkTags = []
    const isPLP = categoryStoreState.isPlp || categoryStoreState.isPlpPlus

    if (!isPLP) return linkTags

    if (productStoreState.currentPage > 1) {
      linkTags.push({
        rel: 'prev',
        href: this.previousPLPLink(productStoreState, categoryStoreState, applicationStore)
      })
    }

    if (productStoreState.currentPage < productStoreState.meta.totalPages) {
      linkTags.push({
        rel: 'next',
        href: this.nextPLPLink(productStoreState, categoryStoreState, applicationStore)
      })
    }

    return linkTags
  }

  static previousPLPLink(productStoreState, categoryStoreState, applicationStore = {}) {
    return this.getPLPLink(productStoreState, categoryStoreState, productStoreState.currentPage - 1, applicationStore)
  }

  static nextPLPLink(productStoreState, categoryStoreState, applicationStore = {}) {
    return this.getPLPLink(productStoreState, categoryStoreState, productStoreState.currentPage + 1, applicationStore)
  }

  static getPLPLink(productStoreState, categoryStoreState, pageNumber, applicationStore = {}) {
    const { isClearance = false, isSaleRoute = false } = applicationStore

    const params = {
      currentCatName: categoryStoreState.categoryName,
      ancestors: categoryStoreState.ancestors,
      currentCatId: categoryStoreState.categoryId,
      filterUriFragments: productStoreState.appliedFilterUriFragments.allFilters,
      sortBy: productStoreState.appliedSortBy,
      pageNumber,
      isClearance,
      isSale: isSaleRoute
    }

    const browsePath = getBrowsePath(BrowseHelper.buildCorrectUrlBrowseParams(params))

    return `${canonicalBaseDomain()}${browsePath}`
  }
}
