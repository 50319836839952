/* eslint-disable id-length */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary, RectangleSkeleton, Button } from '@sainsburys-tech/bolt'
import { debounce } from '@sainsburys-tech/boltui-utils'

import Template from './ListerTools.template'
import AvailabilityModal from '../../containers/AvailabilityModal/AvailabilityModal'
import AvailabilityTooltip from '../AvailabilityTooltip/AvailabilityTooltip'
import EmailWhenBackInStockModal from '../../containers/EmailWhenBackInStockModal/EmailWhenBackInStockModal'
import { CATEGORY_FILTER_ID } from '../../helpers/CategoryHelper'
import { CIS_COOKIE, getAvailabilityRadius, formatMilesText } from '../../helpers/AvailabilityHelper'
import * as Styled from './styles'
import { getValue } from '../../utils/ConfigProvider'
import formatPostcode from '../../utils/formatPostcode'
import useScrollEffects from './useScrollEffects'
import useAsync from '../../hooks/useAsync'
import { availabilityIsEnabled, availabilityRadiusToggleIsEnabled } from '../../utils/availabilityIsEnabled'
import { stubIsEnabled } from '../../utils/stubIsEnabled'
import { pushEventThenTrack } from '../../helpers/TaggingSerializer'

const isCategoryOnlyFilter = appliedFilters => {
  return appliedFilters.length === 1 && appliedFilters[0].id === CATEGORY_FILTER_ID
}

const isFilterShowAllRating = filter =>
  filter.id === 'customer rating' && filter.values && filter.values.length > 0 && filter.values[0] === 'show all'

const getFilterButtonText = appliedFilters => {
  const isFilterShowAll = appliedFilters.some(filter => isFilterShowAllRating(filter))
  if (
    !appliedFilters.length ||
    isCategoryOnlyFilter(appliedFilters) ||
    (appliedFilters.length === 1 && isFilterShowAll)
  ) {
    return Template.filterBtn
  }
  return Template.editBtn
}

const getAppliedFilterCount = appliedFilters => {
  const filterCount = appliedFilters
    .filter(filter => filter.id !== 'category')
    .reduce((acc, cv) => {
      if (!isFilterShowAllRating(cv)) {
        return cv.values ? acc + cv.values.length : acc
      }
      return acc
    }, 0)
  return filterCount > 0 ? filterCount : ''
}

const getSortByObject = (value, sortByValues) => {
  const defaultSortByObject = sortByValues[0]

  if (!value) return defaultSortByObject
  const matchedSortObject = sortByValues.find(sortBy => sortBy.key === value)
  return matchedSortObject || defaultSortByObject
}

/**
 * @param {Object} sortBy i.e {Price: 'asc'}
 * @returns
 */
const getSelectedSortKey = (sortBy, sortByValues) => {
  if (sortBy) {
    const key = Object.keys(sortBy)[0]
    return getSortByObject(`${key}_${sortBy[key]}`, sortByValues).key
  }

  // default to first sort by key when no sort applied
  return sortByValues[0].key
}

const getOffset = selector => document?.querySelector(selector)?.offsetHeight

const fetchUserLocalisation = async (updateLocalisationPostcode, cisCookie) => {
  const hasSessionCookie = cisCookie || stubIsEnabled()
  if (!hasSessionCookie) return {}

  const url = `/stores/api/orchestrator/v0/cis-locator/availability/localisation?channel=web_plp`

  const response = await fetch(url, {
    headers: {
      'cache-control': 'no-store, must-revalidate, no-cache'
    }
  })

  if (response.ok) {
    const data = await response.json()

    if (data.deliverTo) {
      updateLocalisationPostcode(data.deliverTo)
      return data
    }

    return Promise.reject(new Error('User is not localised'))
  }

  // network errors etc
  return Promise.reject(response)
}

const ListerTools = ({
  sortByValues,
  totalData,
  appliedSortBy,
  appliedFilters,
  handleShowFilterModalClick,
  handleSortByChange,
  showLocalisationModal,
  toggleLocalisationModal,
  localisationPostcode,
  updateLocalisationPostcode,
  pageType,
  allCookies,
  location,
  isError,
  collectionStoresError
}) => {
  const OFFSET_SELECTOR = getValue('features.listerTools.offsetSelector')
  const SAFE_ZONE = getValue('features.listerTools.safeZone')
  const REVERSE_SCROLL = getValue('features.listerTools.reverseScrollBehaviour')
  const countIsSticky = !getValue('features.listerTools.hideMobileResultsCount')
  const availabilityTooltipEnabled = getValue('features.availabilityTooltip.enabled')
  const localisationHeaderEnabled = getValue('features.availability.hasLocalisationHeader')
  const emailMeBackModalEnabled = getValue('features.backInStock.enabled')

  const availabilityEnabled = availabilityIsEnabled({
    pageType,
    urlPath: location?.pathname
  })

  const hasLocalisationHeader = availabilityEnabled && localisationHeaderEnabled
  const hasEmailMeBackModal = availabilityEnabled && emailMeBackModalEnabled

  const cisCookie = allCookies?.[CIS_COOKIE]
  const localisationTooltipCookie = allCookies?.localisationTooltip

  const [offset, setOffset] = useState(0)
  const [isHidden] = useScrollEffects(SAFE_ZONE, REVERSE_SCROLL)

  useEffect(() => {
    // Define the event listener within the closure, to ensure it's referentially equal for the removal.
    const onResize = debounce(() => {
      const newOffset = getOffset(OFFSET_SELECTOR)
      if (newOffset !== offset) {
        setOffset(newOffset)
      }
    }, 300)
    // Add resize event listener to window
    window.addEventListener('resize', onResize)
    // Call the function immediately to set the initial offset
    onResize()
    // Return a function to cleanup the listener when the component unmounts
    return () => window.removeEventListener('resize', onResize)
  })

  const filterButtonText = getFilterButtonText(appliedFilters)
  const selectedSort = getSelectedSortKey(appliedSortBy, sortByValues)
  const sortByOptions = sortByValues.map(({ key, label }, index) => (
    <option key={index} value={key} label={label}>
      {label}
    </option>
  ))
  const filterCount = getAppliedFilterCount(appliedFilters)

  const onFilterButtonClick = () => {
    handleShowFilterModalClick()
    window.scrollTo(0, 0)
  }

  const resultsCountText = Template.pageCount(totalData)

  const onSortChange = selectedKey => {
    const sortBy = {}
    const { type, order } = getSortByObject(selectedKey, sortByValues).value

    sortBy[`${type}`] = order

    handleSortByChange(sortBy)
    window.scrollTo(0, 0)
  }

  const handleLocalisationModal = () => {
    toggleLocalisationModal(!showLocalisationModal)

    if (!showLocalisationModal) {
      const prefix = ['search', 'canned', 'sd'].includes(pageType) ? 'slp' : 'plp'

      // Track modal open
      pushEventThenTrack({
        eventInfo: {
          eventName: `${prefix}_initiateLocalisation`,
          eventAction: `${prefix.toUpperCase()} Initiate Localisation`
        },
        attributes: {
          clickOrigin: 'Add postcode',
          localisationPrompt: !!localisationTooltipCookie
        }
      })
    }
  }

  const { status, run } = useAsync()

  useEffect(() => {
    if (hasLocalisationHeader && !localisationPostcode) {
      run(fetchUserLocalisation(updateLocalisationPostcode, cisCookie))
    }
  }, [])

  return (
    <ErrorBoundary>
      <Styled.ListerTools hidden={isHidden} offset={offset} data-el='search-toolbar'>
        {hasLocalisationHeader && (
          <LocalisationHeader
            handleLocalisationModal={handleLocalisationModal}
            localisationPostcode={localisationPostcode}
            status={status}
            allCookies={allCookies}
            pageType={pageType}
            availabilityTooltipEnabled={availabilityTooltipEnabled}
          />
        )}

        <Styled.ResultsCount
          className={`${countIsSticky ? '' : 'xs-hidden'} md-block ${
            hasLocalisationHeader ? 'md-4--none lg-12--none' : 'md-12--none lg-6--none'
          }`}
          data-search-results={totalData}
          tabIndex='0'
          availabilityEnabled={hasLocalisationHeader}>
          {resultsCountText}
        </Styled.ResultsCount>

        <Styled.FilterButton
          className='xs-6--none lg-hidden'
          onClick={onFilterButtonClick}
          data-el='edit-filters'
          availabilityEnabled={hasLocalisationHeader}>
          <Styled.FilterButtonLabel data-test='filter-button__label'>{filterButtonText}</Styled.FilterButtonLabel>
          <Styled.FilterButtonCount data-search-results={filterCount}>
            {filterCount > 0 ? `(${filterCount})` : ''}
          </Styled.FilterButtonCount>
          <Styled.FilterButtonIcon width='1.5em' height='1.5em' fill='currentColor' />
        </Styled.FilterButton>

        <Styled.SortPanel
          htmlFor='sort-select'
          className='xs-6--none lg-4--none'
          availabilityEnabled={hasLocalisationHeader}>
          <Styled.SortBy className='lg-2--none' id='sort-select-label'>
            {Template.sortBy}
          </Styled.SortBy>
          <Styled.SortSelectWrapper>
            <Styled.SortIcon width='1.5em' height='1.5em' fill='currentColor' dir='down' />
            <Styled.SortSelect
              id='sort-select'
              aria-labelledby='sort-select-label'
              value={selectedSort}
              data-el='sort-select'
              onChange={e => onSortChange(e.target.value)}>
              {sortByOptions}
            </Styled.SortSelect>
          </Styled.SortSelectWrapper>
        </Styled.SortPanel>
      </Styled.ListerTools>

      {availabilityEnabled && isError ? (
        <Styled.AvailabilityErrorAlertContainer>
          <Styled.AvailabilityErrorAlert kind='error' isCloseable={false}>
            {Template.availabilityErrorMessage}
          </Styled.AvailabilityErrorAlert>
        </Styled.AvailabilityErrorAlertContainer>
      ) : null}

      {availabilityEnabled && !isError && collectionStoresError ? (
        <Styled.AvailabilityErrorAlertContainer>
          <Styled.AvailabilityErrorAlert kind='error' isCloseable={false}>
            {Template.availabilityNoCollectionStoresMessage}
          </Styled.AvailabilityErrorAlert>
        </Styled.AvailabilityErrorAlertContainer>
      ) : null}

      <div>
        {hasLocalisationHeader && (
          <AvailabilityModal
            handleLocalisationModal={handleLocalisationModal}
            fetchUserLocalisation={() => run(fetchUserLocalisation(updateLocalisationPostcode, cisCookie))}
            availabilityTooltipEnabled={availabilityTooltipEnabled}
            tooltipCookie={Template.availabilityTooltip.cookie}
            availabilityRadiusToggleIsEnabled={availabilityRadiusToggleIsEnabled}
          />
        )}
      </div>

      {hasEmailMeBackModal && <EmailWhenBackInStockModal />}
    </ErrorBoundary>
  )
}

const LocalisationHeader = ({
  handleLocalisationModal,
  localisationPostcode,
  status,
  pageType,
  availabilityTooltipEnabled
}) => {
  const radius = getAvailabilityRadius()

  if ((!localisationPostcode && status === 'idle') || status === 'pending') {
    return (
      <Styled.LoadingLocalisation className='xs-block xs-12--none md-8--none lg-8--none'>
        <RectangleSkeleton width={276} height={24} />
      </Styled.LoadingLocalisation>
    )
  }

  return (
    <Styled.Localisation className='xs-block xs-12--none md-8--none lg-8--none'>
      <Styled.LocalisationHeaderWrapper>
        {/* user is localised */}
        {localisationPostcode && (
          <>
            <Styled.LocalisationIcon />
            <div>
              <Styled.LocalisationText>{Template.localisedText}</Styled.LocalisationText>
              <Button kind='link' data-test='ao-localised' onClick={handleLocalisationModal}>
                {`${formatPostcode(localisationPostcode)}${
                  availabilityRadiusToggleIsEnabled ? ` within ${radius} ${formatMilesText(radius)}` : ''
                }`}
              </Button>
            </div>
          </>
        )}
        {/* user is unlocalised */}
        {!localisationPostcode && (
          <>
            <Styled.LocalisationContainer>
              <Styled.LocalisationIcon />
              <Button kind='link' data-test='ao-add-postcode' onClick={handleLocalisationModal}>
                {Template.unlocalisedBtn}
              </Button>
              {availabilityTooltipEnabled && (
                <AvailabilityTooltip cookie={Template.availabilityTooltip.cookie} pageType={pageType} />
              )}
            </Styled.LocalisationContainer>
            <Styled.LocalisationText>{Template.unlocalisedText}</Styled.LocalisationText>
          </>
        )}
      </Styled.LocalisationHeaderWrapper>
    </Styled.Localisation>
  )
}

ListerTools.propTypes = {
  appliedSortBy: PropTypes.object,
  totalData: PropTypes.number.isRequired,
  sortByValues: PropTypes.array.isRequired,
  appliedFilters: PropTypes.array.isRequired,
  handleShowFilterModalClick: PropTypes.func,
  handleSortByChange: PropTypes.func,
  showLocalisationModal: PropTypes.bool,
  toggleLocalisationModal: PropTypes.func,
  updateLocalisationPostcode: PropTypes.func,
  localisationPostcode: PropTypes.string,
  pageType: PropTypes.string.isRequired,
  allCookies: PropTypes.object,
  location: PropTypes.object.isRequired,
  isError: PropTypes.string.isRequired,
  collectionStoresError: PropTypes.bool.isRequired
}

ListerTools.defaultProps = {
  appliedSortBy: {},
  handleShowFilterModalClick: () => {},
  handleSortByChange: () => {},
  showLocalisationModal: false,
  toggleLocalisationModal: () => {},
  updateLocalisationPostcode: () => {},
  localisationPostcode: null,
  allCookies: {}
}

LocalisationHeader.propTypes = {
  handleLocalisationModal: PropTypes.func,
  localisationPostcode: PropTypes.string,
  availabilityTooltipEnabled: PropTypes.bool,
  status: PropTypes.oneOf(['idle', 'pending', 'resolved', 'rejected']).isRequired,
  pageType: PropTypes.string.isRequired
}

LocalisationHeader.defaultProps = {
  handleLocalisationModal: () => {},
  localisationPostcode: null,
  availabilityTooltipEnabled: false
}

export default ListerTools
