import config from '../config'

export class ProductRequestHelper {
  static buildQueryParams(
    category,
    page,
    sort,
    refinements,
    queryParams,
    categoryResponse = {},
    templateType = null,
    isClearance = false,
    isBrowse = false
  ) {
    if (category) {
      if (this.isCategoryMultiselect(categoryResponse)) {
        queryParams = this.getMultiselectCategories(categoryResponse.attributes.pimid, queryParams)
      } else {
        queryParams['filter[category]'] = category
      }
    }
    if (isClearance) {
      queryParams['filter[clearance]'] = `${isClearance}`
    }
    if (page) {
      queryParams.page = page
    }
    queryParams.templateType = templateType
    const sortValue = this.getSort(sort, category, isBrowse, isClearance)

    if (sortValue) {
      queryParams[`sort[${sortValue.type}]`] = sortValue.order
    }

    queryParams = { ...queryParams, ...this.getRefinements(refinements) }

    return queryParams
  }

  static getMultiselectCategories(pimIds = [], queryParams) {
    queryParams['filter[category]'] = pimIds.join(',')
    return queryParams
  }

  static isCategoryMultiselect(categoryResponse) {
    return (
      !!categoryResponse &&
      !!categoryResponse.attributes &&
      !!categoryResponse.attributes.pimid &&
      categoryResponse.attributes.pimid.length > 0
    )
  }

  static getRefinements(refinements) {
    if (!refinements || !Object.keys(refinements).length) {
      return {}
    }

    return refinements.reduce((params, refinement) => {
      const { type, value } = refinement
      params[`filter[${type}]`] = value
      return params
    }, [])
  }

  static getSort(sort, category, isBrowse, isClearance) {
    const sortByValues = isBrowse ? config.browseSortByValues(category, isClearance) : config.searchSortByValues
    const selectedSortBy = sortByValues.find(sortBy => sortBy.path === `sort:${sort}`)
    return selectedSortBy ? selectedSortBy.value : null
  }
}
