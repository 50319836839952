import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'

import { showFilterModal } from '../../redux/product/product'
import sortBy from '../../actions/sortBy'
import ListerTools from '../../components/ListerTools/ListerTools'
import { toggleLocalisationModal, updateLocalisationPostcode } from '../../redux/application/application'

const mapStateToProps = state => ({
  pageType: state.application.pageType,
  templateType: state.category.templateType,
  isClearance: state.application.isClearance,
  showLocalisationModal: state.application.availability.showLocalisationModal,
  localisationPostcode: state.application.availability.localisationPostcode,
  isError: state.application.availability.availabilityError.isError,
  collectionStoresError: state.application.availability.collectionStoresError
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleShowFilterModalClick: () => {
    dispatch(showFilterModal())
  },
  handleSortByChange: sortByValue => {
    dispatch(sortBy(sortByValue, ownProps.history))
  },
  toggleLocalisationModal: payload => {
    dispatch(toggleLocalisationModal(payload))
  },
  updateLocalisationPostcode: payload => {
    dispatch(updateLocalisationPostcode(payload))
  }
})

// This needs the Router props, specifically `history` to use in `sortBy`.
// This could be avoided with react-router-redux.
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withCookies(ListerTools)))
