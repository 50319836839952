import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ListWrapper, ListWrapperItem, Pill } from './styles'
import { getBrand } from '../../utils/ConfigProvider'

const FilterPills = ({
  pills,
  onActivate,
  onDeactivate,
  'data-test': dataTest,
  className,
  itemClassName,
  pillClassName,
  setClickOrigin
}) => {
  const [_prevPills, _setPills] = useState(pills)
  const isTu = getBrand() === 'tu'

  useEffect(() => {
    if (pills !== _prevPills) {
      _setPills(pills)
    }
  }, [pills])

  const onClickHandler = (pill, index, event) => {
    isTu && setClickOrigin('filter-size-pill')
    toggle(pill, index, event)
  }

  const toggle = (pill, index, event) => {
    event.preventDefault()
    event.stopPropagation()

    const updatedPills = _prevPills.map((oldPill, oldPillIndex) => {
      return index === oldPillIndex ? { ...oldPill, active: !oldPill.active } : oldPill
    })
    _setPills(updatedPills)

    pill?.active ? onDeactivate(pill) : onActivate(pill)
  }

  const containerClassName = `filter-pill-wrapper ${className}`
  const containerItemClassName = `filter-pill--wrapper-item ${itemClassName}`

  return (
    <ListWrapper className={containerClassName} data-test={`${dataTest}-wrapper`}>
      {_prevPills.map((pill, index) => {
        const { label, active, disabled } = pill
        const isDisabled = disabled || false
        const isActiveClass = active ? 'is-active' : ''

        return (
          <ListWrapperItem
            key={`${dataTest}-wrapper-item-${index}`}
            className={containerItemClassName}
            data-test={`${dataTest}-wrapper-item-${index}`}>
            <Pill
              label={label}
              active={active}
              disabled={isDisabled}
              onClick={event => !isDisabled && onClickHandler(pill, index, event)}
              data-test={`${dataTest}-pill-${index}`}
              className={`${isActiveClass} ${pillClassName}`}
            />
          </ListWrapperItem>
        )
      })}
    </ListWrapper>
  )
}

FilterPills.propTypes = {
  /**
   * Specify the content of each Pill inside the Pills
   */
  pills: PropTypes.arrayOf(
    PropTypes.shape(
      {
        label: PropTypes.node,
        active: PropTypes.bool,
        disabled: PropTypes.bool
      } || undefined
    )
  ),

  /**
   * Optional prop to provide a callback function which is called when a Pill is activated
   */
  onActivate: PropTypes.func,

  /**
   * Optional prop to provide a callback function which is called when a Pill is deactivated
   */
  onDeactivate: PropTypes.func,

  /**
   * Specify an optional data-test attribute to be added to the FilterPills
   */
  'data-test': PropTypes.string,

  /**
   * Specify an optional className to be added to the FilterPills container
   */
  className: PropTypes.string,

  /**
   * Specify an optional className to be added to each Pill wrapper component
   */
  itemClassName: PropTypes.string,

  /**
   * Specify an optional className to be added to each child Pill component
   */
  pillClassName: PropTypes.string,

  /**
   * Optional functional prop to set the click origin application state for analytical purpose
   */

  setClickOrigin: PropTypes.func
}

FilterPills.defaultProps = {
  pills: [],
  onActivate: () => {},
  onDeactivate: () => {},
  'data-test': 'component-filter-pills',
  className: '',
  itemClassName: '',
  pillClassName: '',
  setClickOrigin: () => {}
}

export default FilterPills
