import React from 'react'
import PropTypes from 'prop-types'
import ListerPage from '../containers/ListerPage/ListerPage'
import executeBrowse from '../actions/executeBrowse'
import formatActionPayload from '../helpers/RoutingHelper'

class Clearance extends React.Component {
  static fetchData(routeMatch, url, store, fetchr) {
    const context = {
      reduxStore: store,
      service: fetchr
    }
    const isSaleRoute = routeMatch?.url?.startsWith('/sale')

    let payload = formatActionPayload(routeMatch, url)
    payload = {
      ...payload,
      isClearance: true,
      isSaleRoute
    }

    return new Promise(resolve => {
      executeBrowse(context, payload, resolve)
    })
  }

  render() {
    return <ListerPage />
  }
}

Clearance.contextTypes = {
  fetchr: PropTypes.object
}

export default Clearance
