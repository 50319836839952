import deepcopy from 'lodash.clonedeep'
import { initialState, getContentAreas } from '.'

// -------------------------------------------------
// CONSTANTS
// -------------------------------------------------
export const GET_CONTENT = 'GET_CONTENT'
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS'
export const GET_CONTENT_FAILURE = 'GET_CONTENT_FAILURE'

// -------------------------------------------------
// ACTIONS
// -------------------------------------------------
export const getContent = payload => ({ type: GET_CONTENT, payload })
export const getContentSuccess = payload => ({ type: GET_CONTENT_SUCCESS, payload })
export const getContentFailure = payload => ({ type: GET_CONTENT_FAILURE, payload })

// -------------------------------------------------
// REDUCER
// -------------------------------------------------
export default (state = initialState, action) => {
  const newState = deepcopy(state)
  switch (action.type) {
    case GET_CONTENT_SUCCESS:
      newState.error = null
      newState.data.contentAreas = getContentAreas(action.payload.response.data)
      newState.data.similarAiLinks = action.payload.similarAiLinks?.default
      return newState
    case GET_CONTENT_FAILURE:
      newState.data.contentAreas = []
      newState.error = true
      return newState
    default:
      return state
  }
}
