import { getSearchPath, getBrowsePath } from '@sainsburys-tech/boltui-utils'
import { loadingStart } from '../redux/application/application'
import Logger from '../utils/Logger'
import { BrowseHelper } from '../helpers/BrowseHelper'
import { getSearchTermForSearchType, getSearchType } from '../helpers/ProductHelper'
import { getUriFragmentForFilterId } from '../redux/product'
import { selectFilter } from '../redux/product/product'

const logger = new Logger()

export default function(context, payload, ...args) {
  logger.debug('functionLogger', { args }, 'addFilter')

  const productStore = context.reduxStore.getState().product
  const categoryStore = context.reduxStore.getState().category
  const applicationStore = context.reduxStore.getState().application
  const { appliedSortBy, appliedFilterUriFragments, filters, isCannedSearch } = productStore
  const filtersToAdd = getUriFragmentForFilterId(payload.filterId, payload.facetId, filters)
  const searchTerm = getSearchTermForSearchType(productStore)
  const filterUriFragments = appliedFilterUriFragments.allFilters
  let filterId = ''

  if (filtersToAdd && filtersToAdd.length > 0) {
    if (filtersToAdd[0].includes('filter')) {
      filterId = filtersToAdd[0].split(`filter=${payload.facetId}:`)[1] //eslint-disable-line
    }
  }

  if (filterId !== '') {
    if (
      filtersToAdd !== 'attributeFacets=true' &&
      filterUriFragments[payload.facetId] &&
      !filterUriFragments[payload.facetId].includes(filterId)
    ) {
      filterUriFragments[payload.facetId].push(filterId)
    } else {
      filterUriFragments[payload.facetId] = [filterId]
    }

    // Track order filters are applied
    context.reduxStore.dispatch(selectFilter(payload))
  }

  context.reduxStore.dispatch(loadingStart())

  if (searchTerm) {
    const { categoryFilterId: categoryId, categoryMatchOverride } = productStore
    const url = getSearchPath(searchTerm, {
      categoryId,
      filterUriFragments,
      sortBy: appliedSortBy,
      searchType: getSearchType(productStore),
      isCannedSearch,
      categoryMatch: categoryMatchOverride
    })

    context.history.push(url)

    return
  }

  const { ancestors, categoryName, categoryId } = categoryStore
  const { isClearance, isSaleRoute } = applicationStore
  const params = {
    currentCatName: categoryName,
    ancestors,
    currentCatId: categoryId,
    filterUriFragments,
    sortBy: appliedSortBy,
    isClearance,
    isSale: isSaleRoute
  }

  context.history.push(getBrowsePath(BrowseHelper.buildCorrectUrlBrowseParams(params)))
}
