import { CategoryHelper } from '../../helpers/CategoryHelper'

export default {
  title404: `404 Not Found`,
  title500: `500 Internal Server error`,
  searchDelivery: (term, brand) => {
    if (brand === 'habitat') {
      return `${CategoryHelper.formatBrandName(term)} at ${CategoryHelper.formatBrandName(
        brand
      )}. Same Day delivery 7 days a week, or fast store collection.`
    }
    if (brand === 'tu') {
      return `Shop ${CategoryHelper.formatBrandName(
        brand
      )} online. Free in-store Click & Collect or 3-5 day/next day Home Delivery on all orders.`
    }
    return `Get set for ${term} at ${CategoryHelper.formatBrandName(
      brand
    )}. Same Day delivery 7 days a week, or fast store collection.`
  },
  mobileResultTitle: brand => `${CategoryHelper.formatBrandName(brand)} Mobile Search Results`,
  searchResult: term => `Results for ${term}`
}
