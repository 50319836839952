import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from '@sainsburys-tech/bolt'
import ErrorModal from '../ErrorModal/ErrorModal'
import NoResultsModal from '../../containers/NoResultsModal/NoResultsModal'

const Modals = ({ noResultsModal, errorModalReason, displayErrorModal }) => (
  <ErrorBoundary>
    <>
      {noResultsModal && <NoResultsModal data-test='no-results-modal' />}
      {displayErrorModal && <ErrorModal data-test='error-modal' errorModalReason={errorModalReason} />}
    </>
  </ErrorBoundary>
)

Modals.propTypes = {
  displayErrorModal: PropTypes.bool,
  errorModalReason: PropTypes.string,
  noResultsModal: PropTypes.bool
}

Modals.defaultProps = {
  displayErrorModal: false,
  errorModalReason: '',
  noResultsModal: false
}

export default Modals
