/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary, GoInStoreWidget } from '@sainsburys-tech/bolt'
import ListerMeta from '../ListerTools/ListerMeta'
import ProductList from '../../containers/ProductList/ProductList'
import Pagination from '../Pagination/Pagination'
import Feedback from '../Feedback/Feedback'
import ListerTools from '../../containers/ListerTools/ListerTools'
import SpellcheckBanner from '../SpellcheckBanner/SpellcheckBanner'
import config, { searchTypes } from '../../config'
import FilterPanel from '../../containers/FilterPanel/FilterPanel'
import FilterPillsConfigWrapper from '../FilterPillsConfigWrapper/FilterPillsConfigWrapper'
import ListerTitle from '../ListerTitle/ListerTitle'
import ProductListRelevancy from '../ProductList/ProductListRelevancy'
import { getContentAreas } from '../../helpers/ProductListerContentHelper'
import ContentHelper from '../../helpers/ContentHelper'
import { getCustomRefinedTitle } from '../../helpers/RefinementHelper'
import BrandsNICBanner from '../BrandsNICBanner/BrandsNICBanner'
import ErrorPage from '../ErrorPage/ErrorPage'
import NoResultsSuggestions from '../../containers/NoResultsSuggestions/NoResultsSuggestions'
import CitrusBanners from '../CitrusBanners/CitrusBanners'
import { getValue } from '../../utils/ConfigProvider'

import * as Styled from './styles'

import addFilter from '../../actions/addFilter'
import removeFilter from '../../actions/removeFilter'
import SimilarAiPills from '../SimilarAiPills/SimilarAiPills'

class Lister extends React.PureComponent {
  categoryUnmatchCallback = () => {
    this.context.navigateTo(`${window.location.pathname}opt/catmatch:off/`)
  }

  hasProducts(products) {
    return products && (products[0]?.length > 0 || products[1]?.length > 0)
  }

  render() {
    const {
      searchTerm,
      products,
      isCannedSearch,
      searchType,
      appliedSortBy,
      categoryId,
      meta,
      pageType,
      categoryName,
      results,
      noResultsModalVisible,
      spellcheckedSearch,
      inputSearchTerm,
      searchTermSuggestions,
      brandSuggestions,
      contentAreas,
      topLevelCategoryId,
      filters,
      appliedFilters,
      appliedFilterUriFragments,
      seoArea,
      isPlp,
      isPlpPlus,
      isPlpPlusDescendant,
      categoryNameFromFilter,
      isClearance,
      isSaleRoute,
      categoryAncestors,
      subHeadingAncestorId,
      setClickOrigin,
      similarAiLinks,
      urlRefinements
    } = this.props

    const isGoInStoreWidgetEnabled = getValue('features.goInStoreWidget.enabled')
    const isCitrusBannersEnabled = getValue('features.citrusBanners.enabled')
    const sortByValues =
      pageType === 'browse' ? config.browseSortByValues(categoryId, isClearance) : config.searchSortByValues
    const noResults = results === 0 && !noResultsModalVisible
    const validBreadcrumb =
      categoryAncestors.length > 0 &&
      !meta.categoryMatch &&
      searchType !== searchTypes.brandsNIC &&
      searchType !== searchTypes.NRS
    const hasBrandSuggestions = !!brandSuggestions && brandSuggestions.length > 0

    const allProducts = [].concat(products[0], products[1])
    const enableCommunicatingRelevancy =
      pageType !== 'browse' &&
      appliedSortBy &&
      appliedSortBy.relevance === 'desc' &&
      (appliedFilters.length === 0 || meta.categoryMatch) &&
      !getValue('features.productList.disableComRel')
    const hasRelevancyBucketOne = products[0].length > 0 && products[0].length < 30
    const hasRelevancyBucketTwo = products[1].length > 0

    const customListerTitle = getCustomRefinedTitle(categoryId, urlRefinements)

    let banner
    if (hasBrandSuggestions && brandSuggestions.length > 1 && !isCannedSearch) {
      banner = (
        <BrandsNICBanner
          inputSearchTerm={inputSearchTerm}
          brandSuggestions={brandSuggestions}
          searchType={searchType}
        />
      )
    } else if (!(searchType !== searchTypes.NRS && meta.categoryMatch)) {
      banner = (
        <SpellcheckBanner
          didYouMeanSearch={searchType === searchTypes.didYouMean}
          spellcheckedSearch={spellcheckedSearch}
          inputSearchTerm={inputSearchTerm}
          searchTerm={searchTerm}
          searchTermSuggestions={searchTermSuggestions}
        />
      )
    }

    const showFilterPills = getValue('features.filterPills.enabled')
    const filterPillsComponent = showFilterPills ? (
      <FilterPillsConfigWrapper
        pageType={pageType}
        filters={filters}
        onActivate={(pill, pillFilterType) => {
          addFilter(this.context, { filterId: pill.id, facetId: pillFilterType })
        }}
        onDeactivate={(pill, pillFilterType) => {
          removeFilter(this.context, { filterId: pill.id, facetId: pillFilterType })
        }}
        setClickOrigin={setClickOrigin}
      />
    ) : null

    const showSEOArea = !noResults && !searchTerm

    const getAncestor = () => {
      const clpCategoryArray = categoryAncestors?.filter(obj => obj.type === 'CLP')
      return clpCategoryArray.map(clpCategory => clpCategory.attributes?.name.toLowerCase())
    }
    const clpAncestors = getAncestor()
    const allCategories = [...clpAncestors, categoryName?.toLowerCase()]
    return (
      <ErrorBoundary>
        <div className='search'>
          {(noResults || !this.hasProducts(products)) && !searchTerm && <ErrorPage />}
          {(noResults || !this.hasProducts(products)) && searchTerm && <NoResultsSuggestions searchTerm={searchTerm} />}
          {this.hasProducts(products) && (
            <Styled.SearchContent className='xs-row xs-auto--negative lg-auto--none' withBreadcrumb={validBreadcrumb}>
              <ErrorBoundary>
                <FilterPanel
                  brandSuggestions={brandSuggestions}
                  hasBrandSuggestions={hasBrandSuggestions}
                  searchType={searchType}
                  categoryMatch={meta.categoryMatch}
                />
              </ErrorBoundary>
              <Styled.Container className='xs-12--none lg-9--none xs-stack'>
                {!noResults &&
                  (enableCommunicatingRelevancy ? (
                    <ErrorBoundary
                      key={hasRelevancyBucketOne && hasRelevancyBucketTwo ? 'product-list-2' : 'product-list-1'}>
                      {products[0].length > 0 && (
                        <>
                          <ListerTitle
                            isCannedSearch={isCannedSearch}
                            inputSearchTerm={inputSearchTerm}
                            searchTerm={searchTerm}
                            categoryName={pageType === 'search' ? categoryNameFromFilter : categoryName}
                            hasBrandSuggestions={hasBrandSuggestions}
                            brandSuggestions={brandSuggestions}
                            spellcheckedSearch={spellcheckedSearch}
                            fallBackCategoryName={categoryName}
                            searchType={searchType}
                            categoryUnmatchCallback={this.categoryUnmatchCallback}
                            pageType={pageType}
                            isClearance={isClearance}
                            isSaleRoute={isSaleRoute}
                            enableCommunicatingRelevancy={enableCommunicatingRelevancy}
                            customListerTitle={customListerTitle}
                          />
                          {!!banner && <ErrorBoundary>{banner}</ErrorBoundary>}
                          {!noResults &&
                            getContentAreas(
                              contentAreas,
                              topLevelCategoryId,
                              ContentHelper.onFindabilityClick.bind(this),
                              isPlp,
                              isPlpPlus,
                              isPlpPlusDescendant
                            )}
                          {filterPillsComponent}
                          <ListerTools
                            appliedSortBy={appliedSortBy}
                            appliedFilters={appliedFilters}
                            totalData={meta.totalData}
                            categoryMatch={meta.categoryMatch}
                            sortByValues={sortByValues}
                            searchTerm={searchTerm}
                          />
                          <ProductList
                            products={products[0]}
                            currentPage={meta.currentPage}
                            pageSize={meta.pageSize}
                            totalPages={meta.totalPages}
                            pageType={pageType}
                            categoryAncestors={categoryAncestors}
                            categoryName={categoryName}
                            isClearance={isClearance}
                            isCannedSearch={isCannedSearch}
                            appliedFilters={appliedFilters}
                          />
                        </>
                      )}
                      {hasRelevancyBucketTwo && hasRelevancyBucketOne && (
                        <ProductListRelevancy inputSearchTerm={inputSearchTerm} products={products} />
                      )}
                      {products[1].length > 1 && !hasRelevancyBucketOne && (
                        <>
                          <ProductListRelevancy inputSearchTerm={inputSearchTerm} products={products} />
                          <ListerTools
                            appliedSortBy={appliedSortBy}
                            appliedFilters={appliedFilters}
                            totalData={meta.totalData}
                            categoryMatch={meta.categoryMatch}
                            sortByValues={sortByValues}
                            searchTerm={searchTerm}
                          />
                          {filterPillsComponent}
                        </>
                      )}
                      <ProductList
                        products={products[1]}
                        relavancyRecallOffset={products[0].length}
                        pageType={pageType}
                        categoryName={categoryName}
                        categoryAncestors={categoryAncestors}
                        isClearance={isClearance}
                        isCannedSearch={isCannedSearch}
                        appliedFilters={appliedFilters}
                      />
                    </ErrorBoundary>
                  ) : (
                    <>
                      <ErrorBoundary>
                        <ErrorBoundary>
                          <ListerTitle
                            isCannedSearch={isCannedSearch}
                            inputSearchTerm={inputSearchTerm}
                            searchTerm={searchTerm}
                            categoryName={pageType === 'search' ? categoryNameFromFilter : categoryName}
                            hasBrandSuggestions={hasBrandSuggestions}
                            brandSuggestions={brandSuggestions}
                            spellcheckedSearch={spellcheckedSearch}
                            fallBackCategoryName={categoryName}
                            searchType={searchType}
                            categoryUnmatchCallback={this.categoryUnmatchCallback}
                            pageType={pageType}
                            isClearance={isClearance}
                            isSaleRoute={isSaleRoute}
                            enableCommunicatingRelevancy={enableCommunicatingRelevancy}
                            categoryAncestors={categoryAncestors}
                            subHeadingAncestorId={subHeadingAncestorId}
                            filters={filters}
                            appliedFilters={appliedFilters}
                            customListerTitle={customListerTitle}
                          />
                          {!!banner && <ErrorBoundary>{banner}</ErrorBoundary>}
                          {!noResults &&
                            getContentAreas(
                              contentAreas,
                              topLevelCategoryId,
                              ContentHelper.onFindabilityClick.bind(this),
                              isPlp,
                              isPlpPlus,
                              isPlpPlusDescendant
                            )}
                          {filterPillsComponent}
                        </ErrorBoundary>
                        <ListerTools
                          appliedSortBy={appliedSortBy}
                          appliedFilters={appliedFilters}
                          totalData={meta.totalData}
                          categoryMatch={meta.categoryMatch}
                          sortByValues={sortByValues}
                          searchTerm={searchTerm}
                        />
                      </ErrorBoundary>

                      <ErrorBoundary>
                        <ProductList
                          products={allProducts}
                          pageType={pageType}
                          categoryName={categoryName}
                          categoryAncestors={categoryAncestors}
                          isClearance={isClearance}
                          isCannedSearch={isCannedSearch}
                          appliedFilters={appliedFilters}
                        />
                      </ErrorBoundary>
                    </>
                  ))}

                {isGoInStoreWidgetEnabled && (
                  <ErrorBoundary>
                    <GoInStoreWidget hideMobile />
                  </ErrorBoundary>
                )}

                {!noResults && (
                  <>
                    <ErrorBoundary>
                      <Pagination
                        appliedFilterUriFragments={appliedFilterUriFragments.allFilters}
                        appliedSortBy={appliedSortBy}
                        searchTerm={
                          searchType === searchTypes.brandsNIC || searchType === searchTypes.NRS
                            ? inputSearchTerm
                            : searchTerm
                        }
                        searchType={searchType}
                        categoryId={categoryId}
                        currentPage={meta.currentPage}
                        totalPages={meta.totalPages}
                        categoryAncestors={categoryAncestors}
                        categoryName={categoryName}
                        categoryIsClearance={isClearance}
                        isSaleRoute={isSaleRoute}
                        categoryMatch={meta.categoryMatch}
                      />
                    </ErrorBoundary>

                    {pageType !== 'browse' && !isCannedSearch && (
                      <ErrorBoundary>
                        <Feedback searchTerm={searchTerm} />
                      </ErrorBoundary>
                    )}
                  </>
                )}

                <ListerMeta />
              </Styled.Container>
            </Styled.SearchContent>
          )}

          {isCitrusBannersEnabled && (
            <aside className='xs-row xs-auto--negative'>
              <div className='xs-hidden lg-block lg-3' />
              <div className='xs-block xs-12 lg-9'>
                <CitrusBanners
                  searchTerm={searchTerm}
                  categoryName={allCategories}
                  placement={searchTerm ? 'search-below-grid-argos' : 'category-below-grid-argos'}
                />
              </div>
            </aside>
          )}

          {similarAiLinks && similarAiLinks.length > 0 && (
            <div className='xs-row xs-auto--negative'>
              <div className='xs-hidden lg-block lg-3' />
              <div className='xs-block xs-12 lg-9'>
                <SimilarAiPills similarAiLinksData={similarAiLinks} />
              </div>
            </div>
          )}

          {showSEOArea && (
            <Styled.PlpSeoText className='xs-row xs-auto--negative' data-test='seo-footer'>
              <div className='xs-hidden lg-block lg-3' />
              <div className='xs-block xs-12 lg-9'>{getContentAreas(seoArea)}</div>
            </Styled.PlpSeoText>
          )}
        </div>
      </ErrorBoundary>
    )
  }
}

Lister.defaultProps = {
  filters: [],
  searchTermSuggestions: [],
  brandSuggestions: [],
  categoryAncestors: [],
  contentAreas: [],
  appliedFilters: [],
  setClickOrigin: () => {}
}

Lister.propTypes = {
  searchTerm: PropTypes.string,
  categoryId: PropTypes.number,
  results: PropTypes.number,
  products: PropTypes.array,
  filters: PropTypes.array,
  isCannedSearch: PropTypes.bool,
  appliedFilters: PropTypes.array,
  appliedSortBy: PropTypes.object,
  meta: PropTypes.object,
  categoryName: PropTypes.string,
  categoryAncestors: PropTypes.array,
  subHeadingAncestorId: PropTypes.string,
  appliedFilterUriFragments: PropTypes.object,
  noResultsModalVisible: PropTypes.bool.isRequired,
  spellcheckedSearch: PropTypes.bool.isRequired,
  searchType: PropTypes.string,
  inputSearchTerm: PropTypes.string,
  searchTermSuggestions: PropTypes.array,
  brandSuggestions: PropTypes.array,
  pageType: PropTypes.string,
  seoArea: PropTypes.array,
  contentAreas: PropTypes.array,
  setClickOrigin: PropTypes.func
}

Lister.contextTypes = {
  navigateTo: PropTypes.func,
  loadingStart: PropTypes.func,
  reduxStore: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default Lister
