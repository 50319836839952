import { take, put, fork } from 'redux-saga/effects'
import Logger from '../../utils/Logger'
import {
  GET_NO_RESULTS_CONTENT,
  getNoResultsContentSuccess,
  getNoResultsContentFailure
} from '../../redux/no-results/getNoResultsContent'

const logger = new Logger()

// -------------------------------------------------
// WORKER SAGAS
// -------------------------------------------------
export function* getNoResultsContent(payload) {
  try {
    if (payload && !payload.error) {
      yield put(getNoResultsContentSuccess(payload))
    } else {
      yield put(getNoResultsContentFailure(payload))
    }
  } catch (error) {
    logger.error('sagasLogger', error, 'Unable to get No Results content')
  }
}

// -------------------------------------------------
// WATCHER SAGAS
// -------------------------------------------------
export function* watchGetNoResultsContent() {
  while (true) {
    const { payload } = yield take(GET_NO_RESULTS_CONTENT)
    yield fork(getNoResultsContent, payload)
  }
}
