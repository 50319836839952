import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary, Modal } from '@sainsburys-tech/bolt'
import * as Styled from '../Modals/styles'
import Template from './ErrorModal.template'

class ErrorModal extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.reloadPage = this.reloadPage.bind(this)
  }

  reloadPage() {
    this.context.loadingStart()
    window.location.reload()
  }

  render() {
    const lostConnection = this.props.errorModalReason === 'lostConnection'
    return (
      <ErrorBoundary>
        <Modal height='auto' show>
          <div className='error-modal text-center'>
            <Styled.Title>{Template.errorTitle(lostConnection)}</Styled.Title>
            {lostConnection && <p tabIndex='0'>{Template.tryAgainDesc}</p>}
            <Styled.ErrorButton tabIndex='0' onClick={this.reloadPage}>
              {Template.tryAgainBtn}
            </Styled.ErrorButton>
          </div>
        </Modal>
      </ErrorBoundary>
    )
  }
}

ErrorModal.contextTypes = {
  loadingStart: PropTypes.func
}

ErrorModal.propTypes = {
  errorModalReason: PropTypes.string
}

ErrorModal.defaultProps = {
  errorModalReason: ''
}

export default ErrorModal
