import React from 'react'
import { ErrorBoundary } from '@sainsburys-tech/bolt'
import loadable from '@loadable/component'
import deepcopy from 'lodash.clonedeep'
import ContentHelper from './ContentHelper'

const CMS = loadable.lib(() => import(/* webpackChunkName: "cms-components" */ '@sainsburys-tech/bolt-cms-components'))

function getContentAreas(contentAreas, topLevelCategoryId, onFindabilityClick, isPlp, isPlpPlus, isPlpPlusDescendant) {
  const areas = deepcopy(contentAreas)
  const contentComponents = []
  !!areas &&
    areas.forEach(area => {
      !!area.attributes &&
        area.attributes.forEach((currentArea, j) => {
          const contentData = {
            ...currentArea,
            categoryId: topLevelCategoryId,
            onLinkClick: onFindabilityClick,
            listerPageType: ContentHelper.getBrowseListerType(isPlp, isPlpPlus, isPlpPlusDescendant)
          }
          if (currentArea.type && currentArea.type !== 'M053') {
            const content = { ...{ ...currentArea, ...contentData } }
            contentComponents.push(
              <div className='search__cms-content' key={`${j + area.id}`}>
                <ErrorBoundary>
                  <CMS>
                    {({ GetComponent }) => {
                      return <GetComponent {...content} />
                    }}
                  </CMS>
                </ErrorBoundary>
              </div>
            )
          }
        })
    })
  return (
    <CMS>
      {({ default: FindabilityComponents, components }) => {
        return <FindabilityComponents components={components}>{contentComponents}</FindabilityComponents>
      }}
    </CMS>
  )
}

export { getContentAreas }
