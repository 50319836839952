import { connect } from 'react-redux'

import EmailWhenBackInStockModal from '../../components/EmailWhenBackInStockModal/EmailWhenBackInStockModal'
import { toggleEMWBISModal } from '../../redux/product/product'

const mapStateToProps = state => ({
  showEmailWhenBackInStockModal: state.product.emwbisModalVisible,
  productId: state.product.emwbisModalData.productId,
  postcode: state.application.availability.localisationPostcode
})

const mapDispatchToProps = dispatch => ({
  handleModalClose: () => dispatch(toggleEMWBISModal({ visible: false }))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailWhenBackInStockModal)
