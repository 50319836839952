import Search from '../components/Search'
import ListerPage from '../components/ListerPage/ListerPage'
import Browse from '../components/Browse'
import Clearance from '../components/Clearance'
import List from '../components/List'

const routes = [
  {
    path: '/sd/:term/:category(category:\\d+)?/:path([^?]*)?/(.*)?',
    component: Search
  },
  {
    path: '/list/:term/:category(category:\\d+)?/:path([^?]*)?/(.*)?',
    component: List
  },
  {
    path: '/search/:term/:category(category:\\d+)?/:path([^?]*)?/(.*)?',
    component: Search
  },
  {
    path: '/browse/:categoryTree*/:category(c:\\d+)/:path([^?]*)?/(.*)?',
    component: Browse
  },
  {
    path: '/clearance/:categoryTree*/:category(c:\\d+)/:path([^?]*)?/(.*)?',
    component: Clearance
  },
  {
    path: '/sale/:categoryTree*/:category(c:\\d+)/:path([^?]*)?/(.*)?',
    component: Clearance
  },
  {
    path: '*',
    component: ListerPage
  }
]

export default routes
