import executeCitrus from '../actions/executeCitrus'
import { get } from './PolyfillLodash'

export default function initCitrus(context) {
  const { product, category, application } = context.reduxStore.getState()
  const { searchTerm, appliedFilters, meta, products } = product
  const { pageType } = application

  if (get(products || {}, 'length')) {
    executeCitrus(context, {
      keyword: searchTerm,
      appliedFilters,
      appliedSorts: get(meta, 'appliedSorts'),
      currentPage: get(meta, 'currentPage'),
      categoryTree: get(category, 'ancestors'),
      currentCategory: {
        id: get(category, 'categoryId'),
        name: get(category, 'categoryName')
      },
      isPlp: get(category, 'isPlp') || get(category, 'isPlpPlus'),
      pageType
    })
  }
}
