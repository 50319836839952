import styled from 'styled-components'
import { Pill as BoltPill } from '@sainsburys-tech/bolt'

export const ListWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const ListWrapperItem = styled.li`
  margin: 0;
  padding: 0;
`

export const Pill = styled(BoltPill)`
  min-width: 94px;
  margin: 4px;
  font-weight: ${props => props.theme.font.weight.regular};
  background-color: ${props => props.theme.color.palette['brand-1-light']};
  color: ${props => props.theme.color.palette['brand-1-dark']};
  border: 1px solid ${props => props.theme.color.content.white};

  .filter-pill-item-label {
    /* font-size should be 15 px for the labels */
    font-weight: ${props => props.theme.font.weight.bold};
  }

  /* Quick fix for styling out active pills */
  &[data-test$='--active'],
  &:active {
    color: ${props => props.theme.color.palette['brand-1']};
    border: 1px solid ${props => props.theme.color.palette['brand-1']};
  }

  &:hover {
    color: ${props => props.theme.color.palette['brand-1']};
    background-color: ${props => props.theme.color.monochrome.light};
  }

  &:focus-visible {
    color: ${props => props.theme.color.palette['brand-1']};
    background-color: ${props => props.theme.color.monochrome.light};
    outline-style: auto;
    outline-color: ${props => props.theme.color.palette['brand-1']};
  }
`
