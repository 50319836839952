import React from 'react'
import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'

const CategoryListSkeleton = ({ height, width, speed, primaryColor, secondaryColor, isFullSize }) => (
  <div className={isFullSize ? 'xs-12' : 'xs-12 lg-9'}>
    <ContentLoader
      height={height}
      width={width}
      speed={speed}
      backgroundColor={primaryColor}
      foregroundColor={secondaryColor}
      uniqueKey='cl-content-loader'
      className='xs-hidden md-block'>
      <rect x='0' y='42' width='225' height='28' />
      <rect x='0' y='84' width='66.6%' height='194' />
      <rect x='68%' y='84' width='32%' height='194' />
      <rect x='0' y='290' width='100%' height='88' />
      <rect x='0' y='440' width='225' height='28' />
      <rect x='0' y='480' width='49%' height='260' />
      <rect x='51%' y='480' width='49%' height='260' />
      <rect x='0' y='780' width='225' height='28' />
      <rect x='0' y='820' width='31%' height='175' />
      <rect x='34.5%' y='820' width='31%' height='175' />
      <rect x='69%' y='820' width='31%' height='175' />
    </ContentLoader>
    <ContentLoader
      height={height}
      width={width}
      speed={speed}
      backgroundColor={primaryColor}
      foregroundColor={secondaryColor}
      uniqueKey='cl-mobile-content-loader'
      className='md-hidden'>
      <rect x='0' y='20' rx='0' ry='0' width='60%' height='30' />
      <rect x='0' y='60' rx='0' ry='0' width='100%' height='450' />
      <rect x='0' y='520' rx='0' ry='0' width='100%' height='150' />
    </ContentLoader>
  </div>
)

CategoryListSkeleton.defaultProps = {
  height: 1000,
  width: '100%',
  speed: 1,
  primaryColor: '#f3f3f3',
  secondaryColor: '#ecebeb',
  isFullSize: false
}

CategoryListSkeleton.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  speed: PropTypes.number,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  isFullSize: PropTypes.bool
}

export default CategoryListSkeleton
