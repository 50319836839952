import { getSearchPath, getBrowsePath } from '@sainsburys-tech/boltui-utils'
import { loadingStart } from '../redux/application/application'
import { BrowseHelper } from '../helpers/BrowseHelper'
import { resetAllFilters } from '../redux/product/product'
import { getSearchTermForSearchType, getSearchType } from '../helpers/ProductHelper'

export default function(context) {
  const productStore = context.reduxStore.getState().product
  const applicationStore = context.reduxStore.getState().application
  const { appliedSortBy, isCannedSearch, categoryMatchOverride } = productStore
  const searchTerm = getSearchTermForSearchType(productStore)
  context.reduxStore.dispatch(loadingStart())

  const pimId = getCategoryId(productStore.appliedFilters)

  if (searchTerm) {
    const url = getSearchPath(searchTerm, {
      sortBy: appliedSortBy,
      searchType: getSearchType(productStore),
      isCannedSearch,
      categoryId: pimId,
      categoryMatch: categoryMatchOverride
    })
    context.history.push(url)

    return
  }

  context.reduxStore.dispatch(resetAllFilters())

  const categoryStore = context.reduxStore.getState().category
  const { categoryId, ancestors, categoryName } = categoryStore
  const { isClearance, isSaleRoute } = applicationStore
  const params = {
    currentCatName: categoryName,
    ancestors,
    currentCatId: categoryId,
    sortBy: appliedSortBy,
    isClearance,
    isSale: isSaleRoute
  }

  const url = getBrowsePath(BrowseHelper.buildCorrectUrlBrowseParams(params))
  context.history.push(url)
}

const getCategoryId = filters => {
  for (let i = 0; i < filters.length; i++) {
    if (filters[i].id === 'category') {
      return filters[i].pimId
    }
  }
}
