import React from 'react'

export default {
  headerText: 'Email me when back in stock',
  bodyText: (
    <>
      <p>Type in your email address and we will email you when this product is back in stock.</p>
    </>
  ),
  buttonText: 'Email me',
  confirmationHeaderText: <>You&rsquo;re all set!</>,
  confirmationBodyText: <p>We&rsquo;ll be in touch as soon as this product comes back in stock.</p>
}
