import { useCookies } from 'react-cookie'

const getExpiryDateByDay = (days = 0) => {
  const date = new Date()
  const newDate = date.setDate(date.getDate() + days)
  return new Date(newDate)
}

const isCookieSet = (cookieName = '', value = '') => {
  const [cookies] = useCookies([cookieName])
  const hasCookieSet = cookies[cookieName] && cookies[cookieName] === value

  if (hasCookieSet === undefined || !hasCookieSet) return false

  return true
}

export { isCookieSet, getExpiryDateByDay }
